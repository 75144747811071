import React, { useState, useContext } from "react";
import styled from "styled-components";
import colors from "../common/Color";
import Title from "../common/Title";
import SubTitle from "./SubTitle";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import ErrorMessage from "../common/ErrorMessage";
import { StyledTextField } from "./BankItem";
import IconAdorn from "../common/IconAdornment";
import { ReactComponent as DeleteButton } from "../assets/Icons/Icons Black/ic_cross.svg";
import { ReactComponent as AddIcon } from "../assets/Icons/ic_add_circle.svg";
import IconButton from "@material-ui/core/IconButton";
import Button from "../common/WebButton";
import { Bank } from "./BankItem";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { ReactComponent as IconBaac } from "../assets/Icons Bank/ic_baac.svg";
import { ReactComponent as IconBnp } from "../assets/Icons Bank/ic_bnp.svg";
import { ReactComponent as IconBoa } from "../assets/Icons Bank/ic_boa.svg";
import { ReactComponent as IconBualuang } from "../assets/Icons Bank/ic_bualuang.svg";
import { ReactComponent as IconCacib } from "../assets/Icons Bank/ic_cacib.svg";
import { ReactComponent as IconCimb } from "../assets/Icons Bank/ic_cimb.svg";
import { ReactComponent as IconCiti } from "../assets/Icons Bank/ic_citi.svg";
import { ReactComponent as IconDb } from "../assets/Icons Bank/ic_db.svg";
import { ReactComponent as IconGhb } from "../assets/Icons Bank/ic_ghb.svg";
import { ReactComponent as IconGsb } from "../assets/Icons Bank/ic_gsb.svg";
import { ReactComponent as IconHsbc } from "../assets/Icons Bank/ic_hsbc.svg";
import { ReactComponent as IconIcbc } from "../assets/Icons Bank/ic_icbc.svg";
import { ReactComponent as IconIsbt } from "../assets/Icons Bank/ic_isbt.svg";
import { ReactComponent as IconJpm } from "../assets/Icons Bank/ic_jpm.svg";
import { ReactComponent as IconKbank } from "../assets/Icons Bank/ic_kbank.svg";
import { ReactComponent as IconKkp } from "../assets/Icons Bank/ic_kkp.svg";
import { ReactComponent as IconKrungsri } from "../assets/Icons Bank/ic_krungsri.svg";
import { ReactComponent as IconKtb } from "../assets/Icons Bank/ic_ktb.svg";
import { ReactComponent as IconLh } from "../assets/Icons Bank/ic_lh.svg";
import { ReactComponent as IconMb } from "../assets/Icons Bank/ic_mb.svg";
import { ReactComponent as IconMega } from "../assets/Icons Bank/ic_mega.svg";
import { ReactComponent as IconMufg } from "../assets/Icons Bank/ic_mufg.svg";
import { ReactComponent as IconRbs } from "../assets/Icons Bank/ic_rbs.svg";
import { ReactComponent as IconSc } from "../assets/Icons Bank/ic_sc.svg";
import { ReactComponent as IconScb } from "../assets/Icons Bank/ic_scb.svg";
import { ReactComponent as IconSmbc } from "../assets/Icons Bank/ic_smbc.svg";
import { ReactComponent as IconTbank } from "../assets/Icons Bank/ic_tbank.svg";
import { ReactComponent as IconTcd } from "../assets/Icons Bank/ic_tcd.svg";
import { ReactComponent as IconTisco } from "../assets/Icons Bank/ic_tisco.svg";
import { ReactComponent as IconTmb } from "../assets/Icons Bank/ic_tmb.svg";
import { ReactComponent as IconUob } from "../assets/Icons Bank/ic_uob.svg";
import BackButton from "../common/components/BackButtonBlue";
import { StoreContext } from "../Context/Store";
import {
  ModalContainer,
  StyledModal,
  MobileDiv,
  IcCross,
  Root,
  GridButtonMob,
  Container,
  NavBar,
  NavContainer,
  Logo
} from "./EditAddressModal";

const ModalBox = styled.div`
  width: 47.13rem;
  background: ${colors.white};
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  margin: auto;
  @media (max-width: 1024px) {
    display: none;
  }
`;
const GridButton = styled(Grid)`
  && {
    padding: 3rem 0 2.5rem;
  }
`;
const Box = styled.div`
  width: 100%;
  height: 8.5rem;
  background: ${colors.white};
  border: 0.06rem solid #ebedfa;
  border-radius: 0.5rem;
  margin-top: 1rem;
  @media (max-width: 960px) {
    height: 14.75rem;
  }
`;
const DeleteIcon = styled(DeleteButton)`
  width: 1.5rem;
  height: 1.5rem;
`;
const IconBtn = styled(IconButton)`
  && {
    padding: 0;
  }
`;
const Blank = styled.div`
  width: 1.5rem;
  height: 1.5rem;
`;

const AddButton = styled(Button)`
  && {
    width: 100%;
    height: 4.5rem;
    border-radius: 0.25rem;
    border: 0.06rem solid #ebedfa;
    background: ${colors.white};
    display: flex;
    align-items: center;
    cursor: pointer;
    &:hover {
      background: ${colors.surfaceGray};
    }
  }
`;
const AddingIcon = styled(AddIcon)`
  font-size: 1.46rem;
  fill: ${colors.primary};
  margin-right: 1.14rem;
`;
const AddText = styled.div`
  font-family: "SukhumvitSet-Semibold";
  font-size: 0.88rem;
  letter-spacing: 0.08rem;
  line-height: 1rem;
  color: ${colors.primary};
  align-self: center;
`;
const BtnLayout = styled.div`
  display: flex;
  padding: 1rem 1.5rem;
  margin-right: auto;
`;
const ItemText = styled(ListItemText)`
  & .MuiTypography-body1 {
    font-family: "Sarabun-Regular";
    color: ${colors.black};
    opacity: 0.87;
    letter-spacing: 0.03rem;
    line-height: 1.75rem;
  }
`;

export default function EditBankModal(props) {
  const { openBank, handleCloseBank } = props;
  const { bankaccount } = useContext(StoreContext);
  const [counter, setCounter] = useState(bankaccount[0].length);
  const [bankData, setBankData] = useState(bankaccount[0]);

  const editBankData = async (item, index) => {
    var newData = [...bankData];
    newData[index] = item;
    setBankData(newData);
  };

  const addBank = () => {
    setBankData(prevBankData => [
      ...prevBankData,
      { bankName: "ธนาคารกสิกรไทย", bankNumber: "" }
    ]);
    setCounter(prevCounter => prevCounter + 1);
  };

  const removeBank = index => () => {
    setBankData(prevBankData => [
      ...prevBankData.filter((item, i) => i !== index)
    ]);
    setCounter(prevCounter => prevCounter - 1);
  };

  const handleChangeBank = (event, index) => {
    editBankData(
      {
        bankName: event.target.value,
        bankNumber: bankData[index].bankNumber
      },
      index
    );
  };

  const handleChangeBankData = (event, index) => {
    editBankData(
      {
        bankName: bankData[index].bankName,
        bankNumber: event.target.value
      },
      index
    );
  };

  const submitChangeData = () => {
    if (!props.formProps.errors.bankNumber) {
      bankaccount[1](bankData);
      handleCloseBank();
    }
  };

  const cancelChangeData = () => {
    setCounter(bankaccount[0].length);
    setBankData(bankaccount[0]);
    handleCloseBank();
  };

  return (
    <StyledModal
      id="root-modal"
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={openBank}
      onClose={handleCloseBank}
      disableEnforceFocus={true}
    >
      <ModalContainer id="container-modal">
        <ModalBox id="modal-box">
          <Grid id="grid-flex-end" container justify="flex-end">
            <IcCross id="close-ic" onClick={handleCloseBank} />
          </Grid>
          <Grid id="grid-center" container justify="center">
            <Grid id="grid-title" item xs={10}>
              <Title id="title">แก้ไขบัญชีธนาคาร</Title>
            </Grid>
            <Grid id="grid-subtitle" item xs={10}>
              <SubTitle id="subtitle" props="edit" />
            </Grid>
            <Grid id="grid-center" container spacing={2} justify="center">
              <Grid id="grid-item" item xs={10}>
                {bankData.map((items, indexes) => (
                  <Box id="box" key={indexes}>
                    <Grid
                      id="grid-container"
                      container
                      style={{ padding: "1rem 1.5rem" }}
                    >
                      <Grid
                        id="grid-flex-end"
                        container
                        justify="flex-end"
                        item
                        xs={12}
                      >
                        {(indexes === 0 && counter === 1) ||
                        (indexes > 2 && counter === 1) ? (
                          <IconBtn id="btn-disabled" disabled>
                            <Blank id="blank" />
                          </IconBtn>
                        ) : (
                          <IconBtn id="ic-btn" onClick={removeBank(indexes)}>
                            <DeleteIcon id="delete-ic" />
                          </IconBtn>
                        )}
                      </Grid>
                      <Grid id="grid-item" item md={6} xs={12}>
                        <StyledTextField
                          id="bankName"
                          primary="true"
                          name="bankName"
                          select
                          label="ธนาคาร"
                          value={bankData[indexes].bankName}
                          onChange={e => handleChangeBank(e, indexes)}
                          margin="normal"
                          variant="outlined"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment id="adornment" position="start">
                                <IconAdorn id="disabled-ic" disabled />
                              </InputAdornment>
                            )
                          }}
                        >
                          {Bank.map((item, index) => (
                            <MenuItem
                              id="menu-item"
                              key={index}
                              value={item.option}
                              style={{ display: "flex" }}
                            >
                              <ListItemIcon id="list-item-ic">
                                {index === 0 ? (
                                  <IconKbank id="ic-kb" />
                                ) : index === 1 ? (
                                  <IconBualuang id="ic-bl" />
                                ) : index === 2 ? (
                                  <IconScb id="ic-scb" />
                                ) : index === 3 ? (
                                  <IconTmb id="ic-tmb" />
                                ) : index === 4 ? (
                                  <IconKrungsri id="ic-ks" />
                                ) : index === 5 ? (
                                  <IconKtb id="ic-ktb" />
                                ) : index === 6 ? (
                                  <IconKkp id="ic-kkp" />
                                ) : index === 7 ? (
                                  <IconUob id="ic-uob" />
                                ) : index === 8 ? (
                                  <IconGsb id="ic-gsb" />
                                ) : index === 9 ? (
                                  <IconBaac id="ic-baac" />
                                ) : index === 10 ? (
                                  <IconTbank id="ic-tbank" />
                                ) : index === 11 ? (
                                  <IconIsbt id="ic-isbt" />
                                ) : index === 12 ? (
                                  <IconTisco id="ic-tisco" />
                                ) : index === 13 ? (
                                  <IconHsbc id="ic-hsbc" />
                                ) : index === 14 ? (
                                  <IconSc id="ic-sc" />
                                ) : index === 15 ? (
                                  <IconCiti id="ic-citi" />
                                ) : index === 16 ? (
                                  <IconGhb id="ic-ghb" />
                                ) : index === 17 ? (
                                  <IconSmbc id="ic-smbc" />
                                ) : index === 18 ? (
                                  <IconCimb id="ic-cimb" />
                                ) : index === 19 ? (
                                  <IconMega id="ic-mega" />
                                ) : index === 20 ? (
                                  <IconBoa id="ic-boa" />
                                ) : index === 21 ? (
                                  <IconMb id="ic-mb" />
                                ) : index === 22 ? (
                                  <IconIcbc id="ic-icbc" />
                                ) : index === 23 ? (
                                  <IconTcd id="ic-tcd" />
                                ) : index === 24 ? (
                                  <IconLh id="ic-lh" />
                                ) : index === 25 ? (
                                  <IconBnp id="ic-bnp" />
                                ) : index === 26 ? (
                                  <IconDb id="ic-db" />
                                ) : index === 27 ? (
                                  <IconCacib id="ic-cacib" />
                                ) : index === 28 ? (
                                  <IconRbs id="ic-rbs" />
                                ) : index === 29 ? (
                                  <IconJpm id="ic-jpm" />
                                ) : index === 30 ? (
                                  <IconMufg id="ic-mufg" />
                                ) : (
                                  ""
                                )}
                              </ListItemIcon>
                              <ItemText id="item-txt">{item.option}</ItemText>
                            </MenuItem>
                          ))}
                        </StyledTextField>
                      </Grid>
                      <Grid id="grid-item" item md={6} xs={12}>
                        <StyledTextField
                          id={indexes.toString() + " Desktop"}
                          label="เลขที่บัญชี"
                          name="bankNumber"
                          placeholder="เลขที่บัญชี"
                          margin="normal"
                          variant="outlined"
                          inputRef={props.formProps.register}
                          error={!!props.formProps.errors.bankNumber}
                          value={bankData[indexes].bankNumber}
                          onChange={e => handleChangeBankData(e, indexes)}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment id="adornment" position="start">
                                <IconAdorn id="disabled-ic" disabled />
                              </InputAdornment>
                            )
                          }}
                        />
                        {props.formProps.errors.bankNumber && (
                          <ErrorMessage id="err-msg">
                            {props.formProps.errors.bankNumber.message}
                          </ErrorMessage>
                        )}
                      </Grid>
                    </Grid>
                  </Box>
                ))}
              </Grid>
              {counter === 3 ? (
                ""
              ) : (
                <Grid item xs={10} id="grid-item">
                  <AddButton id="add-btn" onClick={addBank}>
                    <BtnLayout id="btn-layout">
                      <AddingIcon id="add-ic" />
                      <AddText id="text">เพิ่มบัญชี</AddText>
                    </BtnLayout>
                  </AddButton>
                </Grid>
              )}
            </Grid>
          </Grid>
          <GridButton id="grid-btn" container item xs={11} justify="flex-end">
            <Button
              id="cancel-btn"
              width="7.25rem"
              height="2.75rem"
              style={{ marginRight: "1rem" }}
              onClick={cancelChangeData}
            >
              ยกเลิก
            </Button>
            <Button
              id="save-btn"
              primary="true"
              width="7.25rem"
              height="2.75rem"
              onClick={submitChangeData}
            >
              บันทึก
            </Button>
          </GridButton>
        </ModalBox>
        <MobileDiv id="mobile-div">
          <Root id="mobile-root">
            <NavBar id="nav-bar">
              <NavContainer id="nav-container">
                <div id="btn-div" onClick={handleCloseBank}>
                  <BackButton id="back-btn" />
                </div>
                <Logo id="logo" />
              </NavContainer>
            </NavBar>
            <Container id="mobile-container">
              <Grid id="grid-flex-start" container justify="flex-start">
                <Grid id="grid-title" item xs={12}>
                  <Title id="title">แก้ไขบัญชีธนาคาร</Title>
                </Grid>
                <Grid id="grid-subtitle" item xs={12}>
                  <SubTitle id="subtitle" />
                </Grid>
                <Grid id="grid-container" container spacing={2}>
                  <Grid id="grid-item" item xs={12}>
                    {bankData.map((items, indexes) => (
                      <Box key={indexes} id="box">
                        <Grid
                          id="grid-container"
                          container
                          style={{ padding: "1rem 1.5rem" }}
                        >
                          <Grid
                            id="grid-flex-end"
                            container
                            justify="flex-end"
                            item
                            xs={12}
                          >
                            {(indexes === 0 && counter === 1) ||
                            (indexes > 2 && counter === 1) ? (
                              <IconBtn id="disabled-ic" disabled>
                                <Blank id="blank" />
                              </IconBtn>
                            ) : (
                              <IconBtn
                                id="ic-btn"
                                onClick={removeBank(indexes)}
                              >
                                <DeleteIcon id="delete-ic" />
                              </IconBtn>
                            )}
                          </Grid>
                          <Grid id="grid-item" item md={6} xs={12}>
                            <StyledTextField
                              id="bankName"
                              primary="true"
                              name="bankName"
                              select
                              label="ธนาคาร"
                              value={bankData[indexes].bankName}
                              onChange={e => handleChangeBank(e, indexes)}
                              margin="normal"
                              variant="outlined"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment
                                    id="adornment"
                                    position="start"
                                  >
                                    <IconAdorn id="disabled-ic" disabled />
                                  </InputAdornment>
                                )
                              }}
                            >
                              {Bank.map((item, index) => (
                                <MenuItem
                                  id="menu-item"
                                  key={index}
                                  value={item.option}
                                  style={{ display: "flex" }}
                                >
                                  <ListItemIcon id="list-item-ic">
                                    {index === 0 ? (
                                      <IconKbank id="ic-kb" />
                                    ) : index === 1 ? (
                                      <IconBualuang id="ic-bl" />
                                    ) : index === 2 ? (
                                      <IconScb id="ic-scb" />
                                    ) : index === 3 ? (
                                      <IconTmb id="ic-tmb" />
                                    ) : index === 4 ? (
                                      <IconKrungsri id="ic-ks" />
                                    ) : index === 5 ? (
                                      <IconKtb id="ic-ktb" />
                                    ) : index === 6 ? (
                                      <IconKkp id="ic-kkp" />
                                    ) : index === 7 ? (
                                      <IconUob id="ic-uob" />
                                    ) : index === 8 ? (
                                      <IconGsb id="ic-gsb" />
                                    ) : index === 9 ? (
                                      <IconBaac id="ic-baac" />
                                    ) : index === 10 ? (
                                      <IconTbank id="ic-tbank" />
                                    ) : index === 11 ? (
                                      <IconIsbt id="ic-isbt" />
                                    ) : index === 12 ? (
                                      <IconTisco id="ic-tisco" />
                                    ) : index === 13 ? (
                                      <IconHsbc id="ic-hsbc" />
                                    ) : index === 14 ? (
                                      <IconSc id="ic-sc" />
                                    ) : index === 15 ? (
                                      <IconCiti id="ic-citi" />
                                    ) : index === 16 ? (
                                      <IconGhb id="ic-ghb" />
                                    ) : index === 17 ? (
                                      <IconSmbc id="ic-smbc" />
                                    ) : index === 18 ? (
                                      <IconCimb id="ic-cimb" />
                                    ) : index === 19 ? (
                                      <IconMega id="ic-mega" />
                                    ) : index === 20 ? (
                                      <IconBoa id="ic-boa" />
                                    ) : index === 21 ? (
                                      <IconMb id="ic-mb" />
                                    ) : index === 22 ? (
                                      <IconIcbc id="ic-icbc" />
                                    ) : index === 23 ? (
                                      <IconTcd id="ic-tcd" />
                                    ) : index === 24 ? (
                                      <IconLh id="ic-lh" />
                                    ) : index === 25 ? (
                                      <IconBnp id="ic-bnp" />
                                    ) : index === 26 ? (
                                      <IconDb id="ic-db" />
                                    ) : index === 27 ? (
                                      <IconCacib id="ic-cacib" />
                                    ) : index === 28 ? (
                                      <IconRbs id="ic-rbs" />
                                    ) : index === 29 ? (
                                      <IconJpm id="ic-jpm" />
                                    ) : index === 30 ? (
                                      <IconMufg id="ic-mufg" />
                                    ) : (
                                      ""
                                    )}
                                  </ListItemIcon>
                                  <ItemText id="item-txt">
                                    {item.option}
                                  </ItemText>
                                </MenuItem>
                              ))}
                            </StyledTextField>
                          </Grid>
                          <Grid id="grid-item" item md={6} xs={12}>
                            <StyledTextField
                              id={indexes.toString() + " Mobile"}
                              label="เลขที่บัญชี"
                              name="bankNumber"
                              placeholder="เลขที่บัญชี"
                              margin="normal"
                              variant="outlined"
                              inputRef={props.formProps.register}
                              error={!!props.formProps.errors.bankNumber}
                              value={bankData[indexes].bankNumber}
                              onChange={e => handleChangeBankData(e, indexes)}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment
                                    id="adornment"
                                    position="start"
                                  >
                                    <IconAdorn id="disabled-ic" disabled />
                                  </InputAdornment>
                                )
                              }}
                            />
                            {props.formProps.errors.bankNumber && (
                              <ErrorMessage id="err-msg">
                                {props.formProps.errors.bankNumber.message}
                              </ErrorMessage>
                            )}
                          </Grid>
                        </Grid>
                      </Box>
                    ))}
                  </Grid>
                  {counter === 3 ? (
                    ""
                  ) : (
                    <Grid id="grid-item" item xs={12}>
                      <AddButton id="add-btn" onClick={addBank}>
                        <BtnLayout id="btn-layout">
                          <AddingIcon id="add-ic" />
                          <AddText id="text">เพิ่มบัญชี</AddText>
                        </BtnLayout>
                      </AddButton>
                    </Grid>
                  )}
                </Grid>
                <GridButtonMob id="grid-btn-mb" container item>
                  <Grid id="grid-item" item xs={12}>
                    <Button
                      id="save-btn"
                      primary="true"
                      width="100%"
                      height="2.75rem"
                      style={{ marginBottom: "1rem" }}
                      onClick={submitChangeData}
                    >
                      บันทึก
                    </Button>
                  </Grid>
                  <Grid id="grid-item" item xs={12}>
                    <Button
                      id="cancel-btn"
                      width="100%"
                      height="2.75rem"
                      onClick={cancelChangeData}
                    >
                      ยกเลิก
                    </Button>
                  </Grid>
                </GridButtonMob>
              </Grid>
            </Container>
          </Root>
        </MobileDiv>
      </ModalContainer>
    </StyledModal>
  );
}
