import React from "react";
import styled from "styled-components";
import Button from "@material-ui/core/Button";
import colors from "../common/Color";

const PricingBox = styled.div`
  margin-left: 9rem;
  width: 21.69rem;
  height: 26.44rem;
  background-color: ${colors.white};
  border-radius: 1.06rem;
  border: 0.06rem solid #d7d7d7;
  box-shadow: 0 0.13rem 1.88rem 0 rgba(127, 127, 127, 0.08);
  position: relative;
  overflow: hidden;
  @media (max-width: 1024px) {
    margin: auto auto 2rem;
    width: 17rem;
    height: 20.56rem;
  }
`;
const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 26.44rem;
  @media (max-width: 1024px) {
    height: 20.56rem;
  }
`;
const SpecialPriceBadge = styled.div`
  width: 8.63rem;
  height: 2rem;
  position: absolute;
  background: ${colors.primary};
  text-align: center;
  transform: rotate(45deg);
  font-family: "SukhumvitSet-Text";
  font-size: 0.88rem;
  letter-spacing: 0.02rem;
  line-height: 1.25rem;
  color: ${colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  top: 1.25rem;
  right: -2rem;
  transition: opacity 0.4s ease-in-out;
`;
const PricePerMonth = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: center;
`;
const PriceText = styled.div`
  font-family: "SukhumvitSet-Text";
  font-size: 3rem;
  letter-spacing: 0rem;
  line-height: 4.75rem;
  color: ${colors.black};
  text-align: center;
  opacity: 0.87;
  @media (max-width: 1024px) {
    font-size: 2.13rem;
    letter-spacing: 0.02rem;
    line-height: 3.38rem;
  }
`;
const PerMonthText = styled.div`
  font-family: "SukhumvitSet-SemiBold";
  font-size: 1.25rem;
  letter-spacing: 0.02rem;
  line-height: 1.94rem;
  color: ${colors.black};
  opacity: 0.6;
  margin-left: 0.5rem;
`;
const LineThroughText = styled.div`
  font-family: "SukhumvitSet-Text";
  font-size: 1rem;
  letter-spacing: 0.03rem;
  line-height: 1.75rem;
  color: rgba(0, 0, 0, 0.54);
  text-decoration: line-through;
`;
const PriceContentText = styled.div`
  font-family: "SukhumvitSet-Text";
  font-size: 1rem;
  letter-spacing: 0.03rem;
  line-height: 1.75rem;
  color: ${colors.black};
  opacity: 0.6;
`;
const PriceContent = styled.div`
  display: flex;
  text-align: center;
`;
const BuyButton = styled(Button)`
  && {
    margin: 0.5rem;
    width: 14.06rem;
    height: 2.75rem;
    background-color: ${props =>
      props.secondary ? colors.white : colors.primary};
    color: ${props => (props.secondary ? colors.primary : colors.white)};
    border: ${props => (props.secondary ? "0.08rem solid #3b54d4" : "none")};
    font-family: "SukhumvitSet-Semibold";
    font-size: 0.88rem;
    letter-spacing: 0.08rem;
    line-height: 1rem;
    box-shadow: ${props =>
      props.secondary ? "" : "0 0.06rem 0.06rem 0 rgba(0,0,0,0.50)"};
    &:hover {
      background: ${props => (props.secondary ? "" : colors.primaryDark)};
    }
  }
  &.MuiButton-root.Mui-disabled {
    color: #939394;
    background-color: #DEDEE0;
  }
`;
const Div = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem;
`;

export default function PricingBoxDetail(props) {
  const { oneMonth, fourMonth, twelveMonth } = props;
  return (
    <PricingBox id="pricing-box">
      {twelveMonth === true ? (
        <SpecialPriceBadge id="spp-badge">ลด 30%</SpecialPriceBadge>
      ) : (
        <SpecialPriceBadge
          id="spp-badge"
          style={{ opacity: 0 }}
        ></SpecialPriceBadge>
      )}
      <Container id="container">
        <PricePerMonth id="price">
          {oneMonth === true ? (
            <PriceText id="price-txt">99 บาท</PriceText>
          ) : fourMonth === true ? (
            <PriceText id="price-txt">62 บาท</PriceText>
          ) : twelveMonth === true ? (
            <PriceText id="price-txt">34 บาท</PriceText>
          ) : (
            ""
          )}
          <PerMonthText id="permonth">/ เดือน</PerMonthText>
        </PricePerMonth>
        <Div id="div">
          <PriceContent id="price-content">
            {twelveMonth === true ? (
              <LineThroughText id="line-through">590 บาท</LineThroughText>
            ) : fourMonth === true ? (
              <LineThroughText id="line-through">386 บาท</LineThroughText>
            ) : (
              ""
            )}
            {twelveMonth === true ? (
              <PriceContentText id="price-ct-txt">
                &ensp; 410 บาท ต่อปี
              </PriceContentText>
            ) : fourMonth === true ? (
              <PriceContentText id="price-ct-txt">
                &ensp; 4 เดือน เพียง 249 บาท
              </PriceContentText>
            ) : (
              ""
            )}
          </PriceContent>
          <PriceContent id="price-content">
            {oneMonth === true ? (
              <PriceContentText id="price-ct-txt">
                สามารถใช้บริการ <br />
                ทั้งหมดของ VerME ได้
              </PriceContentText>
            ) : (
              <PriceContentText id="price-ct-txt">
                ใช้บริการของ VerME ได้ทั้งหมด <br />
                (สำหรับการซื้อครั้งแรกเท่านั้น)
              </PriceContentText>
            )}
          </PriceContent>
        </Div>
        <BuyButton id="buy-btn" href="/prepare-before-register" target="_blank">
          ทดลองใช้บริการ ฟรี!
        </BuyButton>
      </Container>
    </PricingBox>
  );
}
