import React, { useState, createContext } from "react";

//สร้าง Store Context
export const RegisterContext = createContext({});

export const RegisterContextProvider = ({ children }) => {
  const [contact, setContact] = useState({
    firstName: undefined,
    lastName: undefined,
    email: undefined,
    phone: undefined
  });

  const [information, setInformation] = useState({
    vermeId: undefined,
    description: undefined
  });

  const [prefix, setPrefix] = useState("นาย");
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [reportId, setReportId] = useState("");
  const [reportVmId, setReportVmId] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState([]);
  const [checkImage, setCheckImage] = useState(true);
  const [checkErrorPhone, setCheckErrorPhone] = useState(false);
  const [checkPolicy, setCheckPolicy] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [count, setCount] = useState(30);
  const [open, setOpen] = useState(false);

  const store = {
    contact: [contact, setContact],
    information: [information, setInformation],
    prefix: [prefix, setPrefix],
    name: [name, setName],
    surname: [surname, setSurname],
    email: [email, setEmail],
    phone: [phone, setPhone],
    reportId: [reportId, setReportId],
    reportVmId: [reportVmId, setReportVmId],
    description: [description, setDescription],
    image: [image, setImage],
    checkImage: [checkImage, setCheckImage],
    checkErrorPhone: [checkErrorPhone, setCheckErrorPhone],
    checkPolicy: [checkPolicy, setCheckPolicy],
    loading: [loading, setLoading],
    errorOpen: [errorOpen, setErrorOpen],
    count: [count, setCount],
    open: [open, setOpen]
  };

  return (
    <RegisterContext.Provider value={store}>
      {children}
    </RegisterContext.Provider>
  );
};
