import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import LogoVerme from "../assets/Logo/svg/logo_verme_small_dark_color.svg";
import LogoFb from "../assets/Icons/Icons Black/ic_social_facebook.svg";

const styles = theme => ({
  footer: {
    left: 0,
    bottom: 0,
    width: "100%",
    display: "flex",
    flexWrap: "nowrap",
    position: "unset",
    height: "4.5rem",
    alignItems: "center",
    [theme.breakpoints.down(1025)]: {
      position: "inherit"
    }
  },
  footerHome: {
    left: 0,
    bottom: 0,
    width: "100%",
    display: "flex",
    flexWrap: "nowrap",
    position: "unset",
    height: "4.5rem",
    alignItems: "center",
    backgroundColor: "#ebedfa",
    [theme.breakpoints.down(1025)]: {
      position: "inherit"
    }
  },
  credit: {
    marginLeft: "auto",
    marginRight: "auto",
    display: "flex"
  },
  creditText: {
    color: "#696969",
    textAlign: "center",
    letterSpacing: "0.03rem",
    fontSize: "0.75rem",
    fontFamily: "Sarabun"
  },
  fb: {
    width: "1.43rem",
    height: "1.43rem",
    padding: "0.5rem",
    opacity: "0.4"
  },
  logoverme: {
    opacity: "0.5",
    width: "2.75rem",
    height: "0.75rem",
    marginLeft: theme.spacing(0.5),
    alignSelf: "center"
  },
  rightlogo: {
    marginRight: "2rem",
    [theme.breakpoints.down(1025)]: {
      marginRight: "0.75rem"
    }
  },
  leftdiv: {
    width: "1.25rem",
    padding: "0.5rem",
    marginLeft: "2rem",
    [theme.breakpoints.down(1025)]: {
      marginLeft: "0.75rem"
    }
  }
});

class BottomCredit extends Component {
  render() {
    const { classes } = this.props;
    const checkPath = window.location.pathname;
    return (
      <div className={checkPath === "/" ? classes.footerHome : classes.footer}>
        <div className={classes.leftdiv}></div>
        <div className={classes.credit}>
          <div className={classes.creditText}>Powered & Secured by</div>
          <img src={LogoVerme} alt="logoverme" className={classes.logoverme} />
        </div>
        <div className={classes.rightlogo}>
          <a
            href="https://www.facebook.com/verme.me"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={LogoFb} alt="logofb" className={classes.fb} />
          </a>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(BottomCredit);
