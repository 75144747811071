import React, { useState } from "react";
import colors from "../common/Color";
import styled from "styled-components";
import Button from "@material-ui/core/Button";
import TermPolicy from "./TermPolicyOfPromotion";
import Grid from "@material-ui/core/Grid";
import { ReactComponent as LogoVerme } from "../assets/Logo/logo_verme_dark.svg";
import { ReactComponent as IcCross } from "../assets/Icons/Icons Black/ic_cross.svg";
import StyledTextField from "../common/StyledTextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import ErrorMessage from "../common/ErrorMessage";
import { postCovidPromotion } from "../actions/action";
import { CircularProgress } from "@material-ui/core";
import useForm from "react-hook-form";
import * as yup from "yup";
import { Link } from "react-router-dom";
import ErrorModal from "../common/ErrorModal";

const Root = styled.div`
  padding-top: 4rem;
  min-height: calc(100vh - 4rem);
  width: 100vw;
  background: ${colors.surfaceGray};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  top: 0;
  width: 100%;
  height: 4rem;
  background-color: ${colors.white};
  position: fixed;
  z-index: 10;
  /* @media (max-width: 1024px) {
    display: none;
  } */
`;

const Nav = styled.div`
  width: 90%;
  height: 4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 5%;
`;

const BtnDiv = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
`;

const Logo = styled(LogoVerme)`
  width: 6.13rem;
  height: 1.63rem;
  cursor: pointer;
`;

const StyledButton = styled(Button)`
  && {
    font-family: "Sarabun-SemiBold";
    font-size: 0.88rem;
    letter-spacing: 0.08rem;
    line-height: 1rem;
    color: ${colors.primary};
    display: flex;
  }
`;

const Box = styled.div`
  border-radius: 0.5rem;
  background-color: ${colors.surfaceGray};
  padding: 4rem 0;
  @media (max-width: 1025px) {
    width: 65.7vw;
  }
  @media (max-width: 768px) {
    width: 90vw;
  }
`;

const Rect = styled.div`
  border-radius: 0.75rem;
  background-color: ${colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Content = styled.form`
  padding: 2.5rem 3rem;
  @media (max-width: 768px) {
    padding: 0;
  }
`;

const HeaderText = styled.div`
  font-family: "SukhumvitSet-Text";
  font-size: 1.5rem;
  color: ${colors.primary};
  text-align: center;
  margin-bottom: 1rem;
`;

const TextInputAdornment = styled.span`
  margin-left: 1rem;
  color: ${colors.black};
`;

const Submit = styled(Button)`
  && {
    width: 20.25rem;
    height: 2.63rem;
    background-color: ${colors.primary};
    font-family: "SukhumvitSet-Bold";
    font-size: 0.88rem;
    line-height: 1rem;
    letter-spacing: 0.08rem;
    color: ${colors.white};
    box-shadow: 0 0.063rem 0.063rem 0 rgba(0, 0, 0, 0.5);
    &:hover {
      background-color: ${colors.primaryDark};
    }
    @media (max-width: 768px) {
      width: 100%;
    }
  }
`;

const StyledTextFieldCustum = styled(StyledTextField)`
  && {
    width: 20.25rem;
    @media (max-width: 768px) {
      width: 100%;
    }
  }
`;

const LoadingScreen = styled.div`
  height: 100vh;
  width: 100vw;
  z-index: 100;
  background: rgba(255, 255, 255, 0.5);
  position: fixed;
  top: 0;
`;

const promotionSchema = yup.object().shape({
  vermeId: yup
    .string()
    .required("กรุณากรอก VerME ID")
    .min(6, "กรุณากรอก VerME ID ให้ครบถ้วน")
    .max(6, "VerME ID จะต้องไม่เกิน 6 หลัก"),
  thaiName: yup.string().required("กรุณากรอกชื่อ")
});

export default function PromotionPage() {
  const [termModal, setTermModal] = useState(true);
  const [vmID, setVmID] = useState("");
  const [nameThai, setNameThai] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [textMessage, setTextMessage] = useState("");

  const handleTerm = () => {
    setTermModal(false);
  };

  const handleErrorClose = () => {
    setErrorOpen(false);
  };

  const submitForm = async () => {
    setLoading(true);
    const data = {
      vm_id: "vm-" + vmID.toLocaleLowerCase(),
      name: {
        TH: nameThai
      },
      promotionName: "COVID-19",
      value: "give free 3 months"
    };

    try {
      await postCovidPromotion(data);
      setLoading(false);
    } catch (err) {
      console.log(err.response);
      setLoading(false);
      if (err.response.data === "VerME ID not found") {
        setTextMessage(err.response.data);
      } else {
        setTextMessage(err.response.data.error);
      }

      setErrorOpen(true);
    }
  };

  const promotionForm = useForm({
    validationSchema: promotionSchema
  });

  return (
    <Root id="root">
      {loading ? <LoadingScreen id="loading-screen" /> : " "}
      <ErrorModal
        id="err-modal"
        textMessage={textMessage}
        handleErrorClose={handleErrorClose}
        errorOpen={errorOpen}
      />
      <Container
        id="container"
        style={{ boxShadow: "0 0.13rem 0.25rem 0 rgba(0,0,0,0.03)" }}
      >
        <Nav id="nav">
          <Link id="link" to="/">
            <Logo id="logo" />
          </Link>
          <BtnDiv id="btn-div" to="/">
            <StyledButton id="btn">
              <IcCross id="cross-ic" />
              ยกเลิก
            </StyledButton>
          </BtnDiv>
        </Nav>
      </Container>
      {termModal ? (
        <TermPolicy id="term-policy" handleTerm={handleTerm} />
      ) : (
        <div id="div-promo">
          <Grid
            id="grid-container"
            container
            direction="column"
            justify="center"
            alignItems="center"
          >
            <Grid id="grid-item" item xs={12} sm={12} md={12} lg={12}>
              <Box id="box">
                <Rect id="rect">
                  <Content
                    id="content"
                    onSubmit={promotionForm.handleSubmit(submitForm)}
                  >
                    <HeaderText id="header-txt">
                      กรอกข้อมูลเพื่อรับโปรโมชัน
                    </HeaderText>
                    <div id="div-textfield" style={{ marginBottom: "2.19rem" }}>
                      <StyledTextFieldCustum
                        id="vermeId"
                        name="vermeId"
                        label="VerME ID"
                        margin="normal"
                        variant="outlined"
                        defalutvalue={vmID}
                        onChange={e => setVmID(e.target.value)}
                        inputRef={promotionForm.register}
                        error={!!promotionForm.errors.vermeId}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment id="adornment" position="start">
                              <TextInputAdornment id="txt-adornment">
                                VM&nbsp;&nbsp;&nbsp;-
                              </TextInputAdornment>
                            </InputAdornment>
                          )
                        }}
                        inputProps={{
                          maxLength: 6
                        }}
                      />
                      {promotionForm.errors.vermeId && (
                        <ErrorMessage id="err-msg">
                          {promotionForm.errors.vermeId.message}
                        </ErrorMessage>
                      )}
                    </div>
                    <div id="div-textfield" style={{ marginBottom: "2.19rem" }}>
                      <StyledTextFieldCustum
                        id="thaiName"
                        name="thaiName"
                        label="ชื่อ-นามสกุล (ภาษาไทย)"
                        margin="normal"
                        variant="outlined"
                        defalutvalue={nameThai}
                        onChange={e => setNameThai(e.target.value)}
                        inputRef={promotionForm.register}
                        error={!!promotionForm.errors.thaiName}
                      />
                      {promotionForm.errors.thaiName && (
                        <ErrorMessage id="err-msg">
                          {promotionForm.errors.thaiName.message}
                        </ErrorMessage>
                      )}
                    </div>
                    <Submit id="submit-btn" type="submit" disabled={loading}>
                      {loading ? (
                        <CircularProgress
                          id="circular-progress"
                          size={25}
                          thickness={4}
                          style={{ color: "white" }}
                        />
                      ) : (
                        "ยืนยัน"
                      )}
                    </Submit>
                  </Content>
                </Rect>
              </Box>
            </Grid>
          </Grid>
        </div>
      )}
    </Root>
  );
}
