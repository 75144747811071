import React from "react";
import styled from "styled-components";
import Badge from "../assets/badge/badge_verified.svg";

const HeaderContent = styled.div`
  margin-bottom: 1rem;
  font-family: "SukhumvitSet-Text";
  font-size: 1.5rem;
  color: #3b54d4;
  @media (max-width: 1024px) {
    margin-bottom: 1.5rem;
  }
`;

const UpdateContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  opacity: 0.34;
  font-family: "Sarabun-Light";
  font-size: 0.88rem;
  color: #000000;
  letter-spacing: 0.25px;
  text-align: left;
  line-height: 20px;
  @media (max-width: 1024px) {
    justify-content: flex-start;
    margin-bottom: 1rem;
  }
`;

const ContentFrame = styled.div`
  margin-bottom: 1.75rem;
  @media (max-width: 1024px) {
    margin-bottom: 1.5rem;
  }
`;

const ContentHeader = styled.div`
  opacity: 0.87;
  font-family: "SukhumvitSet-SemiBold";
  font-size: 1.25rem;
  color: #000000;
  letter-spacing: 0.25px;
  margin-bottom: 0.5rem;
`;

const ContentData = styled.div`
  opacity: 0.6;
  font-family: "Sarabun-Regular";
  font-size: 1rem;
  color: #000000;
  letter-spacing: 0.5px;
  text-align: left;
  line-height: 28px;
  ul {
    margin: 0;
  }
  li {
    line-height: 35px;
  }
`;

const ImageFrame = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export default function PolicyContent(props) {
  return (
    <div id="root">
      <HeaderContent id="header-content">นโยบายความปลอดภัย</HeaderContent>
      <UpdateContent id="update-content">
        อัปเดตล่าสุดเมื่อวันที่ 18 มีนาคม 2563
      </UpdateContent>
      <ContentFrame id="content-frame">
        <ContentHeader id="content-header">
          1. วัตถุประสงค์ของการกำหนดนโยบาย
        </ContentHeader>
        <ContentData id="content-data">
          เพื่อการรักษาความไว้วางใจและความเชื่อมั่นในการจัดเก็บข้อมูลส่วนตัวของลูกค้า
          การป้องกันรักษาข้อมูลความเป็นส่วนตัวของลูกค้านั้นเป็นสิ่งสำคัญ
          เราจึงจะใช้ชื่อหรือข้อมูลอื่นๆที่เกี่ยวข้องกับลูกค้าเฉพาะในกรณีจำเป็นที่ต้องใช้เท่านั้น
          (อธิบายรายละเอียดเพิ่มเติมในหัวข้อที่ 4) อย่างไรก็ดี VerME
          มีความเข้าใจอย่างแท้จริงถึงความจำเป็นที่จะต้องปกปิดข้อมูลส่วนบุคคลของลูกค้า
          ทุกรายโดยถือเป็นหน้าที่และความรับผิดชอบโดยตรงที่สำคัญอย่างยิ่ง
          เราจึงได้กำหนดนโยบายความปลอดภัยด้านข้อมูลส่วนตัวของลูกค้าขึ้นเพื่อเป็น
          มาตรฐานในการดำเนินการต่างๆ เพื่อให้สอดคล้องกับการบริการและเพื่อให้
          ลูกค้าได้รับความพึงพอใจมากที่สุด
        </ContentData>
      </ContentFrame>
      <ContentFrame id="content-frame">
        <ContentHeader id="content-header">2. ขอบเขตของนโยบาย</ContentHeader>
        <ContentData id="content-data">
          นโยบายนี้ควบคุมข้อมูลเฉพาะลูกค้าที่สมัครเป็นสมาชิกของ VerME
          และลูกค้าที่แจ้งรายงานผู้ขายกับทาง VerME เท่านั้นซึ่งมีอยู่ในเว็บไซต์
          www.verme.me
        </ContentData>
      </ContentFrame>
      <ContentFrame id="content-frame">
        <ContentHeader id="content-header">
          3. ข้อมูลส่วนตัวของผู้ขายที่ VerME เป็นผู้เก็บ
        </ContentHeader>
        <ContentData id="content-data">
          &nbsp;&nbsp;&nbsp;&nbsp;3.1.เราจะไม่เก็บข้อมูลที่เป็นข้อมูลส่วนตัวของลูกค้าเกินความจำเป็น
          เท่าที่จะทำได้
          ซึ่งการเก็บข้อมูลของลูกค้าจะเป็นไปตามกฎหมายคุ้มครองข้อมูลส่วนบุคคล
          กำหนด หากมีกรณีที่ลูกค้าท่านใด ไม่ยอมเปิดเผยข้อมูล ตามที่กำหนดในข้อ 4
          เปิดเผยข้อมูลแก่บุคคลที่สาม หรือสมัครเป็นสมาชิก แต่มีข้อมูลไม่ครบ
          ทางเราจะถือว่า การสมัครไม่สำเร็จ ข้อมูลส่วนตัว ที่จะต้องเก็บ
          เพื่อประโยชน์สำหรับการให้บริการ มีดังนี้
          <ul id="ul">
            <li id="list">ชื่อและนามสกุล (ภาษาอังกฤษและภาษาไทย)</li>
            <li id="list">หมายเลขโทรศัพท์</li>
            <li id="list">E-mail</li>
            <li id="list">ชื่อ Facebook</li>
            <li id="list">เลขที่บัญชี</li>
            <li id="list">วัน เดือน ปีเกิด</li>
            <li id="list">เลขบัตรประจำตัวประชาชน</li>
            <li id="list">เลขหลังบัตรประจำตัวประชาชน</li>
            <li id="list">ที่อยู่</li>
            <li id="list">
              รูปถ่าย (ถ่ายคู่กับบัตรประชาชนและป้าย VerME,
              รูปบัตรประชาชนหน้าบัตร - หลังบัตร)
            </li>
          </ul>
          VerME
          อาจจะใช้ข้อมูลที่ได้รับมาเป็นฐานในการใช้ติดต่อกับลูกค้าเมื่อมีความจำเป็น{" "}
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;3.2. เราจะไม่อนุญาตุให้ผู้ที่อายุต่ำกว่า 20 ปี
          สมัครเป็นสมาชิกเพื่อนำ VerME Card ไปใช้ขายของ ตาม พรบ.คุ้มครองเด็ก
          พ.ศ. 2546 <br />
          &nbsp;&nbsp;&nbsp;&nbsp;3.3.
          เราอนุญาตให้ผู้ใช้งานแก้ไขหรือลบข้อมูลส่วนตัวของผู้ใช้งานได้ตามที่ผู้ใช้งานร้องขอ{" "}
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;3.4. เราจะยังคงเก็บข้อมูลส่วนตัว(ตามข้อ 3.1)
          ของผู้ขายไว้ในระบบ VerME ถึงแม้ว่าจะยกเลิกการใช้บริการกับ VerME ไปแล้ว
          กล่าวคือ หลังจากที่ผู้ขายแจ้งลบข้อมูล หรือยกเลิกการใช้บริการกับ Verme
          ข้อมูลส่วนบุคคลของผู้ขาย จะถูกเก็บไว้ต่อไปอีก 90 วัน วัตถุประสงค์
          เพื่อประโยชน์สำหรับการสืบสวน กรณีมีเหตุกระทำความผิดกฎหมาย
          หรือเพื่อประโยชน์สำหรับการปฎิบัติตามคำขอจากเจ้าหน้าที่รัฐ
        </ContentData>
      </ContentFrame>
      <ContentFrame id="content-frame">
        <ContentHeader id="content-header">
          4. เปิดเผยข้อมูลแก่บุคคลที่สาม
        </ContentHeader>
        <ContentData id="content-data">
          VerME จะเปิดเผยข้อมูลแก่บุคคลที่สาม (ธนาคาร หรือสำนักงานตำรวจ)
          ก็ต่อเมื่อ ในกรณีที่มีผู้แจ้งรายงานผู้ขาย (ลูกค้า)
          เข้ามาว่าเป็นบุคคลอันตราย เท่านั้น แต่ทาง VerME ยังยึดถือ
          กฎหมายคุ้มครองข้อมูลส่วนบุคคล
          ที่ไม่เปิดเผยข้อมูลผู้ใช้บริการให้กับผู้อื่นโดยไม่ได้รับอนุญาตจากผู้ใช้บริการอย่างชัด
          เจนหรือตามที่กฎหมายกำหนด
        </ContentData>
      </ContentFrame>
      <ContentFrame id="content-frame">
        <ContentHeader id="content-header">
          5. มาตรการรักษาความปลอดภัยของข้อมูลส่วนตัว
        </ContentHeader>
        <ContentData id="content-data">
          ในการเก็บข้อมูลของ VerME จะอยู่ภายใต้กฎหมายคุ้มครองข้อมูลส่วนบุคคล โดย
          กระทรวงดิจิทัลเพื่อเศรษฐกิจและสังคม
          และภายใต้การบริหารจัดการและกำกับดูแล โดย…
          ซึ่งได้วางมาตรการรักษาความปลอดภัยของข้อมูลโดยใช้ระบบป้องกันที่มีมาตรฐาน
          ทางเทคโนโลยีสูง
          ข้อมูลส่วนตัวของผู้ใช้บริการทุกรายจะได้รับการคุ้มครองอย่าง
          ดีจากการสูญหาย การลักลอบนำไปใช้ การเปลี่ยนแปลงหรือการทำลาย โดย VerME
          ได้ตะหนักถึงความสำคัญของการรักษาข้อมูลผู้ใช้บริการเป็นอย่างมาก
          ซึ่งข้อมูลของท่านจะถูกเก็บไว้ในระบบที่มีการรักษาความปลอดภัยของข้อมูลที่มีมาตรฐานสูง(VBS)
          และอยู่ในศูนย์คอมพิวเตอร์ของ VerME ซึ่งผู้ที่สามารถเข้าถึงข้อมูล
          ของท่านได้มีเฉพาะเจ้าหน้าที่ที่ปฏิบัติงานของ VerME เท่านั้น
        </ContentData>
      </ContentFrame>
      <ContentFrame id="content-frame">
        <ContentHeader id="content-header">
          6. กำหนดการแจ้งเตือนเมื่อเกิดเหตุข้อมูลรั่วไหล
        </ContentHeader>
        <ContentData id="content-data">
          หากตรวจสอบพบ ข้อมูลของผู้ขายในระบบ VerME รั่วไหล ทาง VerME
          จะรีบแจ้งให้ท่าน ทราบโดยทันที
          และจะแจ้งเหตุไปยังสำนักงานคุ้มครองข้อมูลส่วนบุคคล ภายในเวลาไม่เกิน 72
          ชม.
        </ContentData>
      </ContentFrame>
      <ContentFrame id="content-frame">
        <ContentHeader id="content-header">
          7. ข้อจำกัดด้านความรับผิดชอบ
        </ContentHeader>
        <ContentData id="content-data">
          &nbsp;&nbsp;&nbsp;&nbsp;7.1. VerME รับรองว่า
          หากเกิดเหตุทุจริตผ่านแพลตฟอร์ม VerME
          ผู้เสียหายที่ได้รับความเสียหายอย่างแท้จริง จะได้รับเงินคืนครบถ้วน
          เต็มจำนวน <br />
          &nbsp;&nbsp;&nbsp;&nbsp; 7.2. VerME เป็นเพียงตัวกลาง
          สำหรับยืนยันตัวตนผู้ขาย จึงไม่สามารถรับรองได้ว่าผลิตภัณฑ์ของผู้ขาย
          จะครบถ้วนสมบูรณ์ เกิดปัญหา หรือไม่ และไม่รับผิดชอบว่า
          ผลิตภัณฑ์จะปลอดภัย มั่นคง หรือปราศจากข้อผิดพลาด
        </ContentData>
      </ContentFrame>
      <ImageFrame id="img-frame">
        <img id="img" src={Badge} alt="badge-verified" />
      </ImageFrame>
    </div>
  );
}
