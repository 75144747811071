import React from "react";
import styled from "styled-components";
import colors from "../common/Color";
import { ReactComponent as IconCheck } from "../assets/Icons/ic_check_filled.svg";
import Button from "@material-ui/core/Button";

const Root = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
const IcCheck = styled(IconCheck)`
  width: 12.25rem;
  height: 12.25rem;
`;
const Title = styled.div`
  font-family: "SukhumvitSet-Bold";
  font-size: 3rem;
  color: ${colors.black};
  opacity: 0.87;
  text-align: center;
  letter-spacing: 0;
  line-height: 4.75rem;
  margin: 2rem 0 0.5rem;
  @media (max-width: 1024px) {
    font-size: 2.5rem;
    line-height: 3.94rem;
  }
`;
const ContentText = styled.div`
  font-family: "Thonburi";
  font-size: 1rem;
  color: ${colors.black};
  opacity: 0.6;
  letter-spacing: 0.03rem;
  line-height: 1.75rem;
  text-align: center;
`;
const Submit = styled(Button)`
  && {
    width: 12.44rem;
    height: 2.75rem;
    background-color: ${colors.primary};
    font-family: "SukhumvitSet-Semibold";
    font-size: 0.875rem;
    line-height: 1rem;
    letter-spacing: 0.08rem;
    color: ${colors.white};
    margin-top: 2rem;
    box-shadow: 0 0.063rem 0.063rem 0 rgba(0, 0, 0, 0.5);
    &:hover {
      background-color: ${colors.primaryDark};
    }
  }
`;
const DesktopDiv = styled.div`
  display: block;
  @media (max-width: 500px) {
    display: none;
  }
`;
const MobileDiv = styled.div`
  display: none;
  @media (max-width: 500px) {
    display: block;
  }
`;
export default function SuccessPayment() {
  return (
    <Root id="root">
      <IcCheck id="check-ic" />
      <Title id="title">ชำระเงินเรียบร้อย</Title>
      <ContentText id="content-txt">ทางทีมงานจะทำการตรวจสอบข้อมูล</ContentText>
      <DesktopDiv id="desk-div">
        <ContentText id="content-txt">
          และแจ้งเตือนทางอีเมลภายใน 24 ชม.
        </ContentText>
      </DesktopDiv>
      <MobileDiv id="mb-div">
        <ContentText id="content-txt">และแจ้งเตือนทางอีเมล</ContentText>
        <ContentText id="content-txt">ภายใน 24 ชม.</ContentText>
      </MobileDiv>
      <Submit id="submit-btn" href="/">
        กลับไปหน้าแรก
      </Submit>
    </Root>
  );
}
