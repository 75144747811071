import React from "react";
import Title from "../common/Title";
import styled from "styled-components";
import Button from "@material-ui/core/Button";
import { ReactComponent as IconCheck } from "../assets/Icons/ic_check.svg";
import Grid from "@material-ui/core/Grid";
import colors from "../common/Color";

const GrayText = styled.div`
  font-family: "Sarabun-Regular";
  font-size: 1rem;
  color: ${colors.black};
  letter-spacing: 0.03rem;
  line-height: 1.75rem;
  text-align: center;
  opacity: 0.6;
`;
const Submit = styled(Button)`
  && {
    width: 10.81rem;
    height: 2.75rem;
    background-color: ${colors.primary};
    font-family: "SukhumvitSet-Semibold";
    font-size: 0.875rem;
    line-height: 1rem;
    letter-spacing: 0.08rem;
    color: ${colors.white};
    box-shadow: 0 0.063rem 0.063rem 0 rgba(0, 0, 0, 0.5);
    &:hover {
      background-color: ${colors.primaryDark};
    }
  }
`;
const IcCheck = styled(IconCheck)`
  width: 11.25rem;
  height: 11.25rem;
`;
const Root = styled.div`
  @media (min-width: 1024px) {
    margin-top: 28vh;
  }
`;
export default function Success(props) {
  return (
    <Root id="root">
      <Grid
        id="container"
        container
        justify="center"
        alignItems="center"
        direction="column"
        spacing={3}
      >
        <Grid id="grid-item" item xs={12}>
          <Title id="title">สำเร็จ!</Title>
        </Grid>
        <Grid id="grid-item" item xs={12}>
          <IcCheck id="check-ic" />
        </Grid>
        <Grid id="grid-item" item xs={12}>
          <GrayText id="txt-gray">
            เราได้รับการรายงานของคุณแล้ว <br />
            เราจะติดต่อคุณกลับ
          </GrayText>
        </Grid>
        <Grid id="grid-item" item xs={12}>
          <Submit id="submit-btn" href="/">
            กลับไปหน้าแรก
          </Submit>
        </Grid>
      </Grid>
    </Root>
  );
}
