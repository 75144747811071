import React, { useState, useContext } from "react";
import styled from "styled-components";
import Title from "../common/Title";
import SubTitle from "./SubTitle";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import colors from "../common/Color";
import InputAdornment from "@material-ui/core/InputAdornment";
import { ReactComponent as AddIcon } from "../assets/Icons/ic_add_circle.svg";
import { ReactComponent as DeleteButton } from "../assets/Icons/Icons Black/ic_cross copy.svg";
import IconButton from "@material-ui/core/IconButton";
import ErrorModal from "./ErrorModal";
import IconAdorn from "../common/IconAdornment";
import { RegisterContext } from "../Context/RegisterStore";

const StyledTextField = styled(TextField)`
  & .MuiFormLabel-root {
    border-color: ${colors.black};
  }
  & .MuiFormLabel-root.Mui-focused {
    color: ${colors.primary};
  }
  & .MuiOutlinedInput-root.Mui-focused fieldset {
    border-color: ${colors.primary};
    opacity: 1;
  }
  & .MuiOutlinedInput-root fieldset {
    border-color: ${colors.black};
    opacity: 0.38;
  }
  & .MuiOutlinedInput-adornedStart {
    padding-left: 0.5rem;
  }
  & .MuiOutlinedInput-inputMultiline {
    padding: ${props => (props.primary ? "" : "0.25rem")};
  }
`;
const ErrorMessage = styled.div`
  font-size: 0.75rem;
  letter-spacing: 0.03rem;
  line-height: 0rem;
  margin: 0 1rem;
  color: ${colors.statusError};
  margin-top: ${props => (props.primary ? "0" : "3.5rem")};
  @media (max-width: 374px) {
    margin-top: ${props => (props.primary ? "0" : "1.5rem")};
    margin: ${props => (props.primary ? "0 1rem" : "1.5rem")};
  }
`;
const TitleContent = styled.div`
  font-family: "SukhumvitSet-Text";
  font-size: 1.5rem;
  line-height: 2.31rem;
  color: ${colors.black};
  opacity: 0.87;
  margin-top: 2.23rem;
  @media (max-width: 960px) {
    margin-top: 1.77rem;
  }
`;
const Content = styled.div`
  font-family: "Sarabun-Regular";
  font-size: 1rem;
  letter-spacing: 0.03rem;
  line-height: 1.75rem;
  color: ${colors.black};
  opacity: 0.6;
  margin-bottom: 0.5rem;
`;
const AddImgButton = styled.label`
  && {
    width: 7.5rem;
    height: 7.5rem;
    border-radius: 0.25rem;
    border: 0.06rem solid #ebedfa;
    background: #fbfcfe;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0.5rem 0;
    cursor: pointer;
    &:hover {
      background: ${colors.surfaceGray};
    }
    @media (max-width: 960px) {
      width: 6.78rem;
      height: 6.78rem;
    }
  }
`;
const AddingIcon = styled(AddIcon)`
  font-size: 1.46rem;
  fill: ${colors.primary};
`;
const BgImage = styled.div`
  width: 7.5rem;
  height: 7.5rem;
  background: #eaeefb;
  border-radius: 0.25rem;
  margin: 0.5rem 1rem 0.5rem 0;
  position: relative;
  @media (max-width: 960px) {
    width: 6.78rem;
    height: 6.78rem;
  }
`;
const ImageContainer = styled.div`
  display: flex;
`;
const DeleteBtn = styled(IconButton)`
  && {
    position: absolute;
    padding: 0;
    border-radius: 0 0rem 0 0.25rem;
    background: ${colors.white};
    width: 1.5rem;
    height: 1.5rem;
    position: absolute;
    right: 0;
    z-index: 1;
  }
`;
const DeleteIcon = styled(DeleteButton)`
  width: 1rem;
  height: 1rem;
`;

const ImagePreview = styled.img`
  && {
    width: 7.5rem;
    height: 7.5rem;
    object-fit: contain;
    @media (max-width: 960px) {
      width: 6.78rem;
      height: 6.78rem;
    }
  }
`;

export default function Information(props) {
  const { reportVmId, description, image, checkImage } = useContext(
    RegisterContext
  );
  const [vmTemp, setVmTemp] = useState("");
  const handleChangeVermeId = e => {
    let value = e.target.value;
    value = value.replace(/[^A-Za-z0-9]/gi, "");
    reportVmId[1]("vm-" + value);
    setVmTemp(value);
  };
  return (
    <Grid id="root" container justify="flex-start">
      <ErrorModal id="err-modal" />
      <Grid id="grid-item" item xs={12}>
        <Title id="title">ข้อมูลผู้กระทำผิด</Title>
      </Grid>
      <Grid id="grid-item" item xs={12}>
        <SubTitle id="sub-title" />
      </Grid>
      <Grid id="grid-item" item xs={12} lg={6}>
        <StyledTextField
          primary="true"
          id="vermeId"
          name="vermeId"
          label="VerME ID ของผู้กระทำผิด"
          inputRef={props.formProps.register}
          error={!!props.formProps.errors.vermeId}
          placeholder="000000"
          margin="normal"
          variant="outlined"
          defaultValue={reportVmId[0]}
          value={vmTemp}
          style={{ width: "100%", height: "3.5rem" }}
          onChange={handleChangeVermeId}
          InputProps={{
            startAdornment: (
              <InputAdornment id="adornment" position="end">
                <span>VM&nbsp;-&nbsp;</span>
              </InputAdornment>
            )
          }}
          inputProps={{
            maxLength: 6
          }}
        />
        {props.formProps.errors.vermeId && (
          <ErrorMessage
            id="err-msg"
            primary="true"
            style={{ lineHeight: "1rem" }}
          >
            {props.formProps.errors.vermeId.message}
          </ErrorMessage>
        )}
      </Grid>
      <Grid id="grid-item" item xs={12}>
        <StyledTextField
          id="description"
          name="description"
          inputRef={props.formProps.register}
          error={!!props.formProps.errors.description}
          label="คำอธิบายเกี่ยวกับเหตุการณ์"
          placeholder="เหตุการณ์ที่เกิดขึ้น"
          margin="normal"
          multiline
          rows="5"
          defaultValue={description[0]}
          variant="outlined"
          style={{ width: "100%" }}
          onChange={e => description[1](e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment id="adornment" position="end">
                <IconAdorn id="disabled-ic" disabled />
              </InputAdornment>
            )
          }}
        />
        {props.formProps.errors.description && (
          <ErrorMessage id="err-msg" primary="true">
            {props.formProps.errors.description.message}
          </ErrorMessage>
        )}
      </Grid>
      <Grid id="container-row" container direction="row" item xs={12}>
        <TitleContent id="title-ct">หลักฐาน</TitleContent>
        <br />
        {!checkImage[0] ? (
          <ErrorMessage id="err-msg">*กรุณาอัปโหลดภาพหลักฐาน*</ErrorMessage>
        ) : (
          ""
        )}
      </Grid>
      <Grid id="grid-item" item xs={12}>
        <Content id="content">
          เช่น ใบเสร็จการทำรายการ ประวัติการพูดคุย ภาพถ่ายสินค้า เป็นต้น
        </Content>
      </Grid>
      <Grid id="container-item" container item>
        {image[0].map((item, index) => (
          <ImageContainer id="img-container" key={index}>
            <BgImage id="img-bg" key={index}>
              <ImagePreview
                id="img-preview"
                src={item.preview}
                alt={item.raw.name}
              />
              <DeleteBtn
                id="delete-btn"
                onClick={() => props.deleteImageData(index)}
              >
                <DeleteIcon id="delete-ic" />
              </DeleteBtn>
            </BgImage>
          </ImageContainer>
        ))}
        <AddImgButton id="upload-btn" htmlFor="upload-button">
          <AddingIcon id="add-ic" />
        </AddImgButton>

        <input
          onChange={e =>
            props.setImageData({
              preview: URL.createObjectURL(e.target.files[0]),
              raw: e.target.files[0]
            })
          }
          type="file"
          id="upload-button"
          style={{ display: "none" }}
        />
      </Grid>
    </Grid>
  );
}
