import React, { useState, useRef } from "react";
import styled, { keyframes, css } from "styled-components";
import colors from "../common/Color";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import { ReactComponent as CamIcon } from "../assets/Icons/Icons Black/ic_camera_filled.svg";
import { useUserMedia } from "./Camera/use-user-media";
import { ReactComponent as CrossIcon } from "../assets/Icons/Icons Black/ic_cross_filled.svg";
import { ReactComponent as RedoIcon } from "../assets/Icons/Icons Black/ic_redo.svg";

export const CAPTURE_OPTIONS = {
  audio: false,
  video: { facingMode: "environment" }
};
export const ModalBox = styled.div`
  width: 36rem;
  height: 35.5rem;
  background: ${colors.white};
  border-radius: 0.5rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: auto;
  margin-top: calc(50vh - 18rem);
`;
const flashAnimation = keyframes`
  from {
    opacity: 0.75;
  }
  to {
    opacity: 0;
  }
`;
export const CameraBtn = styled.div`
  width: 3.5rem;
  height: 3.5rem;
  background: ${colors.primary};
  border-radius: 50%;
  position: relative;
  margin: 0.8rem auto 0.75rem;
  cursor: pointer;
`;
export const CameraIcon = styled(CamIcon)`
  fill: ${colors.white};
  width: 2rem;
  height: 2rem;
  position: absolute;
  top: 0.8rem;
  left: 0.74rem;
`;
export const RedoIc = styled(RedoIcon)`
  fill: #757575;
  font-size: 1.5rem;
  margin: auto 10rem auto -12rem;
  cursor: pointer;
`;
export const Container = styled.div`
  position: relative;
  width: 36rem;
  height: 27.11rem;
  margin-top: -0.7rem;
`;
export const Canvas = styled.canvas`
  position: absolute;
  background-color: ${colors.black};
  display: none;
`;
export const Video = styled.video`
  position: absolute;
  width: 100%;
  height: 100%;
  &::-webkit-media-controls-play-button {
    display: none !important;
    -webkit-appearance: none;
  }
`;
const Flash = styled.div`
  position: absolute;
  width: 36rem;
  height: 27.11rem;
  background-color: #ffffff;
  opacity: 0;

  ${({ flash }) => {
    if (flash) {
      return css`
        animation: ${flashAnimation} 750ms ease-out;
      `;
    }
  }}
`;
export const CaptureDiv = styled.div`
  display: flex;
  height: 3.5rem;
  align-items: center;
  margin: 0.8rem auto 0.75rem;
`;
export const ConfirmBtn = styled(Button)`
  && {
    width: 10.81rem;
    height: 2.75rem;
    background-color: ${colors.primary};
    font-family: "SukhumvitSet-Semibold";
    font-size: 0.875rem;
    line-height: 1rem;
    letter-spacing: 0.08rem;
    color: ${colors.white};
    box-shadow: 0 0.063rem 0.063rem 0 rgba(0, 0, 0, 0.5);
    &:hover {
      background-color: ${colors.primaryDark};
    }
  }
`;
export const CloseBtn = styled(CrossIcon)`
  font-size: 1.5rem;
  fill: ${colors.statusError};
  margin: 1rem 1rem auto auto;
  cursor: pointer;
`;
export const CameraPreview = styled.img`
  width: 36rem;
  height: 27.11rem;
  position: absolute;
  z-index: 10;
`;

export default function CameraModal(props) {
  const {
    open,
    handleClose,
    iscameraopen,
    onCapture,
    cardImage,
    setIsCanvasEmpty,
    onClear,
    onConfirm,
    setOpen
  } = props;
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [isFlashing, setIsFlashing] = useState(false);
  const [isCapture, setIsCapture] = useState(false);
  const mediaStream = useUserMedia(CAPTURE_OPTIONS);
  const [canvasWidth] = useState(1440);
  const [canvasHeight] = useState(1080);
  const videoRef = useRef();
  const canvasRef = useRef();

  if (mediaStream && videoRef.current && !videoRef.current.srcObject) {
    videoRef.current.srcObject = mediaStream;
  }

  function handleCanPlay() {
    setIsVideoPlaying(true);
    videoRef.current.play();
  }
  function handleCapture() {
    const context = canvasRef.current.getContext("2d");
    // context.drawImage(videoRef.current, 0, 0, 1280, 1280, 0, 0, 600, 400);
    context.drawImage(videoRef.current, 0, 0, canvasWidth, canvasHeight);
    setIsCapture(true);
    canvasRef.current.toBlob(e => onCapture(e), "image/jpeg", 1);
    setIsCanvasEmpty(false);
    setIsFlashing(true);
  }
  function handleConfirm() {
    canvasRef.current.toBlob(e => onConfirm(e), "image/jpeg", 1);
    setOpen(false);
  }
  function handleClear() {
    const context = canvasRef.current.getContext("2d");
    context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    setIsCanvasEmpty(true);
    onClear();
  }
  if (!mediaStream) {
    return null;
  }
  return (
    <div id="root">
      <Modal
        id="modal"
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={handleClose}
        iscameraopen={iscameraopen}
      >
        <ModalBox id="modal-box">
          <CloseBtn id="close-btn" onClick={handleClose} />
          <Container id="container">
            <Canvas
              id="canvas"
              ref={canvasRef}
              width={canvasWidth}
              height={canvasHeight}
            ></Canvas>
            <Video
              id="video"
              ref={videoRef}
              hidden={!isVideoPlaying}
              onCanPlay={handleCanPlay}
              autoPlay
              playsInline
              muted
            />
            {cardImage && (
              <div id="preview-div">
                <CameraPreview
                  id="cam-preview"
                  src={cardImage && URL.createObjectURL(cardImage)}
                />
              </div>
            )}
            <Flash
              id="flash"
              flash={isFlashing}
              onAnimationEnd={() => setIsFlashing(false)}
            />
          </Container>
          {isCapture && cardImage ? (
            <CaptureDiv id="cap-div">
              <RedoIc id="ic-redo" onClick={handleClear} />
              <ConfirmBtn id="ic-confirm" onClick={handleConfirm}>
                ใช้ภาพนี้
              </ConfirmBtn>
            </CaptureDiv>
          ) : (
            <CameraBtn id="cam-btn" onClick={handleCapture}>
              <CameraIcon id="cam-ic" />
            </CameraBtn>
          )}
        </ModalBox>
      </Modal>
    </div>
  );
}
