import React from "react";
import styled from "styled-components";
import Badge from "../assets/badge/badge_verified.svg";

const HeaderContent = styled.div`
  margin-bottom: 1rem;
  font-family: "SukhumvitSet-Text";
  font-size: 1.5rem;
  color: #3b54d4;
`;

const UpdateContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  opacity: 0.34;
  font-family: "Sarabun-Light";
  font-size: 0.88rem;
  color: #000000;
  letter-spacing: 0.25px;
  text-align: left;
  line-height: 20px;
`;
const ContentFrame = styled.div`
  margin-bottom: 1.75rem;
  @media (max-width: 1024px) {
    margin-bottom: 1.5rem;
  }
`;

const ContentData = styled.div`
  opacity: 0.6;
  font-family: "Sarabun-Regular";
  font-size: 1rem;
  color: #000000;
  letter-spacing: 0.5px;
  text-align: left;
  line-height: 28px;
  ul {
    margin: 0;
  }
  li {
    line-height: 35px;
  }
`;

const ImageFrame = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;
const policy = [
  "2.  ในกรณีที่ทาง VerME พบหรือสงสัยว่า ภาพถ่าย เอกสาร หรือข้อมูลที่ทาง VerME ได้รับจากผู้ขอใช้บริการไม่ถูกต้อง ไม่ครบถ้วน หรือไม่เป็นจริง ผู้ขอใช้บริการตกลงให้ทาง VerME มีสิทธิระงับ หรือปิดบัญชีสมาชิกของผู้ขอใช้บริการเมื่อใดก็ได้ โดยจะมีการแจ้งให้ผู้ขอใช้บริการทราบต่อไป และทาง VerME ไม่ต้องรับผิดชอบในความเสียหายใด ๆ ที่เกิดขึ้นทั้งสิ้น นอกจากนี้ หากทาง VerME ได้รับความเสียหายใด ๆ จากการเปิดบัญชีโดยใช้หรืออ้างอิงข้อมูลดังกล่าว ผู้ขอใช้บริการจะต้องรับผิดชดใช้ต่อทาง VerME ในความเสียหายดังกล่าว",
  "3.  ผู้ขอใช้บริการยินยอมให้ทาง VerME เก็บรวบรวม ใช้ข้อมูลส่วนบุคคล ข้อมูลทางชีวภาพ (เช่น ใบหน้า เป็นต้น) หรือ ข้อมูลใด ๆ ของผู้ขอใช้บริการที่ให้ไว้แก่ทาง VerME หรือที่ทาง VerME ได้รับ หรือเข้าถึงได้จากแหล่งอื่น(ข้อมูลในลิงก์โปรไฟล์บนเฟซบุ๊ก) เพื่อวัตถุประสงค์ในการตรวจสอบและยืนยันตัวตนของผู้ขอใช้บริการ รวมถึงการดำเนินการใด ๆ เกี่ยวกับการวิเคราะห์ข้อมูล การบริหารกิจการของทาง VerME เสนอให้ใช้หรือปรับปรุงบริการ หรือผลิตภัณฑ์ใด ๆ ของทาง VerME ที่เกี่ยวกับสัญญาการให้บริการ หรือเพื่อตรวจสอบรายการธุรกรรมที่อาจจะเกิดการทุจริต ธุรกรรมที่มีเหตุอันควรสงสัย หรือเปิดเผยข้อมูลดังกล่าวให้แก่บริษัทในกลุ่มพาร์ทเนอร์ของทาง VerME",
  "4.  ในการใช้บริการกับทาง VerME  ผู้ขอใช้บริการยินยอมให้ทาง VerME มีการเปิดเผยข้อมูลบางส่วน(เช่น ลิงก์โปรไฟล์บนเฟซบุ๊ก) ให้กับบุคคลภายนอก(ลูกค้าของผู้ขอใช้บริการ) วัตถุประสงค์เพื่อให้ลูกค้าของผู้ขอใช้บริการ สามารถเข้าถึงหรือเยี่ยมชมสำหรับตรวจสอบข้อมูลเบื้องต้น",
  "5.  ในกรณีที่คอมพิวเตอร์ของทาง VerME หรือระบบไฟฟ้า หรือระบบการติดต่อสื่อสารขัดข้อง หรือมีเหตุอื่นใด และมีผลให้การให้บริการของทาง VerMEเกิดขัดข้องในขณะหนึ่งขณะใด ซึ่งเหตุดังกล่าวไม่ได้มีสาเหตุมาจากการที่ทาง VerME ไม่ดูแลรักษาระบบคอมพิวเตอร์ อย่างเพียงพอตามมาตรฐาน ผู้ขอใช้บริการตกลงจะไม่ยกเอาเหตุขัดข้องดังกล่าวเป็นข้อเรียกร้องให้ทาง VerME ต้องรับผิดชอบแต่ประการใดทั้งสิ้น",
  "6.  ทาง VerME ไม่ต้องรับผิดชอบในความเสียหายใด ๆ อันเกิดขึ้นแก่ผู้ขอใช้บริการอันเนื่องมาจากการใช้บริการของทาง VerME เว้นแต่เกิดจากทาง VerME จงใจกระทำความผิด หรือประมาทอย่างร้ายแรง",
  "7.  เพื่อประโยชน์ของผู้ขอใช้บริการ ทาง VerME อาจส่งข้อมูลข่าวสารในเชิงพาณิชย์เช่น ข้อมูลผลิตภัณฑ์และ บริการต่างๆ ข้อมูลทางการตลาด และรายการส่งเสริมการขาย เป็นต้น ไปยังที่อยู่อิเล็กทรอนิกส์ต่างๆ ของผู้ขอใช้บริการ เช่น E-mail Address เป็นต้น โดยผู้ขอใช้บริการสามารถบอกเลิกหรือปฏิเสธการรับข้อมูล ดังกล่าวได้ตามช่องทางที่ทาง VerME กำหนด",
  "8.  ทาง VerME มีสิทธิระงับการให้บริการเกี่ยวกับบัญชีสมาชิกของผู้ขอใช้บริการ เมื่อใดก็ได้หากทาง VerME มีเหตุอันควรสงสัยว่าบัญชีสมาชิกได้ถูกใช้ในกิจการที่ไม่ถูกต้องตามกฎหมาย หรือระเบียบ ข้อบังคับ หรือคำสั่ง ของทางราชการ หรือไม่เหมาะสมไม่ว่าด้วยประการใดๆ โดยผู้ขอใช้บริการจะไม่เรียกร้องค่าเสียหายใดๆ จากทาง VerME",
  "9.  ผู้ขอใช้บริการ ยินยอมให้มีการตัดเงินผ่านบัตรเดบิต/เครดิตทุกประเภท สำหรับการจ่ายค่าสมัครในแต่ละแพ็คเกจตามที่ทาง VerME กำหนด",
  "10. ทาง VerME มีสิทธิเพิ่มเติม และ/หรือ เปลี่ยนแปลงเงื่อนไขนี้ รวมทั้งอัตราค่าค่าบริการ และ/หรือ ค่าใช้จ่ายต่างๆ ในการใช้บริการของธนาคารได้โดยแจ้งให้ทราบล่วงหน้า 30 วันก่อนดำเนินการ ด้วยการประกาศบนเว็บไซต์ VerME และ/หรือ ด้วยวิธีการอื่นใดที่ทาง VerME จะกำหนดเพิ่มเติม",
  "11. ผู้ขอใช้บริการจะต้องเป็นบุคคลธรรมดาทีมีถิ่นที่อยู่ในประเทศไทยเท่านั้น หรือหากเป็นชาวต่างชาติที่เข้ามาในราชอาณาจักรไทยอย่างถูกต้องตามกฎหมายและมีใบอนุญาตทำงานตามกฎหมายและมีหลักฐานที่แสดงการพำนักพักพิงในประเทศ ไทยเกิน 1 ปี",
  "12. ผู้ขอใช้บริการ 1 คน เปิดบัญชีสมาชิกกับทาง VerME ได้เพียง 1 บัญชีเท่านั้น และไม่สามารถใช้ชื่อคณะบุคคลหรือข้อมูลทางชีวภาพ (เช่น ใบหน้า เป็นต้น) ของผู้อื่นในการสมัครได้",
  "13. ในกรณีที่ผู้ขอใช้บริการมีการเปลี่ยนแปลงข้อมูล(เช่น เปลี่ยนแปลงข้อมูลโปรไฟล์เฟซบุ๊ก ชื่อและนามสกุล เลขบัญชี หรือข้อมูลอื่นๆ ตามที่ทาง VerME ได้กำหนดไว้) ผู้ขอใช้บริการจำเป็นต้องแจ้งให้ทาง VerME ได้ทราบ เพื่อแก้ไขข้อมูลให้ตรงตามข้อมูลปัจจุบันของผู้ขอใช้บริการและสำหรับตรวจสอบรายการธุรกรรมที่อาจจะเกิดการทุจริต ธุรกรรมที่มีเหตุอันควรสงสัย",
  "14. ในกรณีที่ผู้ขอใช้บริการมีการตรวจสอบรายการธุรกรรมที่อาจจะเกิดการทุจริต และ/หรือการเกิดข้อพิพาทขึ้น คำตัดสินของVerME ถือเป็นที่สิ้นสุด และข้อมูลของผู้ทำผิดนั้นจะถูกเปิดเผย และให้ผู้เสียหายดำเนินการต่อไป",
  "15. การเปิดบัญชีสมาชิกกับทาง VerME  ผู้ขอใช้บริการจะต้องเป็นบุคคลธรรมดาที่มีอายุตั้งแต่ 20 ปี ถึง 69 ปี (เช่น เกิดวันที่ 8 มกราคม 2500 จะสามารถเปิดบัญชีได้ในวันที่ 8 มกราคม 2515 เป็นต้นไป โดยวันสุดท้ายที่จะ สามารถเปิดบัญชีได้คือวันที่ 7 มกราคม 2569)",
  "16. ในกรณีที่ผู้ขอใช้บริการที่มีอายุต่ำกว่า 20 ปี ยังเข้ามาสมัครใช้บริการของ VerME ต่อ ทั้งที่อายุไม่ถึงตามเงื่อนไขการใช้บริการ ทาง VerME จะขอสงวนสิทธิ์ในการไม่ออก VerME Card ให้ เพราะถือว่าทาง VerME ได้แจ้งให้ผู้ใช้บริการทราบก่อนสมัครแล้ว",
  "17. ในกรณีที่ผู้ขอใช้บริการทำรายการจ่ายเงินค่าสมัครสมาชิกเสร็จสิ้นสมบูรณ์ ทาง VerME จะแจ้งผลการจ่ายเงินและการหักบัญชีแก่ผู้ขอใช้บริการทางฟังก์ชั่นการแจ้งเตือน หรือจดหมายอิเล็กทรอนิกส์ (E-mail) ผู้ขอใช้บริการสามารถตรวจสอบผลการจ่ายเงินและสั่งพิมพ์ (print out) และ/หรือ บันทึก (save as) หลักฐานการจ่ายเงิน (Statement) รายการนั้นได้ที่ระบบบริการอิเล็กทรอนิกส์ของทาง VerME ภายในวันเดียวกันกับที่จ่ายเงินนั้น",
  "18. ผู้ขอใช้บริการต้องทำรายการตามขั้นตอนและวิธีการที่ทาง VerME กำหนดผ่านเว็บ verme.me เท่านั้นเพื่อป้องกันการสวมรอยจากผู้ไม่ประสงค์ดี",
  "19. ผู้ขอใช้บริการสามารถระงับหรือยกเลิกการระงับ การใช้บัตร VerME ได้ตามขั้นตอนและวิธีการที่ทาง VerME กำหนดผ่าน verme.me หรือผ่านช่องทางอิเล็กทรอนิกส์ประเภทอื่นใดที่ธนาคารจะเปิดให้บริการแก่ผู้ขอใช้บริการ",
  "20. ผู้ขอใช้บริการสามารถขอแก้ไขข้อมูลที่เคยให้ไว้กับทาง VerME  ได้ตามขั้นตอนและวิธีการที่ทาง VerME กำหนดผ่าน verme.me หรือผ่านช่องทางอิเล็กทรอนิกส์ประเภทอื่นใดที่ธนาคารจะเปิดให้บริการแก่ผู้ขอใช้บริการ",
  "21. ผู้ขอใช้บริการรับทราบเป็นอย่างดีแล้วว่า VerME เป็นผู้ให้บริการที่เป็นตัวกลางสำหรับยืนยันข้อมูลและตัวตนบนโลกออนไลน์ เพื่อสนับสนุนกิจกรรมพาณิชย์อิเล็กทรอนิกส์ของผู้ขอใช้บริการเท่านั้น และมิได้มีส่วนเกี่ยวข้องใดๆ กับการซื้อ จำหน่ายสินค้า หรือการให้บริการต่างๆ",
  "22. ผู้ขอใช้บริการยินยอมว่าจะมีการตั้งราคาขายและเปิดเผยข้อมูล รายละเอียดต่างๆของสินค้าให้ครบถ้วน ตามประกาศจากทางคณะกรรมการกลางว่าด้วยราคาสินค้าและบริการ ฉบับที่ 44 พ.ศ.2560 การซื้อขายผ่านระบบพาณิชย์อิเล็กทรอนิกส์หรือออนไลน์ เกี่ยวกับการตั้งราคาขายและเปิดเผยข้อมูล รายละเอียดต่างๆของสินค้าให้ครบถ้วน",
  "23. การใช้บริการของVerMeนั้น ผู้ขอใช้บริการจำเป็นต้องเข้าใจและยอมรับข้อตกลงและเงื่อนไขต่างๆ ที่ทาง VerME กำหนดไว้ทั้งหมด",
  "24. หากผู้ใช้บริการไม่ปฏิบัติตามข้อกำหนดและเงื่อนไขตามคำขอใช้บริการฉบับนี้ในข้อใดข้อหนึ่ง ผู้ให้บริการขอสงวนสิทธิในการเพิกถอนสมาชิกภาพ และสามารถยกเลิกการให้บริการกับท่านได้ทันที โดยไม่ต้องแจ้งล่วงหน้า การพิจารณาการเพิกถอนสมาชิกภาพ  ขึ้นอยู่กับดุลยพินิจของเจ้าหน้าที่เว็บไซต์ ซึ่งอาจจะอ้างอิงจากผู้เสียหายหรือการร้องเรียน"
];
export default function PolicyContent(props) {
  const policyItems = policy.map((item, index) => (
    <ContentFrame id="content-frame">
      <ContentData id="content-header" key={index}>
        {item}
      </ContentData>
    </ContentFrame>
  ));

  return (
    <div id="root">
      <HeaderContent id="header-content">
      เงื่อนไขทั่วไปสำหรับการสมัคร และใช้บริการของเวอร์มี
      </HeaderContent>
      <UpdateContent id="update-content">
        อัปเดตล่าสุดเมื่อวันที่ 17 มิถุนายน 2563
      </UpdateContent>
      <ContentFrame id="content-frame">
        <ContentData id="content-header">
          1. ทาง VerME
          จะมีการอ่านข้อมูลและภาพถ่ายของผู้ขอใช้บริการตามที่ได้มีการบันทึกไว้ในบัตรประจำตัวประชาชน
          ลิงก์โปรไฟล์บนเฟซบุ๊ก
          และเอกสารแสดงตัวตนอื่นใดที่ผู้ขอใช้บริการได้ส่งมอบให้แก่ทาง VerME
          และจัดเก็บข้อมูลและภาพถ่ายดังกล่าว
          เพื่อประโยชน์ในการสมัครเป็นสมาชิกกับทาง VerME <br /> &emsp;ทั้งนี้
          ผู้ขอใช้บริการรับรองว่า ภาพถ่าย เอกสาร และข้อมูลใด ๆ
          ที่ผู้ขอใช้บริการได้ให้แก่ทาง VerME ภายหน้า ไม่ว่าผ่านช่องทางใด ๆ
          ซึ่งรวมถึงช่องทางอิเล็กทรอนิกส์
          เป็นข้อมูลที่ระบุและพิสูจน์ตัวตนของผู้ขอใช้บริการ
          รวมทั้งเป็นข้อมูลของผู้ขอใช้บริการที่ถูกต้อง ครบถ้วน
          และเป็นจริงทุกประการ โดยทาง VerME
          จะมีการจัดเก็บข้อมูลดังกล่าวไว้ในระบบฐานข้อมูลลูกค้าของ VerME
          เพื่อประโยชน์ในการให้บริการของทาง VerME หรือการใช้ทำธุรกรรมกับทาง
          VerME
        </ContentData>
      </ContentFrame>
      {policyItems}
      <ImageFrame id="img-frame">
        <img id="img" src={Badge} alt="badge-verified" />
      </ImageFrame>
    </div>
  );
}
