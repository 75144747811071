import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";
import notfoundImg from "../assets/Images/img_404.svg";
import colors from "../common/Color";

const styles = theme => ({
  root: {
    width: "100%",
    height: "19.75rem",
    margin: "auto"
  },
  notfoundimg: {
    width: "9.02rem",
    height: "11.14rem",
    marginTop: "1rem"
  },
  txt1: {
    fontFamily: "Sarabun-Semibold",
    color: "#666666",
    opacity: "0.6",
    fontSize: "1.25rem",
    letterSpacing: "0.02rem",
    lineHeight: "1.63rem",
    marginTop: "2.67rem"
  },
  txt2: {
    fontFamily: "Sarabun-Regular",
    fontSize: "1rem",
    letterSpacing: "0.01rem",
    lineHeight: "1.5rem",
    color: colors.black,
    opacity: "0.34",
    marginRight: "0.2rem"
  },
  link: {
    textDecoration: "underline",
    color: colors.primary,
    fontFamily: "SukhumvitSet-Semibold"
  },
  group: {
    display: "flex",
    justifyContent: "center",
    marginTop: "0.5rem"
  },
  container: {
    display: "flex",
    flexDirection: "column",
    textAlign: "center"
  }
});

function NotFound(props) {
  const { classes } = props;
  return (
    <div id="root" className={classes.root}>
      <div id="container" className={classes.container}>
        <div id="img-div">
          <img
            id="img"
            className={classes.notfoundimg}
            src={notfoundImg}
            alt="notfound"
          />
        </div>
        <div id="txt-1" className={classes.txt1}>
          ไม่พบผู้ขายในระบบ VerME
        </div>
        <div id="group" className={classes.group}>
          <div id="txt-2" className={classes.txt2}>
            คุณพบปัญหาใช่หรือไม่?
          </div>
          <Link
            id="link"
            className={classes.link}
            href="https://www.facebook.com/verme.me"
            target="_blank"
            rel="noopener noreferrer"
          >
            ติดต่อเรา
          </Link>
        </div>
      </div>
    </div>
  );
}

export default withStyles(styles)(NotFound);
