import React from "react";
import colors from "../common/Color";
import styled from "styled-components";
import Subtitle from "./Subtitle";
import StyledTextField from "../common/StyledTextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import ErrorMessage from "../common/ErrorMessage";

const Root = styled.div`
  margin-bottom: 2rem;
`;
const StyledRadioGroup = styled(RadioGroup)`
  &.MuiFormGroup-root {
    justify-content: space-between;
    @media (min-width: 551px) {
      flex-direction: row;
    }
  }
`;
const PackageDiv = styled.div`
  @media (max-width: 550px) {
    display: flex;
  }
`;
const PriceText = styled.div`
  font-family: "Sarabun-Regular";
  font-size: 0.88rem;
  letter-spacing: 0.02rem;
  line-height: 1.25rem;
  color: ${colors.black};
  opacity: 0.6;
  margin-left: 2.6rem;
  @media (max-width: 550px) {
    margin-top: 0.9rem;
    margin-left: 0;
  }
`;
const useStyles = makeStyles({
  root: {
    "&:hover": {
      backgroundColor: "rgba(59, 84, 212, 0.1)"
    }
  },
  icon: {
    borderRadius: "50%",
    border: "0.06rem solid #575757",
    width: "2rem",
    height: "2rem",
    backgroundColor: "#fff",
    "input:hover ~ &": {
      backgroundColor: "#fff"
    }
  },
  checkedIcon: {
    backgroundColor: "#fff",
    backgroundImage: "#3B54D4",
    border: "0.06rem solid #3b54d4",
    "&:before": {
      display: "block",
      width: "0.88rem",
      height: "0.88rem",
      background: "#3b54d4",
      borderRadius: "50%",
      margin: "0.55rem auto",
      content: '""'
    }
  }
});
function StyledRadio(props) {
  const classes = useStyles();

  return (
    <Radio
      className={classes.root}
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
}
export default function FirstStep(props) {
  const { vermeID } = props.data[0];
  return (
    <Root id="root">
      <Subtitle id="subtitle" style={{ marginBottom: "1.5rem" }}>
        ประเภทแพ็กเกจ
      </Subtitle>
      <StyledRadioGroup
        id="radio-group"
        aria-label="position"
        name="position"
        value={props.choosePackage}
        onChange={e => props.handlePackage(e.target.value)}
        style={{ marginBottom: "2rem" }}
      >
        {props.packageData.map(item => (
          <PackageDiv id="package-div" key={item.promotion}>
            <FormControlLabel
              id="form-control"
              key={item.promotion}
              value={item.promotion}
              control={<StyledRadio />}
              label={
                <span
                  id="span"
                  style={{
                    fontSize: "1rem",
                    fontFamily: "Sarabun-Regular",
                    letterSpacing: "0.03rem"
                  }}
                >
                  {item.promotion}
                </span>
              }
            />
            <PriceText id="price-txt" key={item.price}>
              {item.price}
            </PriceText>
          </PackageDiv>
        ))}
      </StyledRadioGroup>
      <Subtitle id="subtitle" style={{ marginBottom: "1rem" }}>
        ข้อมูลบัญชี
      </Subtitle>
      <StyledTextField
        id="vermeID"
        name="vermeID"
        label="VerME ID"
        placeholder="000000"
        margin="normal"
        variant="outlined"
        inputRef={props.formProps.register}
        error={!!props.formProps.errors.vermeID}
        defaultValue={vermeID}
        InputProps={{
          startAdornment: (
            <InputAdornment id="adornment" position="end">
              <span>VM&nbsp;-&nbsp;</span>
            </InputAdornment>
          )
        }}
        inputProps={{
          maxLength: 6
        }}
        onChange={e => props.handleVmID(e.target.value)}
      />
      {props.formProps.errors.vermeID && (
        <ErrorMessage
          id="err-msg"
          primary="true"
          style={{ lineHeight: "1rem" }}
        >
          {props.formProps.errors.vermeID.message}
        </ErrorMessage>
      )}
    </Root>
  );
}
