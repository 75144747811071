import styled from "styled-components";
import React from "react";
import colors from "../common/Color";

const Root = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const GrayTxt = styled.div`
  font-family: "Sarabun-Regular";
  font-size: 1rem;
  letter-spacing: 0.03rem;
  line-height: 1.75rem;
  color: ${colors.black};
  opacity: 0.6;
`;

const Line = styled.div`
  height: 0.06rem;
  background: #e0e0e0;
  margin: 1.25rem 0;
`;

export default function SubTitle() {
  return (
    <Root id="root">
      <GrayTxt id="txt-gray">
        ผู้ที่แจ้งเบาะแสอันเป็นเท็จหรือจงใจกระทำให้ผู้อื่นได้รับความเสียหายต่อชื่อเสียงและความน่าเชื่อถือจะถูกดำเนินคดีตามกฎหมาย
      </GrayTxt>
      <Line id="line" />
    </Root>
  );
}
