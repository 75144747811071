import styled from "styled-components";
import colors from "./Color";
import TextField from "@material-ui/core/TextField";

const StyledTextField = styled(TextField)`
  & .MuiFormLabel-root {
    border-color: ${colors.black};
  }
  & .MuiFormLabel-root.Mui-focused {
    color: ${colors.primary};
  }
  & .MuiOutlinedInput-root {
    background: white;
  }
  & .MuiOutlinedInput-root.Mui-focused fieldset {
    border-color: ${colors.primary};
    opacity: 1;
  }
  & .MuiOutlinedInput-root fieldset {
    border-color: ${colors.black};
    opacity: 0.38;
  }
  & .MuiOutlinedInput-adornedStart {
    padding-left: 0.5rem;
  }
  &.MuiFormControl-marginNormal {
    margin-bottom: 0;
  }
  width: 100%;
  height: 3.5rem;
`;

export default StyledTextField;
