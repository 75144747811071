import React from "react";
import Button from "@material-ui/core/Button";
import icon_back from "../../assets/ic_back.svg";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

const Root = styled.div`
  text-align: justify;
  @media (max-width: 600px) {
    text-align: left;
    padding: 0;
  }
`;
const Text = styled.span`
  font-size: 0.875rem;
  color: #5856d6;
  letter-spacing: 0.08rem;
  line-height: 1rem;
  font-family: "Sarabun-Semibold";
`;

export default function BackButton() {
  let history = useHistory();
  return (
    <Root>
      <Button onClick={() => history.goBack()}>
        <img src={icon_back} alt="icon" />
        <Text>ย้อนกลับ</Text>
      </Button>
    </Root>
  );
}
