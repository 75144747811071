import React from "react";
import styled from "styled-components";
import colors from "../common/Color";
import { Root, Logo, Header } from "../common/FormHeader";
import Title from "../common/Title";
import { ReactComponent as SuccessIcon } from "../assets/Icons/ic_success.svg";
import Button from "../common/WebButton";

const Container = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 500px) {
    width: 80%;
    margin-top: 2rem;
  }
`;
const FlexCenter = styled.div`
  display: flex;
  justify-content: center;
`;
const StyledTitle = styled(Title)`
  && {
    text-align: center;
  }
`;
const SuccessIc = styled(SuccessIcon)`
  font-size: 10.38rem;
  margin: 2rem 0;
`;
const Text = styled.div`
  font-family: "Sarabun-Regular";
  font-size: 1rem;
  color: ${props => (props.email ? colors.primary : "rgba(0,0,0,0.6)")};
  letter-spacing: 0.03rem;
  line-height: 1.75rem;
  text-align: center;
`;
const TextDiv = styled.div`
  margin: 0 auto;
`;
const EmailDiv = styled.div`
  display: flex;
  justify-content: center;
  @media (max-width: 500px) {
    display: block;
  }
`;
const Btn = styled(Button)`
  && {
    width: 10.81rem;
    height: 2.75rem;
    margin-top: 2rem;
    @media (max-width: 500px) {
      width: 100%;
    }
  }
`;

export default function ConfirmSuccessPage(props) {
  const { userData } = props;

  return (
    <div id="root">
      <Root id="root-header">
        <Header id="header">
          <Logo id="logo" />
        </Header>
      </Root>
      <FlexCenter id="container-center">
        <Container id="container">
          <StyledTitle id="title">การสมัครเสร็จสิ้น</StyledTitle>
          <SuccessIc id="success-ic" />
          <TextDiv id="text-div">
            <Text id="text">
              เราจะส่ง VerME Card และลิงก์สำหรับการตั้งรหัสผ่านไปที่อีเมล
            </Text>
            <EmailDiv id="email-div">
              <Text id="text" email="true">
                {userData.email ? userData.email.data : ""} &nbsp;
              </Text>
              <Text id="text">ภายในเวลา 48 ชั่วโมง</Text>
            </EmailDiv>
          </TextDiv>
          <Btn id="btn" href="/" primary="true">
            กลับไปหน้าแรก
          </Btn>
        </Container>
      </FlexCenter>
    </div>
  );
}
