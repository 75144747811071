import React from "react";
import styled from "styled-components";
import colors from "../common/Color";
import { ReactComponent as ImageBenefit } from "../assets/Images/img_benefit.svg";
import { ReactComponent as IconIdcard } from "../assets/Icons/Icons Black Big/ic_big_id_card.svg";
import { ReactComponent as IconLaw } from "../assets/Icons/Icons Black Big/ic_big_law.svg";
import { ReactComponent as IconMoney } from "../assets/Icons/Icons Black Big/ic_big_money.svg";
import TitleText from "./TitleText";
import ContentText from "./ContentText";
import Divider from "./Divider";
import SubtitleText from "./SubtitleText";

const Root = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const Container = styled.div`
  width: 69.63rem;
  @media (max-width: 1190px) {
    width: 59.63rem;
  }
  @media (max-width: 1024px) {
    width: 23.38rem;
  }
  @media (max-width: 375px) {
    width: 17rem;
  }
`;
const ImgBenefit = styled(ImageBenefit)`
  width: 23.88rem;
  height: 25.75rem;
  margin-top: 3.5rem;
  margin-left: 22rem;
  @media (max-width: 1190px) {
    margin-left: 18rem;
  }
  @media (max-width: 1024px) {
    width: 17.48rem;
    margin: 1.9rem 0 0 2.5rem;
  }
  @media (max-width: 375px) {
    margin: 1.9rem 0 0;
  }
`;
const IcIdcard = styled(IconIdcard)`
  font-size: 4.25rem;
  fill: ${colors.primary};
`;
const IcLaw = styled(IconLaw)`
  font-size: 4.25rem;
  fill: ${colors.primary};
`;
const IcMoney = styled(IconMoney)`
  font-size: 4.25rem;
  opacity: 0.37;
`;
const ContentRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 5.5rem 0 8rem;
  @media (max-width: 1024px) {
    display: block;
    margin: 3rem 0;
  }
`;
const ContentBox = styled.div`
  width: 21.88rem;
  height: 18.75rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  @media (max-width: 1024px) {
    width: 17rem;
    height: 15.88rem;
    margin: 0 auto 1.5rem;
  }
`;
const ContentDetail = styled.div`
  width: 17.64rem;
`;
const PsText = styled.div`
  font-family: "Sarabun-Semibold";
  font-size: 0.75rem;
  letter-spacing: 0.03rem;
  line-height: 1rem;
  color: #000;
  opacity: 0.34;
  text-align: center;
  margin: 0.25rem 0;
`;
const SubtitleGrey = styled(SubtitleText)`
  font-family: "SukhumvitSet-Text";
  color: #000;
  opacity: 0.34;
`;
const EachContent = [
  {
    icon: <IcIdcard />,
    subtitle: <SubtitleText>ยืนยันตัวตนผู้ขาย</SubtitleText>,
    desc:
      "ผู้ซื้อสามารถตรวจสอบตัวตนของผู้ขายของออนไลน์ได้โดยการค้นหาด้วย VerME ID ผ่านเว็บไซต์ verme.me หรือสแกน QR Code บน VerME Card",
    ps: "",
    props: false
  },
  {
    icon: <IcLaw />,
    subtitle: <SubtitleText>การดำเนินคดี</SubtitleText>,
    desc:
      "ส่งหลักฐานของคุณมาให้เรา เพื่อทำการตรวจสอบ ช่วยเหลือคุณในการดำเนินดดี และเพิ่มผู้ขายนั้นขึ้นแบล็คลิสต์ของ VerME",
    ps: "หมายเหตุ: ต้องเป็นผู้ขายที่ลงทะเบียนกับ VerME เท่านั้น",
    props: false
  },
  {
    icon: <IcMoney />,
    subtitle: <SubtitleGrey>การันตีเงินคืน</SubtitleGrey>,
    desc: "สามารถคืนเงินได้ทันทีเมื่อถูกโกง (กำลังพัฒนา)",
    ps: "",
    props: true
  }
];
export default function BuyerSection() {
  return (
    <Root id="root">
      <Container id="container">
        <TitleText id="title">
          ผู้ซื้อจะได้รับอะไรบ้าง <br />
          หากซื้อสินค้าออนไลน์กับผู้ขายที่มี VerME Card
        </TitleText>
        <Divider id="divider" />
        <ContentText id="ct-txt" subtitle="true">
          VerME ไม่ได้มีบริการให้แค่ฝั่งผู้ขายเท่านั้น กับฝั่งผู้ซื้อ VerME
          จะช่วยให้คุณมั่นใจกับการซื้อสินค้า ด้วยการที่
          ผู้ซื้อจะสามารถตรวจสอบตัวตนของผู้ขายได้ด้วยการนำ ID
          มาตรวจสอบในเว็บไซต์ หรือแสกน QR Code บนVerME Card ของผู้ขาย
          และหากเกิดเหตุการณ์ไม่คาดคิดจากการซื้อขาย
          ผู้ซื้อสามารถแจ้งความกับทางเรา
          เพื่อขอหลักฐานไปใช้ในการดำเนินคดีต่อไปได้
        </ContentText>
        <ImgBenefit id="img-benefit" />
        <ContentRow id="ct-row">
          {EachContent.map((item, index) => (
            <ContentBox id="ct-box" key={index}>
              {item.icon}
              {item.subtitle}
              <ContentDetail id="ct-detail">
                <ContentText id="ct-txt" inactive={item.props}>
                  {item.desc}
                </ContentText>
              </ContentDetail>
              <PsText id="ps-txt">{item.ps}</PsText>
            </ContentBox>
          ))}
        </ContentRow>
      </Container>
    </Root>
  );
}
