import React, { useContext, useState } from "react";
import Title from "../common/Title";
import SubTitle from "./SubTitle";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import InputAdornment from "@material-ui/core/InputAdornment";
import ErrorMessage from "../common/ErrorMessage";
import StyledTextField from "../common/StyledTextField";
import IconAdorn from "../common/IconAdornment";
import styled from "styled-components";
import colors from "../common/Color";
import Button from "../common/WebButton";
import Modal from "@material-ui/core/Modal";
import { ReactComponent as IconCross } from "../assets/Icons/Icons Black/ic_cross2.svg";
import { StoreContext } from "../Context/Store";
import AddressDatabase from "../AddressDatabase/thailand.json";
import _ from "lodash";
import { ReactComponent as LogoVerme } from "../assets/Logo/logo_verme_dark.svg";
import BackButton from "../common/components/BackButtonBlue";

export const ModalContainer = styled.div`
  min-width: 100%;
  min-height: 100%;
  display: flex;
  @media (max-width: 1024px){
    display: block;
  }
`;
export const StyledModal = styled(Modal)`
  && {
    height: 100vh;
    overflow: scroll;
  }
`;
export const MobileDiv = styled.div`
  background-color: #f7f8fa;
  @media (min-width: 1025px) {
    display: none;
  }
`;
const ModalBox = styled.div`
  width: 47.13rem;
  height: 100%;
  padding-bottom: 2.5rem;
  background: ${colors.white};
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  margin: auto;
  @media (max-width: 1024px) {
    display: none;
  }
`;
export const IcCross = styled(IconCross)`
  font-size: 1.15rem;
  opacity: 0.5;
  cursor: pointer;
  margin: 1.5rem 1.5rem 0;
`;
export const GridButton = styled(Grid)`
  && {
    margin-top: 3rem;
  }
`;
//Mobile
export const Root = styled.div`
  background-color: #f7f8fa;
  display: block;
  min-height: 100vh;
  @media (min-width: 1025px) {
    display: none;
  }
`;
export const GridButtonMob = styled(Grid)`
  && {
    margin: 1.5rem 0 6rem;
  }
`;
export const Container = styled.div`
  width: 90%;
  margin: 0 5%;
  padding-top: 6rem;
`;
export const NavBar = styled.div`
  top: 0;
  width: 100%;
  height: 4rem;
  background: ${colors.white};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.03);
  position: fixed;
  z-index: 10;
`;
export const NavContainer = styled.div`
  width: 90%;
  height: 4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 5%;
`;
export const Logo = styled(LogoVerme)`
  width: 6.13rem;
  height: 1.63rem;
`;

export default function EditAddressModal(props) {
  const { openAddress, handleCloseAddress } = props;
  const {
    addresses,
    address,
    province,
    district,
    subDistrict,
    postalCode,
    checkProvince,
    checkDistrict,
    checkSubDistrict,
    checkAddress,
    checkPostalCode,
    loading
  } = useContext(StoreContext);

  const [addressData,] = useState(address[0]);
  const [provinceData,] = useState(province[0]);
  const [districtData,] = useState(district[0]);
  const [subDistrictData,] = useState(subDistrict[0]);
  const [postalCodeData,] = useState(postalCode[0]);

  const cancelChangeData = () => {
    address[1](addressData);
    province[1](provinceData);
    district[1](districtData);
    subDistrict[1](subDistrictData);
    postalCode[1](postalCodeData);
    handleCloseAddress();
  };

  const handleProvince = data => {
    province[1](data);
    district[1]({});
    subDistrict[1]({});
    postalCode[1]("");
  };

  const handleAmphoe = data => {
    district[1](data);
    subDistrict[1]({});
    postalCode[1]("");
  };

  const handleTambon = data => {
    subDistrict[1](data);
    postalCode[1](data.zipcode);
  };

  const provinceDatabase = () => {
    const result = _.map(AddressDatabase, item => item);
    return result;
  };

  const amphoesDatabase = () => {
    if (province[0]) {
      const result = _.map(province[0].amphoes, item => item);
      return result;
    }
    return [];
  };

  const tambonsDatabase = () => {
    if (district[0]) {
      const result = _.map(district[0].tambons, item => item);
      return result;
    }
    return [];
  };
  const handleSaveAddress = data => {
    if (
      !props.formProps.errors.address &&
      !props.formProps.errors.postalCode &&
      province[0].name &&
      district[0].name &&
      subDistrict[0].name
    ) {
      addresses[1](data);
      checkProvince[1](false);
      checkDistrict[1](false);
      checkSubDistrict[1](false);
      checkAddress[1](false);
      checkPostalCode[1](false);
      handleCloseAddress();
    } else {
      checkProvince[1](province[0].name ? false : true);
      checkDistrict[1](district[0].name ? false : true);
      checkSubDistrict[1](subDistrict[0].name ? false : true);
      checkAddress[1](true);
      checkPostalCode[1](true);
      loading[1](false);
    }
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  return (
    <div id="root">
      <StyledModal
        id="modal"
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={openAddress}
        onClose={handleCloseAddress}
        disableEnforceFocus={true}
      >
        <ModalContainer id="modal-container">
          <ModalBox id="modal-box">
            <Grid id="grid-ic" container justify="flex-end">
              <IcCross id="cross-ic" onClick={cancelChangeData} />
            </Grid>
            <Grid id="center-grid" container justify="center">
              <Grid id="grid-title" item xs={10}>
                <Title id="title">แก้ไขที่อยู่ปัจจุบัน</Title>
              </Grid>
              <Grid item xs={10} id="grid-subtitle">
                <SubTitle id="subtitle" props="edit" />
              </Grid>
              <Grid
                id="container-center"
                container
                spacing={2}
                justify="center"
              >
                <Grid id="grid-item" item md={10}>
                  <StyledTextField
                    id="address"
                    name="address"
                    label="ที่อยู่"
                    multiline
                    rows="3"
                    inputRef={props.formProps.register}
                    error={!!props.formProps.errors.address}
                    placeholder="12/123 ซอยเวอร์มี ถนนปลอดภัย"
                    style={{ margin: "1.25rem 0 2.25rem" }}
                    variant="outlined"
                    defaultValue={address[0]}
                    onChange={e => address[1](e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment id="adornment" position="end">
                          <IconAdorn id="disabled-ic" disabled />
                        </InputAdornment>
                      )
                    }}
                  />
                  {props.formProps.errors.address && (
                    <ErrorMessage id="err-msg">
                      {props.formProps.errors.address.message}
                    </ErrorMessage>
                  )}
                </Grid>
              </Grid>
              <Grid
                id="container-center"
                container
                item
                spacing={2}
                justify="center"
              >
                <Grid id="grid-item" item md={5}>
                  <StyledTextField
                    select
                    id="province"
                    name="province"
                    label="จังหวัด"
                    value={province[0]}
                    onChange={e => handleProvince(e.target.value)}
                    error={checkProvince[0] && province[0].name}
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment id="adornment" position="end">
                          <IconAdorn id="disabled-ic" disabled />
                        </InputAdornment>
                      )
                    }}
                  >
                    {provinceDatabase().map((option, index) => (
                      <MenuItem id="menu-item" key={index} value={option}>
                        {option.name.th}
                      </MenuItem>
                    ))}
                  </StyledTextField>
                  {checkProvince[0] && (
                    <ErrorMessage id="err-msg">กรุณาเลือกจังหวัด</ErrorMessage>
                  )}
                </Grid>
                <Grid id="grid-item" item md={5}>
                  <StyledTextField
                    select
                    id="district"
                    name="district"
                    error={checkDistrict[0]}
                    label="เขต / อำเภอ"
                    placeholder="เขต / อำเภอ"
                    value={district[0]}
                    margin="normal"
                    variant="outlined"
                    disabled={province[0].name ? false : true}
                    onChange={e => handleAmphoe(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment id="adornment" position="end">
                          <IconAdorn id="disable-ic" disabled />
                        </InputAdornment>
                      )
                    }}
                  >
                    {amphoesDatabase().map((option, index) => (
                      <MenuItem id="menu-item" key={index} value={option}>
                        {option.name.th}
                      </MenuItem>
                    ))}
                  </StyledTextField>
                  {checkDistrict[0] && (
                    <ErrorMessage id="err-msg">
                      กรุณาเลือกเขต / อำเภอ
                    </ErrorMessage>
                  )}
                </Grid>
              </Grid>
              <Grid
                id="container-center"
                container
                item
                spacing={2}
                justify="center"
              >
                <Grid item md={5} id="grid-item">
                  <StyledTextField
                    select
                    id="subDistrict"
                    name="subDistrict"
                    disabled={district[0].name ? false : true}
                    value={subDistrict[0]}
                    onChange={e => handleTambon(e.target.value)}
                    error={checkSubDistrict[0]}
                    label="แขวง / ตำบล"
                    placeholder="แขวง / ตำบล"
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment id="adornment" position="end">
                          <IconAdorn id="disabled-ic" disabled />
                        </InputAdornment>
                      )
                    }}
                  >
                    {tambonsDatabase().map((option, index) => (
                      <MenuItem id="menu-item" key={index} value={option}>
                        {option.name.th}
                      </MenuItem>
                    ))}
                  </StyledTextField>
                  {checkSubDistrict[0] && (
                    <ErrorMessage id="err-msg">
                      กรุณาเลือกแขวง / ตำบล
                    </ErrorMessage>
                  )}
                </Grid>
                <Grid item md={5} id="grid-item">
                  <StyledTextField
                    id="postalCode"
                    name="postalCode"
                    disabled={subDistrict[0].name ? false : true}
                    inputRef={props.formProps.register}
                    error={!!props.formProps.errors.postalCode}
                    label="รหัสไปรษณีย์"
                    placeholder="รหัสไปรษณีย์"
                    margin="normal"
                    variant="outlined"
                    value={postalCode[0]}
                    onChange={e => postalCode[1](e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment id="adornment" position="end">
                          <IconAdorn id="disabled-ic" disabled />
                        </InputAdornment>
                      )
                    }}
                    inputProps={{
                      maxLength: 5
                    }}
                  />
                  {props.formProps.errors.postalCode && (
                    <ErrorMessage id="err-msg">
                      {props.formProps.errors.postalCode.message}
                    </ErrorMessage>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <GridButton
              id="container-end"
              container
              item
              xs={11}
              justify="flex-end"
            >
              <Button
                id="cancel-btn"
                width="7.25rem"
                height="2.75rem"
                style={{ marginRight: "1rem" }}
                onClick={cancelChangeData}
              >
                ยกเลิก
              </Button>
              <Button
                id="save-btn"
                primary="true"
                width="7.25rem"
                height="2.75rem"
                onClick={handleSaveAddress}
              >
                บันทึก
              </Button>
            </GridButton>
          </ModalBox>
          <MobileDiv id="mobile">
            <Root id="root">
              <NavBar id="nav-bar">
                <NavContainer id="nav-container">
                  <div id="back-btn-div" onClick={cancelChangeData}>
                    <BackButton id="back-btn" />
                  </div>
                  <Logo id="logo" />
                </NavContainer>
              </NavBar>
              <Container id="container">
                <Grid id="container-start" container justify="flex-start">
                  <Grid id="grid-title" item xs={12}>
                    <Title id="title">แก้ไขที่อยู่</Title>
                  </Grid>
                  <Grid id="grid-item" item xs={12}>
                    <SubTitle id="subtitle" />
                  </Grid>
                  <Grid id="container" container spacing={2}>
                    <Grid id="grid-item" item xs={12}>
                      <StyledTextField
                        id="address"
                        name="address"
                        label="ที่อยู่"
                        multiline
                        rows="3"
                        inputRef={props.formProps.register}
                        error={!!props.formProps.errors.address}
                        placeholder="12/123 ซอยเวอร์มี ถนนปลอดภัย"
                        style={{ margin: "1.25rem 0 2.25rem" }}
                        variant="outlined"
                        defaultValue={address[0]}
                        onChange={e => address[1](e.target.value)}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment id="adornment" position="end">
                              <IconAdorn id="disabled-ic" disabled />
                            </InputAdornment>
                          )
                        }}
                      />
                      {props.formProps.errors.address && (
                        <ErrorMessage id="err-msg">
                          {props.formProps.errors.address.message}
                        </ErrorMessage>
                      )}
                    </Grid>
                    <Grid id="container-item" container item spacing={2}>
                      <Grid id="grid-item" item md={6} xs={12}>
                        <StyledTextField
                          select
                          id="province"
                          name="province"
                          label="จังหวัด"
                          value={province[0]}
                          onChange={e => handleProvince(e.target.value)}
                          error={checkProvince[0] && province[0].name}
                          margin="normal"
                          variant="outlined"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment id="adornment" position="end">
                                <IconAdorn id="disabled-ic" disabled />
                              </InputAdornment>
                            )
                          }}
                        >
                          {provinceDatabase().map((option, index) => (
                            <MenuItem id="menu-item" key={index} value={option}>
                              {option.name.th}
                            </MenuItem>
                          ))}
                        </StyledTextField>
                        {checkProvince[0] && (
                          <ErrorMessage id="err-msg">
                            กรุณาเลือกจังหวัด
                          </ErrorMessage>
                        )}
                      </Grid>
                      <Grid id="grid-item" item md={6} xs={12}>
                        <StyledTextField
                          select
                          id="district"
                          name="district"
                          error={checkDistrict[0]}
                          label="เขต / อำเภอ"
                          placeholder="เขต / อำเภอ"
                          value={district[0]}
                          margin="normal"
                          variant="outlined"
                          disabled={province[0].name ? false : true}
                          onChange={e => handleAmphoe(e.target.value)}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment id="adornment" position="end">
                                <IconAdorn id="disabled-ic" disabled />
                              </InputAdornment>
                            )
                          }}
                        >
                          {amphoesDatabase().map((option, index) => (
                            <MenuItem id="menu-item" key={index} value={option}>
                              {option.name.th}
                            </MenuItem>
                          ))}
                        </StyledTextField>
                        {checkDistrict[0] && (
                          <ErrorMessage id="err-msg">
                            กรุณาเลือกเขต / อำเภอ
                          </ErrorMessage>
                        )}
                      </Grid>
                    </Grid>
                    <Grid id="container-item" container item spacing={2}>
                      <Grid id="grid-item" item md={6} xs={12}>
                        <StyledTextField
                          select
                          id="subDistrict"
                          name="subDistrict"
                          disabled={district[0].name ? false : true}
                          value={subDistrict[0]}
                          onChange={e => handleTambon(e.target.value)}
                          error={checkSubDistrict[0]}
                          label="แขวง / ตำบล"
                          placeholder="แขวง / ตำบล"
                          margin="normal"
                          variant="outlined"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment id="adornment" position="end">
                                <IconAdorn id="disabled-ic" disabled />
                              </InputAdornment>
                            )
                          }}
                        >
                          {tambonsDatabase().map((option, index) => (
                            <MenuItem id="menu-item" key={index} value={option}>
                              {option.name.th}
                            </MenuItem>
                          ))}
                        </StyledTextField>
                        {checkSubDistrict[0] && (
                          <ErrorMessage id="err-msg">
                            กรุณาเลือกแขวง / ตำบล
                          </ErrorMessage>
                        )}
                      </Grid>
                      <Grid id="grid-item" item md={6} xs={12}>
                        <StyledTextField
                          id="postalCode"
                          name="postalCode"
                          inputRef={props.formProps.register}
                          error={!!props.formProps.errors.postalCode}
                          label="รหัสไปรษณีย์"
                          placeholder="รหัสไปรษณีย์"
                          margin="normal"
                          variant="outlined"
                          onChange={e => postalCode[1](e.target.value)}
                          defaultValue={postalCode[0]}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment id="adornment" position="end">
                                <IconAdorn id="disabled-ic" disabled />
                              </InputAdornment>
                            )
                          }}
                          inputProps={{
                            maxLength: 5
                          }}
                        />
                        {props.formProps.errors.postalCode && (
                          <ErrorMessage id="err-msg">
                            {props.formProps.errors.postalCode.message}
                          </ErrorMessage>
                        )}
                      </Grid>
                    </Grid>
                    <GridButtonMob id="grid-btn-mb" container item>
                      <Grid id="grid-item" item xs={12}>
                        <Button
                          id="save-btn"
                          primary="true"
                          width="100%"
                          height="2.75rem"
                          style={{ marginBottom: "1rem" }}
                          onClick={handleSaveAddress}
                        >
                          บันทึก
                        </Button>
                      </Grid>
                      <Grid id="grid-item" item xs={12}>
                        <Button
                          id="cancel-btn"
                          width="100%"
                          height="2.75rem"
                          onClick={cancelChangeData}
                        >
                          ยกเลิก
                        </Button>
                      </Grid>
                    </GridButtonMob>
                  </Grid>
                </Grid>
              </Container>
            </Root>
          </MobileDiv>
        </ModalContainer>
      </StyledModal>
    </div>
  );
}
