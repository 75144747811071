import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Button from "@material-ui/core/Button";
import colors from "../common/Color";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import { ReactComponent as LogoFacebook } from "../assets/Icons/Social/logo_facebook.svg";
import { ReactComponent as LogoFacebookGrey } from "../assets/Icons/Social/logo_facebook_grey.svg";
import { ReactComponent as ImgLogin } from "../assets/Images/img_login2.svg";
import IconAdorn from "../common/IconAdornment";
import Link from "@material-ui/core/Link";
import WebBtn from "../common/WebButton";
import Navbar from "../common/NavBar";
import NavMobile from "../common/NavMobile";
import { CircularProgress } from "@material-ui/core";
import ErrorModal from "../common/ErrorModal";
import { useAuth } from "../use-auth";
import Cookies from "universal-cookie";
import MetaDecorator from "../MetaDecorator/MetaDecorator";

const cookies = new Cookies();
/*global FB*/

const Root = styled.div`
  background: ${colors.surfaceGray};
  min-height: 100vh;
  height: 100%;
  min-width: 100vw;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const LeftComponent = styled.div`
  width: 29.81rem;
  height: 37.5rem;
  background: ${colors.primary};
  border-radius: 0.5rem 0 0 0.5rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.12);
  position: relative;
  overflow: hidden;
  @media (max-width: 959px) {
    display: none;
  }
`;
const RightComponent = styled.div`
  width: 27.81rem;
  height: 37.5rem;
  background: ${colors.white};
  border-radius: 0 0.5rem 0.5rem 0;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 959px) {
    width: 100%;
    background: transparent;
    border-radius: none;
    box-shadow: none;
  }
`;
const RightDiv = styled.div`
  width: 20.5rem;
  margin-top: 4.88rem;
  @media (max-width: 959px) {
    width: 90%;
    margin: 6rem 5% 0;
  }
`;
const Title = styled.div`
  opacity: 0.87;
  font-family: "SukhumvitSet-Text";
  font-size: 24px;
  color: #000000;
  margin-bottom: 0.81rem;
  @media (max-width: 959px) {
    font-family: "SukhumvitSet-Semibold";
    font-size: 20px;
    letter-spacing: 0.25px;
  }
`;
const FbLogo = styled(LogoFacebook)`
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 1rem;
`;
const FbLogoGrey = styled(LogoFacebookGrey)`
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 1rem;
`;
const RegistBtn = styled(Button)`
  && {
    width: 100%;
    height: 2.5rem;
    border: ${({disabled}) => disabled ? '1px solid #bdbdbd' : '1px solid #38569d'};
    border-radius: 4px;
    font-family: "SukhumvitSet-Medium";
    font-size: 0.88rem;
    letter-spacing: 0.03rem;
    color: #38569d;
    margin: 1rem 0 0;
    text-transform: none;
  }
`;
const LeftText = styled.div`
  opacity: 0.5;
  font-family: "SukhumvitSet-Text";
  font-size: 34px;
  color: #ffffff;
  letter-spacing: 0.25px;
  text-align: left;
  margin: 6.31rem 0 0 3.94rem;
`;
const ImageLogin = styled(ImgLogin)`
  position: absolute;
  left: -1rem;
  top: 14rem;
`;
const InputField = styled.div`
  margin-top: 2.19rem;
`;
const StyledTextField = styled(TextField)`
  & .MuiFormLabel-root {
    border-color: ${colors.black};
  }
  & .MuiFormLabel-root.Mui-focused {
    color: ${colors.primary};
  }
  & .MuiOutlinedInput-root {
    background: white;
  }
  & .MuiOutlinedInput-root.Mui-focused fieldset {
    border-color: ${colors.primary};
    opacity: 1;
  }
  & .MuiOutlinedInput-root fieldset {
    border-color: ${colors.black};
    opacity: 0.38;
  }
  & .MuiOutlinedInput-adornedStart {
    padding-left: 0.5rem;
  }
  &.MuiFormControl-marginNormal {
    margin-bottom: 0;
  }
  width: 100%;
  height: 3.31rem;
`;
const StyledLink = styled(Link)`
  && {
    font-family: "SukhumvitSet-Text";
    font-size: 16px;
    color: ${colors.primary};
    letter-spacing: 0.5px;
    text-align: center;
    line-height: 28px;
    cursor: pointer;
  }
`;
const AskText = styled.span`
  opacity: 0.6;
  font-family: "SukhumvitSet-Text";
  font-size: 16px;
  color: #000000;
  letter-spacing: 0.5px;
  text-align: center;
  line-height: 28px;
`;
const AskRegistDiv = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
`;
const AskRememberDiv = styled.div`
  margin: 1.69rem 0 1.5rem;
`;

const LoadingScreen = styled.div`
  height: 100vh;
  width: 100vw;
  z-index: 100;
  background: rgba(255, 255, 255, 0.5);
  position: fixed;
  top: 0;
`;

export default function SignInPage() {
  const [emailUser, setEmailUser] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [textMessage, setTextMessage] = useState("");
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const { signInWithEmail, signInWithFacebook } = useAuth();

  useEffect(() => {
    if (cookies.get("token")) {
      window.location.href = "/";
    }
  }, []);

  const handleErrorClose = () => {
    setErrorOpen(false);
  };

  const loginWithFacebook = () => {
    setLoading(true);
    try {
      FB.login(
        result => {
          if (result.status === "connected") {
            checkToSignInWithFacebook(result);
          }
        },
        {
          scope: "email"
        }
      );
    } catch (err) {
      console.log(err);
    }
  };

  const checkToSignInWithFacebook = async data => {
    try {
      const res = await signInWithFacebook(data);
      if (res.status === 200) {
        window.location.href = "/profile";
        setLoading(false);
      }
    } catch (err) {
      setTextMessage(err.response.data.error);
      setLoading(false);
      setErrorOpen(true);
    }
  };

  const loginWithEmail = async () => {
    setLoading(true);
    try {
      const res = await signInWithEmail(emailUser, password);
      if (res.status === 200) {
        window.location.href = "/profile";
        setLoading(false);
      }
    } catch (err) {
      setTextMessage(err.response.data.error);
      setLoading(false);
      setErrorOpen(true);
    }
  };

  return (
    <Root id="root">
      <MetaDecorator
        description="มั่นใจทุกการขาย สบายใจทุกการซื้อ"
        title= "เข้าสู่ระบบ"
        // imageUrl={metaThumbnail}
        // imageAlt={blog.metaImageAlt}
      />
      {loading ? <LoadingScreen id="loading-screen" /> : " "}
      <ErrorModal
        id="err-modal"
        textMessage={textMessage}
        handleErrorClose={handleErrorClose}
        errorOpen={errorOpen}
      />
      <Navbar id="navbar" props="sign-in" />
      <NavMobile id="nav-mb" />
      <LeftComponent id="left-component">
        <LeftText id="left-txt">
          เราเชื่อว่าทุกคน <br /> สามารถเป็นผู้ขายได้
        </LeftText>
        <ImageLogin id="img-login" />
      </LeftComponent>
      <RightComponent id="right-component">
        <RightDiv id="right-div">
          <Title id="title">เข้าสู่ระบบ VerME</Title>
          <InputField>
            <StyledTextField
              id="email"
              label="อีเมล"
              value={emailUser}
              onChange={e => setEmailUser(e.target.value)}
              placeholder="example@email.com"
              variant="outlined"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment id="adornment" position="end">
                    <IconAdorn id="disabled-ic" disabled />
                  </InputAdornment>
                )
              }}
            />
          </InputField>
          <InputField>
            <StyledTextField
              id="password"
              label="รหัสผ่าน"
              value={password}
              onChange={e => setPassword(e.target.value)}
              type={showPassword ? "text" : "password"}
              placeholder="password"
              variant="outlined"
              inputProps={{
                maxLength: 16
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment id="adornment" position="end">
                    <IconAdorn id="disabled-ic" disabled />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment id="adornment" position="end">
                    <IconButton
                      id="ic-btn"
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                    >
                      {showPassword ? (
                        <Visibility id="visible-ic" />
                      ) : (
                        <VisibilityOff id="invisible-ic" />
                      )}
                    </IconButton>
                  </InputAdornment>
                )
              }}
              fullWidth
            />
          </InputField>
          <AskRememberDiv id="ask-remember-div">
            <StyledLink id="link-forgot" href="/forgot-password">
              ลืมรหัสผู้ใช้
            </StyledLink>
          </AskRememberDiv>
          <WebBtn
            id="web-btn"
            onClick={loginWithEmail}
            primary="true"
            width="100%"
            height="2.5rem"
          >
            เข้าสู่ระบบ
          </WebBtn>
          <RegistBtn id="regist-btn" onClick={loginWithFacebook}>
          {/* <RegistBtn disabled id="regist-btn"> */}
            <FbLogo id="fb-logo" />
            {/* <FbLogoGrey id="fb-logo" /> */}
            เข้าสู่ระบบด้วย Facebook
          </RegistBtn>
          <AskRegistDiv id="ask-register-div">
            <AskText id="ask-txt">ยังไม่ได้เป็นสมาชิก? &nbsp;</AskText>
            <StyledLink
              id="link"
              href="/prepare-before-register"
              target="_blank"
              rel="noopener noreferrer"
            >
              {loading ? (
                <CircularProgress
                  id="circular-progress"
                  size={25}
                  thickness={4}
                  style={{ color: "white" }}
                />
              ) : (
                "ลงทะเบียน"
              )}
            </StyledLink>
          </AskRegistDiv>
        </RightDiv>
      </RightComponent>
    </Root>
  );
}
