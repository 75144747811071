import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import General from "./General";
import AddressStep from "./AddressStep";
import BookBank from "./BookBank";
import Authen from "./Authen";
import Summary from "./Summary";
import PolicyPage from "./PolicyPage";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepConnector from "@material-ui/core/StepConnector";
import colors from "../common/Color";
import StepButton from "./StepButton";
import useForm from "react-hook-form";
import ModalOtp from "./NextOtp";
import { NextBtn } from "./NextOtp";
import * as yup from "yup";
import { StoreContext } from "../Context/Store";
import { CircularProgress } from "@material-ui/core";
import { createUserRegister, createOTPRegister } from "../actions/action";
import PleaseConfirmPage from "./PleaseConfirmPage";
import FormHeader from "../common/FormHeader";
import FacebookRegister from "./FacebookRegister";
import CategoryComponents from "./Category";
import moment from "moment";
import clsx from "clsx";
import ErrorModal from "../common/ErrorModal";

const Root = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
`;
const FormDiv = styled.div`
  width: 50vw;
  @media (min-width: 1025px) {
    margin: 13.5vh 0 0 25vw;
    padding: 1.5rem 0 0;
  }
  @media (max-width: 1024px) {
    width: 84%;
    margin: 10rem auto auto;
    padding: 0;
  }
`;
const DesktopStep = styled.div`
  @media (max-width: 1024px) {
    display: none;
  }
`;
const MobileStep = styled.div`
  @media (min-width: 1025px) {
    display: none;
  }
`;
const StyledStep = styled.div`
  width: 25vw;
  margin-top: 13.5vh;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
`;

const StyledStepMobile = styled.div`
  width: 100%;
  height: 5rem;
  margin-top: 4rem;
  margin-bottom: 1rem;
  position: fixed;
  z-index: 2;
  background: #fdfdfe;
`;
const StyledStepper = styled(Stepper)`
  &.MuiStepper-vertical {
    align-items: center;
  }
  &.MuiPaper-root {
    background: transparent;
  }
  &.MuiStepper-root {
    padding: 1.5rem 8%;
  }
`;
const StyledMobileStep = styled(Step)`
  && {
    padding: 0;
  }
`;
const customStyle = makeStyles({
  root: {
    backgroundColor: "#D8D8D8",
    zIndex: 1,
    color: "#fff",
    width: "3rem",
    height: "3rem",
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Sarabun-Regular",
    fontSize: "1.5rem",
    lineHeight: "1.75rem"
  },
  active: {
    backgroundColor: "#3B54D4",
    boxShadow: "inset 0 0 0 0.25rem #fff",
    width: "3.25rem",
    height: "3.25rem",
    border: "0.25rem solid #3B54D4"
  },
  completed: {
    backgroundColor: "#3B54D4",
    color: "rgba(255,255,255,0.5)"
  }
});
const customStyleMobile = makeStyles({
  root: {
    backgroundColor: "#D8D8D8",
    zIndex: 1,
    color: "transparent",
    width: "1.73rem",
    height: "1.73rem",
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Sarabun-Regular",
    fontSize: "1.5rem",
    lineHeight: "1.75rem"
  },
  active: {
    backgroundColor: "#3B54D4",
    boxShadow: "inset 0 0 0 0.13rem #fff",
    width: "1.86rem",
    height: "1.86rem",
    border: "0.13rem solid #3B54D4"
  },
  completed: {
    backgroundColor: "#3B54D4",
    color: "rgba(255,255,255,0.5)"
  }
});
const CustomConnector = withStyles({
  active: {
    "& $line": {
      backgroundColor: "#3B54D4",
      minHeight: "3.8rem",
      border: "none"
    }
  },
  completed: {
    "& $line": {
      backgroundColor: "#3B54D4",
      minHeight: "3.8rem",
      border: "none"
    }
  },
  line: {
    backgroundColor: "#D8D8D8",
    width: "0.25rem",
    minHeight: "3.8rem",
    border: "none"
  }
})(StepConnector);

const StyledConnector = styled(CustomConnector)`
  && {
    padding: 0;
    margin: 0;
  }
`;

const StyledStepLabel = styled(StepLabel)`
  & .MuiStepLabel-iconContainer {
    padding: 0;
  }
`;
function CustomStepIcon(props) {
  const classes = customStyle();
  const { active, completed } = props;

  const icons = {
    1: "1",
    2: "2",
    3: "3",
    4: "4",
    5: "5"
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}
function CustomStepIconMobile(props) {
  const classes = customStyleMobile();
  const { active, completed } = props;

  const icons = {
    1: "",
    2: "",
    3: "",
    4: "",
    5: ""
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}
const MobileConnector = withStyles({
  alternativeLabel: {
    top: 22
  },
  active: {
    "& $line": {
      backgroundColor: "#3B54D4"
    }
  },
  completed: {
    "& $line": {
      backgroundColor: "#3B54D4"
    }
  },
  line: {
    height: "0.14rem",
    border: 0,
    backgroundColor: "#D8D8D8"
  }
})(StepConnector);
const StyledMobileConnector = styled(MobileConnector)`
  && {
    padding: 0;
  }
`;
const LoadingScreen = styled.div`
  height: 100vh;
  width: 100vw;
  z-index: 999;
  background: rgba(255, 255, 255, 0.5);
  position: fixed;
`;

const GridStepButton = styled(Grid)`
  && {
    margin-top: 2rem;
    padding-bottom: 5rem;
    @media (max-width: 1024px) {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      justify-content: space-around;
      margin-top: 1.5rem;
      padding-bottom: 0;
    }
  }
`;
function getSteps() {
  return [
    "ข้อมูลทั่วไป",
    "ที่อยู่ปัจจุบัน",
    "บัญชีธนาคาร",
    "ยืนยันตัวตน",
    "ตรวจสอบข้อมูล"
  ];
}
const thaiOnlyRegExp = /^[ก-๏\s]+$/;
const engOnlyRegExp = /^[A-Za-z]+$/;
const phoneRegExp = /([0])([6,8,9])([0-9]{8})/;
const onlyNumber = /^[0-9]+$/;
const citizenRegEx = /([0-9]{13})/;
const postalRegEx = /([0-9]{5})/;
const laserCodeRegEx = /([A-Za-z]{2})([0-9]{10})/;
const generalSchema = yup.object().shape({
  thaiFirstName: yup
    .string()
    .matches(thaiOnlyRegExp, "กรุณากรอกเฉพาะภาษาไทย")
    .required("กรุณากรอกชื่อ"),
  thaiLastName: yup
    .string()
    .matches(thaiOnlyRegExp, "กรุณากรอกเฉพาะภาษาไทย")
    .required("กรุณากรอกนามสกุล"),
  enFirstName: yup
    .string()
    .matches(engOnlyRegExp, "กรุณากรอกเฉพาะภาษาอังกฤษ")
    .required("กรุณากรอกชื่อ (ภาษาอังกฤษ)"),
  enLastName: yup
    .string()
    .matches(engOnlyRegExp, "กรุณากรอกเฉพาะภาษาอังกฤษ")
    .required("กรุณากรอกนามสกุล (ภาษาอังกฤษ)"),
  email: yup
    .string()
    .email("อีเมลไม่ถูกต้อง")
    .required("กรุณากรอกอีเมล"),
  phone: yup
    .string()
    .matches(phoneRegExp, "เบอร์โทรศัพท์มือถือไม่ถูกต้อง")
    .required("กรุณากรอกเบอร์โทรศัพท์มือถือ")
    .min(10, "กรุณากรอกเบอร์โทรศัพท์มือถือให้ครบถ้วน")
});

const addressesSchema = yup.object().shape({
  address: yup.string().required("กรุณากรอกที่อยู่"),
  postalCode: yup
    .string()
    .matches(postalRegEx, "รหัสไปรษณีย์ไม่ถูกต้อง")
    .required("กรุณากรอกรหัสไปรษณีย์")
    .min(5, "กรุณากรอกรหัสไปรษณีย์ให้ครบ 5 หลัก")
});

const bankSchema = yup.object().shape({
  bankNumber: yup
    .string()
    .matches(onlyNumber, "เลขที่บัญชีไม่ถูกต้อง")
    .required("กรุณากรอกเลขที่บัญชี")
});

const authenSchema = yup.object().shape({
  identityNumber: yup
    .string()
    .matches(citizenRegEx, "เลขประจำตัวประชาชนไม่ถูกต้อง")
    .required("กรุณากรอกเลขประจำตัวประชาชน")
    .min(13, "กรุณากรอกเลขประจำตัวประชาชนให้ครบ 13 หลัก"),
  laserCode: yup
    .string()
    .matches(laserCodeRegEx, "เลขหลังบัตรประชาชนไม่ถูกต้อง")
    .required("กรุณากรอกเลขหลังบัตรประชาชน")
    .min(12, "กรุณากรอกเลขหลังบัตรประชาชนให้ครบ 12 หลัก"),
  uploadFront: yup.string().required(),
  uploadBack: yup.string().required(),
  uploadFace: yup.string().required()
});

const StyledForm = styled.form`
  display: flex;
  width: 100vw;
  background: ${colors.surfaceGray};
  @media (max-width: 960px) {
    background-color: rgba(247, 248, 250, 0.24);
  }
`;

const SpaceDivPage = styled.div`
  width: 100%;
  height: 100%;
`;

export default function Register() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [bookBankData, setBookbankData] = React.useState([]);
  const [open, setOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [textMessage, setTextMessage] = useState("");
  const steps = getSteps();
  const {
    general,
    userId,
    bankaccount,
    authen,
    prefix,
    nameTh,
    surnameTh,
    nameEn,
    surnameEn,
    email,
    phone,
    addresses,
    address,
    province,
    district,
    subDistrict,
    postalCode,
    citizenId,
    laserCode,
    citizenImageFront,
    citizenImageBack,
    citizenImageFace,
    loading,
    count,
    checkPolicy,
    checkProvince,
    checkDistrict,
    checkSubDistrict,
    checkAddress,
    checkPostalCode,
    facebookUser,
    dataCategory,
    birthdate,
    isPromptpay,
    facebookPic
  } = useContext(StoreContext);
  // eslint-disable-next-line
  const [checkExistPhone, setCheckExistPhone] = useState(false);
  // eslint-disable-next-line
  const [checkExistEmail, setCheckExistEmail] = useState(false);
  const [checkImageFront, setCheckImageFront] = useState(true);
  const [checkImageBack, setCheckImageBack] = useState(true);
  const [checkImageFace, setCheckImageFace] = useState(true);

  useEffect(() => {
    const id = setInterval(() => count[1](c => c - 1), 1000);
    return () => clearInterval(id);
    // eslint-disable-next-line
  }, []);

  const resetTimer = phone => {
    sendOTPAgain(phone[0]);
    count[1](30);
  };

  const handleErrorClose = () => {
    setErrorOpen(false);
  };

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  const handleBack = () => {
    if (activeStep === 1) {
      handleClose();
      setActiveStep(prevActiveStep => prevActiveStep - 1);
    } else {
      setActiveStep(prevActiveStep => prevActiveStep - 1);
    }
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  const handleBankToCategory = () => {
    dataCategory[1]([]);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    loading[1](false);
  };

  const generalForm = useForm({
    validationSchema: generalSchema
  });
  const addressesForm = useForm({
    validationSchema: addressesSchema
  });
  const bankForm = useForm({
    validationSchema: bankSchema
  });
  const authenForm = useForm({
    validationSchema: authenSchema
  });
  const summaryForm = useForm({});
  const checkDataSubmit = data => {
    const checkbirth = moment(birthdate[0]).format("YYYY");
    if (
      birthdate[0] &&
      birthdate[0].toString() !== "Invalid Date" &&
      checkbirth <= "2000"
    ) {
      loading[1](true);
      general[1](data);
      SendOTPToUser();
      count[1](30);
    } else {
      loading[1](false);
    }
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  const addressSubmit = data => {
    // loading[1](true);
    if (
      address[0] &&
      province[0].name &&
      district[0].name &&
      subDistrict[0].name &&
      postalCode[0]
    ) {
      addresses[1](data);
      checkProvince[1](false);
      checkDistrict[1](false);
      checkSubDistrict[1](false);
      checkAddress[1](false);
      checkPostalCode[1](false);
      handleNext();
    } else {
      checkProvince[1](province[0].name ? false : true);
      checkDistrict[1](district[0].name ? false : true);
      checkSubDistrict[1](subDistrict[0].name ? false : true);
      checkAddress[1](true);
      checkPostalCode[1](true);
      loading[1](false);
    }
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  const bookBankSubmit = async () => {
    let newData = bookBankData.filter(item => {
      return item.bankNumber.length !== 0;
    });
    setBookbankData(newData);
    bankaccount[1](newData);
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    handleNext();
  };

  const authenSubmit = data => {
    if (
      citizenImageFront[0].raw &&
      citizenImageBack[0].raw &&
      citizenImageFace[0].raw &&
      citizenId &&
      laserCode
    ) {
      authen[1](data);
      setCheckImageFace(true);
      setCheckImageBack(true);
      setCheckImageFront(true);
      handleNext();
    } else {
      loading[1](false);
    }
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };
  const summarySubmit = () => {
    checkDataToUpload();
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return (
          <General
            formProps={generalForm}
            checkExistEmail={checkExistEmail}
            checkExistPhone={checkExistPhone}
            data={general}
          />
        );
      case 1:
        return <AddressStep formProps={addressesForm} data={addresses} />;
      case 2:
        return (
          <BookBank
            formProps={bankForm}
            bookBankData={bookBankData}
            setBookbank={setBookbank}
            data={bankaccount}
          />
        );
      case 3:
        return (
          <Authen
            formProps={authenForm}
            data={authen}
            checkImageFront={checkImageFront}
            checkImageBack={checkImageBack}
            checkImageFace={checkImageFace}
          />
        );
      case 4:
        return (
          <Summary
            formProps={generalForm}
            formPropsAd={addressesForm}
            formPropsBank={bankForm}
            handleBack={handleBack}
            bookBankData={bookBankData}
          />
        );
      default:
        return "Unknown stepIndex";
    }
  }

  function setBookbank(item) {
    setBookbankData(item);
  }

  async function SendOTPToUser() {
    setCheckExistEmail(false);
    setCheckExistPhone(false);
    if (process.env.REACT_APP_API !== "https://test.api.verme.me") {
      sendOTP(phone[0]);
    } else {
      loading[1](false);
      handleNext();
    }
  }
  // eslint-disable-next-line
  async function sendOTP(item) {
    var data = {
      phone: item
    };

    try {
      const res = await createOTPRegister(data);
      if (res.status === 200) {
        loading[1](false);
        handleOpen();
      }
    } catch (err) {
      loading[1](false);
      console.log(err);
    }
  }

  async function sendOTPAgain(item) {
    var data = {
      phone: item
    };

    try {
      await createOTPRegister(data);
    } catch (err) {
      console.log(err);
    }
  }

  const checkDataToUpload = async () => {
    let formData = new FormData();
    formData.append("name.data.en", nameEn[0] + " " + surnameEn[0]);
    formData.append("name.data.th", nameTh[0] + " " + surnameTh[0]);
    formData.append(
      "name.prefix.en",
      prefix[0] === "นาย" ? "Mr." : prefix[0] === "นางสาว" ? "Ms." : "Mrs."
    );
    formData.append("name.prefix.th", prefix[0]);
    formData.append("email.data", email[0]);
    formData.append("phone.data", phone[0]);
    formData.append("facebook.data", facebookUser[0].name);
    formData.append(
      "birthday",
      moment(birthdate[0])
        .unix()
        .toString()
    );
    formData.append("address.data.address", address[0]);
    formData.append("address.data.province", province[0].name.th);
    formData.append("address.data.district", district[0].name.th);
    formData.append("address.data.subDistrict", subDistrict[0].name.th);
    formData.append("address.data.postalCode", postalCode[0]);
    formData.append("identity.citizenID.data.front", citizenId[0]);
    formData.append("identity.citizenID.data.back", laserCode[0]);
    formData.append("citizenImageFront", citizenImageFront[0].raw);
    formData.append("citizenImageBack", citizenImageBack[0].raw);
    formData.append("citizenImageFace", citizenImageFace[0].raw);
    formData.append("phone.isPromtpay", isPromptpay[0]);
    formData.append(
      "socialAccount.facebookAccount.email",
      facebookUser[0].email
    );
    formData.append("socialAccount.facebookAccount.userID", facebookUser[0].id);
    formData.append("socialAccount.facebookAccount.link", facebookUser[0].link);
    formData.append(
      "socialAccount.facebookAccount.userName",
      facebookUser[0].name
    );
    formData.append(
      "socialAccount.facebookAccount.profilePhoto",
      facebookPic[0]
    );
    for (var i = 0; i < bookBankData.length; i++) {
      formData.append("bankAccountName", bookBankData[i].bankName);
      formData.append("bankAccountNumber", bookBankData[i].bankNumber);
    }

    for (var j = 0; j < dataCategory[0].length; j++) {
      formData.append("category.default", dataCategory[0][j]);
    }

    loading[1](true);

    try {
      const res = await createUserRegister(formData);
      if (res.status === 201) {
        userId[1](res.data);
        loading[1](false);
        console.log(userId[0]);
        handleNext();
      }
    } catch (err) {
      if (err.response) {
        if (err.response.status === 400) {
          // var splitedError = err.response.data.split(" ");
          setTextMessage(err.response.data);
          setErrorOpen(true);
          // if (splitedError[1] === "phone") {
          //   setCheckExistPhone(true);
          // } else if (splitedError[1] === "email") {
          //   setCheckExistEmail(true);
          // }
        }
      }
      loading[1](false);
      console.log(err);
    }
  };

  return (
    <Root id="root">
      <ErrorModal
        id="err-modal"
        textMessage={textMessage}
        handleErrorClose={handleErrorClose}
        errorOpen={errorOpen}
      />
      {loading[0] ? <LoadingScreen id="loading-screen" /> : " "}
      {!checkPolicy[0] ? (
        <PolicyPage id="policy-page" />
      ) : !facebookUser[0] ? (
        <SpaceDivPage id="space-div-page">
          <FacebookRegister id="fb-register-page" />
        </SpaceDivPage>
      ) : dataCategory[0].length === 0 ? (
        <SpaceDivPage id="space-div-page">
          <CategoryComponents id="category-component" />
        </SpaceDivPage>
      ) : activeStep === steps.length ? (
        <PleaseConfirmPage id="please-confirm-page" userId={userId} />
      ) : (
        <StyledForm
          id="form"
          onSubmit={
            activeStep === 0
              ? generalForm.handleSubmit(checkDataSubmit)
              : activeStep === 1
              ? addressesForm.handleSubmit(addressSubmit)
              : activeStep === 2
              ? bankForm.handleSubmit(bookBankSubmit)
              : activeStep === 3
              ? authenForm.handleSubmit(authenSubmit)
              : activeStep === 4
              ? summaryForm.handleSubmit(summarySubmit)
              : null
          }
        >
          <FormHeader id="form-header" />
          <DesktopStep id="step-desktop">
            <StyledStep id="step">
              <StyledStepper
                id="stepper"
                activeStep={activeStep}
                orientation="vertical"
                connector={<StyledConnector />}
              >
                {steps.map(label => (
                  <Step id="step" key={label}>
                    <StyledStepLabel
                      id="step-label"
                      StepIconComponent={CustomStepIcon}
                    ></StyledStepLabel>
                  </Step>
                ))}
              </StyledStepper>
            </StyledStep>
          </DesktopStep>
          <Grid id="grid-container" container>
            <Grid
              id="grid-center"
              container
              justify="center"
              alignItems="flex-start"
              item
              xs={12}
              style={{
                display: "block",
                backgroundColor:
                  activeStep === 0 || activeStep === steps.length ? "" : ""
              }}
            >
              <Grid id="grid-item" item xs={12}>
                <MobileStep id="step-mb-div">
                  <StyledStepMobile id="step-mb">
                    <StyledStepper
                      id="stepper-mb"
                      activeStep={activeStep}
                      orientation="horizontal"
                      connector={<StyledMobileConnector />}
                    >
                      {steps.map((label, index) => (
                        <StyledMobileStep id="step-mob" key={label}>
                          <StyledStepLabel
                            id="step-label"
                            StepIconComponent={CustomStepIconMobile}
                          ></StyledStepLabel>
                        </StyledMobileStep>
                      ))}
                    </StyledStepper>
                  </StyledStepMobile>
                </MobileStep>
              </Grid>
              <FormDiv id="form-div">
                {getStepContent(activeStep)}
                {activeStep === 0 ? (
                  <GridStepButton
                    id="grid-step-btn"
                    container
                    item
                    xs={12}
                    justify="flex-end"
                  >
                    <StepButton id="step-btn" onClick={handleBankToCategory}>
                      ย้อนกลับ
                    </StepButton>
                    <NextBtn id="next-btn" type="submit" disabled={loading[0]}>
                      {loading[0] ? (
                        <CircularProgress
                          id="circular-progress"
                          size={25}
                          thickness={4}
                          style={{ color: "white" }}
                        />
                      ) : (
                        "ต่อไป"
                      )}
                    </NextBtn>
                    <ModalOtp
                      id="modal-otp"
                      handleNext={handleNext}
                      loading={loading}
                      open={open}
                      handleClose={handleClose}
                      handleOpen={handleOpen}
                      phone={phone}
                      resetTimer={resetTimer}
                    />
                  </GridStepButton>
                ) : (
                  <GridStepButton
                    id="grid-step-btn"
                    container
                    justify="flex-end"
                    item
                    xs={12}
                  >
                    <StepButton id="step-btn" onClick={handleBack}>
                      ย้อนกลับ
                    </StepButton>
                    <StepButton
                      id="step-btn"
                      type="submit"
                      blue="true"
                      disabled={loading[0]}
                    >
                      {loading[0] ? (
                        <CircularProgress
                          id="circular-progress"
                          size={25}
                          thickness={4}
                          style={{ color: "white" }}
                        />
                      ) : (
                        "ต่อไป"
                      )}
                    </StepButton>
                  </GridStepButton>
                )}
              </FormDiv>
            </Grid>
          </Grid>
        </StyledForm>
      )}
    </Root>
  );
}
