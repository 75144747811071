import React, { useState, useContext } from "react";
import styled from "styled-components";
import { animateScroll as scroll } from "react-scroll";
import Grid from "@material-ui/core/Grid";
import Title from "../common/Title";
import SubTitle from "./SubTitle";
import colors from "../common/Color";
import InputAdornment from "@material-ui/core/InputAdornment";
import ErrorMessage from "../common/ErrorMessage";
import StyledTextField from "../common/StyledTextField";
import IconAdorn from "../common/IconAdornment";
import { ReactComponent as IconCheck } from "../assets/Icons/ic_check_filled.svg";
import IconChecking from "../assets/Icons/ic_check_filled.svg";
import { ReactComponent as UpIcon } from "../assets/Icons/Icons Black/ic_upload.svg";
import { ReactComponent as CamIcon } from "../assets/Icons/Icons Black/ic_camera_filled.svg";
import { ReactComponent as FrontCard } from "../assets/IDCard/front.svg";
import { ReactComponent as BackCard } from "../assets/IDCard/back.svg";
import { ReactComponent as SelfieImage } from "../assets/Images/img_selfie.svg";
import { ReactComponent as LaserIcon } from "../assets/Icons/ic_laser_idcard.svg";
import CameraModal from "./CameraModal";
import { StoreContext } from "../Context/Store";
import WebButton from "../common/WebButton";
import ImgModal from "./ImgModal";

const Box = styled(Grid)`
  width: 100%;
  /* height: 16.63rem; */
  /* background: #f7f8fa; */
  /* border-radius: 0.5rem;
  border: 0.06rem solid #ebedfa; */
  @media (max-width: 960px) {
    height: 100%;
  }
`;
const FrontImg = styled(FrontCard)`
  width: 16.38rem;
  height: 9.25rem;
  margin: 0 auto 1rem;
  @media (max-width: 1280px) {
    width: 14.06rem;
    height: 7.94rem;
  }
`;
const BackImg = styled(BackCard)`
  width: 16.38rem;
  height: 9.25rem;
  margin: 0 auto 1rem;
  @media (max-width: 1279px) {
    width: 14.06rem;
    height: 7.94rem;
  }
`;
const SelfieImg = styled(SelfieImage)`
  width: 16.38rem;
  margin: 0 auto 1rem;
  @media (max-width: 1279px) {
    width: 14.06rem;
  }
`;
const UploadIcon = styled(UpIcon)`
  fill: ${colors.primary};
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.38rem;
`;
const CameraIcon = styled(CamIcon)`
  fill: ${colors.primary};
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.38rem;
`;
const Button = styled(WebButton)`
  && {
    width: 17.38rem;
    height: 2.75rem;
    margin: 0.75rem auto 0;
    cursor: pointer;
    @media (max-width: 1279px) {
      width: 14.93rem;
      height: 2.36rem;
    }
  }
`;
const CamButton = styled(WebButton)`
  && {
    width: 17.38rem;
    height: 2.75rem;
    margin: 0.75rem auto 0;
    cursor: pointer;
    display: ${props => (props.mobile ? "none" : "flex")};
    @media (max-width: 1279px) {
      width: 14.93rem;
      height: 2.36rem;
    }
    @media (max-width: 1024px) {
      display: ${props => (props.mobile ? "flex" : "none")};
    }
  }
`;
const CheckIcon = styled(IconCheck)`
  width: 1.25rem;
  height: 1.25rem;
`;
const CheckingIcon = styled.img`
  width: 1.25rem;
  height: 1.25rem;
`;
const CheckListTxt = styled.span`
  font-family: ${props => (props.bold ? "Sarabun-Bold" : "Sarabun-Light")};
  font-size: 0.88rem;
  letter-spacing: 0.02rem;
  line-height: 1.25rem;
  color: ${colors.black};
  opacity: 0.6;
  margin-left: 0.19rem;
  text-align: left;
`;
const CheckListDiv = styled.div`
  display: flex;
  align-items: center;
  margin: 0.25rem 0;
`;
const GridHowto = styled(Grid)`
  && {
    display: ${props => (props.mobile ? "block" : "none")};
    margin-bottom: 1.5rem;
    @media (min-width: 961px) {
      display: ${props => (props.mobile ? "none" : "block")};
    }
  }
`;
const GridHowtoGroup = styled(Grid)`
  && {
    @media (min-width: 961px) {
      height: 23rem;
    }
  }
`;

const ImagePreview = styled.img`
  && {
    width: 16.38rem;
    height: 9.69rem;
    object-fit: contain;
    margin: 0 auto 1rem;
    @media (max-width: 1279px) {
      width: 14.06rem;
    }
  }
`;

const GridUploadImage = styled(Grid)`
  && {
    margin: 0.5rem 0;
    @media (max-width: 960px) {
      display: flex;
      flex-direction: ${props => (props.last ? "row" : "column")};
    }
  }
`;

const UploadTitleText = styled.div`
  font-family: ${props =>
    props.correct ? "SukhumvitSet-Text" : "SukhumvitSet-Semibold"};
  font-size: 0.86rem;
  letter-spacing: 0.03rem;
  line-height: 1.5rem;
  color: ${colors.primary};
  text-align: center;
  margin: 0.5rem auto 0.25rem;
`;
const UploadDescText = styled.div`
  font-family: "SukhumvitSet-Text";
  font-size: 0.75rem;
  letter-spacing: 0.01rem;
  line-height: 1.07rem;
  color: ${colors.black};
  opacity: 0.6;
  text-align: center;
  margin-bottom: 0.75rem;
`;
const UploadBox = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 0.69rem;
  padding: 1rem 0 0.5rem;
  border: ${({ isNotUploaded }) =>
    !isNotUploaded ? "0.13rem dashed #afafaf" : "0.13rem dashed #ff3b30"};
  background: ${colors.white};
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  position: relative;
  @media (max-width: 960px) {
    height: ${props => (props.correct ? "27.4rem" : "23.38rem")};
    background: ${colors.white};
    flex-direction: column;
    margin: auto;
    justify-content: center;
  }
`;
const ErrMessage = styled.div`
  font-family: "SukhumvitSet-Text";
  font-size: 1rem;
  letter-spacing: 0.03rem;
  line-height: 1.75rem;
  color: ${colors.statusError};
  text-align: center;
  margin: 0.5rem auto;
`;
const StyledInput = styled.input`
  && {
    width: 100%;
    height: 100%;
    z-index: 1;
    background: red;
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
`;
const StyledInputMobile = styled.input`
  && {
    width: 100%;
    height: 100%;
    z-index: 1;
    background: red;
    position: absolute;
    opacity: 0;
    cursor: pointer;
    @media (min-width: 1025px) {
      display: none;
    }
  }
`;
const ImageText = styled.span`
  opacity: 0.87;
  font-family: "SukhumvitSet-Semibold";
  text-align: left;
  font-size: 1.25rem;
  color: ${colors.black};
  letter-spacing: 0.02rem;
  line-height: 1.94rem;
  margin: 1rem 0;
  @media (max-width: 960px) {
    margin: ${props => (props.correct ? "0" : "2rem 0")};
  }
`;
const ImageBlueText = styled.span`
  font-family: "Sarabun-Regular";
  font-size: 1rem;
  letter-spacing: 0.03rem;
  line-height: 1.75rem;
  text-align: left;
  color: ${colors.primary};
  cursor: pointer;
  margin: 1.1rem 0.38rem;
  @media (max-width: 960px) {
    margin: 0 0 0 0.25rem;
  }
  @media (max-width: 530px) {
    margin: 0 0 1.5rem 0;
  }
`;

const RightContentDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const LaserCard = styled(LaserIcon)`
  width: 3.44rem;
  height: 3.44rem;
`;

export default function Authen(props) {
  const { identityNumber } = props.data[0];
  const [openImg, setOpenImg] = useState(true);
  const [frontOpen, setFrontOpen] = useState(false);
  const [backOpen, setBackOpen] = useState(false);
  const [faceOpen, setFaceOpen] = useState(false);
  const [isCorrectPage, setIsCorrectPage] = useState(true);
  const [isCameraFrontOpen, setIsCameraFrontOpen] = useState(false);
  const [isCameraBackOpen, setIsCameraBackOpen] = useState(false);
  const [isCameraFaceOpen, setIsCameraFaceOpen] = useState(false);
  const [cardImageFront, setCardImageFront] = useState();
  const [cardImageBack, setCardImageBack] = useState();
  const [cardImageFace, setCardImageFace] = useState();
  const [, setIsCanvasEmptyFront] = useState(true);
  const [, setIsCanvasEmptyBack] = useState(true);
  const [, setIsCanvasEmptyFace] = useState(true);
  const {
    citizenImageFront,
    citizenImageBack,
    citizenImageFace,
    citizenId,
    laserCode
  } = useContext(StoreContext);

  const handleOpenModal = () => {
    setOpenImg(true);
  };
  const handleCloseModal = () => {
    setOpenImg(false);
    setIsCorrectPage(true);
    scroll.scrollTo(1);
  };

  const handleFrontCameraOpen = () => {
    setFrontOpen(true);
    setIsCameraFrontOpen(true);
    setCardImageFront(undefined);
  };
  const handleFrontCameraClose = () => {
    setFrontOpen(false);
    setIsCameraFrontOpen(false);
    setCardImageFront(undefined);
    setIsCanvasEmptyFront(true);
  };
  const handleBackCameraOpen = () => {
    setBackOpen(true);
    setIsCameraBackOpen(true);
    setCardImageBack(undefined);
  };
  const handleBackCameraClose = () => {
    setBackOpen(false);
    setIsCameraBackOpen(false);
    setCardImageBack(undefined);
    setIsCanvasEmptyBack(true);
  };
  const handleFaceCameraOpen = () => {
    setFaceOpen(true);
    setIsCameraFaceOpen(true);
    setCardImageFace(undefined);
  };
  const handleFaceCameraClose = () => {
    setFaceOpen(false);
    setIsCameraFaceOpen(false);
    setCardImageFace(undefined);
    setIsCanvasEmptyFace(true);
  };

  return (
    <Grid container id="root">
      <ImgModal
        id="img-modal"
        openImg={openImg}
        handleCloseModal={handleCloseModal}
        isCorrectPage={isCorrectPage}
        setIsCorrectPage={setIsCorrectPage}
      />
      <Grid item xs={12} id="grid-title">
        <Title id="title">การยืนยันตัวตน</Title>
      </Grid>
      <Grid item xs={12} id="grid-subtitle">
        <SubTitle id="subtitle" />
      </Grid>
      <Grid container spacing={2} id="container">
        <Grid container item xs={12} id="container-item-1">
          <Box container spacing={2} id="box-1">
            <Grid item xs={12} style={{ margin: "auto" }} id="item-1">
              <StyledTextField
                label="เลขประจำตัวประชาชน"
                id="identityNumber"
                name="identityNumber"
                inputRef={props.formProps.register}
                error={!!props.formProps.errors.identityNumber}
                placeholder="เลขประจำตัวบัตรประชาชน 13 หลัก"
                margin="normal"
                variant="outlined"
                onChange={e => citizenId[1](e.target.value)}
                defaultValue={identityNumber}
                InputProps={{
                  startAdornment: (
                    <InputAdornment id="adornment-1" position="end">
                      <IconAdorn id="disabled-1" disabled />
                    </InputAdornment>
                  )
                }}
                inputProps={{
                  maxLength: 13
                }}
              />
              {props.formProps.errors.identityNumber && (
                <ErrorMessage primary="true" id="error-1">
                  {props.formProps.errors.identityNumber.message}
                </ErrorMessage>
              )}
            </Grid>
            <GridUploadImage container item xs={12} id="grid-upload">
              <ImageText id="text-1">ภาพถ่ายบัตรประชาชน</ImageText>
              <Grid container spacing={3} id="container-img">
                <Grid
                  item
                  xs={12}
                  id="item-3"
                  style={{ marginBottom: "2.75rem" }}
                >
                  <UploadBox
                    isNotUploaded={!!props.formProps.errors.uploadFront}
                    id="upload-box-1"
                  >
                    <RightContentDiv>
                      {citizenImageFront[0].preview ? (
                        <ImagePreview
                          id="img-preview-1"
                          src={citizenImageFront[0].preview}
                          alt="preview-front"
                        ></ImagePreview>
                      ) : (
                        <FrontImg id="front-example" />
                      )}
                      <UploadTitleText id="text-2">
                        รูปถ่ายบัตรประชาชน(ด้านหน้า)
                      </UploadTitleText>
                      <UploadDescText id="text-3">
                        ท่านสามารถถ่าย <br /> หรืออัปโหลดรูปภาพจากเครื่องของท่าน
                      </UploadDescText>
                    </RightContentDiv>
                    <RightContentDiv>
                      <CamButton onClick={handleFrontCameraOpen} id="cam-btn">
                        <CameraIcon id="cam-cion" />
                        เปิดกล้องถ่ายรูป
                      </CamButton>
                      <CameraModal
                        id="cam-front"
                        props="front"
                        open={frontOpen}
                        setOpen={setFrontOpen}
                        handleClose={handleFrontCameraClose}
                        handleOpen={handleFrontCameraOpen}
                        iscameraopen={isCameraFrontOpen.toString()}
                        onCapture={e => setCardImageFront(e)}
                        cardImage={cardImageFront}
                        setIsCanvasEmpty={setIsCanvasEmptyFront}
                        onClear={() => setCardImageFront(undefined)}
                        onConfirm={e =>
                          citizenImageFront[1]({
                            preview: URL.createObjectURL(e),
                            raw: e
                          })
                        }
                      />
                      <CamButton
                        mobile="true"
                        htmlFor="upload-button"
                        id="cam-btn-mb"
                      >
                        <StyledInputMobile
                          type="file"
                          accept="image/*"
                          capture
                          id="upload-button"
                          onChange={e =>
                            citizenImageFront[1]({
                              preview: URL.createObjectURL(e.target.files[0]),
                              raw: e.target.files[0]
                            })
                          }
                        />
                        <CameraIcon id="cam-ic-mb" />
                        เปิดกล้องถ่ายรูป
                      </CamButton>
                      <Button htmlFor="upload-button" id="input-btn">
                        <StyledInput
                          type="file"
                          accept="image/*"
                          id="upload-button"
                          onChange={e => {
                            citizenImageFront[1]({
                              preview: URL.createObjectURL(e.target.files[0]),
                              raw: e.target.files[0]
                            });
                          }}
                        />
                        <UploadIcon id="upload-icon" />
                        อัปโหลดรูปภาพ
                      </Button>
                      {props.formProps.errors.uploadFront && (
                        <ErrMessage id="error-3">
                          กรุณาอัปโหลดรูปถ่ายบัตรประชาชน(ด้านหน้า)
                        </ErrMessage>
                      )}
                      <StyledTextField
                        id="uploadFront"
                        name="uploadFront"
                        inputRef={props.formProps.register}
                        margin="normal"
                        variant="outlined"
                        value={citizenImageFront[0].preview}
                        style={{display: 'none'}}
                        // disabled
                      />
                    </RightContentDiv>
                  </UploadBox>
                </Grid>
                <Grid item xs={12} style={{ margin: "auto" }} id="item-2">
                  <StyledTextField
                    label="เลขหลังบัตรประชาชน (Laser Code)"
                    id="laserCode"
                    name="laserCode"
                    inputRef={props.formProps.register}
                    error={!!props.formProps.errors.laserCode}
                    placeholder="เลขหลังบัตรประชาชน (Laser Code) 12 หลัก"
                    margin="normal"
                    variant="outlined"
                    onChange={e => laserCode[1](e.target.value)}
                    defaultValue={laserCode[0]}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment id="adornment-2" position="end">
                          <IconAdorn id="disabled-2" disabled />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment id="adornment-2-end" position="end">
                          <LaserCard id="laser-icon" />
                        </InputAdornment>
                      )
                    }}
                    inputProps={{
                      maxLength: 12
                    }}
                  />
                  {props.formProps.errors.laserCode && (
                    <ErrorMessage id="error-2" primary="true">
                      {props.formProps.errors.laserCode.message}
                    </ErrorMessage>
                  )}
                </Grid>
              </Grid>
            </GridUploadImage>
            <GridUploadImage container item xs={12} id="grid-upload">
              <ImageText id="text-1">ภาพถ่ายหลังบัตรประชาชน</ImageText>
              <StyledTextField
                id="uploadBack"
                name="uploadBack"
                inputRef={props.formProps.register}
                margin="normal"
                variant="outlined"
                value={citizenImageBack[0].preview}
                style={{ display: "none" }}
                // disabled
              />
              <Grid container spacing={3} id="container-img">
                <Grid
                  item
                  xs={12}
                  id="item-4"
                  style={{ marginBottom: "2.75rem" }}
                >
                  <UploadBox
                    id="upload-box-2"
                    isNotUploaded={!!props.formProps.errors.uploadBack}
                  >
                    <RightContentDiv>
                      {citizenImageBack[0].preview ? (
                        <ImagePreview
                          id="img-preview-2"
                          src={citizenImageBack[0].preview}
                          alt="preview-back"
                        ></ImagePreview>
                      ) : (
                        <BackImg id="back-example" />
                      )}
                      <UploadTitleText id="text-1">
                        รูปถ่ายบัตรประชาชน(ด้านหลัง)
                      </UploadTitleText>
                      <UploadDescText id="text-2">
                        ท่านสามารถถ่าย <br /> หรืออัปโหลดรูปภาพจากเครื่องของท่าน
                      </UploadDescText>
                    </RightContentDiv>
                    <RightContentDiv>
                      <CamButton id="cam-btn" onClick={handleBackCameraOpen}>
                        <CameraIcon id="cam-icon" />
                        เปิดกล้องถ่ายรูป
                      </CamButton>
                      <CameraModal
                        id="cam-back"
                        props="back"
                        open={backOpen}
                        setOpen={setBackOpen}
                        handleClose={handleBackCameraClose}
                        handleOpen={handleBackCameraOpen}
                        iscameraopen={isCameraBackOpen.toString()}
                        onCapture={e => setCardImageBack(e)}
                        cardImage={cardImageBack}
                        setIsCanvasEmpty={setIsCanvasEmptyBack}
                        onClear={() => setCardImageBack(undefined)}
                        onConfirm={e =>
                          citizenImageBack[1]({
                            preview: URL.createObjectURL(e),
                            raw: e
                          })
                        }
                      />
                      <CamButton
                        mobile="true"
                        htmlFor="upload-button"
                        id="cam-btn"
                      >
                        <StyledInputMobile
                          type="file"
                          accept="image/*"
                          capture
                          id="upload-button"
                          onChange={e =>
                            citizenImageBack[1]({
                              preview: URL.createObjectURL(e.target.files[0]),
                              raw: e.target.files[0]
                            })
                          }
                        />
                        <CameraIcon id="cam-icon" />
                        เปิดกล้องถ่ายรูป
                      </CamButton>
                      <Button htmlFor="upload-button" id="upload-btn">
                        <StyledInput
                          type="file"
                          accept="image/*"
                          id="upload-button"
                          onChange={e =>
                            citizenImageBack[1]({
                              preview: URL.createObjectURL(e.target.files[0]),
                              raw: e.target.files[0]
                            })
                          }
                        />
                        <UploadIcon id="upload-icon" />
                        อัปโหลดรูปภาพ
                      </Button>
                      {props.formProps.errors.uploadBack && (
                        <ErrMessage id="error-3">
                          กรุณาอัปโหลดรูปถ่ายบัตรประชาชน(ด้านหลัง)
                        </ErrMessage>
                      )}
                    </RightContentDiv>
                  </UploadBox>
                </Grid>
              </Grid>
            </GridUploadImage>
            <GridUploadImage
              last="true"
              container
              item
              xs={12}
              id="grid-upload"
            >
              <ImageText correct="true" id="text-1">
                ภาพถ่ายตัวเองคู่บัตรประชาชน
              </ImageText>
              <ImageBlueText onClick={handleOpenModal} id="text-2">
                ดูตัวอย่างการถ่ายรูปที่ถูกต้อง
              </ImageBlueText>
              <StyledTextField
                id="uploadFace"
                name="uploadFace"
                inputRef={props.formProps.register}
                margin="normal"
                variant="outlined"
                value={citizenImageFace[0].preview}
                style={{ display: "none" }}
                // disabled
              />
              <GridHowto mobile="true" id="grid-howto" item xs={12}>
                <div id="container-howto">
                  <ImageText id="text1">การถ่ายภาพที่ดี...</ImageText>
                  {[
                    {
                      msg: (
                        <div id="check-div">
                          <CheckListTxt id="check-txt-1">
                            ต้องเห็นหน้า
                          </CheckListTxt>
                          <CheckListTxt id="check-txt-2" bold="true">
                            ชัดเจน
                          </CheckListTxt>
                        </div>
                      )
                    },
                    {
                      msg: (
                        <div id="check-div">
                          <CheckListTxt id="check-txt-1">
                            ต้องเห็นข้อมูลบนบัตรประชาชน
                          </CheckListTxt>
                          <CheckListTxt id="check-txt-2" bold="true">
                            ชัดเจน
                          </CheckListTxt>
                        </div>
                      )
                    },
                    {
                      msg: (
                        <div id="check-div">
                          <CheckListTxt id="check-txt-1">
                            เห็นป้าย/แผ่นกระดาษ VerME พร้อมวันที่
                          </CheckListTxt>
                          <CheckListTxt id="check-txt-2" bold="true">
                            ชัดเจน
                          </CheckListTxt>
                        </div>
                      )
                    }
                  ].map((item, index) => (
                    <CheckListDiv id="checklist" key={index}>
                      <div id="icon-div">
                        <CheckingIcon
                          id="check-icon"
                          src={IconChecking}
                          alt="icon-check"
                        />
                      </div>
                      {item.msg}
                    </CheckListDiv>
                  ))}
                </div>
              </GridHowto>
              <GridHowtoGroup
                id="grid-howto"
                container
                spacing={3}
                direction="column-reverse"
                justify="flex-end"
              >
                <Grid
                  item
                  xs={12}
                  id="item-5"
                  style={{ marginBottom: "2.75rem" }}
                >
                  <UploadBox
                    id="upload-box"
                    correct="true"
                    isNotUploaded={!!props.formProps.errors.uploadFace}
                  >
                    <div>
                      {citizenImageFace[0].preview ? (
                        <ImagePreview
                          id="img-preview-3"
                          src={citizenImageFace[0].preview}
                          alt="preview-selfie"
                        ></ImagePreview>
                      ) : (
                        <SelfieImg id="selfie-exam" />
                      )}
                      <UploadTitleText id="text-3" correct="true">
                        รูปถ่ายหน้าพร้อมบัตรประชาชน <br />
                        และกระดาษ VerME
                      </UploadTitleText>
                      <UploadDescText id="text-4">
                        ท่านสามารถถ่าย <br /> หรืออัปโหลดรูปภาพจากเครื่องของท่าน
                      </UploadDescText>
                    </div>
                    <RightContentDiv>
                      <GridHowto id="grid-howto" item xs={12}>
                        <div id="container-howto">
                          <ImageText id="text1">การถ่ายภาพที่ดี...</ImageText>
                          {[
                            {
                              msg: (
                                <div id="check-div">
                                  <CheckListTxt id="check-txt-1">
                                    ต้องเห็นหน้า
                                  </CheckListTxt>
                                  <CheckListTxt id="check-txt-2" bold="true">
                                    ชัดเจน
                                  </CheckListTxt>
                                </div>
                              )
                            },
                            {
                              msg: (
                                <div id="check-div">
                                  <CheckListTxt id="check-txt-1">
                                    ต้องเห็นข้อมูลบนบัตรประชาชน
                                  </CheckListTxt>
                                  <CheckListTxt id="check-txt-2" bold="true">
                                    ชัดเจน
                                  </CheckListTxt>
                                </div>
                              )
                            },
                            {
                              msg: (
                                <div id="check-div">
                                  <CheckListTxt id="check-txt-1">
                                    เห็นป้าย/แผ่นกระดาษ VerME พร้อมวันที่
                                  </CheckListTxt>
                                  <CheckListTxt id="check-txt-2" bold="true">
                                    ชัดเจน
                                  </CheckListTxt>
                                </div>
                              )
                            }
                          ].map((item, index) => (
                            <CheckListDiv id="checklist" key={index}>
                              <div id="icon-div">
                                <CheckIcon id="check-icon" />
                              </div>
                              {item.msg}
                            </CheckListDiv>
                          ))}
                        </div>
                      </GridHowto>
                      <CamButton onClick={handleFaceCameraOpen} id="cam-btn">
                        <CameraIcon id="cam-icon" />
                        เปิดกล้องถ่ายรูป
                      </CamButton>
                      <CameraModal
                        id="cam-face"
                        open={faceOpen}
                        setOpen={setFaceOpen}
                        handleClose={handleFaceCameraClose}
                        handleOpen={handleFaceCameraOpen}
                        iscameraopen={isCameraFaceOpen.toString()}
                        onCapture={e => setCardImageFace(e)}
                        cardImage={cardImageFace}
                        setIsCanvasEmpty={setIsCanvasEmptyFace}
                        onClear={() => setCardImageFace(undefined)}
                        onConfirm={e =>
                          citizenImageFace[1]({
                            preview: URL.createObjectURL(e),
                            raw: e
                          })
                        }
                      />
                      <CamButton
                        mobile="true"
                        htmlFor="upload-button"
                        id="cam-btn"
                      >
                        <StyledInputMobile
                          type="file"
                          accept="image/*"
                          capture
                          id="upload-button"
                          onChange={e =>
                            citizenImageFace[1]({
                              preview: URL.createObjectURL(e.target.files[0]),
                              raw: e.target.files[0]
                            })
                          }
                        />
                        <CameraIcon id="cam-icon" />
                        เปิดกล้องถ่ายรูป
                      </CamButton>
                      <Button htmlFor="upload-button" id="upload-btn">
                        <StyledInput
                          type="file"
                          accept="image/*"
                          id="upload-button"
                          onChange={e =>
                            citizenImageFace[1]({
                              preview: URL.createObjectURL(e.target.files[0]),
                              raw: e.target.files[0]
                            })
                          }
                        />
                        <UploadIcon id="upload-icon" />
                        อัปโหลดรูปภาพ
                      </Button>
                      {props.formProps.errors.uploadFace && (
                        <ErrMessage id="error-3">
                          กรุณาอัปโหลดภาพตัวเองคู่บัตรประชาชน
                        </ErrMessage>
                      )}
                    </RightContentDiv>
                  </UploadBox>
                </Grid>
              </GridHowtoGroup>
            </GridUploadImage>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
}
