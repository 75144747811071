import styled from "styled-components";
import React from "react";
import colors from "../common/Color";

const Root = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const GrayTxt = styled.div`
  font-family: "Sarabun-Regular";
  font-size: 1rem;
  letter-spacing: 0.03rem;
  line-height: 1.75rem;
  color: rgba(0, 0, 0, 0.6);
`;
const TextDiv = styled.div`
  border-bottom: 0.06rem solid #e0e0e0;
  padding-bottom: 1.25rem;
  margin-bottom: 1.25rem;
`;
const EditDiv = styled.div`
  border-bottom: 0.06rem solid #e0e0e0;
  padding-bottom: 1rem;
  margin-bottom: 0.5rem;
`;

const BlueTxt = styled.div`
  font-family: "Sarabun-Regular";
  font-size: 1rem;
  letter-spacing: 0.03rem;
  line-height: 1.75rem;
  color: ${colors.primary};
`;
const FirstLine = styled.div`
  display: flex;
  @media (max-width: 1280px) {
    display: block;
  }
`;
export default function SubTitle(props) {
  return (
    <Root id="root">
      <FirstLine id="first-line">
        <GrayTxt id="txt-gray">
          ข้อมูลทั้งหมดของคุณจะถูกเก็บไว้ด้วยระบบ &nbsp;
        </GrayTxt>
        <BlueTxt id="txt-blue">
          {" "}
          VBS (VerMe Blockchain Timestamp System)
        </BlueTxt>
      </FirstLine>
      {props.props === "edit" ? (
        <EditDiv id="edit-div">
          <GrayTxt id="txt-gray">
            โดยข้อมูลทั้งหมดจะมีเพียงคุณเท่านั้นที่สามารถมองเห็น และแก้ไขได้
          </GrayTxt>
        </EditDiv>
      ) : (
        <TextDiv id="txt-div">
          <GrayTxt id="txt-gray">
            โดยข้อมูลทั้งหมดจะมีเพียงคุณเท่านั้นที่สามารถมองเห็น และแก้ไขได้
          </GrayTxt>
        </TextDiv>
      )}
    </Root>
  );
}
