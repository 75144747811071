import React from "react";
import styled from "styled-components";
import Badge from "../assets/badge/badge_verified.svg";

const HeaderContent = styled.div`
  margin-bottom: 1rem;
  font-family: "SukhumvitSet-Text";
  font-size: 1.5rem;
  color: #3b54d4;
`;

const UpdateContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  opacity: 0.34;
  font-family: "Sarabun-Light";
  font-size: 0.88rem;
  color: #000000;
  letter-spacing: 0.25px;
  text-align: left;
  line-height: 20px;
`;
const ContentFrame = styled.div`
  margin-bottom: 1.75rem;
  @media (max-width: 1024px) {
    margin-bottom: 1.5rem;
  }
`;

const ContentHeader = styled.div`
  opacity: 0.87;
  font-family: "SukhumvitSet-SemiBold";
  font-size: 1.25rem;
  color: #000000;
  letter-spacing: 0.25px;
  margin-bottom: 0.5rem;
`;

const ContentData = styled.div`
  opacity: 0.6;
  font-family: "Sarabun-Regular";
  font-size: 1rem;
  color: #000000;
  letter-spacing: 0.5px;
  text-align: left;
  line-height: 28px;
  ul {
    margin: 0;
  }
  li {
    line-height: 35px;
  }
`;

const ImageFrame = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export default function PolicyContent(props) {
  return (
    <div id="root">
      <HeaderContent id="header-content">นโยบายการรายงานผู้ขาย</HeaderContent>
      <UpdateContent id="update-content">
        อัปเดตล่าสุดเมื่อวันที่ 14 กุมภาพันธ์ 2563
      </UpdateContent>
      <ContentFrame id="content-frame">
        <ContentHeader id="content-header">
          1. เพื่อใช้เป็นช่องทางแจ้งเหตุ ร้องเรียน ต่อการกระทำต่างๆที่ไม่ถูกต้อง
          เกี่ยวกับการกระทำผิดตามกฎหมาย ผิดจริยธรรม ผิดจรรยาบรรณ และอื่นๆ
          ของผู้ขาย
        </ContentHeader>
      </ContentFrame>
      <ContentFrame id="content-frame">
        <ContentHeader id="content-header">
          2. ผู้รายงานจำเป็นต้องให้ข้อมูลที่ชัดเจน และเป็นประโยชน์ต่อการตรวจสอบ
          ซึ่งข้อมูลที่ผู้รายงาน ต้องให้กับทาง VerME มีดังนี้
        </ContentHeader>
        <ContentData id="content-data">
          <ul id="ul">
            <li id="list">ชื่อและนามสกุล (ภาษาอังกฤษและภาษาไทย)</li>
            <li id="list">เบอร์โทร</li>
            <li id="list">E-mail</li>
            <li id="list">
              VerME ID ของบุคคลที่ผู้รายงานต้องการที่จะร้องทุกข์
            </li>
            <li id="list">รายละเอียดเหตุ หรือ หลักฐานต่างๆ ที่ผู้รายงานมี</li>
          </ul>
        </ContentData>
      </ContentFrame>
      <ContentFrame id="content-frame">
        <ContentHeader id="content-header">
          3. ข้อมูลของผู้รายงานทาง VerME
          จะเก็บไว้เท่าที่จำเป็นภายใต้วัตถุประสงค์อันชอบด้วยกฎหมาย
          ของผู้ควบคุมข้อมูลส่วนบุคคล
        </ContentHeader>
      </ContentFrame>
      <ContentFrame id="content-frame">
        <ContentHeader id="content-header">
          4. หากผู้รายงานไม่ปฏิบัติตาม
          ข้อกําหนดและเงื่อนไขตามคําขอใช้บริการฉบับนี้ในข้อใดข้อหนึ่ง ทาง VerME
          จะถือว่าท่านส่งข้อมูล ไม่ครบถ้วนสมบูรณ์ และส่งผลให้การดำเนินการสืบค้น
          ตรวจสอบหรือระงับบัตร ไม่สามารถดำเนินการต่อไปได้
        </ContentHeader>
      </ContentFrame>
      <ContentFrame id="content-frame">
        <ContentHeader id="content-header">
          5. ผู้รายงานจำเป็นต้องทำความเข้าใจ และยอมรับข้อตกลงและเงื่อนไขต่างๆของ
          VerME ทั้งหมด
        </ContentHeader>
      </ContentFrame>
      <ContentFrame id="content-frame">
        <ContentHeader id="content-header">
          6. หากมีการจงใจรายงานเหตุ หรือร้องเรียนอันเป็นเท็จ
          อาจส่งผลให้ผู้รายงาน มีความผิดตามกฎหมาย
        </ContentHeader>
        <ContentData id="content-data">
          <ul id="ul">
            <li id="list">
              มาตรา 137 ประมวลกฎหมายอาญา “ผู้ใด แจ้งข้อความอันเป็นเท็จ
              แก่เจ้าพนักงาน ซึ่ง อาจทำให้ ผู้อื่น หรือ ประชาชน เสียหาย
              ต้องระวางโทษ จำคุกไม่เกิน หกเดือน หรือ ปรับไม่เกิน หนึ่งพันบาท
              หรือ ทั้งจำทั้งปรับ”
            </li>
            <li id="list">
              มาตรา 14 ของพ.ร.บ.คอมพิวเตอร์ฯ ว่าด้วยเรื่อง ทุจริต หรือโดยหลอกลวง
              นำเข้าสู่ระบบคอมพิวเตอร์ซึ่งข้อมูลคอมพิวเตอร์ที่บิดเบือน
              หรือปลอมไม่ว่าทั้งหมดหรือบางส่วน หรือข้อมูลคอมพิวเตอร์อันเป็นเท็จ
              โดยประการที่น่าจะเกิดความเสียหาย แก่ประชาชน
              อันมิใช่การกระทำความผิดฐานหมิ่นประมาทตามประมวลกฎหมายอาญา
            </li>
          </ul>
        </ContentData>
      </ContentFrame>
      <ImageFrame id="img-frame">
        <img id="img" src={Badge} alt="badge-verified" />
      </ImageFrame>
    </div>
  );
}
