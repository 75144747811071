import styled from "styled-components";
import colors from "./Color";

const Title = styled.div`
  font-family: "SukhumvitSet-Bold";
  font-size: 2.13rem;
  letter-spacing: 0.02rem;
  line-height: 3.38rem;
  color: ${colors.primary};
  @media (max-width: 1024px) {
    text-align: center;
    font-size: 1.5rem;
    letter-spacing: 0;
    line-height: 2.31rem;
    margin-bottom: 1.5rem;
  }
`;

export default Title;
