import React, { useContext } from "react";
import styled from "styled-components";
import BankBox from "./BankBox";
import CardBox from "./CardBox";
import InfoBox from "./InfoBox";
import colors from "../common/Color";
import NavBar from "../common/NavBar";
import NavMobile from "../common/NavMobile";
import Skeleton from "@material-ui/lab/Skeleton";
import { LoginContext } from "../Context/LoginStore";

const Root = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: center;
  background-color: ${colors.surfaceGray};
`;
const Container = styled.div`
  width: 58.75rem;
  height: 56.63rem;
  display: flex;
  flex-wrap: wrap;
  margin-top: 6.69rem;
  margin-bottom: 3.63rem;
  justify-content: space-between;
  @media (max-width: 1023px) {
    width: 44rem;
    height: 51.63rem;
  }
  @media (max-width: 767px) {
    width: 90%;
    margin: 5.38rem 5% 3.19rem;
  }
`;

const SkeletonBankBox = styled(Skeleton)`
  width: 33.7rem;
  height: 25.38rem !important;
  transform: none !important;
  border-radius: 0.5rem !important;
  @media (max-width: 1023px) {
    width: 25.7rem;
    height: 22.38rem !important;
  }
  @media (max-width: 767px) {
    width: 100%;
    height: 26.5rem !important;
    margin-bottom: 1.5rem !important;
  }
`;

const SkeletonCardBox = styled(Skeleton)`
  width: 23.75rem;
  height: 25.38rem !important;
  transform: none !important;
  border-radius: 0.5rem !important;
  @media (max-width: 1023px) {
    width: 17.5rem;
    height: 22.38rem !important;
  }
  @media (max-width: 767px) {
    width: 100%;
    height: 21.5rem !important;
    margin-bottom: 1.5rem !important;
  }
`;

const SkeletonInfoBox = styled(Skeleton)`
  width: 58.75rem;
  height: 27.25rem !important;
  transform: none !important;
  border-radius: 0.5rem !important;
  @media (max-width: 1023px) {
    width: 44rem;
  }
  @media (max-width: 767px) {
    width: 100%;
    height: 62.31rem !important;
    margin-bottom: 1.5rem !important;
  }
`;

export default function ProfilePage() {
  const { userLogin } = useContext(LoginContext);

  return (
    <Root id="root">
      <NavBar id="navbar" props="signin" />
      <NavMobile id="nav-mb" />
      {userLogin[0].userProfile ? (
        <Container id="container">
          <BankBox id="bank-box" />
          <CardBox id="card-box" />
          <InfoBox id="info-box" />
        </Container>
      ) : (
        <Container id="container">
          <SkeletonBankBox id="skeleton-box" />
          <SkeletonCardBox id="skeleton-box" />
          <SkeletonInfoBox id="skeleton-box" />
        </Container>
      )}
    </Root>
  );
}
