import React from "react";
import styled from "styled-components";
import colors from "../common/Color";
import { ReactComponent as CardStack } from "../assets/Card/card_03_stack.svg";
import { ReactComponent as IconCheck } from "../assets/Icons/Icons Black Big/ic_big_check.svg";
import { ReactComponent as IconShield } from "../assets/Icons/Icons Black Big/ic_big_shield.svg";
import { ReactComponent as IconTrust } from "../assets/Icons/Icons Black Big/ic_big_trust.svg";
import TitleText from "./TitleText";
import ContentText from "./ContentText";
import Divider from "./Divider";
import SubtitleText from "./SubtitleText";

const Root = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const Container = styled.div`
  width: 69.63rem;
  border-bottom: 0.06rem solid rgba(0, 0, 0, 0.2);
  @media (max-width: 1190px) {
    width: 59.63rem;
  }
  @media (max-width: 1024px) {
    width: 23.38rem;
  }
  @media (max-width: 375px) {
    width: 17rem;
  }
`;
const CardImg = styled(CardStack)`
  width: 34.96rem;
  height: 25.51rem;
  margin-top: 3.56rem;
  margin-left: 17rem;
  @media (max-width: 1190px) {
    margin-left: 13rem;
  }
  @media (max-width: 1024px) {
    width: 17.48rem;
    height: 12.76rem;
    margin: 1.9rem 0 0 3rem;
  }
  @media (max-width: 375px) {
    margin: 1.9rem 0 0;
  }
`;
const HelpText = styled(ContentText)`
  opacity: 0.34;
  font-size: 0.88rem;
`;
const IcShield = styled(IconShield)`
  font-size: 4.25rem;
  fill: ${colors.primary};
`;
const IcTrust = styled(IconTrust)`
  font-size: 4.25rem;
  fill: ${colors.primary};
`;
const IcCheck = styled(IconCheck)`
  font-size: 4.25rem;
  fill: ${colors.primary};
`;
const ContentRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 5.5rem 0 8rem;
  @media (max-width: 1024px) {
    display: block;
    margin: 3rem 0;
  }
`;
const ContentBox = styled.div`
  width: 21.88rem;
  height: 18.75rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  @media (max-width: 1024px) {
    width: 17rem;
    height: 15.88rem;
    margin: 0 auto 1.5rem;
  }
`;
const ContentDetail = styled.div`
  width: 17.64rem;
`;
const EachContent = [
  {
    icon: <IcShield />,
    subtitle: "ความปลอดภัย",
    desc:
      "ผู้ขายสามารถใช้ VerME Card แทนบัตรประชาชนเพื่อสร้างความเชื่อใจให้กับผู้ซื้อได้ โดยไม่ต้องเสี่ยงเปิดเผยข้อมูลส่วนบุคคลของผู้ขาย"
  },
  {
    icon: <IcTrust />,
    subtitle: "ความมั่นใจ",
    desc:
      "เราเก็บข้อมูลผู้ขายด้วยระบบ VBS ที่พัฒนาขึ้นเอง และการเก็บข้อมูลเป็นไปตามกฎหมายพรบ. คุ้มครองข้อมูลส่วนบุคคล (PDPA) (กำลังพัฒนา)"
  },
  {
    icon: <IcCheck />,
    subtitle: "เราการันตีคุณให้กับผู้ซื้อ",
    desc:
      "ผู้ขายที่ลงทะเบียนกับ VerME จะได้รับการยืนยันตัวตนจากธนาคาร และผ่านเทคโนโลยีตรวจจับใบหน้าจากรูปภาพของผู้ขายที่เราเก็บ"
  }
];
export default function SellerSection() {
  return (
    <Root id="root">
      <Container id="container">
        <TitleText id="title">
          ผู้ขายจะได้รับอะไร <br />
          หากผู้ขายยืนยันตัวตนสำเร็จ?
        </TitleText>
        <Divider id="divider" />
        <ContentText id="ct-txt" subtitle="true">
          ผู้ขายสามารถใช้ Electronic Card นี้ ในการการันตีผู้ขายให้กับผู้ซื้อ
          เพื่อเพิ่มความมั่นใจในการซื้อสินค้าบนโซเชียล
          และไม่ต้องให้ข้อมูลส่วนบุคคลกับผู้อื่นอีกต่อไป
        </ContentText>
        <a
          id="link"
          href="/identity/vm-xxxxxx"
          target="_blank"
          rel="noopener noreferrer"
        >
          <CardImg id="card-img" />
        </a>
        <HelpText id="help-txt">
          คลิกที่ภาพเพื่อดูตัวอย่างการใช้งานจริง
        </HelpText>
        <ContentRow id="ct-row">
          {EachContent.map((item, index) => (
            <ContentBox id="ct-box" key={index}>
              {item.icon}
              <SubtitleText id="subtitle-txt">{item.subtitle}</SubtitleText>
              <ContentDetail id="content-detail">
                <ContentText id="ct-txt">{item.desc}</ContentText>
              </ContentDetail>
            </ContentBox>
          ))}
        </ContentRow>
      </Container>
    </Root>
  );
}
