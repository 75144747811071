import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { animateScroll as scroll } from "react-scroll";
import colors from "./Color";
import { ReactComponent as LogoVerme } from "../assets/Logo/logo_verme_dark.svg";
import { ReactComponent as MenuIcon } from "../assets/Icons/Icons Black/ic_menu.svg";
import { ReactComponent as CloseIcon } from "../assets/Icons/Icons Black/ic_cross_3.svg";
import Linker from "@material-ui/core/Link";
import Button from "@material-ui/core/Button";
import WebButton from "./WebButton";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { ReactComponent as IconId } from "../assets/Icons/Icons Black/ic_id_card.svg";
import { ReactComponent as IconLogout } from "../assets/Icons/Icons Black/ic_logout.svg";
import { ReactComponent as IconPerson } from "../assets/Icons/Icons Black/ic_person.svg";
import { ReactComponent as IconRenew } from "../assets/Icons/Icons Black/ic_renew.svg";
import { makeStyles } from "@material-ui/core/styles";
import { LoginContext } from "../Context/LoginStore";
import { useAuth } from "../use-auth";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const Root = styled.div`
  @media (min-width: 1025px) {
    display: none;
  }
`;
const Container = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  height: 3.63rem;
  background-color: ${colors.white};
  z-index: 10;
  position: fixed;
`;
const Menu = styled.div`
  width: 100%;
  height: calc(100vh - 3.5rem);
  background-color: ${colors.white};
  z-index: 9;
  position: fixed;
  top: 0;
  left: 0;
  margin-top: 3.5rem;
  transform: ${({ openmenu }) =>
    openmenu === "true" ? "translateY(0%)" : "translateY(-110%)"};
  transition: 0.3s cubic-bezier(0, 1, 0.5, 1);
`;
const TopMenu = styled.div`
  display: flex;
  flex-direction: column;
  height: 75%;
  justify-content: center;
`;
const BottomMenu = styled.div`
  width: 100%;
  height: 8.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top: 0.06rem solid #e8e8e8;
  bottom: 0;
  position: fixed;
`;
const Nav = styled.div`
  width: 90%;
  height: 3.63rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 5%;
  position: relative;
`;
const Logo = styled(LogoVerme)`
  width: 3.84rem;
  height: 1rem;
  cursor: pointer;
`;
const MenuIc = styled(MenuIcon)`
  font-size: 1.5rem;
  opacity: 0.6;
  cursor: pointer;
  left: 0;
  position: absolute;
  opacity: ${({ openmenu }) => (openmenu === "true" ? 0 : 1)};
  transform: ${({ openmenu }) =>
    openmenu === "true" ? "rotate(90deg)" : "rotate(0deg)"};
  transition: all 0.3s ease-in;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
`;
const CloseIc = styled(CloseIcon)`
  font-size: 1.38rem;
  opacity: 0.6;
  left: 0;
  position: absolute;
  cursor: pointer;
  opacity: ${({ openmenu }) => (openmenu === "true" ? 1 : 0)};
  transform: ${({ openmenu }) =>
    openmenu === "true" ? "rotate(0deg)" : "rotate(-90deg)"};
  transition: all 0.3s ease-out;
`;
const StyledLink = styled(Linker)`
  && {
    margin: 5% 5rem;
    font-family: "SukhumvitSet-Text";
    font-size: 0.88rem;
    letter-spacing: 0.02rem;
    line-height: 1.25rem;
    color: rgba(0, 0, 0, 0.87);
    cursor: pointer;
    &:hover {
      color: ${colors.primary};
    }
    @media (min-width: 600px) {
      margin: 5% 10rem;
    }
  }
`;
const RegistBtn = styled(WebButton)`
  && {
    margin: 0.5rem 2rem 1rem;
  }
`;
const SignBtn = styled(Button)`
  && {
    margin: 1rem 2rem 0.5rem;
    width: 16rem;
    height: 2.75rem;
    opacity: 0.87;
    font-family: "SukhumvitSet-Text";
    font-size: 14px;
    color: #000000;
    letter-spacing: 0.25px;
    text-align: center;
    line-height: 20px;
  }
`;
const NameText = styled.div`
  opacity: ${props => (props.dark ? "0.6" : "0.87")};
  font-family: "SukhumvitSet-SemiBold";
  font-size: 1rem;
  color: #000000;
  letter-spacing: 0.03rem;
  text-align: left;
  line-height: 1.25rem;
`;
const Circle = styled.div`
  background: ${colors.primary};
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "SukhumvitSet-Medium";
  font-size: 1.25rem;
  color: rgba(255, 255, 255, 0.81);
  letter-spacing: 0.04rem;
  text-align: center;
  margin-right: 0.75rem;
`;
const ThinText = styled.div`
  opacity: 0.6;
  font-family: "SukhumvitSet-Text";
  font-size: 14px;
  color: #000000;
  letter-spacing: 0.25px;
  text-align: left;
  line-height: 20px;
`;
const InfoDiv = styled.div`
  height: 5.75rem;
  display: flex;
  align-items: center;
`;
const IcId = styled(IconId)`
  font-size: 1rem;
  fill: rgba(0, 0, 0, 0.6);
`;
const IcLogout = styled(IconLogout)`
  font-size: 1rem;
  fill: rgba(0, 0, 0, 0.6);
`;
const IcPerson = styled(IconPerson)`
  font-size: 1rem;
  fill: rgba(0, 0, 0, 0.6);
`;
const IcRenew = styled(IconRenew)`
  font-size: 1rem;
  fill: rgba(0, 0, 0, 0.6);
`;
const MenuBtn = styled(ListItem)`
  && {
    width: 100%;
    height: 2.63rem;
    font-family: "SukhumvitSet-Text";
    font-size: 0.88rem;
    color: rgba(0, 0, 0, 0.6);
    letter-spacing: 0.25px;
    text-align: left;
    line-height: 20px;
    text-transform: none;
    margin: ${props => (props.logout ? "0.38rem 0 1.5rem" : "0")};
    cursor: pointer;
  }
`;
const StyledListIc = styled(ListItemIcon)`
  && {
    min-width: 2.25rem;
  }
`;
const TopMenuSign = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 10%;
  justify-content: center;
  border-bottom: 0.06rem solid #e8e8e8;
`;
const BottomMenuSign = styled.div`
  width: 100%;
  margin: 1.5rem 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const useStyles = makeStyles({
  btnInactive: {
    margin: "5% auto",
    fontFamily: "SukhumvitSet-Text",
    fontSize: "0.88rem",
    letterSpacing: "0.02rem",
    lineHeight: "1.25rem",
    color: "rgba(0, 0, 0, 0.87)",
    cursor: "pointer",
    "&:hover": {
      color: colors.primary
    }
  },
  btnActive: {
    margin: "5% auto",
    fontFamily: "SukhumvitSet-Text",
    fontSize: "0.88rem",
    letterSpacing: "0.02rem",
    lineHeight: "1.25rem",
    color: colors.primary,
    cursor: "pointer"
  }
});
const LogoutButton = styled(ListItem)`
  && {
    width: 100%;
    height: 2.63rem;
    font-family: "SukhumvitSet-Text";
    font-size: 0.88rem;
    color: rgba(0, 0, 0, 0.6);
    letter-spacing: 0.25px;
    text-align: left;
    line-height: 20px;
    text-transform: none;
    margin: ${props => (props.logout ? "0.38rem 0 1.5rem" : "0")};
    cursor: pointer;
  }
`;
function ListItemLink(props) {
  return <MenuBtn button component="a" {...props} />;
}
export default function NavMobile(props) {
  const { signOutWithEmail, signOutWithFacebook } = useAuth();
  const [boxShadow, setBoxShadow] = useState("0 0 0 0 rgba(0,0,0,0)");
  const [openMenu, setOpenMenu] = useState(false);
  const { userLogin } = useContext(LoginContext);
  // eslint-disable-next-line
  const classes = useStyles();

  const firstLetter = () => {
    if (userLogin[0].userProfile) {
      var splitLetter = userLogin[0].userProfile.display.name.data.en.split("");
      var upperLetter = splitLetter[0].toUpperCase();
      return upperLetter;
    } else {
      return " ";
    }
  };

  const handleClickLogo = () => {
    scroll.scrollToTop();
    setOpenMenu(false);
  };

  const listenScrollEvent = e => {
    if (window.scrollY > 100) {
      setBoxShadow("0 0.13rem 0.25rem 0 rgba(0,0,0,0.03)");
    } else {
      setBoxShadow("0 0 0 0 rgba(0,0,0,0)");
    }
  };

  const loginPage = () => {
    window.location.href = "/sign-in";
  };

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);
  },[]);

  const logout = async () => {
    try {
      if (userLogin[0].loginType === "email") {
        const res = await signOutWithEmail();
        if (res.status === 200) {
          userLogin[1]({});
        }
      } else if (userLogin[0].loginType === "facebook") {
        const res = await signOutWithFacebook();
        if (res.status === 200) {
          userLogin[1]({});
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Root id="root">
      <Container id="container" style={{ boxShadow: boxShadow }}>
        <Nav id="nav">
          <CloseIc
            id="ic-close"
            onClick={() => setOpenMenu(!openMenu)}
            openmenu={openMenu.toString()}
            setopenmenu={setOpenMenu.toString()}
          />
          <MenuIc
            id="ic-menu"
            onClick={() => setOpenMenu(!openMenu)}
            openmenu={openMenu.toString()}
            setopenmenu={setOpenMenu.toString()}
          />
          {props.props === "homepage" ? (
            <Logo id="logo" onClick={handleClickLogo} />
          ) : (
            <a id="link" href="/">
              <Logo id="logo" />
            </a>
          )}
        </Nav>
      </Container>
      {/* Before SignIn */}
      {cookies.get("token") ? (
        <Menu
          id="menu"
          openmenu={openMenu.toString()}
          setopenmenu={setOpenMenu.toString()}
        >
          <TopMenuSign id="top-menu">
            <InfoDiv id="info-div">
              <Circle id="circle">{firstLetter()}</Circle>
              <div id="info-div-in">
                <NameText id="name">
                  {" "}
                  {userLogin[0].userProfile
                    ? userLogin[0].userProfile.display.name.data.th
                    : ""}{" "}
                </NameText>
                <ThinText id="vm-id">
                  {" "}
                  {userLogin[0].userProfile
                    ? userLogin[0].userProfile.display.vm_id
                    : ""}{" "}
                </ThinText>
              </div>
            </InfoDiv>
            <ListItemLink id="list-item-link" href="/profile">
              <StyledListIc id="st-list-ic">
                <IcPerson id="ic-person" />
              </StyledListIc>
              โปรไฟล์
            </ListItemLink>
            <ListItemLink
              id="list-item-link"
              href={
                "/identity/" +
                (userLogin[0].userProfile
                  ? userLogin[0].userProfile.display.vm_id
                  : "")
              }
            >
              <StyledListIc id="st-list-ic">
                <IcId id="ic-id" />
              </StyledListIc>
              VerME Card
            </ListItemLink>
            <ListItemLink id="list-item-link" href="/payment-page">
              <StyledListIc id="st-list-ic">
                <IcRenew id="ic-renew" />
              </StyledListIc>
              ต่ออายุ
            </ListItemLink>
            <LogoutButton id="logout-btn" onClick={logout}>
              <StyledListIc id="st-list-ic">
                <IcLogout id="ic-logout" />
              </StyledListIc>
              ออกจากระบบ
            </LogoutButton>
          </TopMenuSign>
          <BottomMenuSign id="bottom-menu">
            <ListItemLink id="list-item-link" href="/how-to">
              วิธีใช้งาน
            </ListItemLink>
            <ListItemLink id="list-item-link" href="/identity-check">
              ตรวจสอบตัวตน
            </ListItemLink>
            <ListItemLink id="list-item-link" href="/report-form">
              รายงานผู้ขาย
            </ListItemLink>
            <ListItemLink id="list-item-link" href="/faq">
              คำถามที่พบบ่อย
            </ListItemLink>
          </BottomMenuSign>
        </Menu>
      ) : (
        <Menu
          id="menu"
          openmenu={openMenu.toString()}
          setopenmenu={setOpenMenu.toString()}
        >
          <TopMenu id="top-menu">
            <StyledLink id="st-link" underline="none" href="/how-to">
              วิธีใช้งาน
            </StyledLink>
            <StyledLink id="st-link" underline="none" href="/identity-check">
              ตรวจสอบตัวตน
            </StyledLink>
            <StyledLink id="st-link" underline="none" href="/report-form">
              รายงานผู้ขาย
            </StyledLink>
            <StyledLink id="st-link" underline="none" href="/pricing">
              ซื้อแพ็กเกจ
            </StyledLink>
            <StyledLink id="st-link" underline="none" href="/faq">
              คำถามที่พบบ่อย
            </StyledLink>
          </TopMenu>
          <BottomMenu id="bottom-menu">
            <SignBtn id="sign-in-btn" onClick={loginPage}>
              เข้าสู่ระบบ
            </SignBtn>
            <RegistBtn
              id="regist-btn"
              primary="true"
              width="16rem"
              height="2.75rem"
              href="/prepare-before-register"
              target="_blank"
              rel="noopener noreferrer"
              nav="true"
            >
              ลงทะเบียน
            </RegistBtn>
          </BottomMenu>
        </Menu>
      )}
    </Root>
  );
}
