import React from "react";
import PricingDetail from "./PricingDetail";
import NavBar from "../common/NavBar";
import NavMobile from "../common/NavMobile";
import FaqPage from "./FAQpage";
import Footer from "../common/FooterBar";

export default function Pricing() {
  return (
    <div id="root">
      <NavBar id="navbar" props="pricing" />
      <NavMobile id="nav-mb" />
      <PricingDetail id="pricing-detail" />
      <FaqPage id="faq-page" />
      <Footer id="footer" />
    </div>
  );
}
