import styled from "styled-components";
import Button from "@material-ui/core/Button";
import colors from "../common/Color";

const WebButton = styled(Button)`
  && {
    width: ${props => props.width};
    height: ${props => props.height};
    color: ${props => (props.primary ? colors.white : colors.primary)};
    font-family: "SukhumvitSet-Semibold";
    font-size: 0.88rem;
    letter-spacing: 0.08rem;
    line-height: 1rem;
    background-color: ${props =>
      props.primary ? colors.primary : colors.white};
    border-radius: 0.25rem;
    border: ${props => (props.primary ? "none" : "0.06rem solid #3b54d4")};
    box-shadow: ${props =>
      props.primary ? "0 0.06rem 0.06rem 0 rgba(0,0,0,0.50)" : ""};
    margin: ${props => (props.nav ? "0 1.69rem" : "")};
    &:hover {
      background: ${props =>
        props.primary ? colors.primaryDark : "transparent"};
    }
    @media (max-width: 1439px) {
      margin: ${props => (props.nav ? "0 0.69rem" : "")};
    }
    @media (max-width: 1170px) {
      margin: ${props => (props.nav ? "0 0.35rem" : "")};
    }
  }
  &.MuiButton-root {
    text-transform: none;
  }
  &.MuiButton-root.Mui-disabled {
    color: #939394;
    background-color: #DEDEE0;
  }
`;

export default WebButton;
