import fetch from "../services/fetch";

export const getTest = async () => {
  const method = "GET";
  const path = "";
  return await fetch(method, path, {}, {}, {});
};

export const getIdentity = async id => {
  const method = "GET";
  const path = "display/identity/" + id;
  return await fetch(method, path, {}, {}, {});
};

export const getAllIdentityWithFb = async (id) => {
  const method = "GET";
  const path = "identity/" + id + "?ga-category=page_identity&ga-action=fbclid";
  return await fetch(method, path, {}, {}, {});
};

export const getAllIdentityWithQr = async (id) => {
  const method = "GET";
  const path = "identity/" + id + "?ga-category=page_identity&ga-action=verme-card.qrcode";
  return await fetch(method, path, {}, {}, {});
};

export const getAllIdentityCheck = async (id) => {
  const method = "GET";
  const path = "identity/" + id + "?ga-category=page_identity";
  return await fetch(method, path, {}, {}, {});
};

export const getAllIdentityLink = async (id) => {
  const method = "GET";
  const path = "identity/" + id + "?ga-category=page_identity&ga-action=verme-card.link";
  return await fetch(method, path, {}, {}, {});
}
export const getAllIdentity = async id => {
  const method = "GET";
  const path = "identity/" + id;
  return await fetch(method, path, {}, {}, {});
};

export const getResetPasswordLink = async id => {
  const method = "GET";
  const path = "recovery/code/" + id;
  return await fetch(method, path, {}, {}, {});
};

export const postPayment = async data => {
  const method = "POST";
  const path = "create/payment";
  return await fetch(method, path, data, {}, {});
};

export const postImagePayment = async (id, data) => {
  const method = "POST";
  const path = "payment/image/" + id;
  return await fetch(method, path, data, {}, {});
};

export const putPayment = async (id, data) => {
  const method = "PUT";
  const path = "edit/payment/" + id;
  return await fetch(method, path, data, {}, {});
};

export const sendEmailPayment = async id => {
  const method = "POST";
  const path = "payment/send/email/" + id;
  return await fetch(method, path, {}, {}, {});
};

export const sendCheckOTPRegister = async data => {
  const method = "PUT";
  const path = "otp/phone";
  return await fetch(method, path, data, {}, {});
};

export const sendEmailPaymentRegister = async id => {
  const method = "POST";
  const path = "send/email/" + id;
  return await fetch(method, path, {}, {}, {});
};

export const createBankRegister = async (id, data) => {
  const method = "PUT";
  const path = "create/bank/" + id;
  return await fetch(method, path, data, {}, {});
};

export const createUserRegister = async data => {
  const method = "POST";
  const path = "create/user";
  return await fetch(method, path, data, {}, {});
};

export const createOTPRegister = async data => {
  const method = "POST";
  const path = "otp/phone";
  return await fetch(method, path, data, {}, {});
};

export const createImageRegister = async (id, data) => {
  const method = "POST";
  const path = "create/image/" + id;
  return await fetch(method, path, data, {}, {});
};

export const putDataRegister = async (id, data) => {
  const method = "PUT";
  const path = "create/data/" + id;
  return await fetch(method, path, data, {}, {});
};

export const sendCheckOTPReport = async data => {
  const method = "PUT";
  const path = "report/otp/phone";
  return await fetch(method, path, data, {}, {});
};

export const uploadImageReport = async (id, data) => {
  const method = "POST";
  const path = "report/image/" + id;
  return await fetch(method, path, data, {}, {});
};

export const editReportData = async (id, data) => {
  const method = "PUT";
  const path = "edit/report/" + id;
  return await fetch(method, path, data, {}, {});
};

export const sendEmailReport = async id => {
  const method = "POST";
  const path = "report/send/email/" + id;
  return await fetch(method, path, {}, {}, {});
};

export const createReportData = async data => {
  const method = "POST";
  const path = "create/report";
  return await fetch(method, path, data, {}, {});
};

export const sendOTPReport = async data => {
  const method = "POST";
  const path = "report/otp/phone";
  return await fetch(method, path, data, {}, {});
};

export const getCountStatus = async () => {
  const method = "GET";
  const path = "status";
  return await fetch(method, path, {}, {}, {});
};

export const confirmEmailUser = async id => {
  const method = "PUT";
  const path = "confirm-email/" + id;
  return await fetch(method, path, {}, {}, {});
};

export const putResetPassword = async (uid, otp, refer, data) => {
  const method = "PUT";
  const path = "/recovery/password?u=" + uid + "&o=" + otp + "&r=" + refer;
  return await fetch(method, path, data, {}, {});
};

export const sendEmailConfirm = async id => {
  const method = "POST";
  const path = "confirm-email/" + id;
  return await fetch(method, path, {}, {}, {});
};

export const setPasswordUser = async (id, data) => {
  const method = "POST";
  const path = "signup/" + id;
  return await fetch(method, path, data, {}, {});
};

export const sendEmailResetPassword = async data => {
  const method = "POST";
  const path = "recovery/password";
  return await fetch(method, path, data, {}, {});
};

export const login = async (type, data) => {
  const method = "POST";
  const path = "login/" + type;
  return await fetch(method, path, data, {}, {});
};

export const logout = async (type, sessionId, token) => {
  const method = "POST";
  const path = "logout/" + type + "?sessionId=" + sessionId;
  return await fetch(method, path, {}, token, {});
};

export const getUserProfile = async (token, sessionId) => {
  const method = "GET";
  const path = "profile";
  console.log(sessionId)
  return await fetch(method, path, sessionId, token, {});
};

export const postCovidPromotion = async data => {
  const method = "POST";
  const path = "promotion/covid-19";
  return await fetch(method, path, data);
};

export const getImagePreview = async id => {
  const method = "GET";
  const path = "identity/preview/" + id;
  return await fetch(method, path, {}, {}, {});
};
