import React, { useState } from "react";
import colors from "../common/Color";
import styled from "styled-components";
import Subtitle from "./Subtitle";
import { ReactComponent as BankIcon } from "../assets/Icons/ic_bank_account_outlined.svg";
import { ReactComponent as SmartPhoneIcon } from "../assets/Icons/ic_smartphone.svg";
import { ReactComponent as IcKbank } from "../assets/Icons Bank/ic_kbank.svg";
import { ReactComponent as QrCode } from "../assets/QR code/qr_code_promptpay.svg";
import TextField from "@material-ui/core/TextField";
import ErrorMessage from "../common/ErrorMessage";

const ImgQrCode = styled(QrCode)`
  width: 7.31rem;
  height: 7.31rem;
`;
const BlueText = styled.div`
  font-family: "SukhumvitSet-Semibold";
  font-size: 1rem;
  margin-bottom: 0.5rem;
  color: ${colors.primary};
  letter-spacing: 0.013rem;
`;
const BankAccTxt = styled.div`
  font-family: "Sarabun-Regular";
  font-size: 0.88rem;
  color: ${colors.black};
  opacity: 0.6;
  letter-spacing: 0.016rem;
  line-height: 1.25rem;
`;
const IndexTxt = styled.div`
  font-family: "Sarabun-Regular";
  font-size: 0.75rem;
  margin: 0.5rem 0;
  color: ${colors.black};
  opacity: 0.34;
  letter-spacing: 0.013rem;
  line-height: 1.25rem;
`;
const BankDescDiv = styled.div`
  padding: 0.5rem;
`;
const QrDiv = styled.div`
  display: flex;
  margin: 1.5rem 1.8rem 0;
`;
const ChoiceBtn = styled.div`
  width: 100%;
  height: 3.5rem;
  border: 0.06rem solid #d6d6d6;
  border-top: ${props => (props.primary ? "" : "none")};
  border-radius: ${props =>
    props.primary ? "0.38rem 0.38rem 0 0" : "0 0 0.38rem 0.38rem"};
  cursor: ${props => (props.secondary ? "default" : "pointer")};
  display: flex;
  margin-bottom: ${props => (props.primary ? "0" : "2.5rem")};
`;
const ChoiceDetail = styled.div`
  width: 100%;
  border: 0.06rem solid #d6d6d6;
  border-top-style: none;
  padding: 2rem 0 1.5rem;
  height: 100%;
`;
const ChoiceText = styled.div`
  font-family: "SukhumvitSet-Text";
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.87);
  letter-spacing: 0.03rem;
  line-height: 1.75rem;
  margin: auto 0;
`;
const BankIc = styled(BankIcon)`
  font-size: 1.5rem;
  margin: 1rem 1rem 1rem 1.5rem;
`;
const SmartPhoneIc = styled(SmartPhoneIcon)`
  font-size: 1.5rem;
  fill: rgba(0, 0, 0, 0.87);
  margin: 1rem 1rem 1rem 1.5rem;
`;
const CmdText = styled.div`
  font-family: "Sarabun-Regular";
  font-size: 0.9rem;
  letter-spacing: 0.03rem;
  line-height: 1.75rem;
  color: ${colors.black};
  opacity: 0.6;
  margin: auto auto 1.5rem 1.5rem;
`;
const BankNumText = styled.div`
  font-family: "Sarabun-Regular";
  font-size: 1rem;
  letter-spacing: 0.03rem;
  line-height: 1.75rem;
  color: ${colors.black};
  opacity: 0.87;
`;
const BankAccText = styled.div`
  font-family: "Sarabun-Regular";
  font-size: 0.88rem;
  letter-spacing: 0.02rem;
  line-height: 1.25rem;
  color: ${colors.black};
  opacity: 0.6;
`;
const DetailDiv = styled.div`
  margin-left: 1rem;
`;
const BankDiv = styled.div`
  display: flex;
`;
const OtherChoiceBtn = styled.div`
  width: 100%;
  height: 2.75rem;
  margin: 1rem 0;
  font-family: "Roboto";
  font-weight: 600;
  font-size: 0.88rem;
  letter-spacing: 0.08rem;
  line-height: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.6;
  cursor: pointer;
`;
const StyledTextfield = styled(TextField)`
  && {
    margin: 0 0.63rem 0 1.5rem;
    width: 70%;
  }
  & .MuiFormLabel-root {
    border-color: #d6d6d6;
  }
  & .MuiOutlinedInput-input {
    padding: 13px 14px;
  }
`;
const UploadBtn = styled.label`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    height: 2.75rem;
    width: 25%;
    font-family: "SukhumvitSet-SemiBold";
    font-size: 0.88rem;
    letter-spacing: 0.08rem;
    line-height: 1rem;
    color: ${colors.white};
    background: ${colors.primary};
    margin-right: 1.31rem;
    box-shadow: 0 0.06rem 0.06rem 0 rgba(0, 0, 0, 0.5);
    &:hover {
      background: ${colors.primaryDark};
    }
  }
`;
const UploadDiv = styled.div`
  display: flex;
  margin: 1.5rem 0;
`;
export default function SecondStep(props) {
  const { setMethodPaymentData } = props;
  const [openChoice, setOpenChoice] = useState(true);
  const [openBank, setOpenBank] = useState(false);
  const [openPrompt, setOpenPrompt] = useState(false);

  const handleOpenChoice = () => {
    setOpenChoice(true);
    setOpenBank(false);
    setOpenPrompt(false);
  };
  const handleOpenBank = () => {
    setMethodPaymentData("Bank transfer");
    setOpenChoice(false);
    setOpenBank(true);
    setOpenPrompt(false);
  };
  const handleOpenPrompt = () => {
    setMethodPaymentData("Promtpay");
    setOpenChoice(false);
    setOpenBank(false);
    setOpenPrompt(true);
  };
  return (
    <div id="root">
      <Subtitle id="sub-title" style={{ marginBottom: "1rem" }}>
        เลือกวิธีการจ่ายเงิน
      </Subtitle>
      {props.formProps.errors.fileName && (
        <ErrorMessage
          id="err-msg"
          primary="true"
          style={{ lineHeight: "1rem" }}
        >
          {props.formProps.errors.fileName.message}
        </ErrorMessage>
      )}
      {openChoice === true ? (
        <div id="choice-div">
          <ChoiceBtn id="choice-btn" primary="true" onClick={handleOpenBank}>
            <BankIc id="bank-ic" />
            <ChoiceText id="choice-txt">โอนเงินผ่านบัญชีธนาคาร</ChoiceText>
          </ChoiceBtn>
          <ChoiceBtn id="choice-btn" onClick={handleOpenPrompt}>
            <SmartPhoneIc id="phone-ic" />
            <ChoiceText id="choice-txt">พร้อมเพย์</ChoiceText>
          </ChoiceBtn>
        </div>
      ) : (
        ""
      )}
      {openBank === true ? (
        <div id="bank-div-choice">
          <ChoiceBtn id="choice-btn" primary="true" secondary="true">
            <BankIc id="bank-ic" />
            <ChoiceText id="choice-txt">โอนเงินผ่านบัญชีธนาคาร</ChoiceText>
          </ChoiceBtn>
          <ChoiceDetail id="detail" primary="true">
            <CmdText id="cmd-txt">
              โอนเงินมาที่บัญชีที่ระบุไว้ และอัปโหลดหลักฐานการโอน
            </CmdText>
            <BankDiv id="bank-div">
              <IcKbank id="ic-kbank" style={{ marginLeft: "1.5rem" }} />
              <DetailDiv id="detail-div">
                <BankNumText id="bn-txt">064-3-15816-7</BankNumText>
                <BankAccText id="ba-txt">นายนนทวิชช์ ดวงสอดศรี</BankAccText>
              </DetailDiv>
            </BankDiv>
            <UploadDiv id="upload-div">
              <StyledTextfield
                id="fileName"
                name="fileName"
                // label="VerME ID"
                placeholder="ชื่อไฟล์ภาพ"
                margin="normal"
                variant="outlined"
                value={props.imageTransfer.raw.name || ""}
                inputRef={props.formProps.register}
                error={!!props.formProps.errors.fileName}
                disabled
              ></StyledTextfield>
              <input
                type="file"
                id="upload-button"
                onChange={e =>
                  props.setImageTransferData({
                    raw: e.target.files[0]
                  })
                }
                style={{ display: "none" }}
              />
              <UploadBtn
                id="upload-btn"
                htmlFor="upload-button"
                style={{ cursor: "pointer" }}
              >
                เลือกไฟล์
              </UploadBtn>
            </UploadDiv>
          </ChoiceDetail>
        </div>
      ) : (
        ""
      )}
      {openPrompt === true ? (
        <div id="prompt-div">
          <ChoiceBtn id="choice-btn" primary="true" secondary="true">
            <SmartPhoneIc id="phone-ic" />
            <ChoiceText id="choice-txt">พร้อมเพย์</ChoiceText>
          </ChoiceBtn>
          <ChoiceDetail id="detail">
            <CmdText id="cmd-txt">
              โอนเงินมาที่บัญชีที่ระบุไว้ และอัปโหลดหลักฐานการโอน
            </CmdText>
            <QrDiv id="qr-div">
              <ImgQrCode id="img-qr-code" />
              <BankDescDiv id="bank-div">
                <BlueText id="txt-blue">สแกน QR เพื่อโอนเข้าบัญชี</BlueText>
                <BankAccTxt id="ba-txt">
                  ชื่อ: นาย นนทวิชช์ ดวงสอดศรี
                </BankAccTxt>
                <BankAccTxt id="ba-txt">บัญชี: xxx-x-x5815-x</BankAccTxt>
                <IndexTxt id="id-txt">เลขที่อ้างอิง: 004999030369598</IndexTxt>
              </BankDescDiv>
            </QrDiv>
            <UploadDiv id="upoload-div">
              <StyledTextfield
                id="filename"
                name="fileName"
                placeholder="ชื่อไฟล์ภาพ"
                margin="normal"
                variant="outlined"
                inputRef={props.formProps.register}
                error={!!props.formProps.errors.fileName}
                value={props.imagePromptpay.raw.name || ""}
                disabled
              />
              <input
                type="file"
                id="upload-button"
                onChange={e =>
                  props.setImagePromptpayData({
                    raw: e.target.files[0]
                  })
                }
                style={{ display: "none" }}
              />
              <UploadBtn
                id="upload-btn"
                htmlFor="upload-button"
                style={{ cursor: "pointer" }}
              >
                เลือกไฟล์
              </UploadBtn>
            </UploadDiv>
          </ChoiceDetail>
        </div>
      ) : (
        ""
      )}
      {openBank === true || openPrompt === true ? (
        <OtherChoiceBtn id="other-btn" onClick={handleOpenChoice}>
          เลือกวิธีการชำระแบบอื่น
        </OtherChoiceBtn>
      ) : (
        ""
      )}
    </div>
  );
}
