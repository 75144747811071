import React from "react";
import PolicyReport from "./PolicyReport";
import PolicySafty from "./PolicySafty";
import PolicyTerm from "./PolicyTerm";

export default function PolicyContent(props) {
  const { title } = props;

  function chooseContent() {
    switch (title) {
      case 1:
        return <PolicySafty id="safety" />;
      case 2:
        return <PolicyReport id="report" />;
      case 3:
        return <PolicyTerm id="term" />;
      default:
        return <div id="error">Error page</div>;
    }
  }

  return <div id="choose-content">{chooseContent()}</div>;
}
