import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Button from "@material-ui/core/Button";
import colors from "../common/Color";
// import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import IconButton from "@material-ui/core/IconButton";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import CheckImage from "../assets/ResetPassword/ic_check_single.svg";
import CrossImage from "../assets/ResetPassword/ic_cross.svg";
import ImageCreatePass from "../assets/ResetPassword/img_create_password.svg";
// import { StoreContext } from "../Context/Store";
import { getIdentity, setPasswordUser } from "../actions/action";
import ErrorModal from "../common/ErrorModal";
import { CircularProgress } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";

const Root = styled.div`
  background: ${colors.surfaceGray};
  min-height: 100vh;
  height: 100%;
  min-width: 100vw;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LeftComponent = styled.div`
  padding-right: 4.02rem;
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @media (max-width: 1100px) {
    width: 0%;
    padding: 0;
  }
`;

const RightComponent = styled.div`
  padding-left: 4.02rem;
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  @media (max-width: 1100px) {
    height: 100vh;
    width: 100%;
    justify-content: center;
    padding: 0;
    background: ${colors.white};
  }
`;

const ResetPassword = styled.div`
  background: ${colors.white};
  height: 30.75rem;
  width: 20.43rem;
  padding: 3rem 3.69rem;
  @media (max-width: 650px) {
    padding: 5%;
    width: 100%;
  }
`;

const SkeletonResetPassword = styled(Skeleton)`
  height: 30.75rem !important;
  width: 20.43rem;
  border-radius: 1.52rem;
  padding: 3rem 3.69rem;
  @media (max-width: 650px) {
    padding: 5%;
    width: 100%;
  }
`;

const HeaderText = styled.div`
  font-family: "SukhumvitSet-Text";
  font-size: 2.13rem;
  color: ${colors.primary};
  letter-spacing: 0.25px;
  margin-bottom: 0.5rem;
`;

const ContentText = styled.div`
  opacity: 0.6;
  font-family: "Sarabun-Regular";
  font-size: 1rem;
  color: ${colors.black};
  letter-spacing: 0.5px;
  line-height: 28px;
`;

const InputField = styled.div`
  margin-top: 2rem;
`;

const CheckFieldPasswordFrame = styled.div`
  margin: 1.5rem 0rem;
  width: 100%;
`;

const CheckFieldPasswordContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  font-family: "SukhumvitSet-Text";
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.87);
  letter-spacing: 0.5px;
  line-height: 28px;
`;

const IconCheck = styled.img`
  margin-right: 0.5rem;
  width: 1rem;
  height: 1rem;
`;

const ConfirmButton = styled(Button)`
  && {
    width: 20.5rem;
    height: 2.75rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${colors.primary};
    font-family: "SukhumvitSet-SemiBold";
    font-size: 0.88rem;
    color: ${colors.white};
    letter-spacing: 1.25px;
    line-height: 1rem;
    box-shadow: 0 0.06rem 0.06rem 0 rgba(0, 0, 0, 0.5);
    &:hover {
      background: ${colors.primaryDark};
    }
    @media (max-width: 650px) {
      width: 100%;
    }
  }
`;

const LoadingScreen = styled.div`
  height: 100vh;
  width: 100vw;
  z-index: 100;
  background: rgba(255, 255, 255, 0.5);
  position: fixed;
  top: 0;
`;

export default function SetPassword(props) {
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [textMessage, setTextMessage] = useState("");
  const [errorOpen, setErrorOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [statusUser, setStatusUser] = useState(false);
  const paramsId = props.match.params.id;

  useEffect(() => {
    const checkUser = async () => {
      try {
        const res = await getIdentity(paramsId);
        if (res.status === 200) {
          if (res.data.status !== "verified") {
            window.location.href = "/404notfound";
            setStatusUser(false);
          } else {
            setStatusUser(true);
          }
        }
      } catch (err) {
        setStatusUser(false);
        window.location.href = "/404notfound";
      }
    };

    checkUser();
  },[paramsId]);

  const handleErrorClose = () => {
    setErrorOpen(false);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const letterUpperCase = () => {
    const splitPassword = password.split("");
    let status = false;
    splitPassword.forEach(item => {
      const checkUpperCase = item.charCodeAt(0);
      if (checkUpperCase >= 65 && checkUpperCase <= 90) {
        status = true;
      }
    });
    return status;
  };

  const letterNumber = () => {
    const splitPassword = password.split("");
    let status = false;
    splitPassword.forEach(item => {
      const checkUpperCase = item.charCodeAt(0);
      if (checkUpperCase >= 48 && checkUpperCase <= 57) {
        status = true;
      }
    });
    return status;
  };

  const noSpace = () => {
    const splitPassword = password.split("");
    let status = true;
    splitPassword.forEach(item => {
      const checkUpperCase = item.charCodeAt(0);
      if (checkUpperCase === 32) {
        status = false;
      }
    });
    return status;
  };

  const ConfirmFunction = async () => {
    if (!(password.length >= 8)) {
      setTextMessage("รหัสผ่านต้องมี 8-16 อักขระ");
      setErrorOpen(true);
    } else if (!letterUpperCase()) {
      setTextMessage("รหัสผ่านต้องมีตัวภาษาอังกฤษพิมพ์ใหญ่อย่างน้อย 1 ตัว");
      setErrorOpen(true);
    } else if (!letterNumber()) {
      setTextMessage("รหัสผ่านต้องมีตัวเลขอย่างน้อย 1 ตัว");
      setErrorOpen(true);
    } else if (!noSpace()) {
      setTextMessage("รหัสผ่านต้องไม่มีช่องว่าง");
      setErrorOpen(true);
    } else if (password !== confirmPassword) {
      setTextMessage("กรุณากรอกรหัสผ่านให้ตรงกัน");
      setErrorOpen(true);
    } else {
      setLoading(true);
      let formData = new FormData();
      formData.append("password", password);

      try {
        const res = await setPasswordUser(props.match.params.id, formData);
        if (res.status === 201) {
          window.location.href = "/change-password-success";
          setLoading(false);
        }
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    }
  };

  return (
    <Root id="root">
      {loading ? <LoadingScreen id="loading-screen" /> : " "}
      <ErrorModal
        id="err-modal"
        textMessage={textMessage}
        handleErrorClose={handleErrorClose}
        errorOpen={errorOpen}
      />
      <LeftComponent id="left-component">
        <img src={ImageCreatePass} alt="imagepw" />
      </LeftComponent>
      <RightComponent id="right-component">
        {!statusUser ? (
          <SkeletonResetPassword id="skeleton-reset-pw" variant="rect" />
        ) : (
          <ResetPassword id="reset-pw">
            <HeaderText id="header-txt">สร้างรหัสผ่าน</HeaderText>
            <ContentText id="content-txt">
              สร้างรหัสผ่านที่คาดเดาได้ยาก เพื่อความปลอดภัยในบัญชีของคุณ
            </ContentText>
            <InputField id="input-field">
              <OutlinedInput
                id="password"
                label="รหัสผ่าน"
                value={password}
                onChange={e => setPassword(e.target.value)}
                type={showPassword ? "text" : "password"}
                placeholder="รหัสผ่าน"
                variant="outlined"
                inputProps={{
                  maxLength: 16
                }}
                endAdornment={
                  <InputAdornment id="adornment" position="end">
                    <IconButton
                      id="ic-btn"
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                fullWidth
              />
            </InputField>
            <InputField>
              <OutlinedInput
                id="confirmPassword"
                label="ยืนยันรหัสผ่าน"
                value={confirmPassword}
                onChange={e => setConfirmPassword(e.target.value)}
                type={showConfirmPassword ? "text" : "password"}
                placeholder="ยืนยันรหัสผ่าน"
                variant="outlined"
                inputProps={{
                  maxLength: 16
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowConfirmPassword}
                    >
                      {showConfirmPassword ? (
                        <Visibility id="visibility" />
                      ) : (
                        <VisibilityOff id="invisibility" />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                fullWidth
              />
            </InputField>
            <CheckFieldPasswordFrame id="check-pw-frame">
              <CheckFieldPasswordContent id="check-pw-ct">
                <IconCheck
                  id="ic-check"
                  src={password.length >= 8 ? CheckImage : CrossImage}
                />{" "}
                8-16 อักขระ
              </CheckFieldPasswordContent>
              <CheckFieldPasswordContent id="check-pw-ct">
                <IconCheck
                  id="ic-check"
                  src={letterUpperCase() ? CheckImage : CrossImage}
                />{" "}
                มีตัวภาษาอังกฤษพิมพ์ใหญ่อย่างน้อย 1 ตัว
              </CheckFieldPasswordContent>
              <CheckFieldPasswordContent id="check-pw-ct">
                <IconCheck
                  id="ic-check"
                  src={letterNumber() ? CheckImage : CrossImage}
                />{" "}
                มีตัวเลขอย่างน้อย 1 ตัว
              </CheckFieldPasswordContent>
              <CheckFieldPasswordContent id="check-pw-ct">
                <IconCheck
                  id="ic-check"
                  src={noSpace() ? CheckImage : CrossImage}
                />{" "}
                ไม่มีช่องว่าง
              </CheckFieldPasswordContent>
            </CheckFieldPasswordFrame>
            <ConfirmButton id="confirm-btn" onClick={ConfirmFunction}>
              {loading ? (
                <CircularProgress
                  id="circular-progress"
                  size={25}
                  thickness={4}
                  style={{ color: "white" }}
                />
              ) : (
                "ยืนยัน"
              )}
            </ConfirmButton>
          </ResetPassword>
        )}
      </RightComponent>
    </Root>
  );
}
