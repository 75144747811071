import React, {
  useState,
  // useContext,
  useEffect
} from "react";
import styled from "styled-components";
import colors from "../common/Color";
// import { StoreContext } from "../Context/Store";
import { getAllIdentity } from "../actions/action";
import VerMELogo from "../assets/Logo/logo_verme.svg";
import Button from "@material-ui/core/Button";
import VerMELogoSmall from "../assets/Logo/logo_verme_small_black.png";
import FacebookIcon from "../assets/Logo/social_logo/ic_social_facebook_filled.svg";
import InstagramIcon from "../assets/Logo/social_logo/ic_social_instagram_filled.svg";
import TwitterIcon from "../assets/Logo/social_logo/ic_social_twitter_filled.svg";
import ConfirmSuccessPage from "./ConfirmSuccessPage";
import { confirmEmailUser } from "../actions/action";
import { CircularProgress } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";

const Root = styled.div`
  width: 100%;
  height: 100%;
  min-width: 100vw;
  min-height: 100vh;
  background: ${colors.surfaceGray};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const LogoImg = styled.img`
  margin-bottom: 3.5rem;
  opacity: 0.2;
`;

const ContentFrame = styled.div`
  width: 29.74rem;
  height: 13.3rem;
  background: ${colors.white};
  padding: 2.63rem;
  margin-bottom: 3.5rem;
  @media (max-width: 650px) {
    height: 100%;
    width: 73%;
    padding: 5%;
  }
`;

const SkeletonContentFrame = styled(Skeleton)`
  width: 35rem;
  height: 18.56rem !important;
  margin-bottom: 3.5rem;
  border-radius: 1.52rem;
  @media (max-width: 650px) {
    width: 15rem;
    padding: 5%;
  }
`;

const HeaderText = styled.div`
  opacity: 0.87;
  font-family: "SukhumvitSet-SemiBold";
  font-size: 1.25rem;
  color: ${colors.black};
  letter-spacing: 0.25px;
  margin-bottom: 1.25rem;
  @media (max-width: 650px) {
    margin-bottom: 0.75rem;
  }
`;

const ContentText = styled.div`
  opacity: 0.6;
  font-family: "Sarabun-Regular";
  font-size: 1rem;
  color: ${colors.black};
  letter-spacing: 0.5px;
  line-height: 28px;
  margin-bottom: 2.25rem;
  @media (max-width: 650px) {
    margin-bottom: 1rem;
  }
`;

const ButtonFrame = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ConfirmButton = styled(Button)`
  && {
    width: 7.69rem;
    height: 2.63rem;
    font-family: "SukhumvitSet-SemiBold";
    font-size: 0.88rem;
    letter-spacing: 0.08rem;
    line-height: 1rem;
    color: ${colors.white};
    background: ${colors.primary};
    box-shadow: 0 0.06rem 0.06rem 0 rgba(0, 0, 0, 0.5);
    &:hover {
      background: ${colors.primaryDark};
    }
  }
`;

const FooterFrame = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const LogoSocialFrame = styled.div`
  width: 6rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;

const PoweredFrame = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.34;
  font-family: "Roboto";
  font-size: 0.75rem;
  color: ${colors.black};
  letter-spacing: 0.4px;
  line-height: 16px;
`;

const SmallLogoVerME = styled.img`
  width: 2.75rem;
  height: 0.75rem;
  margin-left: 0.13rem;
`;

const LogoSocial = styled.img`
  width: 1.5rem;
  height: 1.5rem;
  opacity: 0.5;
`;

const SpaceDiv = styled.div`
  width: 100%;
  height: 100%;
`;

const LoadingScreen = styled.div`
  height: 100vh;
  width: 100vw;
  z-index: 100;
  background: rgba(255, 255, 255, 0.5);
  position: fixed;
`;

const ContentPosition = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default function ConfirmEmail(props) {
  const [userData, setUserData] = useState({});
  const [checkConfirm, setCheckConfirm] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        const res = await getAllIdentity(props.match.params.id);
        if (res.status === 200) {
          if (res.data.email.confirm === "verified") {
            window.location.href = "/";
          } else {
            setUserData(res.data);
          }
        }
      } catch (err) {
        console.log(err);
      }
    }
    fetchData();
    // eslint-disable-next-line
  }, []);

  const confirmEmail = async () => {
    setLoading(true);
    try {
      const res = await confirmEmailUser(props.match.params.id);
      if (res.status === 200) {
        setCheckConfirm(true);
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  return (
    <Root id="root">
      {loading ? <LoadingScreen id="loading-screen" /> : " "}
      {checkConfirm ? (
        <SpaceDiv id="space-div">
          <ConfirmSuccessPage id="confirm-page" userData={userData} />
        </SpaceDiv>
      ) : (
        <ContentPosition id="content-position">
          <LogoImg id="logo-img" src={VerMELogo} />
          {!userData.name ? (
            <SkeletonContentFrame id="skeleton-ct-frame" variant="rect" />
          ) : (
            <ContentFrame id="content-frame">
              <HeaderText id="header-txt">
                คุณ{userData.name ? userData.name.data.th : ""}
              </HeaderText>
              <ContentText id="content-txt">
                เราขอบคุณที่ท่านร่วมเป็นส่วนหนึ่งในการช่วยสร้างสังคมการซื้อขายที่โปร่งใสกับ
                VerME: Verify me กดปุ่มด้านล่าง เพื่อยืนยันการใช้งานอีเมลนี้:{" "}
                {userData.email ? userData.email.data : ""}
              </ContentText>
              <ButtonFrame id="btn-frame">
                <ConfirmButton
                  id="confirm-btn"
                  onClick={confirmEmail}
                  disabled={loading}
                >
                  {loading ? (
                    <CircularProgress
                      id="circular-pg"
                      size={25}
                      thickness={4}
                      style={{ color: "white" }}
                    />
                  ) : (
                    " ยืนยันอีเมล"
                  )}
                </ConfirmButton>
              </ButtonFrame>
            </ContentFrame>
          )}

          <FooterFrame id="footer-frame">
            <LogoSocialFrame id="logo-sc-frame">
              <a
                href="https://www.facebook.com/verme.me/"
                target="_blank"
                rel="noopener noreferrer"
                id="link-sc-1"
              >
                <LogoSocial id="logo-sc-1" src={FacebookIcon} />
              </a>
              <a
                href="https://www.instagram.com/verifyme.me/"
                target="_blank"
                rel="noopener noreferrer"
                id="link-sc-2"
              >
                <LogoSocial id="logo-sc-2" src={InstagramIcon} />
              </a>
              {/*  eslint-disable-next-line  */}
              <a target="_blank" rel="noopener noreferrer" id="link-sc-3">
                <LogoSocial id="logo-sc-3" src={TwitterIcon} />
              </a>
            </LogoSocialFrame>
            <PoweredFrame id="credit-frame">
              Powered & Secured by{" "}
              <SmallLogoVerME id="sm-logo" src={VerMELogoSmall} />
            </PoweredFrame>
          </FooterFrame>
        </ContentPosition>
      )}
    </Root>
  );
}
