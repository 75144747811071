import styled from "styled-components";

const Subtitle = styled.div`
  font-family: "SukhumvitSet-Bold";
  font-size: 1.5rem;
  color: #000;
  opacity: 0.87;
  letter-spacing: 0;
  line-height: 2.31rem;
`;

export default Subtitle;
