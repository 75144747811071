import React from "react";
import styled from "styled-components";
import iconImage from "../assets/Error/img_404.svg";
import Button from "@material-ui/core/Button";
import colors from "../common/Color";

const Root = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  a {
    text-decoration: none;
  }
`;

const ImageFrame = styled.div`
  margin-bottom: 2.58rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ImageContent = styled.img`
  object-fit: contain;
  width: 90%;
`;

const HeaderText = styled.div`
  font-family: "SukhumvitSet-SemiBold";
  font-size: 3rem;
  color: #3b54d4;
  margin-bottom: 0.5rem;
  @media (max-width: 1024px) {
    font-size: 2.13rem;
  }
`;

const ContentText = styled.div`
  display: flex;
  justify-content: center;
  opacity: 0.6;
  font-family: "Sarabun-Light";
  font-size: 1rem;
  color: #000000;
  letter-spacing: 0.5px;
  text-align: center;
  line-height: 28px;
  margin-bottom: 2.69rem;
`;

const BackHomeButton = styled(Button)`
  && {
    width: 10.06rem;
    height: 2.75rem;
    font-family: "SukhumvitSet-SemiBold";
    font-size: 0.88rem;
    letter-spacing: 0.08rem;
    line-height: 1rem;
    color: #ffffff;
    background: ${colors.primary};
    box-shadow: 0 0.06rem 0.06rem 0 rgba(0, 0, 0, 0.5);
    &:hover {
      background: ${colors.primaryDark};
    }
  }
`;

export default function PageNotFound() {
  return (
    <Root>
      <ImageFrame>
        <ImageContent src={iconImage} />
      </ImageFrame>
      <HeaderText>Page Not Found</HeaderText>
      <ContentText>
        หน้าเว็บที่คุณต้องการเข้าถึงไม่มีอยู่ หรือถูกลบออกไปแล้ว
        <br />
        ขออภัยในความไม่สะดวก
      </ContentText>
      <a href="/">
        <BackHomeButton>กลับไปที่หน้าหลัก</BackHomeButton>
      </a>
    </Root>
  );
}
