import React, { useState, useContext } from "react";
import styled from "styled-components";
import { ReactComponent as CheckIcon } from "../assets/Icons/ic_check_single.svg";
import colors from "../common/Color";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { StoreContext } from "../Context/Store";
import FormHeader from "../common/FormHeader";

const Root = styled.div`
  width: 100%;
  min-height: 100vh;
  background: #f7f8fa;
`;
const Box = styled.div`
  width: 65.7rem;
  padding-top: 8rem;
  margin-bottom: 7.56rem;
  @media (max-width: 1025px) {
    width: 65.7vw;
    margin-bottom: 6rem;
  }
  @media (max-width: 768px) {
    width: 90vw;
    margin: 0 1.5rem 6rem 1.5rem;
  }
  @media (max-width: 474px) {
    width: 85vw;
    margin-bottom: 6rem;
  }
`;
const Title = styled.div`
  font-family: "SukhumvitSet-Text";
  font-size: 2.13rem;
  color: ${colors.primary};
  letter-spacing: 0.02rem;
  text-align: center;
  margin-bottom: 1.5rem;
  @media (max-width: 768px) {
    display: none;
  }
`;
const TitleMobile = styled.div`
  font-family: "SukhumvitSet-Text";
  font-size: 1.5rem;
  color: ${colors.primary};
  letter-spacing: 0.02rem;
  text-align: center;
  margin-bottom: 2.5rem;
  @media (min-width: 769px) {
    display: none;
  }
`;
const Rect = styled.div`
  height: 36rem;
  overflow: scroll;
  border-radius: 0.75rem;
  margin: 0 5%;
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
`;
const Order = styled.ol`
  font-family: "Sarabun-Regular";
  font-size: 1rem;
  line-height: 1.75rem;
  letter-spacing: 0.03rem;
  color: ${colors.black};
  opacity: 0.6;
`;
const List = styled.li`
  font-family: "Sarabun-Regular";
  font-size: 1rem;
  line-height: 1.75rem;
  letter-spacing: 0.03rem;
  color: ${colors.black};
  padding-left: 1rem;
`;
const Content = styled.div`
  padding: 0.5rem 1.5rem 0 0;
`;
const CheckText = styled.div`
  font-family: "Sarabun-Regular";
  font-size: 1rem;
  line-height: 1.75rem;
  letter-spacing: 0.03rem;
  color: ${colors.black};
  opacity: 0.6;
  @media (max-width: 1025px) {
    margin-top: 1.5rem;
  }
  @media screen and (min-width: 750px) and (max-width: 768px) {
    margin-top: 0;
  }
  @media (max-width: 431px) {
    margin-top: 3.5rem;
  }
`;
const Submit = styled(Button)`
  && {
    width: 10.81rem;
    height: 2.75rem;
    background-color: ${props => (props.disabled ? "#E4E4E4" : colors.primary)};
    font-family: "SukhumvitSet-Semibold";
    font-size: 0.875rem;
    line-height: 1rem;
    letter-spacing: 0.08rem;
    color: ${props => (props.disabled ? "#979797" : colors.white)};
    box-shadow: 0 0.063rem 0.063rem 0 rgba(0, 0, 0, 0.5);
    &:hover {
      background-color: ${colors.primaryDark};
    }
    @media (max-width: 768px) {
      width: 100%;
      margin: 0 5%;
    }
  }
`;
const CheckGroup = styled.div`
  position: relative;
  margin: 0.5rem;
`;
const CheckBox = styled.div`
  width: 1.38rem;
  height: 1.38rem;
  border-radius: 0.38rem;
  border: solid 0.063rem #d1d1d1;
  background-color: #f4f4f4;
  position: relative;
  cursor: pointer;
`;
const CheckedIcon = styled(CheckIcon)`
  font-size: 1.5rem;
  fill: ${colors.primary};
  position: absolute;
  top: 0rem;
  cursor: pointer;
`;
const CheckContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 1.25rem 2rem 2rem 5.28rem;
  @media (max-width: 1025px) {
    margin: 0 5% 1.5rem 5%;
  }
  @media (max-width: 768px) {
    margin: 1.25rem 5% 1.5rem 5%;
  }
  @media (max-width: 431px) {
    margin: -1rem 5% 1.5rem 5%;
  }
`;
const FlexCenter = styled.div`
  display: flex;
  justify-content: center;
`;
const UpdateContent = styled.div`
  display: flex;
  justify-content: flex-end;
  opacity: 0.6;
  font-family: "Sarabun-Light";
  font-size: 0.88rem;
  color: #000000;
  letter-spacing: 0.25px;
  text-align: left;
  line-height: 20px;
  margin: 1rem 5%;
`;
const policy = [
  "ในกรณีที่ทาง VerME พบหรือสงสัยว่า ภาพถ่าย เอกสาร หรือข้อมูลที่ทาง VerME ได้รับจากผู้ขอใช้บริการไม่ถูกต้อง ไม่ครบถ้วน หรือไม่เป็นจริง ผู้ขอใช้บริการตกลงให้ทาง VerME มีสิทธิระงับ หรือปิดบัญชีสมาชิกของผู้ขอใช้บริการเมื่อใดก็ได้ โดยจะมีการแจ้งให้ผู้ขอใช้บริการทราบต่อไป และทาง VerME ไม่ต้องรับผิดชอบในความเสียหายใด ๆ ที่เกิดขึ้นทั้งสิ้น นอกจากนี้ หากทาง VerME ได้รับความเสียหายใด ๆ จากการเปิดบัญชีโดยใช้หรืออ้างอิงข้อมูลดังกล่าว ผู้ขอใช้บริการจะต้องรับผิดชดใช้ต่อทาง VerME ในความเสียหายดังกล่าว",
  "ผู้ขอใช้บริการยินยอมให้ทาง VerME เก็บรวบรวม ใช้ข้อมูลส่วนบุคคล ข้อมูลทางชีวภาพ (เช่น ใบหน้า เป็นต้น) หรือ ข้อมูลใด ๆ ของผู้ขอใช้บริการที่ให้ไว้แก่ทาง VerME หรือที่ทาง VerME ได้รับ หรือเข้าถึงได้จากแหล่งอื่น(ข้อมูลในลิงก์โปรไฟล์บนเฟซบุ๊ก) เพื่อวัตถุประสงค์ในการตรวจสอบและยืนยันตัวตนของผู้ขอใช้บริการ รวมถึงการดำเนินการใด ๆ เกี่ยวกับการวิเคราะห์ข้อมูล การบริหารกิจการของทาง VerME เสนอให้ใช้หรือปรับปรุงบริการ หรือผลิตภัณฑ์ใด ๆ ของทาง VerME ที่เกี่ยวกับสัญญาการให้บริการ หรือเพื่อตรวจสอบรายการธุรกรรมที่อาจจะเกิดการทุจริต ธุรกรรมที่มีเหตุอันควรสงสัย หรือเปิดเผยข้อมูลดังกล่าวให้แก่บริษัทในกลุ่มพาร์ทเนอร์ของทาง VerME",
  "ในการใช้บริการกับทาง VerME  ผู้ขอใช้บริการยินยอมให้ทาง VerME มีการเปิดเผยข้อมูลบางส่วน(เช่น ลิงก์โปรไฟล์บนเฟซบุ๊ก) ให้กับบุคคลภายนอก(ลูกค้าของผู้ขอใช้บริการ) วัตถุประสงค์เพื่อให้ลูกค้าของผู้ขอใช้บริการ สามารถเข้าถึงหรือเยี่ยมชมสำหรับตรวจสอบข้อมูลเบื้องต้น",
  "ในกรณีที่คอมพิวเตอร์ของทาง VerME หรือระบบไฟฟ้า หรือระบบการติดต่อสื่อสารขัดข้อง หรือมีเหตุอื่นใด และมีผลให้การให้บริการของทาง VerMEเกิดขัดข้องในขณะหนึ่งขณะใด ซึ่งเหตุดังกล่าวไม่ได้มีสาเหตุมาจากการที่ทาง VerME ไม่ดูแลรักษาระบบคอมพิวเตอร์ อย่างเพียงพอตามมาตรฐาน ผู้ขอใช้บริการตกลงจะไม่ยกเอาเหตุขัดข้องดังกล่าวเป็นข้อเรียกร้องให้ทาง VerME ต้องรับผิดชอบแต่ประการใดทั้งสิ้น",
  "ทาง VerME ไม่ต้องรับผิดชอบในความเสียหายใด ๆ อันเกิดขึ้นแก่ผู้ขอใช้บริการอันเนื่องมาจากการใช้บริการของทาง VerME เว้นแต่เกิดจากทาง VerME จงใจกระทำความผิด หรือประมาทอย่างร้ายแรง",
  "เพื่อประโยชน์ของผู้ขอใช้บริการ ทาง VerME อาจส่งข้อมูลข่าวสารในเชิงพาณิชย์เช่น ข้อมูลผลิตภัณฑ์และ บริการต่างๆ ข้อมูลทางการตลาด และรายการส่งเสริมการขาย เป็นต้น ไปยังที่อยู่อิเล็กทรอนิกส์ต่างๆ ของผู้ขอใช้บริการ เช่น E-mail Address เป็นต้น โดยผู้ขอใช้บริการสามารถบอกเลิกหรือปฏิเสธการรับข้อมูล ดังกล่าวได้ตามช่องทางที่ทาง VerME กำหนด",
  "ทาง VerME มีสิทธิระงับการให้บริการเกี่ยวกับบัญชีสมาชิกของผู้ขอใช้บริการ เมื่อใดก็ได้หากทาง VerME มีเหตุอันควรสงสัยว่าบัญชีสมาชิกได้ถูกใช้ในกิจการที่ไม่ถูกต้องตามกฎหมาย หรือระเบียบ ข้อบังคับ หรือคำสั่ง ของทางราชการ หรือไม่เหมาะสมไม่ว่าด้วยประการใดๆ โดยผู้ขอใช้บริการจะไม่เรียกร้องค่าเสียหายใดๆ จากทาง VerME",
  "ผู้ขอใช้บริการ ยินยอมให้มีการตัดเงินผ่านบัตรเดบิต/เครดิตทุกประเภท สำหรับการจ่ายค่าสมัครในแต่ละแพ็คเกจตามที่ทาง VerME กำหนด",
  "ทาง VerME มีสิทธิเพิ่มเติม และ/หรือ เปลี่ยนแปลงเงื่อนไขนี้ รวมทั้งอัตราค่าค่าบริการ และ/หรือ ค่าใช้จ่ายต่างๆ ในการใช้บริการของธนาคารได้โดยแจ้งให้ทราบล่วงหน้า 30 วันก่อนดำเนินการ ด้วยการประกาศบนเว็บไซต์ VerME และ/หรือ ด้วยวิธีการอื่นใดที่ทาง VerME จะกำหนดเพิ่มเติม",
  "ผู้ขอใช้บริการจะต้องเป็นบุคคลธรรมดาทีมีถิ่นที่อยู่ในประเทศไทยเท่านั้น หรือหากเป็นชาวต่างชาติที่เข้ามาในราชอาณาจักรไทยอย่างถูกต้องตามกฎหมายและมีใบอนุญาตทำงานตามกฎหมายและมีหลักฐานที่แสดงการพำนักพักพิงในประเทศ ไทยเกิน 1 ปี",
  "ผู้ขอใช้บริการ 1 คน เปิดบัญชีสมาชิกกับทาง VerME ได้เพียง 1 บัญชีเท่านั้น และไม่สามารถใช้ชื่อคณะบุคคลหรือข้อมูลทางชีวภาพ (เช่น ใบหน้า เป็นต้น) ของผู้อื่นในการสมัครได้",
  "ในกรณีที่ผู้ขอใช้บริการมีการเปลี่ยนแปลงข้อมูล(เช่น เปลี่ยนแปลงข้อมูลโปรไฟล์เฟซบุ๊ก ชื่อและนามสกุล เลขบัญชี หรือข้อมูลอื่นๆ ตามที่ทาง VerME ได้กำหนดไว้) ผู้ขอใช้บริการจำเป็นต้องแจ้งให้ทาง VerME ได้ทราบ เพื่อแก้ไขข้อมูลให้ตรงตามข้อมูลปัจจุบันของผู้ขอใช้บริการและสำหรับตรวจสอบรายการธุรกรรมที่อาจจะเกิดการทุจริต ธุรกรรมที่มีเหตุอันควรสงสัย",
  "ในกรณีที่ผู้ขอใช้บริการมีการตรวจสอบรายการธุรกรรมที่อาจจะเกิดการทุจริต และ/หรือการเกิดข้อพิพาทขึ้น คำตัดสินของVerME ถือเป็นที่สิ้นสุด และข้อมูลของผู้ทำผิดนั้นจะถูกเปิดเผย และให้ผู้เสียหายดำเนินการต่อไป",
  "การเปิดบัญชีสมาชิกกับทาง VerME  ผู้ขอใช้บริการจะต้องเป็นบุคคลธรรมดาที่มีอายุตั้งแต่ 20 ปี ถึง 69 ปี (เช่น เกิดวันที่ 8 มกราคม 2500 จะสามารถเปิดบัญชีได้ในวันที่ 8 มกราคม 2515 เป็นต้นไป โดยวันสุดท้ายที่จะ สามารถเปิดบัญชีได้คือวันที่ 7 มกราคม 2569)",
  "ในกรณีที่ผู้ขอใช้บริการที่มีอายุต่ำกว่า 20 ปี ยังเข้ามาสมัครใช้บริการของ VerME ต่อ ทั้งที่อายุไม่ถึงตามเงื่อนไขการใช้บริการ ทาง VerME จะขอสงวนสิทธิ์ในการไม่ออก VerME Card ให้ เพราะถือว่าทาง VerME ได้แจ้งให้ผู้ใช้บริการทราบก่อนสมัครแล้ว",
  "ในกรณีที่ผู้ขอใช้บริการทำรายการจ่ายเงินค่าสมัครสมาชิกเสร็จสิ้นสมบูรณ์ ทาง VerME จะแจ้งผลการจ่ายเงินและการหักบัญชีแก่ผู้ขอใช้บริการทางฟังก์ชั่นการแจ้งเตือน หรือจดหมายอิเล็กทรอนิกส์ (E-mail) ผู้ขอใช้บริการสามารถตรวจสอบผลการจ่ายเงินและสั่งพิมพ์ (print out) และ/หรือ บันทึก (save as) หลักฐานการจ่ายเงิน (Statement) รายการนั้นได้ที่ระบบบริการอิเล็กทรอนิกส์ของทาง VerME ภายในวันเดียวกันกับที่จ่ายเงินนั้น",
  "ผู้ขอใช้บริการต้องทำรายการตามขั้นตอนและวิธีการที่ทาง VerME กำหนดผ่านเว็บ verme.me เท่านั้นเพื่อป้องกันการสวมรอยจากผู้ไม่ประสงค์ดี",
  "ผู้ขอใช้บริการสามารถระงับหรือยกเลิกการระงับ การใช้บัตร VerME ได้ตามขั้นตอนและวิธีการที่ทาง VerME กำหนดผ่าน verme.me หรือผ่านช่องทางอิเล็กทรอนิกส์ประเภทอื่นใดที่ธนาคารจะเปิดให้บริการแก่ผู้ขอใช้บริการ",
  "ผู้ขอใช้บริการสามารถขอแก้ไขข้อมูลที่เคยให้ไว้กับทาง VerME  ได้ตามขั้นตอนและวิธีการที่ทาง VerME กำหนดผ่าน verme.me หรือผ่านช่องทางอิเล็กทรอนิกส์ประเภทอื่นใดที่ธนาคารจะเปิดให้บริการแก่ผู้ขอใช้บริการ",
  "ผู้ขอใช้บริการรับทราบเป็นอย่างดีแล้วว่า VerME เป็นผู้ให้บริการที่เป็นตัวกลางสำหรับยืนยันข้อมูลและตัวตนบนโลกออนไลน์ เพื่อสนับสนุนกิจกรรมพาณิชย์อิเล็กทรอนิกส์ของผู้ขอใช้บริการเท่านั้น และมิได้มีส่วนเกี่ยวข้องใดๆ กับการซื้อ จำหน่ายสินค้า หรือการให้บริการต่างๆ",
  "ผู้ขอใช้บริการยินยอมว่าจะมีการตั้งราคาขายและเปิดเผยข้อมูล รายละเอียดต่างๆของสินค้าให้ครบถ้วน ตามประกาศจากทางคณะกรรมการกลางว่าด้วยราคาสินค้าและบริการ ฉบับที่ 44 พ.ศ.2560 การซื้อขายผ่านระบบพาณิชย์อิเล็กทรอนิกส์หรือออนไลน์ เกี่ยวกับการตั้งราคาขายและเปิดเผยข้อมูล รายละเอียดต่างๆของสินค้าให้ครบถ้วน",
  "การใช้บริการของVerMeนั้น ผู้ขอใช้บริการจำเป็นต้องเข้าใจและยอมรับข้อตกลงและเงื่อนไขต่างๆ ที่ทาง VerME กำหนดไว้ทั้งหมด",
  "หากผู้ใช้บริการไม่ปฏิบัติตามข้อกำหนดและเงื่อนไขตามคำขอใช้บริการฉบับนี้ในข้อใดข้อหนึ่ง ผู้ให้บริการขอสงวนสิทธิในการเพิกถอนสมาชิกภาพ และสามารถยกเลิกการให้บริการกับท่านได้ทันที โดยไม่ต้องแจ้งล่วงหน้า การพิจารณาการเพิกถอนสมาชิกภาพ  ขึ้นอยู่กับดุลยพินิจของเจ้าหน้าที่เว็บไซต์ ซึ่งอาจจะอ้างอิงจากผู้เสียหายหรือการร้องเรียน"
];

export default function Policy(props) {
  const [check, setCheck] = useState(false);
  const { checkPolicy } = useContext(StoreContext);

  const handleCheck = () => {
    setCheck(true);
  };

  const handleUncheck = () => {
    setCheck(false);
  };

  const acceptTerm = () => {
    checkPolicy[1](true);
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  const policyItems = policy.map((item, index) => (
      <List id="list" key={index}>
        {item}
      </List>
  ));
  return (
    <Root id="root">
      <FormHeader id="form-header" />
      <Grid
        id="container"
        container
        direction="column"
        justify="center"
        alignItems="center"
      >
        <Grid id="grid-item" item xs={12} sm={12} md={12} lg={12}>
          <Box id="box">
            <Title id="title">
              เงื่อนไขทั่วไปสำหรับการสมัคร และใช้บริการของเวอร์มี 
              {/* <br />{" "}
              และการสมัครเป็นสมาชิก */}
            </Title>
            <TitleMobile id="title-mb">
            เงื่อนไขทั่วไปสำหรับการสมัคร  
              <br />
              และใช้บริการของเวอร์มี
            </TitleMobile>
            <UpdateContent id="update-content">
              อัปเดตล่าสุดเมื่อวันที่ 17 มิถุนายน 2563
            </UpdateContent>
            <Rect id="rect">
              <Content id="content">
                <Order id="order">
                  <List id="list">
                    ทาง VerME จะมีการอ่านข้อมูล
                    และภาพถ่ายของผู้ขอใช้บริการตามที่ได้มีการบันทึกไว้ในบัตรประจำตัวประชาชน
                    ลิงก์โปรไฟล์บนเฟซบุ๊ก
                    และเอกสารแสดงตัวตนอื่นใดที่ผู้ขอใช้บริการได้ส่งมอบให้แก่ทาง
                    VerME และจัดเก็บข้อมูลและภาพถ่ายดังกล่าว
                    เพื่อประโยชน์ในการสมัครเป็นสมาชิกกับทาง VerME <br />
                    &emsp;&emsp;ทั้งนี้ ผู้ขอใช้บริการรับรองว่า ภาพถ่าย เอกสาร
                    และข้อมูลใด ๆ ที่ผู้ขอใช้บริการได้ให้แก่ทาง VerME ภายหน้า
                    ไม่ว่าผ่านช่องทางใด ๆ ซึ่งรวมถึงช่องทางอิเล็กทรอนิกส์
                    เป็นข้อมูลที่ระบุและพิสูจน์ตัวตนของผู้ขอใช้บริการ
                    รวมทั้งเป็นข้อมูลของผู้ขอใช้บริการที่ถูกต้อง ครบถ้วน
                    และเป็นจริงทุกประการ โดยทาง VerME
                    จะมีการจัดเก็บข้อมูลดังกล่าวไว้ในระบบฐานข้อมูลลูกค้าของ
                    VerME เพื่อประโยชน์ในการให้บริการของทาง VerME
                    หรือการใช้ทำธุรกรรมกับทาง VerME
                  </List>
                  {policyItems}
                </Order>
              </Content>
            </Rect>
            <CheckContainer id="check-container">
              <CheckGroup id="check-group">
                <CheckBox id="check-box" onClick={handleCheck} />
                {check === true ? (
                  <CheckedIcon id="check-ic" onClick={handleUncheck} />
                ) : (
                  ""
                )}
              </CheckGroup>
              <CheckText id="check-txt">
                ข้าพเจ้าได้อ่านและเข้าใจเงื่อนไข ข้อกำหนดต่างๆของ VerME
                ดังที่กล่าวมาข้างต้นแล้ว
              </CheckText>
            </CheckContainer>
            <FlexCenter id="flex-center">
              {check === true ? (
                <Submit id="submit-btn" onClick={acceptTerm}>
                  ยอมรับ
                </Submit>
              ) : (
                <Submit id="submit-btn" disabled>
                  ยอมรับ
                </Submit>
              )}
            </FlexCenter>
          </Box>
        </Grid>
      </Grid>
    </Root>
  );
}
