import React from "react";
import styled from "styled-components";
import WebButton from "../common/WebButton";
import { ReactComponent as Banner } from "../assets/Images/img_home_banner.svg";
import BgImg from "../assets/Background/bg_home_banner_bg.svg";
import { ReactComponent as Kx } from "../assets/Logo/logo_kx.svg";
import { ReactComponent as ThaiEcom } from "../assets/Logo/logo_thaiecom.svg";
import { ReactComponent as Ttsa } from "../assets/Logo/logo_ttsa.svg";
import colors from "../common/Color";

const Root = styled.div`
  width: 100%;
  min-height: 100vh;
  background-image: url(${BgImg});
  background-size: auto 100vh;
  background-position: right bottom;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  @media (max-width: 1024px) {
    flex-direction: column;
    min-height: 54.46rem;
    background-size: auto 55.19rem;
    background-position: center bottom;
    position: relative;
    overflow: hidden;
  }
  @media (max-width: 768px) {
    background-size: auto 44.19rem;
  }
`;
const Container = styled.div`
  width: 62.26rem;
  @media (max-width: 1229px) {
    width: 56rem;
  }
  @media (max-width: 1024px) {
    width: 23.38rem;
    height: 54.46rem;
    margin: 4rem auto 0;
  }
  @media (max-width: 375px) {
    width: 17rem;
  }
`;
const Upper = styled.div`
  height: 72.8%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 1024px) {
    flex-direction: column;
    justify-content: center;
  }
  @media (max-height: 620px) and (min-width: 1025px) {
    margin-top: 4rem;
  }
`;
const Lower = styled.div`
  height: 28.2%;
  border-top: 0.06rem solid rgba(0, 0, 0, 0.2);
  @media (max-width: 1024px) {
    display: none;
  }
`;
const VideoBox = styled.div`
  width: 30.38rem;
  height: 17.13rem;
  background: ${colors.white};
  box-shadow: 0 0.06rem 0.06rem 0 rgba(96, 97, 112, 0.16);
  border: 4px solid;
  border-image-source: linear-gradient(200deg, #df8d95, #4a3af7);
  border-image-slice: 1;
  @media (max-width: 1024px) {
    margin-top: 3.5rem;
  }
  @media (max-width: 549px) {
    width: 21.27rem;
    height: 11.5rem;
  }
  @media (max-width: 375px) {
    width: 19.14rem;
    height: 10.35rem;
  }
`;
const Title = styled.div`
  font-family: "SukhumvitSet-Semibold";
  font-size: 3rem;
  color: ${colors.primary};
  line-height: 4rem;
  @media (max-width: 1024px) {
    font-family: "SukhumvitSet-Text";
    font-size: 2.13rem;
    letter-spacing: 0.02rem;
    line-height: 3.38rem;
    text-align: center;
  }
`;
const Content = styled.div`
  font-family: "Sarabun-Light";
  font-size: 1rem;
  color: ${colors.black};
  letter-spacing: 0.03rem;
  line-height: 1.75rem;
  margin: 1.5rem 0 2rem;
  @media (max-width: 1024px) {
    text-align: center;
  }
`;
const BtnDiv = styled.div`
  display: flex;
  z-index: 1;
  @media (max-width: 1024px) {
    display: none;
  }
`;
const LogoDiv = styled.div`
  display: flex;
  margin-top: 1.94rem;
  align-items: center;
`;
const KxLogo = styled(Kx)`
  width: 5rem;
  height: 1.13rem;
  opacity: 0.56;
  margin-right: 2.88rem;
`;
const ThaiEcLogo = styled(ThaiEcom)`
  width: 4.13rem;
  height: 1.75rem;
  opacity: 0.56;
  margin-right: 3.69rem;
`;
const TtsaLogo = styled(Ttsa)`
  width: 3.31rem;
  height: 1.5rem;
  opacity: 0.56;
`;
const StBanner = styled(Banner)`
  width: 25rem;
  height: 38.125rem;
  right: 0;
  bottom: 0;
  position: absolute;
  @media (max-height: 949px) and (min-width: 1025px) {
    width: 18rem;
    height: 27.13rem;
    right: 10%;
  }
  @media (max-width: 1024px) {
    width: 13.91rem;
    height: 21.42rem;
    right: -27px;
  }
`;
const LeftUpper = styled.div`
  z-index: 1;
  width: 25rem;
  @media (max-width: 1024px) {
    width: 100%;
  }
`;
const HowtoBtn = styled(WebButton)`
  && {
    border: 0.06rem solid ${colors.primary};
    width: 10.38rem;
    height: 2.75rem;
    margin-right: 1.5rem;
  }
`;
export default function MainSection() {
  return (
    <Root id="root">
      <Container id="container">
        <Upper id="upper">
          <LeftUpper id="left-upper">
            <Title id="title">
              บริการยืนยันตัวตน <br />
              ผู้ขายของออนไลน์
            </Title>
            <Content id="content">
              เราไม่ใช่แอปหรือเว็บขายของ
              แต่เราเป็นแพลตฟอร์มยืนยันตัวตนผู้ขายของออนไลน์ตาม Facebook Line
              Instagram Twitter และเว็บบอร์ดต่างๆ
              เพื่อให้ผู้ซื้อมั่นใจว่าชำระเงินกับคนที่มีตัวตนจริงๆ
            </Content>
            <BtnDiv id="btn-div">
              <HowtoBtn id="howto-btn" href="/how-to">
                วิธีใช้งาน VerME
              </HowtoBtn>
              <WebButton
                id="web-btn"
                primary="true"
                width="10.38rem"
                height="2.75rem"
                href="/prepare-before-register"
                target="_blank"
                rel="noopener noreferrer"
              >
                ทดลองใช้ฟรีวันนี้!
              </WebButton>
            </BtnDiv>
          </LeftUpper>
          <VideoBox id="video-box">
            <iframe
              id="iframe"
              title="Promote"
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/LvyhUnQVu0I"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </VideoBox>
        </Upper>
        <Lower id="lower">
          <LogoDiv id="logo-div">
            <KxLogo id="kx-logo" />
            <ThaiEcLogo id="thai-ec-logo" />
            <TtsaLogo id="ttsa-logo" />
          </LogoDiv>
        </Lower>
      </Container>
      <StBanner id="st-banner" />
    </Root>
  );
}
