import React, { useState, createContext } from "react";
import fashionImg from "../assets/CategoryIcon/ic_categories_fashion.svg";
import shoeImg from "../assets/CategoryIcon/ic_categories_shoe.svg";
import instrumentImg from "../assets/CategoryIcon/ic_categories_instrument.svg";
import accessaryImg from "../assets/CategoryIcon/ic_categories_accessary.svg";
import carImg from "../assets/CategoryIcon/ic_categories_car.svg";
import bookImg from "../assets/CategoryIcon/ic_categories_book.svg";
import babyStuffImg from "../assets/CategoryIcon/ic_categories_baby_stuff.svg";
import gadGetImg from "../assets/CategoryIcon/ic_categories_gadget.svg";
import furnitureImg from "../assets/CategoryIcon/ic_categories_furniture.svg";
import secondHandImg from "../assets/CategoryIcon/ic_categories_2nd_hand.svg";
import ticketImg from "../assets/CategoryIcon/ic_categories_ticket.svg";
import cosmeticsImg from "../assets/CategoryIcon/ic_categories_cosmetics.svg";
import gameImg from "../assets/CategoryIcon/ic_categories_game.svg";
import collectionImg from "../assets/CategoryIcon/ic_categories_collection.svg";
import footballImg from "../assets/CategoryIcon/ic_categories_football.svg";
import petImg from "../assets/CategoryIcon/ic_categories_pet.svg";

import activeFashionImg from "../assets/CategoryIcon/Active/ic_categories_fashion.svg";
import activeShoeImg from "../assets/CategoryIcon/Active/ic_categories_shoe.svg";
import activeInstrumentImg from "../assets/CategoryIcon/Active/ic_categories_instrument.svg";
import activeAccessaryImg from "../assets/CategoryIcon/Active/ic_categories_accessary.svg";
import activeCarImg from "../assets/CategoryIcon/Active/ic_categories_car.svg";
import activeBookImg from "../assets/CategoryIcon/Active/ic_categories_book.svg";
import activeBabyStuffImg from "../assets/CategoryIcon/Active/ic_categories_baby_stuff.svg";
import activeGadGetImg from "../assets/CategoryIcon/Active/ic_categories_gadget.svg";
import activeFurnitureImg from "../assets/CategoryIcon/Active/ic_categories_furniture.svg";
import activeSecondHandImg from "../assets/CategoryIcon/Active/ic_categories_2nd_hand.svg";
import activeTicketImg from "../assets/CategoryIcon/Active/ic_categories_ticket.svg";
import activeCosmeticsImg from "../assets/CategoryIcon/Active/ic_categories_cosmetics.svg";
import activeGameImg from "../assets/CategoryIcon/Active/ic_categories_game.svg";
import activeCollectionImg from "../assets/CategoryIcon/Active/ic_categories_collection.svg";
import activeFootballImg from "../assets/CategoryIcon/Active/ic_categories_football.svg";
import activePetImg from "../assets/CategoryIcon/Active/ic_categories_pet.svg";

//สร้าง Store Context
export const StoreContext = createContext({});

export const StoreContextProvider = ({ children }) => {
  // initial State
  const [general, setGeneral] = useState({
    thaiFirstName: undefined,
    thaiLastName: undefined,
    enFirstName: undefined,
    enLastName: undefined,
    email: undefined,
    phone: undefined,
    facebook: undefined
  });
  const [addresses, setAddresses] = useState({
    address: undefined,
    province: undefined,
    district: undefined,
    subDistrict: undefined,
    postalCode: undefined
  });
  const [bankaccount, setBankAccount] = useState([]);
  const [authen, setAuthen] = useState({
    identityNumber: undefined,
    laserCode: undefined,
    uploadFront: undefined,
    uploadBack: undefined,
    uploadFace: undefined
  });
  const [promotion, setPromotion] = useState({
    vermeID: undefined
  });

  const [activeStep, setActiveStep] = useState(0);
  const [userId, setUserId] = useState("");
  const [prefix, setPrefix] = useState("นาย");
  const [nameTh, setNameTh] = useState("");
  const [surnameTh, setSurnameTh] = useState("");
  const [nameEn, setNameEn] = useState("");
  const [surnameEn, setSurnameEn] = useState("");
  const [email, setEmail] = useState("");
  const [birthdate, setBirthdate] = useState(new Date(2000, 11, 31));
  const [phone, setPhone] = useState("");
  const [isPromptpay, setIsPromptpay] = useState(false);
  const [facebook, setFacebook] = useState("");
  const [address, setAddress] = useState("");
  const [province, setProvince] = useState("");
  const [district, setDistrict] = useState("");
  const [subDistrict, setSubDistrict] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [citizenImageFace, setCitizenImageFace] = useState({
    preview: "",
    raw: ""
  });
  const [citizenImageFront, setCitizenImageFront] = useState({
    preview: "",
    raw: ""
  });
  const [citizenImageBack, setCitizenImageBack] = useState({
    preview: "",
    raw: ""
  });
  const [citizenId, setCitizenId] = useState("");
  const [laserCode, setLaserCode] = useState("");
  const [open, setOpen] = useState(false);
  const [checkCitizenImageFace, setCheckCitizenImageFace] = useState(true);
  const [checkCitizenImageFront, setCheckCitizenImageFront] = useState(true);
  const [checkCitizenImageBack, setCheckCitizenImageBack] = useState(true);
  const [checkPolicy, setCheckPolicy] = useState(false);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(30);
  const [reportForm, setReportForm] = useState({});
  const [dataUserPayment, setDataUserPayment] = useState({});
  const [dataReport, setDataReport] = useState({});
  const [dataCategory, setDataCategory] = useState([]);
  const [checkProvince, setCheckProvince] = useState(false);
  const [checkDistrict, setCheckDistrict] = useState(false);
  const [checkSubDistrict, setCheckSubDistrict] = useState(false);
  const [checkAddress, setCheckAddress] = useState(false);
  const [checkPostalCode, setCheckPostalCode] = useState(false);
  const [facebookUser, setFacebookUser] = useState(undefined);
  const [facebookPic, setFacebookPic] = useState("");
  const [isPromtpay, setIsPromtpay] = useState(false);
  const [itemCategory, setItemCategory] = useState([
    {
      name: "เสื้อผ้า",
      data: "เสื้อผ้า",
      ActiveImg: activeFashionImg,
      img: fashionImg,
      status: false
    },
    {
      name: "รองเท้าและกระเป๋า",
      data: "รองเท้าและกระเป๋า",
      ActiveImg: activeShoeImg,
      img: shoeImg,
      status: false
    },
    {
      name: "เครื่องดนตรี",
      data: "เครื่องดนตรี",
      ActiveImg: activeInstrumentImg,
      img: instrumentImg,
      status: false
    },
    {
      name: "เครื่องประดับ",
      data: "เครื่องประดับ",
      ActiveImg: activeAccessaryImg,
      img: accessaryImg,
      status: false
    },
    { name: "ของแต่งรถ",data: "ของแต่งรถ", ActiveImg: activeCarImg, img: carImg, status: false },
    { name: "หนังสือ", ActiveImg: activeBookImg, img: bookImg, status: false },
    {
      name: "สินค้าแม่และเด็ก",
      data: "สินค้าแม่และเด็ก",
      ActiveImg: activeBabyStuffImg,
      img: babyStuffImg,
      status: false
    },
    {
      name: "อุปกรณ์ไอที",
      data: "อุปกรณ์ไอที",
      ActiveImg: activeGadGetImg,
      img: gadGetImg,
      status: false
    },
    {
      name: "เฟอร์นิเจอร์",
      data: "เฟอร์นิเจอร์",
      ActiveImg: activeFurnitureImg,
      img: furnitureImg,
      status: false
    },
    {
      name: "ของมือสอง",
      data: "ของมือสอง",
      ActiveImg: activeSecondHandImg,
      img: secondHandImg,
      status: false
    },
    {
      name: "ตั๋วคอนเสิร์ต",
      data: "ตั๋วคอนเสิร์ต",
      ActiveImg: activeTicketImg,
      img: ticketImg,
      status: false
    },
    {
      name: "สุขภาพและความงาม",
      data: "สุขภาพและความงาม",
      ActiveImg: activeCosmeticsImg,
      img: cosmeticsImg,
      status: false
    },
    { name: "เกมส์",data: "เกมส์", ActiveImg: activeGameImg, img: gameImg, status: false },
    {
      name: "ของสะสม",
      data: "ของสะสม",
      ActiveImg: activeCollectionImg,
      img: collectionImg,
      status: false
    },
    {
      name: "กีฬา",
      data: "กีฬา",
      ActiveImg: activeFootballImg,
      img: footballImg,
      status: false
    },
    {
      name: "สัตว์เลี้ยง",
      data: "สัตว์เลี้ยง",
      ActiveImg: activePetImg,
      img: petImg,
      status: false
    },
    { name: "อื่นๆ", data: "", status: false }
  ]);
  const [isCollectCategory, setIsCollectCategory] = useState(false);
  //value สำหรับ return ไปให้หน้าต่าง ๆ ใช้
  const store = {
    general: [general, setGeneral],
    bankaccount: [bankaccount, setBankAccount],
    authen: [authen, setAuthen],
    promotion: [promotion, setPromotion],
    activeStep: [activeStep, setActiveStep],
    userId: [userId, setUserId],
    prefix: [prefix, setPrefix],
    nameTh: [nameTh, setNameTh],
    surnameTh: [surnameTh, setSurnameTh],
    nameEn: [nameEn, setNameEn],
    surnameEn: [surnameEn, setSurnameEn],
    email: [email, setEmail],
    isPromptpay: [isPromptpay, setIsPromptpay],
    phone: [phone, setPhone],
    facebook: [facebook, setFacebook],
    addresses: [addresses, setAddresses],
    address: [address, setAddress],
    province: [province, setProvince],
    district: [district, setDistrict],
    postalCode: [postalCode, setPostalCode],
    subDistrict: [subDistrict, setSubDistrict],
    citizenImageFront: [citizenImageFront, setCitizenImageFront],
    citizenImageBack: [citizenImageBack, setCitizenImageBack],
    citizenImageFace: [citizenImageFace, setCitizenImageFace],
    citizenId: [citizenId, setCitizenId],
    laserCode: [laserCode, setLaserCode],
    open: [open, setOpen],
    checkCitizenImageFront: [checkCitizenImageFront, setCheckCitizenImageFront],
    checkCitizenImageBack: [checkCitizenImageBack, setCheckCitizenImageBack],
    checkCitizenImageFace: [checkCitizenImageFace, setCheckCitizenImageFace],
    checkPolicy: [checkPolicy, setCheckPolicy],
    loading: [loading, setLoading],
    count: [count, setCount],
    reportForm: [reportForm, setReportForm],
    dataUserPayment: [dataUserPayment, setDataUserPayment],
    dataReport: [dataReport, setDataReport],
    dataCategory: [dataCategory, setDataCategory],
    birthdate: [birthdate, setBirthdate],
    checkProvince: [checkProvince, setCheckProvince],
    checkDistrict: [checkDistrict, setCheckDistrict],
    checkSubDistrict: [checkSubDistrict, setCheckSubDistrict],
    checkAddress: [checkAddress, setCheckAddress],
    checkPostalCode: [checkPostalCode, setCheckPostalCode],
    facebookUser: [facebookUser, setFacebookUser],
    facebookPic: [facebookPic, setFacebookPic],
    isPromtpay: [isPromtpay, setIsPromtpay],
    itemCategory: [itemCategory, setItemCategory],
    isCollectCategory: [isCollectCategory, setIsCollectCategory]
  };

  return (
    <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
  );
};
