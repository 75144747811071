import Button from "@material-ui/core/Button";
import styled from "styled-components";
import colors from "../common/Color";

const StepButton = styled(Button)`
  && {
    width: 7.5rem;
    height: 2.75rem;
    background: ${props => (props.blue ? colors.primary : "transparent")};
    font-family: "SukhumvitSet-SemiBold";
    font-size: 0.88rem;
    color: ${props => (props.blue ? "white" : colors.primary)};
    letter-spacing: 0.08rem;
    line-height: 1rem;
    border-radius: 0.25rem;
    border: ${props => (props.blue ? "none" : "0.06rem solid #3D54D4")};
    box-shadow: ${props =>
      props.blue ? "0 0.06rem 0.06rem 0 rgba(0,0,0,0.50)" : ""};
    &:hover {
      background: ${props => (props.blue ? colors.primaryDark : "transparent")};
    }
    margin-right: ${props => (props.blue ? "0" : "1rem")};
    @media (max-width: 1024px) {
      width: 100%;
      height: 2.75rem;
      margin-right: ${props => (props.blue ? "0" : "0")};
      margin-bottom: ${props => (props.blue ? "0.75rem" : "6rem")};
    }
  }
`;

export default StepButton;
