import React, { useState, useContext, useEffect } from "react";
import { CircularProgress } from "@material-ui/core";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import Contact from "./Contact";
import Information from "./Information";
import ReportSuccess from "./ReportSuccess";
import { ReactComponent as LogoVerme } from "../assets/Logo/logo_verme_white.svg";
import { ReactComponent as LogoVermeColor } from "../assets/Logo/svg/logo_verme_color.svg";
import { withStyles } from "@material-ui/core/styles";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepConnector from "@material-ui/core/StepConnector";
import colors from "../common/Color";
import useForm from "react-hook-form";
import StepButton from "../Register/StepButton";
import CancelBtn from "./CancelBtn";
import ModalOtp from "./OtpBtn";
import { NextBtn } from "./OtpBtn";
import * as yup from "yup";
import { RegisterContext } from "../Context/RegisterStore";
import PolicyPage from "./ReportPolicy";
import {
  sendEmailReport,
  sendOTPReport,
  createReportData
} from "../actions/action";

const Root = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
`;
const GridContent = styled(Grid)`
  && {
    margin: 7vh auto;
    @media (max-width: 960px) {
      margin: auto;
    }
  }
`;
const DesktopStep = styled.div`
  @media (max-width: 960px) {
    display: none;
  }
`;
const MobileStep = styled.div`
  @media (min-width: 961px) {
    display: none;
  }
`;
const StyledStep = styled.div`
  width: 23.25rem;
  min-height: 100%;
  background-image: linear-gradient(rgba(0, 0, 0, 0.12), rgba(0, 0, 0, 0.12)),
    linear-gradient(200deg, #df8d95, #4a3af7);
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const StyledStepMobile = styled.div`
  min-width: 100%;
  height: 9rem;
  margin-bottom: 2rem;
  background-image: linear-gradient(
    270deg,
    rgba(188, 119, 134, 1),
    rgba(95, 64, 192, 1)
  );
  background-size: cover;
`;
const Logo = styled(LogoVerme)`
  width: 6.73rem;
  height: 1.75rem;
`;
const Margin = styled.div`
  margin: 2rem 2.4rem;
`;
const StyledStepper = styled(Stepper)`
  &.MuiPaper-root {
    background-color: transparent;
    margin: auto;
  }
  & .MuiStepIcon-root {
    color: transparent;
    border: 0.13rem solid ${colors.white};
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
    opacity: 0.33;
  }
  & .MuiStepIcon-root.MuiStepIcon-active {
    color: transparent;
    opacity: 1;
  }
  & .MuiStepIcon-root.MuiStepIcon-completed {
    color: ${colors.white};
    border: none;
    opacity: 1;
    width: 3.26rem;
    height: 3.26rem;
  }
  & .MuiStepLabel-iconContainer {
    padding-right: 1rem;
  }
`;

const QontoConnector = withStyles({
  active: {
    "& $line": {
      borderColor: colors.white,
      opacity: 1,
      borderWidth: "0.19rem",
      minHeight: "5.5rem"
    }
  },
  completed: {
    "& $line": {
      borderColor: colors.white,
      opacity: 1,
      borderWidth: "0.19rem",
      minHeight: "5.5rem"
    }
  },
  line: {
    borderColor: colors.white,
    opacity: 0,
    minHeight: "5.5rem",
    marginLeft: "0.75rem"
  }
})(StepConnector);

const StyledConnector = styled(QontoConnector)`
  && {
    padding: 0;
  }
`;

const MobileConnector = withStyles({
  alternativeLabel: {
    top: 22
  },
  active: {
    "& $line": {
      backgroundColor: colors.white,
      opacity: 1
    }
  },
  completed: {
    "& $line": {
      backgroundColor: colors.white,
      opacity: 1
    }
  },
  line: {
    height: 4,
    border: 0,
    width: "99%",
    backgroundColor: colors.white,
    borderRadius: 1,
    opacity: 0,
    "@media (max-width:960px)": {
      width: "99%"
    },
    "@media (max-width:768px)": {
      width: "98%"
    },
    "@media (max-width:600px)": {
      width: "97%"
    },
    "@media (max-width:425px)": {
      width: "96%"
    }
  }
})(StepConnector);

const StyledMobileConnector = styled(MobileConnector)`
  && {
    padding: 0;
  }
`;

const StyledLabel = styled(StepLabel)`
  & .MuiStepLabel-label {
    font-family: "SukhumvitSet-Text";
    font-size: 1.5rem;
    line-height: 2.31rem;
    color: ${colors.white};
    opacity: 0.7;
    @media (max-width: 960px) {
      font-size: 1rem;
    }
    @media (max-width: 320px) {
      font-size: 0.75rem;
    }
  }
  & .MuiStepLabel-label.MuiStepLabel-active {
    font-family: "SukhumvitSet-Bold";
    color: ${colors.white};
    opacity: 1;
  }
  & .MuiStepLabel-label.MuiStepLabel-completed {
    font-family: "SukhumvitSet-Text";
    color: ${colors.white};
    opacity: 0.7;
  }
`;

const Text = styled.div`
  font-family: "SukhumvitSet-Text";
  color: ${colors.white};
  letter-spacing: 0.01rem;
  line-height: 1.5rem;
  font-size: 1rem;
  text-align: center;
  opacity: 0.7;
`;
const MobileGrid = styled(Grid)`
  && {
    margin-bottom: 10vh;
    @media (min-width: 1024px) {
      flex-basis: 0;
    }
  }
`;

const GridCancel = styled(Grid)`
  && {
    @media (max-width: 960px) {
      display: flex;
      height: 4rem;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin: 0 2.25rem 0 2rem;
    }
  }
`;
const GridStepButton = styled(Grid)`
  && {
    margin-top: 2rem;
    @media (max-width: 960px) {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      justify-content: space-around;
      margin-top: 1.5rem;
    }
  }
`;
const LogoColor = styled(LogoVermeColor)`
  width: 2.63rem;
  height: 2.25rem;
  @media (min-width: 961px) {
    display: none;
  }
`;
function getSteps() {
  return ["ข้อมูลติดต่อกลับ", "ข้อมูลผู้กระทำผิด"];
}
const StyledForm = styled.form`
  display: flex;
  width: 100vw;
  background: ${colors.surfaceGray};
  @media (max-width: 960px) {
    background-color: rgba(247, 248, 250, 0.24);
  }
`;
const LoadingScreen = styled.div`
  height: 100vh;
  width: 100vw;
  z-index: 100;
  background: rgba(255, 255, 255, 0.5);
  position: fixed;
`;
const phoneRegExp = /([0])([6,8,9])([0-9]{8})/;
const contactSchema = yup.object().shape({
  firstName: yup.string().required("กรุณากรอกชื่อ"),
  lastName: yup.string().required("กรุณากรอกนามสกุล"),
  email: yup
    .string()
    .email("อีเมลไม่ถูกต้อง")
    .required("กรุณากรอกอีเมล"),
  phone: yup
    .string()
    .matches(phoneRegExp, "เบอร์โทรศัพท์มือถือไม่ถูกต้อง")
    .required("กรุณากรอกเบอร์โทรศัพท์มือถือ")
    .min(10, "กรุณากรอกเบอร์โทรศัพท์มือถือให้ครบถ้วน")
});
const informationSchema = yup.object().shape({
  vermeId: yup
    .string()
    .required("กรุณากรอก VerME ID ของผู้กระทำผิด")
    .min(6, "กรุณากรอก VerME ID ของผู้กระทำผิดให้ครบถ้วน")
    .max(6, "VerME ID จะต้องไม่เกิน 6 หลัก"),
  description: yup.string().required("กรุณากรอกคำอธิบายเกี่ยวกับเหตุการณ์")
});

export default function Report() {
  const {
    prefix,
    name,
    surname,
    email,
    phone,
    reportVmId,
    description,
    image,
    checkImage,
    checkErrorPhone,
    checkPolicy,
    loading,
    count,
    open
  } = useContext(RegisterContext);
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();

  useEffect(() => {
    const id = setInterval(() => count[1](c => c - 1), 1000);
    return () => clearInterval(id);
    // eslint-disable-next-line
  }, []);

  const resetTimer = phone => {
    sendOTPAgain();
    count[1](30);
  };

  const contactForm = useForm({
    validationSchema: contactSchema
  });

  const informationForm = useForm({
    validationSchema: informationSchema
  });

  const handleOpen = () => {
    open[1](true);
  };

  const handleClose = () => {
    open[1](false);
  };

  async function uploadVmIdReport() {
    loading[1](true);
    var imageData = image[0].map(item => item.raw);

    let formData = new FormData();

    imageData.forEach((item, index) => {
      formData.append("image", item);
    });

    formData.append("reportData.vm_id", reportVmId[0]);
    formData.append("reportData.description", description[0]);
    formData.append("name.name", name[0] + " " + surname[0]);
    formData.append("phone", phone[0]);
    formData.append("email", email[0]);
    formData.append("name.prefix", prefix[0]);

    if (image[0].length) {
      checkImage[1](true);
    } else {
      checkImage[1](false);
    }

    if (image[0].length && reportVmId[0] && description[0]) {
      try {
        const res = await createReportData(formData);
        if (res.status === 201) {
          sendEmail(res.data.id);
        }
      } catch (err) {
        console.log(err);
      }
    }
  }

  async function sendEmail(item) {
    try {
      const res = await sendEmailReport(item);
      if (res.status === 200) {
        handleNext();
        loading[1](false);
      }
    } catch (err) {
      console.log(err);
    }
  }

  const contactFormSubmit = async () => {
    var checkPhone = phone[0].split(" ");
    const checkData = item => item === "";

    if (checkPhone.some(checkData)) {
      checkErrorPhone[1](true);
      loading[1](false);
    } else {
      var compressPhone = checkPhone[0];
      phone[1](compressPhone);
      checkErrorPhone[1](false);
    }

    if (process.env.REACT_APP_API !== "https://test.api.verme.me") {
      sendOTP();
    } else {
      loading[1](false);
      handleNext();
    }
  };

  async function sendOTP() {
    loading[1](true);
    var data = {
      phone: phone[0]
    };

    try {
      const res = await sendOTPReport(data);
      if (res.status === 200) {
        handleOpen();
        loading[1](false);
      }
    } catch (err) {
      loading[1](false);
      console.log(err);
    }
  }

  async function sendOTPAgain() {
    loading[1](true);
    var data = {
      phone: phone[0]
    };

    var checkPhone = phone[0].split(" ");
    var compressPhone = checkPhone[0]
      .concat(checkPhone[1])
      .concat(checkPhone[2]);

    phone[1](compressPhone);

    try {
      await sendOTPReport(data);
    } catch (err) {
      console.log(err);
    }
  }

  const setImageData = item => {
    let newData = [...image[0]];
    newData.push(item);
    image[1](newData);
  };

  const deleteImageData = index => {
    image[1](prevImage => [...prevImage.filter((item, i) => i !== index)]);
  };

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    if (activeStep === 1) {
      handleClose();
      setActiveStep(prevActiveStep => prevActiveStep - 1);
    } else {
      setActiveStep(prevActiveStep => prevActiveStep - 1);
    }
  };

  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return <Contact id="contact" formProps={contactForm} />;
      case 1:
        return (
          <Information
            id="information"
            formProps={informationForm}
            setImageData={setImageData}
            deleteImageData={deleteImageData}
          />
        );
      default:
        return "Unknown stepIndex";
    }
  }
  return (
    <Root id="root">
      {loading[0] ? <LoadingScreen id="loading-screen" /> : " "}
      {!checkPolicy[0] ? (
        <PolicyPage id="policy-page" />
      ) : (
        <StyledForm
          id="form"
          onSubmit={
            activeStep === 0
              ? contactForm.handleSubmit(contactFormSubmit)
              : informationForm.handleSubmit(uploadVmIdReport)
          }
        >
          <DesktopStep id="desktop-step">
            <StyledStep id="step">
              <Margin id="margin">
                <Logo id="logo" />
              </Margin>
              <StyledStepper
                id="stepper"
                activeStep={activeStep}
                orientation="vertical"
                connector={<StyledConnector id="connector" />}
              >
                {steps.map((label, index) => (
                  <Step id="step" key={label}>
                    <StyledLabel id="label">{label}</StyledLabel>
                  </Step>
                ))}
              </StyledStepper>
              <Margin id="margin">
                <Text id="txt">
                  เราจะช่วยให้การซื้อขายบนโซเชียลมีเดียของคุณ <br />
                  มีความมั่นใจและปลอดภัยมากยิ่งขึ้น
                </Text>
              </Margin>
            </StyledStep>
          </DesktopStep>
          {activeStep === steps.length ? (
            <Grid
              id="grid-container"
              container
              style={{ display: "block", backgroundColor: colors.white }}
            >
              <GridCancel id="grid-cancel" item xs={12}>
                <LogoColor id="logo-color" />
              </GridCancel>
              <div id="mobile-div" style={{ width: "100%" }}>
                <MobileGrid id="grid-mb" item xs={12}>
                  <MobileStep id="step-mb">
                    <StyledStepMobile id="step-mobile">
                      <StyledStepper
                        id="stepper-mobile"
                        activeStep={activeStep}
                        orientation="horizontal"
                        alternativeLabel
                        connector={<StyledMobileConnector id="connector-mb" />}
                      >
                        {steps.map((label, index) => (
                          <Step id="step" key={label}>
                            <StyledLabel id="label">{label}</StyledLabel>
                          </Step>
                        ))}
                      </StyledStepper>
                    </StyledStepMobile>
                  </MobileStep>
                </MobileGrid>
              </div>
              <Grid id="grid-item" item xs={12}>
                <ReportSuccess id="report-success-page" />
              </Grid>
            </Grid>
          ) : (
            <Grid
              id="grid-container"
              container
              style={{ backgroundColor: colors.white }}
            >
              <Grid
                id="container-center"
                container
                justify="center"
                alignItems="flex-start"
                item
                xs={12}
                style={{ display: "block" }}
              >
                <GridCancel id="cancel-grid" item xs={12}>
                  <CancelBtn id="cancel-btn" />
                  <LogoColor id="logo-color" />
                </GridCancel>
                <Grid id="grid-item" item xs={12}>
                  <MobileStep id="step-mb">
                    <StyledStepMobile id="step-mobile">
                      <StyledStepper
                        id="stepper-mobile"
                        activeStep={activeStep}
                        orientation="horizontal"
                        alternativeLabel
                        connector={<StyledMobileConnector id="connector-mb" />}
                      >
                        {steps.map((label, index) => (
                          <Step id="step" key={label}>
                            <StyledLabel id="label">{label}</StyledLabel>
                          </Step>
                        ))}
                      </StyledStepper>
                    </StyledStepMobile>
                  </MobileStep>
                </Grid>
                <GridContent id="grid-content" item xs={8}>
                  {getStepContent(activeStep)}
                  {activeStep === 0 ? (
                    <Grid
                      id="container-item"
                      container
                      item
                      xs={12}
                      justify="flex-end"
                      style={{ marginTop: "1.5rem" }}
                    >
                      <NextBtn
                        id="next-btn"
                        type="submit"
                        disabled={loading[0]}
                      >
                        {loading[0] ? (
                          <CircularProgress
                            id="circular-progress"
                            size={25}
                            thickness={4}
                            style={{ color: "white" }}
                          />
                        ) : (
                          "ต่อไป"
                        )}
                      </NextBtn>
                      <ModalOtp
                        id="modal-otp"
                        handleNext={handleNext}
                        handleClose={handleClose}
                        handleOpen={handleOpen}
                        resetTimer={resetTimer}
                      />
                    </Grid>
                  ) : (
                    <GridStepButton
                      id="grid-step-btn"
                      container
                      justify="flex-end"
                      item
                      xs={12}
                    >
                      <StepButton id="step-btn" onClick={handleBack}>
                        ย้อนกลับ
                      </StepButton>
                      <StepButton
                        id="step-btn"
                        type="submit"
                        blue="true"
                        disabled={loading[0]}
                      >
                        {loading[0] ? (
                          <CircularProgress
                            id="circular-progress"
                            size={25}
                            thickness={4}
                            style={{ color: "white" }}
                          />
                        ) : (
                          "ต่อไป"
                        )}
                      </StepButton>
                    </GridStepButton>
                  )}
                </GridContent>
              </Grid>
            </Grid>
          )}
        </StyledForm>
      )}
    </Root>
  );
}
