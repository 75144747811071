import styled from "styled-components";

const TitleText = styled.div`
  font-family: "SukhumvitSet-Text";
  font-size: 2.13rem;
  color: #000;
  letter-spacing: 0.02rem;
  line-height: 3.38rem;
  text-align: center;
  margin-top: 8rem;
  @media (max-width: 1024px){
    font-size: 1.5rem;
    letter-spacing: 0;
    line-height: 2.31rem;
    margin-top: 3.5rem;
  }
`;

export default TitleText;
