import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { ReactComponent as LogoVerme } from "../assets/Logo/logo_verme_dark.svg";
import colors from "./Color";
import { animateScroll as scroll } from "react-scroll";
import Linker from "@material-ui/core/Link";
import WebButton from "./WebButton";
import AccountMenu from "./AccountMenu";
import { LoginContext } from "../Context/LoginStore";
import Cookies from "universal-cookie";
import { getUserProfile } from "../actions/action";

const cookies = new Cookies();

const Container = styled.div`
  top: 0;
  width: 100%;
  height: 4rem;
  background-color: ${colors.white};
  position: fixed;
  z-index: 10;
  @media (max-width: 1024px) {
    display: none;
  }
`;
const Nav = styled.div`
  width: 90%;
  height: 4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 5%;
`;
const BtnDiv = styled.div`
  display: flex;
  align-items: center;
`;
const Logo = styled(LogoVerme)`
  width: 6.13rem;
  height: 1.63rem;
  cursor: pointer;
`;
const StyledLink = styled(Linker)`
  && {
    margin: 0 1.69rem;
    font-family: "SukhumvitSet-Semibold";
    font-size: 0.88rem;
    letter-spacing: 0.08rem;
    line-height: 1rem;
    color: ${props => (props.active ? colors.primary : "rgba(0, 0, 0, 0.6)")};
    cursor: pointer;
    &:hover {
      color: ${colors.primary};
    }
    @media (max-width: 1439px) {
      margin: 0 0.69rem;
    }
    @media (max-width: 1170px) {
      margin: 0 0.35rem;
    }
  }
`;
const Divider = styled.div`
  width: 0.75rem;
  height: 1.94rem;
  margin-left: 0.75rem;
  border-left: 0.06rem solid #e9e9e9;
`;

export default function Navbar(props) {
  const [boxShadow, setBoxShadow] = useState("0 0 0 0 rgba(0,0,0,0)");
  const { userLogin } = useContext(LoginContext);

  useEffect(() => {
    const getUserData = async () => {
      try {
        const token = cookies.get("token");
        const sessionId = cookies.get("sessionId");
        const res = await getUserProfile(token, { sessionId: sessionId });
        if (res.status === 200) {
          userLogin[1](res.data);
        }
      } catch (err) {
        console.log(err);
        if (err.response.status === 401) {
          cookies.remove("token", { path: "/" });
          cookies.remove("sessionId", { path: "/" });
          userLogin[1]({});
          window.location.href = "/";
        }
      }
    };
    if (cookies.get("token")) {
      getUserData();
    }
    window.addEventListener("scroll", listenScrollEvent);
    // eslint-disable-next-line
  },[]);

  const scrollToTop = () => {
    scroll.scrollToTop();
  };

  const listenScrollEvent = e => {
    if (window.scrollY > 100) {
      setBoxShadow("0 0.13rem 0.25rem 0 rgba(0,0,0,0.03)");
    } else {
      setBoxShadow("0 0 0 0 rgba(0,0,0,0)");
    }
  };

  const loginPage = () => {
    window.location.href = "/sign-in";
  };

  return (
    <Container id="container" style={{ boxShadow: boxShadow }}>
      <Nav id="nav">
        {props.props === "homepage" ? (
          <Logo id="logo" onClick={scrollToTop} />
        ) : (
          <a id="link" href="/">
            <Logo id="logo" />
          </a>
        )}
        <BtnDiv id="btn-div">
          {props.props === "howto" ? (
            <StyledLink
              id="st-link"
              underline="none"
              href="/how-to"
              active="true"
            >
              วิธีใช้งาน
            </StyledLink>
          ) : (
            <StyledLink id="st-link" underline="none" href="/how-to">
              วิธีใช้งาน
            </StyledLink>
          )}
          <StyledLink id="st-link" underline="none" href="/identity-check">
            ตรวจสอบตัวตน
          </StyledLink>
          <StyledLink id="st-link" underline="none" href="/report-form">
            รายงานผู้ขาย
          </StyledLink>
          {props.props === "pricing" ? (
            <StyledLink
              id="st-link"
              underline="none"
              href="/pricing"
              active="true"
            >
              ซื้อแพ็กเกจ
            </StyledLink>
          ) : props.props === "signin" ? (
            ""
          ) : (
            <StyledLink id="st-link" underline="none" href="/pricing">
              ซื้อแพ็กเกจ
            </StyledLink>
          )}
          {props.props === "faq" ? (
            <StyledLink id="st-link" underline="none" href="/faq" active="true">
              คำถามที่พบบ่อย
            </StyledLink>
          ) : props.props === "signin" ? (
            <StyledLink id="st-link" underline="none" href="/faq">
              คำถามที่พบบ่อย
            </StyledLink>
          ) : (
            <StyledLink id="st-link" underline="none" href="/faq">
              คำถามที่พบบ่อย
            </StyledLink>
          )}
          <Divider id="divider" />
          {cookies.get("token") ? (
            <AccountMenu id="account-menu" />
          ) : props.props === "sign-in" ? (
            <div id="link-div">
              <StyledLink
                id="st-link"
                underline="none"
                onClick={loginPage}
                active="true"
              >
                เข้าสู่ระบบ
              </StyledLink>
              <WebButton
                id="regist-btn"
                primary="true"
                width="7.44rem"
                height="2rem"
                href="/prepare-before-register"
                target="_blank"
                rel="noopener noreferrer"
                nav="true"
              >
                ทดลองใช้ฟรี
              </WebButton>
            </div>
          ) : (
            <div id="link-div">
              <StyledLink id="st-link" underline="none" onClick={loginPage}>
                เข้าสู่ระบบ
              </StyledLink>
              <WebButton
                id="regist-btn"
                primary="true"
                width="7.44rem"
                height="2rem"
                href="/prepare-before-register"
                target="_blank"
                rel="noopener noreferrer"
                nav="true"
              >
                ทดลองใช้ฟรี
              </WebButton>
            </div>
          )}
        </BtnDiv>
      </Nav>
    </Container>
  );
}
