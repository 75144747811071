import * as React from "react";
import axios from "axios";

class Notice extends React.Component {
  //! TODO:: Noti Error Later
  accessDenined = response => {
    // notification.error({
    //   message: 'ไม่สามารถเข้าถึงข้อมูลนี้ได้',
    //   description:
    //     `กรุณาติดต่อเจ้าหน้าที่เพื่อขอสิทธิ์การเข้าถึงข้อมูลนี้`,
    // });
  };

  alreadyLoggedin = response => {
    // notification.error({
    //   message: 'กรุณาเข้าสู่ระบบ',
    //   description:
    //     `พบชื่อผู้ใช้นี้เข้าสู่ระบบจากอุปกรณ์หรือเว็บเบราว์เซอร์อื่น\n กรุณาเข้าสู่ระบบใหม่อีกครั้ง`,
    // });
  };
}
// eslint-disable-next-line
const instance = new Notice({});
const handleError = response => {
  //   if (response.httpCode === 401) {
  //     switch (response.errorCode) {
  //       case 2:
  //         auth.clearAllAppStorage()
  //         window.location.href = '/auth'
  //         break
  //       case 5:
  //         instance.alreadyLoggedin(response)
  //         auth.clearAllAppStorage()
  //         setTimeout(() => (window.location.href = '/auth'), 2000)
  //         break
  //     }
  //   }
  //   if (response.httpCode === 403 && response.errorCode === 4) {
  //     instance.accessDenined(response)
  //   }
};

const fetch = (
  method,
  endpoint = "/",
  body = {},
  tokenUser,
  headers = {},
  apiUrl = process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_API
    : process.env.REACT_APP_TEST_API,
  options = {}
) => {
  const url = `${apiUrl}/${endpoint}`;
  const data = body;
  const queryName = method === "GET" ? "params" : "data";

  const api = axios.create({
    baseURL: url
  });

  api.interceptors.request.use(config => {
    const token = tokenUser.length ? tokenUser : process.env.REACT_APP_HEADER;
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  });

  const res = api.request({
    method,
    url,
    [queryName]: data,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...headers
    },
    ...options
  });

  res.catch(error => {
    console.error(error);
    if (error.response) {
      handleError(error.response.data);
    }
  });

  return Promise.resolve(res);
};

export default fetch;
