import styled from "styled-components";

const ContentText = styled.div`
  width: ${props => (props.subtitle ? "46.06rem" : "")};
  font-family: "Sarabun-Regular";
  font-size: 1rem;
  letter-spacing: 0.03rem;
  line-height: 1.75rem;
  color: #000;
  opacity: ${props => (props.inactive ? 0.12 : 0.6)};
  text-align: center;
  margin: auto;
  @media (max-width: 1024px) {
    width: ${props => (props.subtitle ? "23.38rem" : "")};
    font-size: 0.88rem;
    letter-spacing: 0.02rem;
    line-height: 1.25rem;
  }
  @media (max-width: 375px) {
    width: ${props => (props.subtitle ? "17rem" : "")};
  }
`;
export default ContentText;
