import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import checkIcon from "../assets/Icons/Icons Black/ic_check_filled.svg";
import { Link } from "react-router-dom";
import colors from "../common/Color";

const styles = theme => ({
  root: {
    textAlign: "center",
    marginTop: "3rem",
    [theme.breakpoints.down(1025)]: {
      marginBottom: "4.25rem"
    }
  },
  gotoBtn: {
    width: "18.31rem",
    height: "2.25rem",
    color: colors.primary,
    border: "0.06rem solid rgba(0,0,0,0.12)"
  },
  expdate: {
    display: "flex",
    justifyContent: "center"
  },
  ver: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "1rem"
  },
  checkicon: {
    width: "2rem",
    height: "2rem",
    padding: "0.5rem"
  }
});

class VerifiedExample extends Component {
  render() {
    const { classes } = this.props;

    return (
      <div id="root" className={classes.root}>
        <div id="verme-div" className={classes.ver}>
          <Box
            id="box"
            letterSpacing={"0.02rem"}
            lineHeight={"2.81rem"}
            fontFamily={"Roboto Slab"}
            fontSize={"2.13rem"}
            color="#000000"
            fontWeight="fontWeightRegular"
          >
            Verified
          </Box>
          <img
            id="check-icon"
            className={classes.checkicon}
            src={checkIcon}
            alt="check"
          />
        </div>
        <Box
          id="box"
          letterSpacing={"0.03rem"}
          lineHeight={"1.75rem"}
          fontSize={"1rem"}
          color="text.secondary"
          fontWeight="fontWeightRegular"
        >
          This identity is approved successfully.
        </Box>
        <div
          id="exp-date-div"
          className={classes.expdate}
          style={{ marginBottom: "2.5rem" }}
        >
          <Box
            id="box"
            style={{ marginRight: "0.5rem" }}
            letterSpacing={"0.03rem"}
            lineHeight={"1.75rem"}
            fontSize={"1rem"}
            color="text.secondary"
            fontWeight="fontWeightRegular"
          >
            Expire:
          </Box>
          <Box
            id="box"
            letterSpacing={"0.03rem"}
            lineHeight={"1.75rem"}
            fontSize={"1rem"}
            color="#3B54D4"
            fontWeight="fontWeightRegular"
          >
            XX/XX
          </Box>
        </div>
        <Link id="link" to="/" style={{ textDecoration: "none" }}>
          <Button id="goto-btn" variant="outlined" className={classes.gotoBtn}>
            <Box
              id="box"
              letterSpacing={"0.08rem"}
              lineHeight={"1rem"}
              fontSize={"0.88rem"}
              fontWeight="fontWeightMedium"
            >
              GO TO HOMEPAGE
            </Box>
          </Button>
        </Link>
      </div>
    );
  }
}

export default withStyles(styles)(VerifiedExample);
