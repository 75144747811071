import React, { useState, useEffect, useContext, createContext } from "react";
import { login, logout } from "./actions/action";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const authContext = createContext();

export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const useAuth = () => {
  return useContext(authContext);
};

function useProvideAuth() {
  const [currentUser, setCurrentUser] = useState(true);

  const signInWithEmail = async (email, password) => {
    return await login("email", { email: email, password: password }).then(
      (response) => {
        setCurrentUser(response.data.accessToken);
        cookies.set("sessionId", response.data.sessionId, { path: "/" });
        cookies.set("token", response.data.accessToken, { path: "/" });
        return response;
      }
    );
  };

  const signOutWithEmail = async () => {
    return await logout("email", cookies.get("sessionId"), cookies.get("token")).then(
      (response) => {
        setCurrentUser(false);
        cookies.remove("sessionId", { path: "/" });
        cookies.remove("token", { path: "/" });
        return response;
      }
    );
  };

  const signInWithFacebook = async (data) => {
    return await login("facebook", data).then((response) => {
      setCurrentUser(response.data.accessToken);
      cookies.set("sessionId", response.data.sessionId, { path: "/" });
      cookies.set("token", response.data.accessToken, { path: "/" });
      return response;
    });
  };

  const signOutWithFacebook = async () => {
    return await logout("facebook", cookies.get("sessionId"), cookies.get("token")).then(
      (response) => {
        setCurrentUser(false);
        cookies.remove("sessionId", { path: "/" });
        cookies.remove("token", { path: "/" });
        return response;
      }
    );
  };

  useEffect(() => {
    const unsubscribe = cookies.get("token")
      ? setCurrentUser(true)
      : setCurrentUser(false);

    return () => unsubscribe;
  }, []);

  return {
    currentUser,
    setCurrentUser,
    signInWithEmail,
    signOutWithEmail,
    signInWithFacebook,
    signOutWithFacebook,
  };
}
