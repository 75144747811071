import styled from "styled-components";
import colors from "./Color";

const GrayText = styled.div`
  font-family: "Sarabun-Regular";
  font-size: ${props => (props.primary ? "0.88rem" : "1rem")};
  color: ${colors.black};
  opacity: 0.6;
  letter-spacing: 0.03rem;
  line-height: 1.75rem;
`;

export default GrayText;
