import React, { useState } from "react";
import colors from "../common/Color";
import styled from "styled-components";
import { ReactComponent as IconDropDown } from "../assets/Icons/ic_dropdown.svg";

const QuestionText = styled.div`
  font-family: "Sarabun-Regular";
  font-size: 1rem;
  color: ${colors.primary};
  letter-spacing: 0.5px;
  text-align: left;
  line-height: 28px;
`;

const AnswerText = styled.div`
  font-size: 1rem;
  color: ${colors.black};
  opacity: 0.6;
  letter-spacing: 0.03rem;
  line-height: 1.75rem;
  text-align: left;
  display: ${props => (props.collapse ? "block" : "none")};
  margin: ${props => (props.collapse ? "1rem 2.5rem 1.5rem" : "0 2.5rem")};
  transition: 0.3s ease-in-out;
`;

const IconDropdown = styled(IconDropDown)`
  font-size: 1.5rem;
  fill: ${colors.primary};
  transform: ${props => (props.collapse ? "rotate(180deg)" : "")};
  transition: 0.3s;
`;

const ArrowButton = styled.div`
  width: 1.75rem;
  height: 1.75rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const QuestionDiv = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1.5rem 1rem 1rem 1.5rem;
  cursor: pointer;
`;

const Container = styled.div`
  width: 100%;
  margin-top: 1rem;
  background: rgba(245, 247, 255, 0.2);
  border: 1px solid #ebedfa;
  border-radius: 4px;
  user-select: none;
`;

export default function FAQOptions(props) {
  const { title } = props;
  const [collapsis, setCollapsis] = useState([
    [
      {
        question: "1. VerME คืออะไร?",
        answer:
          "VerME ของเราคือแพลตฟอร์มการยืนยันตัวตนผู้ขายบน Facebook Instagram Twitter และเว็บบอร์ดต่างๆ เพื่อให้ผู้ซื้อมั่นใจในการชำระเงิน โดยการออกบัตร Electronic card ให้กับผู้ขาย เพื่อแสดงให้กับผู้ซื้อ",
        status: false
      },
      {
        question: "2. VerME แตกต่างจาก Shopee และ Lazada ยังไง?",
        answer:
          "VerME เป็นแค่แพลตฟอร์มการยืนยันตัวตนผู้ขาย ไม่ใช่เว็บสำหรับขายของ ซึ่งแตกต่างจาก Shopee และ Lazada มาก",
        status: false
      },
      {
        question: "3. VerME แตกต่างจาก NDID และ Paypal ยังไง?",
        answer: (
          <div id="answer-div">
            NDID เป็นมาตรฐาน และเครื่องมือการยืนยันตัวตนแบบหนึ่งซึ่ง VerME
            และแพลตฟอร์มอ่านๆก็มีสิทธิ์ใช้เครื่องมือนี้เช่นกัน, <br />
            Paypal เป็นตัวกลางในการรับชำระเงิน แต่ VerME
            แพลตฟอร์มการยืนยันตัวตนผู้ขายที่ใช้บน Social Media
          </div>
        ),
        status: false
      },
      {
        question: "4. จุดเด่นของ VerME คืออะไร?",
        answer: "มีความปลอดภัยและมีแพลตฟอร์มที่ใช้งานง่าย ",
        status: false
      },
      {
        question: "5. หากมีปัญหาในเรื่องการใช้งานบัตร VerME ต้องติดต่อใคร?",
        answer: (
          <div id="answer-div">
            สามารถติดต่อได้ที่ Facebook Page{" "}
            <a
              id="link"
              href="https://web.facebook.com/verme.me/"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://web.facebook.com/verme.me/
            </a>{" "}
            <br />
            หรือติดต่อได้ที่ verme.contact@gmail.com
          </div>
        ),
        status: false
      },
      {
        question: "6. รายงานผู้ขายคืออะไร?",
        answer:
          "เป็นช่องทางรับร้องทุกข์ ร้องเรียนต่อการกระทำต่างๆที่ไม่ถูกต้อง เกี่ยวกับการกระทำผิดตามกฎหมาย ผิดจริยธรรม ผิดจรรยาบรรณ และอื่นๆ ที่ผู้ขายกระทำต่อผู้ซื้อ ที่ตอนนี้ควบคุมเฉพาะสมาชิก VerME ที่กระทำความผิดเท่านั้น",
        status: false
      }
    ],
    [
      {
        question: "1. ปลอดภัยกับผู้ซื้อและผู้ขายยังไง?",
        answer:
          "ในด้านผู้ซื้อคือสามารถตรวจสอบผู้ขายได้ผ่านเว็บไซต์ได้ตลอด 24 ชั่วโมง ในด้านผู้ขายคือบัตร Electronic Card ของผู้ขายมีจะโชว์แค่ชื่อและรหัส VerME ID เท่านั้น ซึ่งทำให้ผู้ซื้อและบุคคลอื่นๆไม่สามารถนำไปทำอย่างอื่นต่อได้ และข้อมูลที่เหลือของผู้ขายจะอยู่ในระบบ VBS ที่มีความปลอดภัยขั้นสูงซึ่งเราใช้ Blockchain Technologies เข้ามาเป็นส่วนเสริมความปลอดภัยขึ้นไปอีกขั้นด้วย",
        status: false
      },
      {
        question: "2. มีใบรับรอง(PDPA) จากกระทรวงดิจิตอลและปลอดภัยยังไง?",
        answer: (
          <div id="answer-div">
            ผู้ขายมีสิทธิในการได้รับแจ้งข้อมูล เข้าถึงข้อมูล แก้ไข ลบ
            จำกัดการให้ข้อมูลและปฏิเสธการให้ข้อมูล
            รวมถึงไม่อนุญาตให้ใช้ระบบการตัดสินใจอัตโนมัติกับข้อมูล
            ดูรายละเอียดเพิ่มเติมได้ที่{" "}
            <a
              id="link"
              href="/policy"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://verme.me/policy
            </a>
          </div>
        ),
        status: false
      },
      {
        question: "3. VBS คืออะไร?",
        answer:
          "ระบบที่ VerME พัฒนาขึ้นที่ใช้ในการจัดเก็บข้อมูลของผู้ใช้ โดยใช้แนวคิดของ Blockchain Technologies",
        status: false
      },
      {
        question: "4. จุดเด่นของ VBS คืออะไร?",
        answer:
          "ระบบ ​VBS มีการเข้ารหัสแบบทางเดียว (Hash) คือที่ไม่สามารถถอดข้อมูลออกมาได้ และการนำ Smart Contract (Blockchain) มาใช้",
        status: false
      }
    ],
    [
      {
        question: "1. อยากสมัคร VerME ต้องทำยังไง?",
        answer: (
          <div id="answer-div">
            ผู้ที่สนใจอยากสมัครจะต้องกรอกข้อมูลต่อไปนี้ <br /> 1. ชื่อ -
            นามสกุล(ไทย-อังกฤษ) <br /> 2. เบอร์โทรศัพท์ <br /> 3. E-mail <br />{" "}
            4. ชื่อ ​Facebook <br /> 5. เลขที่บัญชี <br /> 6.
            เลขบัตรประจำตัวประชาชน <br /> 7. เลขหลังบัตรประจำตัวประชาชน <br />
            8. วันเดือนปีเกิด
            <br /> 9. ที่อยู่ <br />
            10. รูป(ถ่ายคู่กับบัตรประชาชนและป้าย VerME, รูปบัตรประชาชนหน้าบัตร -
            หลังบัตร) พร้อมวันที่
          </div>
        ),
        status: false
      },
      {
        question: "2. เอา VerME ไปใช้ทำอะไร?",
        answer: (
          <div id="answer-div">
            ใช้ในการขายของบน Social media โดยการนำ VerME Card ที่ทาง VerME
            ออกให้ แนบขายของด้วยทุกครั้ง <br />
            <a id="link" href="/how-to">
              วิดีโอตัวอย่างการนำ VerME ไปใช้งาน
            </a>
          </div>
        ),
        status: false
      },
      {
        question: "3. วิธีตรวจสอบตัวตนผู้ขายมีกี่วิธี?",
        answer: "ในตอนนี้สามารถตรวจสอบได้จากชื่อและ VerME ID เท่านั้น",
        status: false
      },
      {
        question:
          "4. ทำไมต้องถ่ายรูปคู่กับบัตรประชาชน และป้าย VerME พร้อมวันที่",
        answer:
          "เราต้องการตรวจสอบผู้ขายให้กับผู้ซื้อตั้งแต่แรกว่าไม่ใช่บุคคลอันตราย และตามตัวได้เวลาเกิดปัญหาการโกงมาทีหลัง และในการที่ต้องถ่ายรูปคู่กับบัตรประชาชนเพื่อตรวจสอบว่ารูปบัตรกับบุคคลที่ถ่ายหน้าตรงกันไหม เพื่อป้องกันการสวมรอยจากผู้ไม่หวังดี และการที่ต้องถ่ายพร้อมกับป้าย VerME และวันที่ด้วยนั้น ก็เพื่อให้มั่นใจว่าผู้ขายไม่ได้ใช้รูปเก่ามาสมัคร",
        status: false
      },
      {
        question: "5. VerME ช่วยให้ผู้ขายปิดการขายได้เร็วขึ้นยังไง",
        answer:
          "ถ้าผู้ซื้อสามารถตรวจสอบผู้ขายได้ ในการซื้อขายครั้งนั้นก็จะทำให้ผู้ขายปิดการขายได้เร็วขึ้น ",
        status: false
      },
      {
        question: "6. ผู้ซื้อได้อะไรจาก VerME",
        answer: (
          <div id="answer-div">
            1. สามารถตรวจสอบผู้ขายได้ <br /> 2. แจ้งรายงานผู้ขายและทาง VerME
            จะส่งข้อมูลผู้ขายคนนั้นเพื่อให้ไปดำเนินคดีต่อไป
          </div>
        ),
        status: false
      },
      {
        question: "7. ผู้ขายได้อะไรจาก VerME",
        answer: (
          <div id="answer-div">
            1. ผู้ขายไม่ต้องแสดงข้อมูลส่วนตัวให้กับผู้ซื้อเพียงแค่ใช้ VerME card
            ก็ทำให้ผู้มั่นใจ <br /> 2. เลขบัญชีที่แสดงอยู่บน VerME
            มั่นใจได้ว่าเป็นของผู้ขายคนนั้นจริงๆไม่ใช่นำของคนอื่นมาแอบอ้าง{" "}
            <br /> 3. ข้อมูลส่วนบุคคลของผู้ขายมีการเข้ารหัส
            และทำตามข้อกำหนดของกฎหมายคุ้มครองข้อมูลส่วนบุคคล (PDPA)
          </div>
        ),
        status: false
      },
      {
        question: "8. ต้องมีอายุเท่าไหร่ถึงจะสมัครใช้บัตร VerME ได้",
        answer:
          "ต้องมีอายุ 20 ปีขึ้นไป ถึงจะสามารถสมัครใช้บัตร VerME ขายของได้ เพื่อให้เป็นไปตามกฎหมายที่กำหนด",
        status: false
      }
    ],
    [
      {
        question: "1. ทำไมรายงานผู้ขายต้องเก็บข้อมูลของผู้รายงานด้วย",
        answer:
          "เพื่อให้ VerME มั่นใจว่าผู้รายงานไม่ใช่ scammer (การหลอกลวงทางอินเทอร์เน็ตรูปแบบหนึ่ง) และสามารถติดต่อผู้รายงานกลับไปได้",
        status: false
      },
      {
        question:
          "2. หากมีการโกงเกิดขึ้นแต่คนที่โกงไม่มี VerME ID สามารถมาแจ้งรายงานผู้ขายกับทาง VerME ได้ไหม",
        answer: (
          <div id="answer-div">
            ในกรณีนี้ หากเกิดการโกงที่นอกเหนือจากการควบคุมของ VerME
            ทางเราจะไม่สามารถรับแจ้งรายงานผู้ขายให้ท่านได้
            แต่ท่านสามารถเข้าไปแจ้งได้ที่หน่วยงาน{" "}
            <a
              id="link"
              href="https://www.blacklistseller.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Blacklistseller
            </a>{" "}
            แทน
          </div>
        ),
        status: false
      }
    ],
    [
      {
        question: "1. ชำระเงินต่ออายุการใช้งานแล้วจะสามารถใช้ได้เลยหรือไม่",
        answer:
          "หากชำระเงินต่ออายุการใช้งานแล้ว ต้องรอให้ระบบตรวจสอบการชำระเงินให้เสร็จสิ้นก่อน หลังจากนั้นจะมี Email ส่งไปให้ทราบว่าเริ่มใช้บริการต่อได้",
        status: false
      },
      {
        question:
          "2. หากไม่ต่ออายุหลังจากหมดอายุการใช้งานฟรีไปแล้ว จะโดนเรียกเก็บค่าใช้จ่ายหรือไม่",
        answer:
          "หากหมดอายุการใช้งาน และไม่ทำการต่ออายุ ทางเราจะไม่มีการเรียกเก็บแบบอัตโนมัติใดๆ มีเพียงการส่ง Email เพื่อแจ้งเตือนบัตรหมดอายุให้ทราบเท่านั้น เพราะการต่ออายุการใช้งานเป็นสิทธิส่วนบุคคลของท่านเองตามกฎหมาย",
        status: false
      }
    ]
  ]);

  const handleClick = index => {
    var newData = [...collapsis];
    var checkData = newData.map((itemData, indexData) => {
      itemData.map((items, indexes) => {
        if (indexes !== index) {
          items.status = false;
        } else {
          items.status = !items.status;
        }
        return itemData;
      });
      return itemData;
    });
    setCollapsis(checkData);
  };

  return (
    <div id="root">
      {collapsis[title - 1].map((item, index) => (
        <Container id="container" key={index}>
          <QuestionDiv id="qt-div" onClick={() => handleClick(index)}>
            <QuestionText id="qt-txt">{item.question}</QuestionText>
            <ArrowButton id="arrow-btn">
              {item.status === false ? (
                <IconDropdown id="dropdown-ic" />
              ) : (
                <IconDropdown id="dropdown-ic" collapse="true" />
              )}
            </ArrowButton>
          </QuestionDiv>
          {item.status === false ? (
            <AnswerText id="ans-txt">{item.answer}</AnswerText>
          ) : (
            <AnswerText id="ans-txt" collapse="true">
              {item.answer}
            </AnswerText>
          )}
        </Container>
      ))}
    </div>
  );
}
