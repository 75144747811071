import styled from "styled-components";
import colors from "../common/Color";

const SubtitleText = styled.div`
  font-family: "SukhumvitSet-Bold";
  font-size: 1rem;
  letter-spacing: 0.03rem;
  line-height: 1.75rem;
  color: ${colors.primary};
  text-align: center;
  margin: 1rem 0 0.5rem;
`;

export default SubtitleText;
