import React, { useContext } from "react";
import styled from "styled-components";
import Button from "@material-ui/core/Button";
import colors from "../common/Color";
import Modal from "@material-ui/core/Modal";
import { ReactComponent as IconWarn } from "../assets/Icons/ic_big_report_outlined.svg";
import GrayText from "../common/GrayText";
import { RegisterContext } from "../Context/RegisterStore";

const Root = styled.div`
  @media (min-width: 961px) {
    margin: 1.75rem 2.44rem;
    height: 1.5vh;
  }
`;
const ModalBox = styled.div`
  width: 27.88rem;
  height: 18.31rem;
  background: ${colors.white};
  border-radius: 0.5rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-top: calc(50vh - 9.15rem);
  @media (max-width: 960px) {
    width: 22.31rem;
  }
`;
const IcWarn = styled(IconWarn)`
  fill: ${colors.statusWarning};
  font-size: 4.5rem;
`;
const ConfirmBtn = styled(Button)`
  && {
    width: 6.56rem;
    height: 2.75rem;
    border-radius: 0.25rem;
    background: ${colors.primary};
    font-family: "SukhumvitSet-SemiBold";
    font-size: 0.88rem;
    color: ${colors.white};
    letter-spacing: 0.08rem;
    line-height: 1rem;
    margin: 0 0.75rem;
    box-shadow: 0 0.063rem 0.063rem 0 rgba(0, 0, 0, 0.5);
    &:hover {
      background-color: ${colors.primaryDark};
    }
    @media (max-width: 960px) {
      width: 8.88rem;
    }
  }
`;
const FlexRow = styled.div`
  display: flex;
`;
const TextGroup = styled.div`
  margin: 1.75rem;
  text-align: center;
`;

export default function ErrorModal() {
  const { errorOpen } = useContext(RegisterContext);

  const handleClose = () => {
    errorOpen[1](false);
  };
  return (
    <Root id="root">
      <Modal
        id="modal"
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={errorOpen[0]}
        onClose={handleClose}
        disableEnforceFocus={true}
        disableBackdropClick={true}
      >
        <ModalBox id="modal-box">
          <IcWarn id="warn-ic" />
          <TextGroup id="txt-group">
            <GrayText id="txt-gray">กรุณาอัพโหลดรูปภาพไม่เกิน 25MB</GrayText>
          </TextGroup>
          <FlexRow id="flex-row">
            <ConfirmBtn id="confirm-btn" onClick={handleClose}>
              ยืนยัน
            </ConfirmBtn>
          </FlexRow>
        </ModalBox>
      </Modal>
    </Root>
  );
}
