import React from "react";
import { hydrate, render } from "react-dom";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import SinglePageVerXXXXXX from "./SinglePages/SinglePageVer-XXXXXX";
import ErrorNotFound from "./common/404notfound";
import IdentityCheckPage from "./IdentityCheck/CheckPage";
import IdentitySinglePage from "./SinglePages/IdentitySinglePage";
import Prepare from "./Register/PreparePage";
import Register from "./Register/Register";
import SetPassword from "./Register/SetPassword";
import Report from "./Report/Report";
import { StoreContextProvider } from "./Context/Store";
import { RegisterContextProvider } from "./Context/RegisterStore";
import { LoginContextProvider } from "./Context/LoginStore";
import Homepage from "./Homepage/Homepage";
import Promotion from "./Homepage/PromotionPage";
import PolicyPage from "./Policy/Policy";
import Faq from "./FAQ/FAQ";
import Pricing from "./PricingPage/Pricing";
import Payment from "./Payment/PaymentPage";
import HowTo from "./HowTo/HowTo";
import ConfirmEmail from "./Register/ConfirmEmail";
import Profile from "./Profile/ProfilePage";
import SignIn from "./SignIn/SignInPage";
import ForgotPw from "./SignIn/ForgotPasswordPage";
import CheckMail from "./SignIn/PleaseCheckEmailPage";
import ChangePwSuccess from "./SignIn/ChangePwSuccess";
import ChangePassword from "./SignIn/ChangePassword";
import { ProvideAuth } from "./use-auth";
import PrivateRoute from "./PrivateRoute";

// const APP = (
//   <BrowserRouter id="browser-router">
//     <div id="div-path">
//       <ProvideAuth id="provide-auth">
//         <Switch id="switch">
//           <Route
//             id="route"
//             path="/identity/vm-xxxxxx"
//             component={SinglePageVerXXXXXX}
//           />
//           <LoginContextProvider id="login-pvd">
//             <Route id="route" exact path="/" component={Homepage} />
//             <Route id="route" path="/promotion" component={Promotion} />
//             <Route
//               id="route"
//               path="/identity/:id"
//               component={IdentitySinglePage}
//             />
//             <Route
//               id="route"
//               path="/identity-check"
//               component={IdentityCheckPage}
//             />
//             <Route id="route" path="/404notfound" component={ErrorNotFound} />
//             <Route
//               id="route"
//               path="/prepare-before-register"
//               component={Prepare}
//             />
//             <Route id="route" path="/policy" component={PolicyPage} />
//             <Route id="route" path="/faq" component={Faq} />
//             <Route id="route" path="/pricing" component={Pricing} />
//             <Route id="route" path="/how-to" component={HowTo} />
//             <PrivateRoute
//               id="private-route"
//               path="/profile"
//               component={Profile}
//             />
//             <Route id="route" path="/forgot-password" component={ForgotPw} />
//             <Route
//               id="route"
//               path="/recovery/code/:id"
//               component={ChangePassword}
//             />
//             <Route path="/check-email" component={CheckMail} />
//             <Route
//               id="route"
//               path="/change-password-success"
//               component={ChangePwSuccess}
//             />
//             <StoreContextProvider id="store-pvd">
//               <RegisterContextProvider id="register-pvd">
//                 <Route id="route" path="/report-form" component={Report} />
//               </RegisterContextProvider>
//               <MuiPickersUtilsProvider id="mui-picker-pvd" utils={DateFnsUtils}>
//                 <Route id="route" path="/register" component={Register} />
//               </MuiPickersUtilsProvider>
//               <Route id="route" path="/payment-page" component={Payment} />
//               <Route
//                 id="route"
//                 path="/confirm-email/:id"
//                 component={ConfirmEmail}
//               />
//               <Route
//                 id="route"
//                 path="/setpassword/:id"
//                 component={SetPassword}
//               />
//               <Route id="route" path="/sign-in" component={SignIn} />
//             </StoreContextProvider>
//           </LoginContextProvider>
//           <Route id="route" component={ErrorNotFound} />
//         </Switch>
//       </ProvideAuth>
//     </div>
//   </BrowserRouter>
// );
// const rootElement = document.getElementById("root");
// if (rootElement.hasChildNodes()) {
//   hydrate(APP, rootElement);
// } else {
//   render(APP, rootElement);
// }
const renderMethod = module.hot ? render : hydrate;

renderMethod(
  <BrowserRouter id="browser-router">
    <div id="div-path">
      <ProvideAuth id="provide-auth">
        <Switch id="switch">
          <Route
            id="route"
            path="/identity/vm-xxxxxx"
            component={SinglePageVerXXXXXX}
          />
          <LoginContextProvider>
            <Route id="route" exact path="/" component={Homepage} />
            <Route id="route" path="/promotion" component={Promotion} />
            <Route
              id="route"
              path="/identity/:id"
              component={IdentitySinglePage}
            />
            <Route
              id="route"
              path="/identity-check"
              component={IdentityCheckPage}
            />
            <Route id="route" path="/404notfound" component={ErrorNotFound} />
            <Route
              id="route"
              path="/prepare-before-register"
              component={Prepare}
            />
            <Route id="route" path="/policy" component={PolicyPage} />
            <Route id="route" path="/faq" component={Faq} />
            <Route id="route" path="/pricing" component={Pricing} />
            <Route id="route" path="/how-to" component={HowTo} />
            <PrivateRoute
              id="private-route"
              path="/profile"
              component={Profile}
            />
            <Route id="route" path="/forgot-password" component={ForgotPw} />
            <Route
              id="route"
              path="/recovery/code/:id"
              component={ChangePassword}
            />
            <Route path="/check-email" component={CheckMail} />
            <Route
              id="route"
              path="/change-password-success"
              component={ChangePwSuccess}
            />
            <StoreContextProvider id="store-pvd">
              <RegisterContextProvider id="register-pvd">
                <Route id="route" path="/report-form" component={Report} />
              </RegisterContextProvider>
              <MuiPickersUtilsProvider id="mui-picker-pvd" utils={DateFnsUtils}>
                <Route id="route" path="/register" component={Register} />
              </MuiPickersUtilsProvider>
              <Route id="route" path="/payment-page" component={Payment} />
              <Route
                id="route"
                path="/confirm-email/:id"
                component={ConfirmEmail}
              />
              <Route
                id="route"
                path="/setpassword/:id"
                component={SetPassword}
              />
              <Route id="route" path="/sign-in" component={SignIn} />
            </StoreContextProvider>
          </LoginContextProvider>
          <Route id="route" component={ErrorNotFound} />
        </Switch>
      </ProvideAuth>
    </div>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
