import styled from "styled-components";
import colors from "./Color";

const ErrorMessage = styled.div`
  font-size: 0.75rem;
  letter-spacing: 0.03rem;
  line-height: ${props => (props.primary ? "1rem" : "0rem")};
  margin: ${props => (props.otp ? "0.75rem 1.5rem" : "0.5rem 1rem")};
  color: ${colors.statusError};
`;

export default ErrorMessage;
