import React from "react";
import { withStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import { ReactComponent as CheckIcon } from "../assets/Icons/Icons Black/ic_check_filled.svg";
import { ReactComponent as ExpiredIcon } from "../assets/Icons/Icons Black/ic_identity_expired_round.svg";
import { ReactComponent as CheckingIcon } from "../assets/Icons/Icons Black/ic_identity_checking_round.svg";
import { ReactComponent as ReportIcon } from "../assets/Icons/Icons Black/ic_report.svg";
import UserCard from "../components/UserCard";
import Button from "@material-ui/core/Button";
import colors from "../common/Color";


const styles = theme => ({
  box: {
    margin: "auto",
    width: "18.75rem",
    height: "19.75rem",
    background: "#ffffff",
    borderRadius: "0.5rem",
    border: "0.06rem solid rgba(0,0,0,0.20)",
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  },
  fullName: {
    fontFamily: "Roboto",
    fontWeight: "Bold",
    color: "#000000",
    opacity: "0.6",
    letterSpacing: "0.03rem",
    lineHeight: "1.75rem",
    fontSize: "1rem",
    textAlign: "left"
  },
  statusicon: {
    width: "1rem",
    height: "1rem",
    verticalAlign: "middle"
  },
  statusText: {
    fontFamily: "SukhumvitSet-Bold",
    color: "#000000",
    opacity: "0.6",
    letterSpacing: "0.02rem",
    lineHeight: "15.71px",
    fontSize: "0.88rem",
    marginLeft: "0.25rem"
  },
  btn: {
    width: "16.81rem",
    height: "2.25rem",
    border: "0.06rem solid rgba(0,0,0,0.12)",
    borderRadius: "0.25rem",
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  },
  btnText: {
    color: colors.primary,
    fontFamily: "SukhumvitSet-Semibold",
    letterSpacing: "0.08rem",
    lineHeight: "1rem",
    fontSize: "0.88rem"
  },
  status: {
    display: "flex",
    alignItems: "flex-end"
  },
  container: {
    display: "flex",
    flexDirection: "column",
    padding: "1.5rem 1rem"
  },
  cardImage: {
    margin: "1rem 0 1rem 0",
    position: "relative"
  }
});

const ExpIcon = styled(ExpiredIcon)`
  fill: ${colors.statusError};
  font-size: 1rem;
`;
const CheckingIc = styled(CheckingIcon)`
  fill: ${colors.statusProgress};
  font-size: 1rem;
`;
const IcReport = styled(ReportIcon)`
  font-size: ${props => props.big ? "4rem" : "1rem"};
  fill: ${colors.statusError};
`;
const OverlayTerminate = styled.div`
  background-color: rgba(0,0,0,0.6);
  position: absolute;
  width: 16.8rem;
  height: 9.6rem;
  border-radius: 0.8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  font-family: "SukhumvitSet-Bold";
  font-size: 18.2px;
  line-height: 1.41;
  letter-spacing: 0.2px;
  @media (max-width: 959px){
    width: 15rem;
    height: 8.6rem;
    border-radius: 0.8rem;
  }
`;

function CheckStatus(props) {
  const { classes, data, isStatusCheck } = props;

  return (
    <div id="root">
      <div id="box" className={classes.box}>
        <div id="container" className={classes.container}>
          <div id="fullname" className={classes.fullName}>
            {data.data.name}
          </div>
          <div id="status" className={classes.status}>
            {
              isStatusCheck === "verified"
                ? <CheckIcon />
                : isStatusCheck === "expired"
                  ? <ExpIcon />
                  : isStatusCheck === "terminated"
                    ? <IcReport/>
                    : isStatusCheck === "checking"
                      ? <CheckingIc /> : ""
            }
            <div id="status-txt" className={classes.statusText}>
              {isStatusCheck === "verified"
                ? "ยืนยันตัวตนแล้ว"
                : isStatusCheck === "expired"
                  ? "หมดอายุ"
                  : isStatusCheck === "terminated"
                    ? "ระงับการใช้งาน"
                    : isStatusCheck === "checking"
                      ? "กำลังตรวจสอบข้อมูล..."
                      : ""}
            </div>
          </div>
          <div id="card-img" className={classes.cardImage}>
            {isStatusCheck === "verified" ? (
              <UserCard
                id="usercard"
                data={data}
                page="identity check"
              ></UserCard>
            ) : isStatusCheck === "expired" ? (
              <UserCard
                id="usercard"
                data={data}
                page="identity check"
              ></UserCard>
            ) : isStatusCheck === "checking" ? (
              <UserCard
                id="usercard"
                data={data}
                page="identity check"
              ></UserCard>
            ) : isStatusCheck === "terminated" ? (
              <>
              <OverlayTerminate><IcReport big="true"/>ระงับการใช้งาน</OverlayTerminate>
              <UserCard
                id="usercard-ter"
                data={data}
                page="identity check"
              ></UserCard>
              </>
            ) : (
                      ""
                    )}
          </div>
          <Button
            id="btn"
            className={classes.btn}
            href={"/identity/" + data.data.id + "?ga-category=page-identity"}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div id="btn-txt" className={classes.btnText}>
              ดูรายละเอียด
            </div>
          </Button>
        </div>
      </div>
    </div>
  );
}

export default withStyles(styles)(CheckStatus);
