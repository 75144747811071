import React, { useState, useCallback, useContext, useEffect } from "react";
import SingleHead from "./Header";
import NavBar from "../common/NavBar";
import NavMobile from "../common/NavMobile";
import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import chatIcon from "../assets/Icons/Icons Black/ic_chat.svg";
import primaryColor from "../assets/Colors/Icon/Primary.svg";
import Footer from "../common/Footer";
import UserCard from "../components/UserCard";
// import DownloadCardBtn from "../common/components/DownloadCardBtn";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { ReactComponent as CheckIcon } from "../assets/Icons/Icons Black/ic_check_filled.svg";
import { ReactComponent as ExpiredIcon } from "../assets/Icons/Icons Black/ic_identity_expired_round.svg";
import { ReactComponent as CheckingIcon } from "../assets/Icons/Icons Black/ic_identity_checking_round.svg";
import { ReactComponent as ReportIcon } from "../assets/Icons/Icons Black/ic_report.svg";
import { ReactComponent as IconCopy } from "../assets/Icons/Icons Black/ic_copy_2.svg";
import { Link } from "react-router-dom";
import Linker from "@material-ui/core/Link";
import moment from "moment";
import policy from "../assets/Term_of_services.pdf";
import Skeleton from "@material-ui/lab/Skeleton";
import colors from "../common/Color";
import "firebase/auth";
import {
  getAllIdentity,
  getAllIdentityWithFb,
  getAllIdentityWithQr,
  getAllIdentityCheck,
  getAllIdentityLink
  // getImagePreview
} from "../actions/action";
import MetaDecorator from "../MetaDecorator/MetaDecorator";
import { useClipboard } from "use-clipboard-copy";
import { LoginContext } from "../Context/LoginStore";
import Snackbar from "@material-ui/core/Snackbar";
import Cookies from "universal-cookie";
import { getUserProfile } from "../actions/action";

const cookies = new Cookies();

const styles = theme => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    height: "100vh",
    [theme.breakpoints.up("md")]: {
      height: "100vh"
    }
  },
  container: {
    width: "100%",
    [[theme.breakpoints.down(1025)]]: {
      width: "90%",
      margin: "auto"
    }
  },
  containerAfter: {
    width: "60%",
    display: "flex",
    justifyContent: "center",
    margin: "auto",
    [[theme.breakpoints.down(1025)]]: {
      width: "90%",
      margin: "auto",
      flexDirection: "column"
    }
  },
  leftContent: {
    width: "30rem",
    [[theme.breakpoints.down(1025)]]: {
      width: "100%",
      margin: "auto"
    }
  },
  rightContent: {
    marginLeft: "3.5rem",
    display: "flex",
    alignItems: "center",
    height: "18rem",
    [[theme.breakpoints.down(1025)]]: {
      width: "100%",
      marginLeft: "0",
      justifyContent: "center"
    }
  },
  usercard: {
    position: "relative",
    height: "17.9rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    [[theme.breakpoints.down(1025)]]: {
      height: "12.8rem"
    }
  },
  usercardAfter: {
    position: "relative",
    height: "18rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "27.88rem",
    [[theme.breakpoints.down(1025)]]: {
      height: "12.8rem",
      width: "100%"
    }
  },
  circleColor: {
    width: "3.5rem",
    height: "4rem",
    clipPath: "circle(1.75rem at center)",
    WebkitClipPath: "circle(1.75rem at center)",
    position: "relative",
    [[theme.breakpoints.down(1025)]]: {
      clipPath: "circle(1.315rem at center)",
      WebkitClipPath: "circle(1.315rem at center)"
    }
  },
  chaticon: {
    position: "absolute",
    alignSelf: "center",
    width: "2.1rem",
    [[theme.breakpoints.down(1025)]]: {
      width: "1.58rem"
    }
  },
  chatcircle: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    float: "right",
    filter: "drop-shadow(0 0.25rem 0.5rem rgba(0,0,0,0.20))",
    bottom: 0,
    marginRight: "2rem",
    [[theme.breakpoints.down(1025)]]: {
      marginRight: "0.75rem"
    }
  },
  svgcard: {
    enableBackground: "new 0 0 501 286",
    borderRadius: "1.52rem",
    [[theme.breakpoints.down(1025)]]: {
      borderRadius: "1.09rem"
    }
  },
  rootCard: {
    textAlign: "center",
    marginTop: "3rem",
    fontFamily: "Sarabun"
    // [theme.breakpoints.down(1025)]: {
    //   marginBottom: "4.25rem"
    // }
  },
  contentAfter: {
    textAlign: "left",
    margin: 0,
    fontFamily: "Sarabun",
    [[theme.breakpoints.down(1025)]]: {
      textAlign: "center",
      marginTop: "3rem"
    }
  },
  gotoBtn: {
    width: "15.19rem",
    height: "2.36rem",
    color: colors.primary,
    border: "0.06rem solid #3B54D4",
    fontFamily: "SukhumvitSet-SemiBold",
    [[theme.breakpoints.down(601)]]: {
      width: "100%",
      height: "2.75rem"
    }
  },
  fbBtn: {
    width: "15.19rem",
    height: "2.36rem",
    backgroundColor: colors.primary,
    border: "none",
    boxShadow: "0 0.06rem 0.06rem 0 rgba(0,0,0,0.50)",
    color: colors.white,
    marginRight: "1rem",
    fontFamily: "SukhumvitSet-SemiBold",
    [[theme.breakpoints.down(601)]]: {
      width: "100%",
      marginRight: 0,
      marginBottom: "0.88rem",
      height: "2.75rem"
    },
    "&:hover": {
      background: colors.primaryDark
    }
  },
  btnDiv: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    [[theme.breakpoints.down(601)]]: {
      flexDirection: "column"
    }
  },
  justifyLeftDiv: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "left",
    [[theme.breakpoints.down(1025)]]: {
      justifyContent: "center"
    },
    [[theme.breakpoints.down(601)]]: {
      flexDirection: "column"
    }
  },
  expdate: {
    display: "flex",
    justifyContent: "center"
  },
  expdateAfter: {
    display: "flex",
    [[theme.breakpoints.down(1025)]]: {
      justifyContent: "center"
    }
  },
  ver: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "1rem",
    opacity: "0.87",
    fontFamily: "SukhumvitSet-Bold",
    fontSize: "2.13rem",
    color: "#000000",
    letterSpacing: "0.25px",
    textAlign: "center",
    height: "auto"
  },
  headTxtAfter: {
    display: "flex",
    alignItems: "center",
    marginBottom: "1rem",
    opacity: "0.87",
    fontFamily: "SukhumvitSet-Bold",
    fontSize: "2.13rem",
    color: "#000000",
    letterSpacing: "0.25px",
    textAlign: "center",
    height: "auto",
    [[theme.breakpoints.down(1025)]]: {
      justifyContent: "center"
    }
  },
  checkicon: {
    width: "2rem",
    height: "2rem",
    padding: "0 0.5rem",
    display: "flex"
  },
  textPolicy: {
    fontFamily: "SukhumvitSet-Text",
    fontSize: "1rem",
    letterSpacing: "0.03rem",
    textAlign: "center",
    lineHeight: "1.75rem"
  },
  skeletonContent: {
    width: "31.38rem",
    height: "17.9rem",
    borderRadius: "1.52rem",
    [theme.breakpoints.down(1025)]: {
      height: "12.8rem",
      width: "22rem",
      marginTop: "4.5rem"
    }
  },
  skeletonCheckIcon: {
    width: "2rem",
    height: "2rem",
    marginLeft: "0.5rem"
  },
  skeletonText: {
    width: "18rem",
    height: "20px"
  },
  skeletonText2: {
    width: "5.14rem",
    height: "20px"
  },
  skeletonFbProfile: {
    width: "31.38rem",
    heigth: "4.5rem"
  },
  subTextFrame: {
    height: "28px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  subTextExpires: {
    width: "9.1rem",
    height: "20px"
  },
  reNewButton: {
    backgroundColor: colors.primary,
    fontFamily: "SukhumvitSet-SemiBold",
    fontSize: "0.88rem",
    color: "#FFFFFF",
    letterSpacing: "0.08rem",
    textAlign: "center",
    lineHeight: "1rem",
    width: "18.31rem",
    height: "2.25rem",
    "&:hover": {
      backgroundColor: colors.primaryDark
    }
  },
  skeletonButtonFrame: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  skeletonButton: {
    lineHeight: "1rem",
    width: "18.31rem",
    height: "2.25rem"
  },
  skeletonUrlDiv: {
    width: "21rem",
    height: "3rem",
    margin: "1.5rem 0"
  },
  urlCopyDiv: {
    width: "20.56rem",
    height: "3rem",
    border: "1px solid rgba(0,0,0,0.12)",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "1.5rem 0",
    [[theme.breakpoints.down(1025)]]: {
      width: "22.5rem",
      margin: "1.5rem auto"
    }
  },
  urlText: {
    fontFamily: "SukhumvitSet-Medium",
    fontSize: "1rem",
    color: "rgba(0,0,0,0.87)",
    letterSpacing: "0.03rem",
    lineHeight: "1.5rem",
    marginLeft: "1rem"
  }
});

const StyledAccordion = styled(Accordion)`
  && {
    margin-top: 1.95rem;
    @media (max-width: 1024px) {
      margin-top: 1rem;
    }
  }
  &.MuiAccordion-rounded:first-child {
    border-radius: 0.5rem;
  }
  &.MuiAccordion-root.Mui-expanded:first-child {
    margin-top: 1.95rem;
    @media (max-width: 1024px) {
      margin-top: 1rem;
    }
  }
  &.MuiAccordion-root {
    width: 31.38rem;
    min-height: 4.5rem;
    @media (max-width: 1024px) {
      width: 360px;
    }
    @media (max-width: 600px) {
      width: 100%;
    }
  }
  &.MuiPaper-elevation1 {
    box-shadow: none;
    border: 1px solid #ccceda;
  }
`;
const StyledAccSummary = styled(AccordionSummary)`
  &.MuiAccordionSummary-root {
    min-height: 4.5rem;
  }
`;
const StyledAccDetails = styled(AccordionDetails)`
  &.MuiAccordionDetails-root {
    padding: 0 1rem 1rem;
  }
`;
const TitleTxt = styled.span`
  font-family: "SukhumvitSet-Medium";
  font-size: 16px;
  color: rgba(0, 0, 0, 0.6);
  letter-spacing: 0.5px;
  text-align: left;
  line-height: 24px;
`;
const FbDiv = styled.div`
  display: flex;
`;
const FbImg = styled.img`
  width: 4rem;
  height: 4rem;
  border-radius: 0.25rem;
  background: ${colors.surfaceGray};
  margin-right: 1rem;
`;
const FbName = styled.div`
  font-family: "SukhumvitSet-Bold";
  font-size: 16px;
  color: rgba(0, 0, 0, 0.6);
  letter-spacing: 0.4px;
  line-height: 24px;
  margin-bottom: 0.5rem;
`;
const StyledLinker = styled(Linker)`
  && {
    font-family: "SukhumvitSet-Medium";
    font-size: 16px;
    color: ${colors.primary};
    letter-spacing: 0.5px;
    text-align: left;
    line-height: 24px;
  }
`;
const ExpIcon = styled(ExpiredIcon)`
  fill: ${colors.statusError};
  font-size: 2rem;
`;
const CheckingIc = styled(CheckingIcon)`
  fill: ${colors.statusProgress};
  font-size: 2rem;
`;
const IcCopy = styled(IconCopy)`
  font-size: 1.5rem;
  fill: #757575;
  margin-right: 0.25rem;
`;
const IcReport = styled(ReportIcon)`
  font-size: ${props => props.big ? "120px" : "2rem"};
  fill: ${colors.statusError};
  @media (max-width: 1024px){
    font-size: ${props => props.big ? "86px" : "2rem"};
  }
`;
const OverlayTerminate = styled.div`
  background-color: rgba(0,0,0,0.6);
  position: absolute;
  width: 31.4rem;
  height: 18rem;
  border-radius: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  font-family: "SukhumvitSet-Bold";
  font-size: 34px;
  line-height: 1.41;
  letter-spacing: 0.4px;
  @media (max-width: 1024px){
    top: 2.2rem;
    width: 22.5rem;
    height: 13rem;
    border-radius: 1.25rem;
    font-size: 24.4px;
    letter-spacing: 0.29px;
  }
`;
const UserCardTer = styled.div`
  position: relative;
  overflow: hidden;
`;
function IdentitySinglePage(props) {
  const { userLogin } = useContext(LoginContext);
  const { classes } = props;
  const [data, setData] = useState({ data: { id: "", name: "", expiry: "" } });
  const [fbData, setFbData] = useState({});
  const [status, setStatus] = useState("");
  const [checkExp, setCheckExp] = useState("");
  // const [imagePreview, setImagePreview] = useState("");
  const paramsId = props.match.params.id.toLowerCase();

  var searchQr = window.location.search;
  const putData = searchQr.slice(45, searchQr.length);

  const clipboard = useClipboard({
    onSuccess() {
      console.log("Text was copied successfully!");
    },
    onError() {
      console.log("Failed to copy text!");
    }
  });
  // eslint-disable-next-line
  const [open, setOpen] = useState(false);
  const copyClipboard = useCallback(
    id => {
      const url =
        (process.env.NODE_ENV === "production"
          ? "https://verme.me/identity/"
          : "https://test.verme.me/identity/") + id;
      clipboard.copy(url);
    },
    [clipboard]
  );
  const handleClick = id => {
    setOpen(true);
    copyClipboard(id);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    const getUserData = async () => {
      try {
        const token = cookies.get("token");
        const sessionId = cookies.get("sessionId");
        const res = await getUserProfile(token, { sessionId: sessionId });
        if (res.status === 200) {
          userLogin[1](res.data);
        }
      } catch (err) {
        console.log(err);
        if (err.response.status === 401) {
          cookies.remove("token", { path: "/" });
          cookies.remove("sessionId", { path: "/" });
          userLogin[1]({});
          window.location.href = "/";
        }
      }
    };
    if (cookies.get("token")) {
      getUserData();
    }
    // eslint-disable-next-line
  }, []);

  const manageData = (res) => {
    if (res.status === 200) {
      const dataFetch = res.data;
      const idFetch = dataFetch.vm_id.toUpperCase();
      const checkExp = new Date(Number(dataFetch.expired) * 1000);
      const expiry = moment.utc(checkExp).format("MM/YY");
      const timeCurrent = moment.utc(new Date() * 1000).unix();
      const timeExp = moment.utc(checkExp * 1000).unix();
      // const futureMonth = moment(timeExp).add(1, "M");
      // const timeFutureMonth = moment.utc(futureMonth * 1000).unix();
      setData({
        data: {
          id: idFetch,
          expiry: expiry,
          name: dataFetch.name.data.en
        }
      });
      setFbData(res.data.socialAccount.facebookAccount);
      if (dataFetch.status === "terminated") {
        setStatus("terminated");
      } else if (dataFetch.status === "expired") {
        setStatus("expired");
      } else if (dataFetch.status === "verified") {
        setStatus("verified");
        checkExpMonth(timeCurrent, timeExp);
      } else if (dataFetch.status === "checking") {
        setStatus("checking");
      }
    }
  }

  const fetchData = async () => {
    try {
      const res = await getAllIdentity(paramsId);
      manageData(res);
    } catch (err) {
      console.log(err);
      if (err.response.status === 404) {
        props.history.push("/404notfound");
        return;
      }
    }
  };

  const fetchDataClick = async () => {
    try {
      const res = await getAllIdentityWithFb(paramsId);
      manageData(res);
    } catch (err) {
      console.log(err);
      if (err.response.status === 404) {
        props.history.push("/404notfound");
        return;
      }
    }
  };

  const fetchDataQr = async () => {
    try {
      const res = await getAllIdentityWithQr(paramsId);
      manageData(res);
    } catch (err) {
      console.log(err);
      if (err.response.status === 404) {
        props.history.push("/404notfound");
        return;
      }
    }
  };

  const fetchDataCheck = async () => {
    try {
      const res = await getAllIdentityCheck(paramsId);
      manageData(res);
    } catch (err) {
      console.log(err);
      if (err.response.status === 404) {
        props.history.push("/404notfound");
        return;
      }
    }
  };

  const fetchDataLink = async () => {
    try {
      const res = await getAllIdentityLink(paramsId);
      manageData(res);
    } catch (err) {
      console.log(err);
      if (err.response.status === 404) {
        props.history.push("/404notfound");
        return;
      }
    }
  };

  useEffect(() => {
    const keywordFb = searchQr.search("fbclid=");
    const keywordQr = searchQr.search("qrcode");
    const keywordLink = searchQr.search("verme-card.link");
    const keywordCategory = searchQr.search("ga-category");
    const keywordAction = searchQr.search("ga-action");
    if (keywordFb !== -1) {
      fetchDataClick();
    }
    else if (keywordQr !== -1) {
      fetchDataQr();
    }
    else if (keywordLink !== -1) {
      fetchDataLink();
    }
    else if (keywordAction === -1 && keywordCategory !== -1) {
      fetchDataCheck();
    } else {
      fetchData();
    }
    // const fetchImagePreview = async () => {
    //   try {
    //     const res = await getImagePreview(paramsId);
    //     if (res.status === 200) {
    //       setImagePreview(res.data.image);
    //       console.log(res.data);
    //     }
    //   } catch (err) {
    //     console.log(err);
    //   }
    // };

    // fetchImagePreview();
    // eslint-disable-next-line
  }, [paramsId, props.history, putData, searchQr]);

  useEffect(() => {
    console.log(userLogin)
  })

  const headText = () => {
    if (status === "verified") {
      return "ยืนยันตัวตนแล้ว";
    } else if (status === "terminated") {
      return "ผู้ใช้ถูกระงับการใช้งาน";
    } else if (status === "expired") {
      return "หมดอายุ";
    } else if (status === "checking") {
      return "กำลังตรวจสอบข้อมูล...";
    }
  };

  const subText = () => {
    if (status === "verified") {
      return "ตัวตนนี้ได้รับการยืนยันตัวตนสำเร็จจาก VerME";
    } else if (status === "terminated") {
      return "ผู้ใช้นี้มีประวัติประวัติการโกง และอยู่ในรายชื่อต้องห้าม (Blacklist)";
    } else if (status === "expired") {
      return "ตัวตนนี้ได้หมดอายุจาก VerME แล้ว";
    } else if (status === "checking") {
      return "ทีมงานกำลังดำเนินการตรวจสอบตัวตนนี้";
    }
  };

  const subText2 = () => {
    if (status === "verified") {
      return "วันหมดอายุ:";
    } else if (status === "terminated") {
      return "โปรดใช้ความระมัดระวังในการซื้อขายกับผู้ใช้นี้";
    } else if (status === "expired") {
      return "เนื่องจากผู้ใช้ไม่ได้ทำการต่ออายุบัตรภายในเวลาที่กำหนด";
    } else if (status === "checking") {
      return "เนื่องจากมีการแก้ไขข้อมูล หรือตรวจสอบหลักฐานเพิ่มเติม";
    }
  };

  const checkExpMonth = (current, exp) => {
    const currentMoment = moment(current);
    const expMoment = moment(exp);
    var duration = moment.duration(expMoment.diff(currentMoment));
    var years = duration.years();
    var months = duration.months();
    var days = duration.days();
    setCheckExp("เหลือ " + years + " ปี " + months + " เดือน " + days + " วัน");
  };

  return (
    <div id="root" className={classes.root}>
      {console.log(userLogin)}
      <MetaDecorator
        description="มั่นใจทุกการขาย สบายใจทุกการซื้อ"
        title={window.location.hostname + window.location.pathname}
      // imageUrl={imagePreview}
      // imageAlt={blog.metaImageAlt}
      />
      {userLogin[0].userProfile ? (
        <React.Fragment>
          <NavBar id="navbar" props="signin" />
          <NavMobile id="nav-mb" />
          <div className={classes.containerAfter}>
            <div className={classes.leftContent}>
              <div className={classes.usercardAfter}>
                {!status ? (
                  <div id="skeleton-div">
                    <Skeleton
                      id="skeleton"
                      variant="rect"
                      className={classes.skeletonContent}
                    />
                  </div>
                ) : status === "terminated" ? (
                  <>
                    <OverlayTerminate><IcReport big="true"/>ระงับการใช้งาน</OverlayTerminate>
                    <UserCardTer id="usercard-terminated" data={data} />
                  </>
                ) : (
                      <UserCard id="usercard" data={data} />
                    )}
              </div>
              {/* <DownloadCardBtn /> */}
            </div>
            <div className={classes.rightContent}>
              <div id="root-card" className={classes.contentAfter}>
                <div id="verme-div" className={classes.headTxtAfter}>
                  <span id="span-skeleton">
                    {status ? (
                      headText()
                    ) : (
                        <Skeleton id="skeleton" width={217} height={30} />
                      )}
                  </span>
                  {status ? (
                    <div className={classes.checkicon}>
                      {status === "verified" ? (
                        <CheckIcon />
                      ) : status === "expired" ? (
                        <ExpIcon />
                      ) : status === "checking" ? (
                        <CheckingIc />
                      ) : status === "terminated" ? (
                        <IcReport />
                      ) : (
                                ""
                              )}
                    </div>
                  ) : (
                      <Skeleton
                        id="skeleton"
                        variant="circle"
                        className={classes.skeletonCheckIcon}
                      />
                    )}
                </div>
                <Box
                  id="box"
                  letterSpacing={"0.03rem"}
                  lineHeight={"1.75rem"}
                  fontSize={"1rem"}
                  color="text.secondary"
                  fontWeight="fontWeightRegular"
                >
                  {status ? (
                    subText()
                  ) : (
                      <div id="subtxt-frame" className={classes.subTextFrame}>
                        <Skeleton
                          id="skeleton-txt"
                          className={classes.skeletonText}
                        />
                      </div>
                    )}
                </Box>
                <div id="expdate-div" className={classes.expdateAfter}>
                  <Box
                    id="box"
                    style={{ marginRight: "0.5rem" }}
                    letterSpacing={"0.03rem"}
                    lineHeight={"1.75rem"}
                    fontSize={"1rem"}
                    color="text.secondary"
                    fontWeight="fontWeightRegular"
                  >
                    {status ? (
                      subText2()
                    ) : (
                        <Skeleton
                          id="skeleton-txt-2"
                          className={classes.skeletonText2}
                        />
                      )}
                  </Box>
                  <Box
                    id="box"
                    letterSpacing={"0.03rem"}
                    lineHeight={"1.75rem"}
                    fontSize={"1rem"}
                    color="#3B54D4"
                    fontWeight="fontWeightRegular"
                  >
                    {!status ? (
                      <Skeleton
                        id="skeleton-subtxt-exp"
                        className={classes.subTextExpires}
                      />
                    ) : status === "verified" ? (
                      checkExp
                    ) : (
                          " "
                        )}
                  </Box>
                </div>
                {status ? (
                  <div className={classes.urlCopyDiv}>
                    <span className={classes.urlText}>
                      {(process.env.NODE_ENV === "production"
                        ? "verme.me"
                        : "test.verme.me") + window.location.pathname}
                    </span>
                    <Button
                      style={{ minWidth: 0 }}
                      onClick={() =>
                        handleClick(
                          userLogin[0].userProfile
                            ? userLogin[0].userProfile.display.vm_id.toUpperCase()
                            : ""
                        )
                      }
                    >
                      <IcCopy id="copy-ic" />
                    </Button>
                  </div>
                ) : (
                    <div id="skeleton-div">
                      <Skeleton
                        id="skeleton"
                        variant="rect"
                        className={classes.skeletonUrlDiv}
                      />
                    </div>
                  )}

                <Snackbar
                  id="snack-bar"
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "center"
                  }}
                  open={open}
                  autoHideDuration={6000}
                  onClose={handleClose}
                  message="คัดลอกไปยังคลิปบอร์ดแล้ว"
                  action={
                    <React.Fragment id="fragment">
                      <IconButton
                        id="ic-btn"
                        size="small"
                        aria-label="close"
                        color="inherit"
                        onClick={handleClose}
                      >
                        <CloseIcon id="close-ic" fontSize="small" />
                      </IconButton>
                    </React.Fragment>
                  }
                />
                {!status ? (
                  <div
                    id="skeleton-btn-div"
                    className={classes.skeletonButtonFrame}
                  >
                    <Skeleton
                      id="skeleton-btn"
                      variant="rect"
                      className={classes.skeletonButton}
                    />
                  </div>
                ) : (
                    <div className={classes.justifyLeftDiv}>
                      <Link
                        id="link-link"
                        to="/"
                        style={{ textDecoration: "none" }}
                      >
                        <Button
                          id="goto-btn"
                          variant="outlined"
                          className={classes.gotoBtn}
                        >
                          <Box
                            id="box"
                            letterSpacing={"0.08rem"}
                            lineHeight={"1rem"}
                            fontSize={"0.88rem"}
                            fontWeight="fontWeightMedium"
                          >
                            กลับไปหน้าแรก
                        </Box>
                        </Button>
                      </Link>
                    </div>
                  )}
              </div>
            </div>
          </div>
        </React.Fragment>
      ) : (
          <React.Fragment>
            <SingleHead id="header" />
            <div id="container" className={classes.container}>
              <div id="usercard" className={classes.usercard}>
                {!status ? (
                  <div id="skeleton-div">
                    <Skeleton
                      id="skeleton"
                      variant="rect"
                      className={classes.skeletonContent}
                    />
                  </div>
                ) : status === "terminated" ? (
                  <>
                    <OverlayTerminate><IcReport big="true"/>ระงับการใช้งาน</OverlayTerminate>
                    <UserCard id="usercard-terminated" data={data} />
                  </>
                ) : (
                      <UserCard id="usercard" data={data} />
                    )}
              </div>
              <div id="root-card" className={classes.rootCard}>
                <div id="verme-div" className={classes.ver}>
                  <span id="span-skeleton">
                    {status ? (
                      headText()
                    ) : (
                        <Skeleton id="skeleton" width={217} height={30} />
                      )}
                  </span>
                  {status ? (
                    <div className={classes.checkicon}>
                      {status === "verified" ? (
                        <CheckIcon />
                      ) : status === "expired" ? (
                        <ExpIcon />
                      ) : status === "checking" ? (
                        <CheckingIc />
                      ) : status === "terminated" ? (
                        <IcReport />
                      ) : (
                                ""
                              )}
                    </div>
                  ) : (
                      <Skeleton
                        id="skeleton"
                        variant="circle"
                        className={classes.skeletonCheckIcon}
                      />
                    )}
                </div>
                <Box
                  id="box"
                  letterSpacing={"0.03rem"}
                  lineHeight={"1.75rem"}
                  fontSize={"1rem"}
                  color="text.secondary"
                  fontWeight="fontWeightRegular"
                >
                  {status ? (
                    subText()
                  ) : (
                      <div id="subtxt-frame" className={classes.subTextFrame}>
                        <Skeleton
                          id="skeleton-txt"
                          className={classes.skeletonText}
                        />
                      </div>
                    )}
                </Box>
                <div id="expdate-div" className={classes.expdate}>
                  <Box
                    id="box"
                    style={{ marginRight: "0.5rem" }}
                    letterSpacing={"0.03rem"}
                    lineHeight={"1.75rem"}
                    fontSize={"1rem"}
                    color="text.secondary"
                    fontWeight="fontWeightRegular"
                  >
                    {status ? (
                      subText2()
                    ) : (
                        <Skeleton
                          id="skeleton-txt-2"
                          className={classes.skeletonText2}
                        />
                      )}
                  </Box>
                  <Box
                    id="box"
                    letterSpacing={"0.03rem"}
                    lineHeight={"1.75rem"}
                    fontSize={"1rem"}
                    color="#3B54D4"
                    fontWeight="fontWeightRegular"
                  >
                    {!status ? (
                      <Skeleton
                        id="skeleton-subtxt-exp"
                        className={classes.subTextExpires}
                      />
                    ) : status === "verified" ? (
                      checkExp
                    ) : (
                          " "
                        )}
                  </Box>
                </div>
                <div
                  id="txt-policy"
                  className={classes.textPolicy}
                  style={{ marginBottom: "2.5rem" }}
                >
                  {status === "terminatedd" ? (
                    <a
                      id="link"
                      href={policy}
                      style={{ color: "#3B54D4" }}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      นโยบายของเรา
                    </a>
                  ) : (
                      " "
                    )}
                </div>
                {!status ? (
                  <div
                    id="skeleton-btn-div"
                    className={classes.skeletonButtonFrame}
                  >
                    <Skeleton
                      id="skeleton-btn"
                      variant="rect"
                      className={classes.skeletonButton}
                    />
                  </div>
                ) : (
                    <div className={classes.btnDiv}>
                      <Link
                        id="link-link"
                        to="/"
                        style={{ textDecoration: "none" }}
                      >
                        <Button
                          id="goto-btn"
                          variant="outlined"
                          className={classes.gotoBtn}
                        >
                          <Box
                            id="box"
                            letterSpacing={"0.08rem"}
                            lineHeight={"1rem"}
                            fontSize={"0.88rem"}
                            fontWeight="fontWeightMedium"
                          >
                            กลับไปหน้าแรก
                      </Box>
                        </Button>
                      </Link>
                    </div>
                  )}
              </div>
              {status === "verified" ? (
                <div className={classes.btnDiv}>
                  {fbData ? (
                    <StyledAccordion>
                      <StyledAccSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3a-content"
                        id="panel3a-header"
                      >
                        <TitleTxt>เฟซบุ๊ก โพรไฟล์</TitleTxt>
                      </StyledAccSummary>
                      <StyledAccDetails>
                        <FbDiv>
                          <FbImg
                            src={
                              "http://graph.facebook.com/" +
                              fbData.userID +
                              "/picture?type=large"
                            }
                          />
                          <div>
                            <FbName>{fbData.userName}</FbName>
                            <StyledLinker
                              id="link-link"
                              href={"https://www.facebook.com/" + fbData.userName}
                              target="_blank"
                              style={{ textDecoration: "none" }}
                            >
                              เข้าชมโพรไฟล์
                          </StyledLinker>
                          </div>
                        </FbDiv>
                      </StyledAccDetails>
                    </StyledAccordion>
                  ) : (
                      <div id="fbprofile-frame">
                        <Skeleton
                          id="skeleton-fb"
                          className={classes.skeletonFbProfile}
                        />
                      </div>
                    )}
                </div>
              ) : (
                  ""
                )}
              <div id="div-outer">
                <div id="div-inner">
                  <a
                    id="link"
                    href="https://verme.page.link/check-identity"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div id="chat-circle" className={classes.chatcircle}>
                      <img
                        id="img"
                        className={classes.circleColor}
                        src={primaryColor}
                        alt="color"
                      />
                      <img
                        id="img"
                        className={classes.chaticon}
                        src={chatIcon}
                        alt="chaticon"
                      />
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </React.Fragment>
        )}
      <Footer id="footer"></Footer>
    </div>
  );
}

export default withStyles(styles)(IdentitySinglePage);
