import React, { useContext } from "react";
import Title from "../common/Title";
import SubTitle from "./SubTitle";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import InputAdornment from "@material-ui/core/InputAdornment";
import ErrorMessage from "../common/ErrorMessage";
// import NumberFormat from "react-number-format";
import StyledTextField from "../common/StyledTextField";
import DatePicker from "../common/DatePicker";
import IconAdorn from "../common/IconAdornment";
import { StoreContext } from "../Context/Store";
import { ReactComponent as CheckIcon } from "../assets/Icons/ic_check_single.svg";
import styled from "styled-components";
import colors from "../common/Color";

const Prefix = ["นาย", "นาง", "นางสาว"];
const CheckText = styled.div`
  font-family: "SukhumvitSet-Text";
  font-size: 0.88rem;
  line-height: 1.25rem;
  letter-spacing: 0.02rem;
  color: ${colors.black};
  opacity: 0.34;
`;
const CheckGroup = styled.div`
  position: relative;
  margin: 0.5rem;
`;
const CheckBox = styled.div`
  width: 1.38rem;
  height: 1.38rem;
  border-radius: 0.38rem;
  border: solid 0.063rem #d1d1d1;
  background-color: #f4f4f4;
  position: relative;
  cursor: pointer;
`;
const CheckedIcon = styled(CheckIcon)`
  font-size: 1.5rem;
  fill: ${colors.primary};
  position: absolute;
  top: 0rem;
  cursor: pointer;
`;
const CheckContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 0.5rem 0;
`;
export default function General(props) {
  const {
    prefix,
    nameTh,
    surnameTh,
    nameEn,
    surnameEn,
    email,
    phone,
    birthdate,
    isPromptpay
  } = useContext(StoreContext);

  const handleCheck = () => {
    isPromptpay[1](true);
  };

  const handleUncheck = () => {
    isPromptpay[1](false);
  };
  return (
    <Grid id="root" container justify="flex-start">
      <Grid id="grid-title" item xs={12}>
        <Title id="title">ข้อมูลทั่วไป</Title>
      </Grid>
      <Grid id="grid-subtitle" item xs={12}>
        <SubTitle id="subtitle" />
      </Grid>
      <Grid id="grid-container" container spacing={2}>
        <Grid id="grid-item" item xs={12}>
          <StyledTextField
            id="prefix"
            select
            label="คำนำหน้า"
            value={prefix[0]}
            onChange={e => prefix[1](e.target.value)}
            margin="normal"
            variant="outlined"
            style={{ width: "7.31rem" }}
            InputProps={{
              startAdornment: (
                <InputAdornment id="adornment" position="end">
                  <IconAdorn id="disabled-ic" disabled />
                </InputAdornment>
              )
            }}
          >
            {Prefix.map(option => (
              <MenuItem id="menu-item" key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </StyledTextField>
        </Grid>
        <Grid id="container-item" container item spacing={2}>
          <Grid id="grid-item" item md={6} xs={12}>
            <StyledTextField
              id="thaiFirstName"
              name="thaiFirstName"
              label="ชื่อ (ไทย)"
              inputRef={props.formProps.register}
              error={!!props.formProps.errors.thaiFirstName}
              placeholder="ชื่อ"
              margin="normal"
              variant="outlined"
              defaultValue={nameTh[0]}
              onChange={e => nameTh[1](e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment id="adornment" position="end">
                    <IconAdorn id="disabled-ic" disabled />
                  </InputAdornment>
                )
              }}
            />
            {props.formProps.errors.thaiFirstName && (
              <ErrorMessage id="err-msg">
                {props.formProps.errors.thaiFirstName.message}
              </ErrorMessage>
            )}
          </Grid>
          <Grid id="grid-item" item md={6} xs={12}>
            <StyledTextField
              id="thaiLastName"
              name="thaiLastName"
              inputRef={props.formProps.register}
              error={!!props.formProps.errors.thaiLastName}
              label="นามสกุล (ไทย)"
              placeholder="นามสกุล"
              margin="normal"
              defaultValue={surnameTh[0]}
              variant="outlined"
              onChange={e => surnameTh[1](e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment id="adornment" position="end">
                    <IconAdorn id="disabled-ic" disabled />
                  </InputAdornment>
                )
              }}
            />
            {props.formProps.errors.thaiLastName && (
              <ErrorMessage id="err-msg">
                {props.formProps.errors.thaiLastName.message}
              </ErrorMessage>
            )}
          </Grid>
        </Grid>
        <Grid id="container-item" container item spacing={2}>
          <Grid id="grid-item" item md={6} xs={12}>
            <StyledTextField
              id="enFirstName"
              name="enFirstName"
              inputRef={props.formProps.register}
              error={!!props.formProps.errors.enFirstName}
              label="First name (English)"
              placeholder="First name"
              margin="normal"
              variant="outlined"
              onChange={e => nameEn[1](e.target.value)}
              defaultValue={nameEn[0]}
              InputProps={{
                startAdornment: (
                  <InputAdornment id="adornment" position="end">
                    <IconAdorn id="disabled-ic" disabled />
                  </InputAdornment>
                )
              }}
            />
            {props.formProps.errors.enFirstName && (
              <ErrorMessage id="err-msg">
                {props.formProps.errors.enFirstName.message}
              </ErrorMessage>
            )}
          </Grid>
          <Grid id="grid-item" item md={6} xs={12}>
            <StyledTextField
              id="enLastName"
              name="enLastName"
              inputRef={props.formProps.register}
              error={!!props.formProps.errors.enLastName}
              label="Last name (English)"
              placeholder="Last name"
              margin="normal"
              variant="outlined"
              onChange={e => surnameEn[1](e.target.value)}
              defaultValue={surnameEn[0]}
              InputProps={{
                startAdornment: (
                  <InputAdornment id="adornment" position="end">
                    <IconAdorn id="disabled-ic" disabled />
                  </InputAdornment>
                )
              }}
            />
            {props.formProps.errors.enLastName && (
              <ErrorMessage id="err-msg">
                {props.formProps.errors.enLastName.message}
              </ErrorMessage>
            )}
          </Grid>
        </Grid>
        <Grid id="container-item" container item spacing={2}>
          <Grid
            id="grid-item"
            item
            md={6}
            xs={12}
            style={{ marginTop: "1rem" }}
          >
            <DatePicker
              id="birthdate"
              name="birthdate"
              autoOk
              disableFuture
              variant="inline"
              inputVariant="outlined"
              label="วันเกิด"
              placeholder="วันเกิด"
              maxDate={new Date(2000, 11, 31)}
              format="dd/MM/yyyy"
              value={birthdate[0]}
              InputAdornmentProps={{ position: "start" }}
              onChange={date => birthdate[1](date)}
            />
            {birthdate[0] === null && (
              <ErrorMessage id="err-msg">
                กรุณากรอกวันเกิด
              </ErrorMessage>
            )}
          </Grid>
          <Grid id="grid-item" item md={6} xs={12}>
            <StyledTextField
              id="email"
              name="email"
              inputRef={props.formProps.register}
              error={!!props.formProps.errors.email}
              label="อีเมล"
              placeholder="อีเมล"
              margin="normal"
              variant="outlined"
              onChange={e => email[1](e.target.value)}
              defaultValue={email[0]}
              InputProps={{
                startAdornment: (
                  <InputAdornment id="adornment" position="end">
                    <IconAdorn id="disabled-ic" disabled />
                  </InputAdornment>
                )
              }}
            />
            {props.formProps.errors.email ? (
              <ErrorMessage id="err-msg">
                {props.formProps.errors.email.message}
              </ErrorMessage>
            ) : props.checkExistEmail ? (
              <ErrorMessage id="err-msg">อีเมลนี้ถูกใช้งานแล้ว</ErrorMessage>
            ) : (
              ""
            )}
          </Grid>
        </Grid>
        <Grid id="container-item" container item spacing={2}>
          <Grid id="grid-item" item md={6} xs={12}>
            <StyledTextField
              // customInput={StyledTextField}
              id="phone"
              name="phone"
              inputRef={props.formProps.register}
              error={!!props.formProps.errors.phone}
              label="เบอร์โทรศัพท์มือถือ"
              placeholder="เบอร์โทรศัพท์มือถือ 10 หลัก"
              margin="normal"
              variant="outlined"
              onChange={e => phone[1](e.target.value)}
              defaultValue={phone[0]}
              // format="### ### ####"
              InputProps={{
                startAdornment: (
                  <InputAdornment id="adornment" position="end">
                    <IconAdorn id="disabled-ic" disabled />
                  </InputAdornment>
                )
              }}
              inputProps={{
                maxLength: 10
              }}
            />
            {props.formProps.errors.phone ? (
              <ErrorMessage id="err-msg">
                {props.formProps.errors.phone.message}
              </ErrorMessage>
            ) : props.checkExistPhone ? (
              <ErrorMessage id="err-msg">
                เบอร์โทรศัพท์มือถือนี้ถูกใช้งานแล้ว
              </ErrorMessage>
            ) : (
              ""
            )}
          </Grid>
        </Grid>
        <Grid id="container-item" container item spacing={2}>
          <CheckContainer id="check-container">
            <CheckGroup id="check-group">
              <CheckBox id="check-box" onClick={handleCheck} />
              {isPromptpay[0] === true ? (
                <CheckedIcon id="check-ic" onClick={handleUncheck} />
              ) : (
                ""
              )}
            </CheckGroup>
            <CheckText id="text">
              เบอร์โทรศัพท์นี้สมัครใช้บริการพร้อมเพย์แล้ว
            </CheckText>
          </CheckContainer>
        </Grid>
      </Grid>
    </Grid>
  );
}
