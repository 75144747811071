import React from "react";
import styled from "styled-components";
import TitleText from "./TitleText";
import Divider from "./Divider";
import ImageProfile1 from "../assets/Images/img_profile_1.png";
import ImageProfile2 from "../assets/Images/img_profile_2.png";

const Root = styled.div`
  min-height: 100%;
  background: #ebedfa;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const Container = styled.div`
  @media (max-width: 1024px) {
    width: 17rem;
  }
`;
const QuoteRow = styled.div`
  display: flex;
  width: 62.26rem;
  justify-content: space-between;
  margin-bottom: 8rem;
  @media (max-width: 1024px) {
    width: 23.38rem;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 2.5rem;
  }
  @media (max-width: 475px) {
    width: 20rem;
  }
  @media (max-width: 320px) {
    width: 17rem;
  }
`;
const StyledDivider = styled(Divider)`
  margin: 2.5rem auto 4rem;
`;
const QuoteBox = styled.div`
  width: 30.38rem;
  height: 25.13rem;
  border-radius: 0.5rem;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 1024px) {
    width: 23.38rem;
    height: 30rem;
    margin-bottom: 2rem;
    align-items: center;
  }
  @media (max-width: 475px) {
    width: 20rem;
  }
  @media (max-width: 320px) {
    width: 17rem;
  }
`;
const NameText = styled.div`
  font-family: "SukhumvitSet-Text";
  font-size: 1.25rem;
  color: #000;
  opacity: 0.6;
  text-align: center;
  letter-spacing: 0.02rem;
  line-height: 1.63rem;
  margin: 1rem 0 0.25rem;
`;
const DetailText = styled.div`
  font-family: "Sarabun-Light";
  font-size: 0.8rem;
  color: #000;
  opacity: 0.6;
  letter-spacing: 0.03rem;
  line-height: 1.75rem;
  text-align: center;
`;
const QuoteDiv = styled.div`
  width: 25.88rem;
  margin: 1.75rem auto 0;
  @media (max-width: 1024px) {
    width: 20.38rem;
  }
  @media (max-width: 475px) {
    width: 14rem;
  }
`;
const CircleImg = styled.img`
  width: 3.75rem;
  height: 3.75rem;
  border-radius: 50%;
  margin: 0 auto;
`;

export default function QuoteSection() {
  return (
    <Root id="root">
      <Container id="container">
        <TitleText id="title">
          เพราะเราเชื่อว่า... <br />{" "}
          สังคมการซื้อขายที่โปร่งใสสามารถเกิดขึ้นได้จริง
        </TitleText>
        <StyledDivider id="divider" />
      </Container>
      <QuoteRow id="quote-row">
        <QuoteBox id="quote-box">
          <CircleImg id="circle-img" src={ImageProfile1} alt="profile_nk" />
          <NameText id="name-txt">นนทวิชช์ ดวงสอดศรี</NameText>
          <DetailText id="detail-txt">
            CEO บริษัท The Existing Company
          </DetailText>
          <QuoteDiv id="quote-div">
            <DetailText id="detail-txt">
              “ประเทศไทยเราใช้ Social ขายของเป็นอันดับ 1 ของโลก
              ซึ่งมีสิ่งที่ตามมาก็คือการโกง และหลอกลวงมากขึ้นเช่นกัน
              การที่ผู้ขายใช้บัตรประชาชน
              เพื่อยืนยันตัวตนกับผู้ซื้อเป็นช่องทางที่อันตรายและมีช่องโหว่มากมาย
              รวมถึงการโอนเงินโดยไม่เช็คตัวตนผู้ขายว่ามีอยู่จริงหรือไม่ VerME
              จะมาช่วยแก้ปัญหานี้ทั้งหมดนี้ เราจะโอนเงินได้ปลอดภัยขึ้น
              สบายใจขึ้น และไม่เสี่ยงอีกต่อไป”
            </DetailText>
          </QuoteDiv>
        </QuoteBox>
        <QuoteBox id="quote-box">
          <CircleImg id="circle-img" src={ImageProfile2} alt="profile_tt" />
          <NameText id="name-txt">พ.ต.ท.มนุ​พัศ ศรีบุญ​ลือ</NameText>
          <DetailText id="detail-txt">Data Protection Officer (DPO)</DetailText>
          <QuoteDiv id="quote-div">
            <DetailText id="detail-txt">
              “ข้อมูลส่วนบุคคล และความเป็นส่วนตัวของผู้ใช้บริการ VerME
              ต้องได้รับความคุ้มครองอย่างปลอดภัย
              สำหรับผู้ขายออนไลน์ที่ได้รับการยืนยันตัวตนจาก VerME
              จะทำให้ผู้ซื้อสามารถมั่นใจได้ว่า ผู้ขายมีตัวตนอยู่จริง
              และหากพบผู้ขายที่เป็นมิจฉาชีพ ผู้ซื้อจะได้รับเงินคืนอย่างรวดเร็ว
              และผู้ขายมิจฉาชีพนั้นจะต้องถูกดำเนินคดีตามกฎหมาย​”
            </DetailText>
          </QuoteDiv>
        </QuoteBox>
      </QuoteRow>
    </Root>
  );
}
