import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuth } from './use-auth';

const PrivateRoute = ({ component: Component, ...rest }) => {
    const auth = useAuth()

    return (
        <Route {...rest} render={props => (
            auth.currentUser? 
            <React.Fragment>
                <Component {...props} /> 
            </React.Fragment>
            : 
            <Redirect to="/sign-in" />
        )} />
    );
};

export default PrivateRoute;