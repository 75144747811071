import React, { useContext , useState } from "react";
import styled from "styled-components";
import colors from "../common/Color";
import Title from "../common/Title";
import SubTitle from "./SubTitle";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import InputAdornment from "@material-ui/core/InputAdornment";
import DatePicker from "../common/DatePicker";
import ErrorMessage from "../common/ErrorMessage";
import StyledTextField from "../common/StyledTextField";
import IconAdorn from "../common/IconAdornment";
import Button from "../common/WebButton";
import { StoreContext } from "../Context/Store";
import BackButton from "../common/components/BackButtonBlue";
import moment from 'moment';
import {
  ModalContainer,
  StyledModal,
  MobileDiv,
  IcCross,
  Root,
  GridButton,
  GridButtonMob,
  Container,
  NavBar,
  NavContainer,
  Logo
} from "./EditAddressModal";

const Prefix = ["นาย", "นาง", "นางสาว"];
const ModalBox = styled.div`
  width: 47.13rem;
  height: 100%;
  padding-bottom: 2.5rem;
  background: ${colors.white};
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  margin: auto;
  @media (max-width: 1024px) {
    display: none;
  }
`;

export default function EditGeneralModal(props) {
  const { openGeneral, handleCloseGeneral } = props;
  const {
    prefix,
    nameTh,
    surnameTh,
    nameEn,
    surnameEn,
    email,
    birthdate,
    loading,
    general
  } = useContext(StoreContext);

  const [prefixData,] = useState(prefix[0]);
  const [nameThData,] = useState(nameTh[0]);
  const [surnameThData,] = useState(surnameTh[0]);
  const [nameEnData,] = useState(nameEn[0]);
  const [surnameEnData,] = useState(surnameEn[0]);
  const [emailData,] = useState(email[0]);
  const [birthdateData,] = useState(birthdate[0]);

  const handleSave = data => {
    const checkbirth = moment(birthdate[0]).format("YYYY");
    if (
      birthdate[0] &&
      birthdate[0].toString() !== "Invalid Date" &&
      checkbirth <= "2000" &&
      !props.formProps.errors.thaiFirstName &&
      !props.formProps.errors.thaiLastName &&
      !props.formProps.errors.enFirstName &&
      !props.formProps.errors.enLastName &&
      !props.formProps.errors.email
    ) {
      general[1](data);
      handleCloseGeneral();
    } else {
      loading[1](false);
    }
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };
  const cancelChangeData = () => {
    prefix[1](prefixData);
    nameTh[1](nameThData);
    surnameTh[1](surnameThData);
    nameEn[1](nameEnData);
    surnameEn[1](surnameEnData);
    email[1](emailData);
    birthdate[1](birthdateData);
    handleCloseGeneral();
  };

  return (
    <div id="root">
      <StyledModal
        id="modal"
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={openGeneral}
        onClose={handleCloseGeneral}
        disableEnforceFocus={true}
      >
        <ModalContainer id="modal-container">
          <ModalBox id="modal-box">
            <Grid id="grid-close" container justify="flex-end">
              <IcCross id="close-btn" onClick={cancelChangeData} />
            </Grid>
            <Grid id="grid-center" container justify="center">
              <Grid id="grid-title" item xs={10}>
                <Title id="title">แก้ไขข้อมูลทั่วไป</Title>
              </Grid>
              <Grid id="grid-subtitle" item xs={10}>
                <SubTitle id="subtitle" props="edit" />
              </Grid>
              <Grid id="grid-center" container spacing={2} justify="center">
                <Grid id="grid-item" item xs={10}>
                  <StyledTextField
                    id="prefix"
                    select
                    label="คำนำหน้า"
                    value={prefix[0]}
                    onChange={e => prefix[1](e.target.value)}
                    margin="normal"
                    variant="outlined"
                    style={{ width: "7.31rem" }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment id="adornment" position="end">
                          <IconAdorn id="disabled-ic" disabled />
                        </InputAdornment>
                      )
                    }}
                  >
                    {Prefix.map(option => (
                      <MenuItem id="menu-item" key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </StyledTextField>
                </Grid>
                <Grid
                  id="grid-center"
                  container
                  item
                  spacing={2}
                  justify="center"
                >
                  <Grid id="grid-item" item md={5}>
                    <StyledTextField
                      id="thaiFirstName"
                      name="thaiFirstName"
                      label="ชื่อ (ไทย)"
                      inputRef={props.formProps.register}
                      error={!!props.formProps.errors.thaiFirstName}
                      placeholder="ชื่อ"
                      margin="normal"
                      variant="outlined"
                      defaultValue={nameTh[0]}
                      onChange={e => nameTh[1](e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment id="adornment" position="end">
                            <IconAdorn id="disabled-ic" disabled />
                          </InputAdornment>
                        )
                      }}
                    />
                    {props.formProps.errors.thaiFirstName && (
                      <ErrorMessage id="err-msg">
                        {props.formProps.errors.thaiFirstName.message}
                      </ErrorMessage>
                    )}
                  </Grid>
                  <Grid item md={5} id="grid-item">
                    <StyledTextField
                      id="thaiLastName"
                      name="thaiLastName"
                      inputRef={props.formProps.register}
                      error={!!props.formProps.errors.thaiLastName}
                      label="นามสกุล (ไทย)"
                      placeholder="นามสกุล"
                      margin="normal"
                      defaultValue={surnameTh[0]}
                      variant="outlined"
                      onChange={e => surnameTh[1](e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment id="adornment" position="end">
                            <IconAdorn id="disabled-ic" disabled />
                          </InputAdornment>
                        )
                      }}
                    />
                    {props.formProps.errors.thaiLastName && (
                      <ErrorMessage id="err-msg">
                        {props.formProps.errors.thaiLastName.message}
                      </ErrorMessage>
                    )}
                  </Grid>
                </Grid>
                <Grid
                  id="grid-center"
                  container
                  item
                  spacing={2}
                  justify="center"
                >
                  <Grid id="grid-item" item md={5}>
                    <StyledTextField
                      id="enFirstName"
                      name="enFirstName"
                      inputRef={props.formProps.register}
                      error={!!props.formProps.errors.enFirstName}
                      label="First name (English)"
                      placeholder="First name"
                      margin="normal"
                      variant="outlined"
                      onChange={e => nameEn[1](e.target.value)}
                      defaultValue={nameEn[0]}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment id="adornment" position="end">
                            <IconAdorn id="disabled-ic" disabled />
                          </InputAdornment>
                        )
                      }}
                    />
                    {props.formProps.errors.enFirstName && (
                      <ErrorMessage id="err-msg">
                        {props.formProps.errors.enFirstName.message}
                      </ErrorMessage>
                    )}
                  </Grid>
                  <Grid id="grid-item" item md={5}>
                    <StyledTextField
                      id="enLastName"
                      name="enLastName"
                      inputRef={props.formProps.register}
                      error={!!props.formProps.errors.enLastName}
                      label="Last name (English)"
                      placeholder="Last name"
                      margin="normal"
                      variant="outlined"
                      onChange={e => surnameEn[1](e.target.value)}
                      defaultValue={surnameEn[0]}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment id="adornment" position="end">
                            <IconAdorn id="disabled-ic" disabled />
                          </InputAdornment>
                        )
                      }}
                    />
                    {props.formProps.errors.enLastName && (
                      <ErrorMessage id="err-msg">
                        {props.formProps.errors.enLastName.message}
                      </ErrorMessage>
                    )}
                  </Grid>
                </Grid>
                <Grid
                  id="grid-center"
                  container
                  item
                  spacing={2}
                  justify="center"
                >
                  <Grid
                    id="grid-item"
                    item
                    md={5}
                    style={{ marginTop: "1rem" }}
                  >
                    <DatePicker
                      id="birthdate"
                      name="birthdate"
                      autoOk
                      disableFuture
                      variant="inline"
                      inputVariant="outlined"
                      label="วันเกิด"
                      placeholder="วันเกิด"
                      maxDate={new Date(2000, 11, 31)}
                      format="dd/MM/yyyy"
                      value={birthdate[0]}
                      InputAdornmentProps={{ position: "start" }}
                      onChange={date => birthdate[1](date)}
                    />
                    {birthdate[0] === null && (
                      <ErrorMessage id="err-msg">กรุณากรอกวันเกิด</ErrorMessage>
                    )}
                  </Grid>
                  <Grid id="grid-item" item md={5}>
                    <StyledTextField
                      id="email"
                      name="email"
                      inputRef={props.formProps.register}
                      error={!!props.formProps.errors.email}
                      label="อีเมล"
                      placeholder="อีเมล"
                      margin="normal"
                      variant="outlined"
                      onChange={e => email[1](e.target.value)}
                      defaultValue={email[0]}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment id="adornment" position="end">
                            <IconAdorn id="disable-ic" disabled />
                          </InputAdornment>
                        )
                      }}
                    />
                    {props.formProps.errors.email ? (
                      <ErrorMessage id="err-msg">
                        {props.formProps.errors.email.message}
                      </ErrorMessage>
                    ) : props.checkExistEmail ? (
                      <ErrorMessage id="err-msg">
                        อีเมลนี้ถูกใช้งานแล้ว
                      </ErrorMessage>
                    ) : (
                      ""
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <GridButton id="grid-btn" container item xs={11} justify="flex-end">
              <Button
                id="cancel-btn"
                width="7.25rem"
                height="2.75rem"
                style={{ marginRight: "1rem" }}
                onClick={cancelChangeData}
              >
                ยกเลิก
              </Button>
              <Button
                id="save-btn"
                primary="true"
                width="7.25rem"
                height="2.75rem"
                onClick={handleSave}
              >
                บันทึก
              </Button>
            </GridButton>
          </ModalBox>
          <MobileDiv id="mobile-div">
            <Root id="mobile-root">
              <NavBar id="nav-ber">
                <NavContainer id="nav-container">
                  <div id="btn-div" onClick={cancelChangeData}>
                    <BackButton id="back-btn" />
                  </div>
                  <Logo id="logo" />
                </NavContainer>
              </NavBar>
              <Container id="container-mb">
                <Grid id="grid-flex-start" container justify="flex-start">
                  <Grid id="grid-item" item xs={12}>
                    <Title id="title">แก้ไขข้อมูลทั่วไป</Title>
                  </Grid>
                  <Grid id="grid-item" item xs={12}>
                    <SubTitle id="subtitle" />
                  </Grid>
                  <Grid id="grid-container" container spacing={2}>
                    <Grid id="grid-item" item xs={12}>
                      <StyledTextField
                        id="prefix"
                        select
                        label="คำนำหน้า"
                        value={prefix[0]}
                        onChange={e => prefix[1](e.target.value)}
                        margin="normal"
                        variant="outlined"
                        style={{ width: "7.31rem" }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment id="adornment" position="end">
                              <IconAdorn id="disabled-ic" disabled />
                            </InputAdornment>
                          )
                        }}
                      >
                        {Prefix.map(option => (
                          <MenuItem id="menu-item" key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </StyledTextField>
                    </Grid>
                    <Grid id="container-item" container item spacing={2}>
                      <Grid id="grid-item" item md={6} xs={12}>
                        <StyledTextField
                          id="thaiFirstName"
                          name="thaiFirstName"
                          label="ชื่อ (ไทย)"
                          inputRef={props.formProps.register}
                          error={!!props.formProps.errors.thaiFirstName}
                          placeholder="ชื่อ"
                          margin="normal"
                          variant="outlined"
                          defaultValue={nameTh[0]}
                          onChange={e => nameTh[1](e.target.value)}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment id="adornment" position="end">
                                <IconAdorn id="disabled-ic" disabled />
                              </InputAdornment>
                            )
                          }}
                        />
                        {props.formProps.errors.thaiFirstName && (
                          <ErrorMessage id="err-msg">
                            {props.formProps.errors.thaiFirstName.message}
                          </ErrorMessage>
                        )}
                      </Grid>
                      <Grid id="grid-item" item md={6} xs={12}>
                        <StyledTextField
                          id="thaiLastName"
                          name="thaiLastName"
                          inputRef={props.formProps.register}
                          error={!!props.formProps.errors.thaiLastName}
                          label="นามสกุล (ไทย)"
                          placeholder="นามสกุล"
                          margin="normal"
                          defaultValue={surnameTh[0]}
                          variant="outlined"
                          onChange={e => surnameTh[1](e.target.value)}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment id="adornment" position="end">
                                <IconAdorn id="disabled-ic" disabled />
                              </InputAdornment>
                            )
                          }}
                        />
                        {props.formProps.errors.thaiLastName && (
                          <ErrorMessage id="err-msg">
                            {props.formProps.errors.thaiLastName.message}
                          </ErrorMessage>
                        )}
                      </Grid>
                    </Grid>
                    <Grid id="container-item" container item spacing={2}>
                      <Grid id="grid-item" item md={6} xs={12}>
                        <StyledTextField
                          id="enFirstName"
                          name="enFirstName"
                          inputRef={props.formProps.register}
                          error={!!props.formProps.errors.enFirstName}
                          label="First name (English)"
                          placeholder="First name"
                          margin="normal"
                          variant="outlined"
                          onChange={e => nameEn[1](e.target.value)}
                          defaultValue={nameEn[0]}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment id="adornment" position="end">
                                <IconAdorn id="disabled-ic" disabled />
                              </InputAdornment>
                            )
                          }}
                        />
                        {props.formProps.errors.enFirstName && (
                          <ErrorMessage id="err-msg">
                            {props.formProps.errors.enFirstName.message}
                          </ErrorMessage>
                        )}
                      </Grid>
                      <Grid id="grid-item" item md={6} xs={12}>
                        <StyledTextField
                          id="enLastName"
                          name="enLastName"
                          inputRef={props.formProps.register}
                          error={!!props.formProps.errors.enLastName}
                          label="Last name (English)"
                          placeholder="Last name"
                          margin="normal"
                          variant="outlined"
                          onChange={e => surnameEn[1](e.target.value)}
                          defaultValue={surnameEn[0]}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment id="adornment" position="end">
                                <IconAdorn id="disabled-ic" disabled />
                              </InputAdornment>
                            )
                          }}
                        />
                        {props.formProps.errors.enLastName && (
                          <ErrorMessage id="err-msg">
                            {props.formProps.errors.enLastName.message}
                          </ErrorMessage>
                        )}
                      </Grid>
                    </Grid>
                    <Grid id="container-item" container item spacing={2}>
                      <Grid
                        id="grid-item"
                        item
                        md={6}
                        xs={12}
                        style={{ display: "flex", alignItems: "flex-end" }}
                      >
                        <DatePicker
                          id="birthdate"
                          name="birthdate"
                          inputRef={props.formProps.register}
                          error={!!props.formProps.errors.birthdate}
                          autoOk
                          disableFuture
                          variant="inline"
                          inputVariant="outlined"
                          label="วันเกิด"
                          placeholder="วันเกิด"
                          format="MM/dd/yyyy"
                          value={birthdate[0]}
                          InputAdornmentProps={{ position: "start" }}
                          onChange={date => birthdate[1](date)}
                        />
                      </Grid>
                      <Grid id="grid-item" item md={6} xs={12}>
                        <StyledTextField
                          id="email"
                          name="email"
                          inputRef={props.formProps.register}
                          error={!!props.formProps.errors.email}
                          label="อีเมล"
                          placeholder="อีเมล"
                          margin="normal"
                          variant="outlined"
                          onChange={e => email[1](e.target.value)}
                          defaultValue={email[0]}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment id="adornment" position="end">
                                <IconAdorn id="disabled-id" disabled />
                              </InputAdornment>
                            )
                          }}
                        />
                        {props.formProps.errors.email ? (
                          <ErrorMessage id="err-msg">
                            {props.formProps.errors.email.message}
                          </ErrorMessage>
                        ) : props.checkExistEmail ? (
                          <ErrorMessage id="err-msg">
                            อีเมลนี้ถูกใช้งานแล้ว
                          </ErrorMessage>
                        ) : (
                          ""
                        )}
                      </Grid>
                    </Grid>
                    <GridButtonMob id="grid-btn-mb" container item>
                      <Grid id="grid-item" item xs={12}>
                        <Button
                          id="save=btn"
                          primary="true"
                          width="100%"
                          height="2.75rem"
                          style={{ marginBottom: "1rem" }}
                          onClick={handleSave}
                        >
                          บันทึก
                        </Button>
                      </Grid>
                      <Grid id="grid-item" item xs={12}>
                        <Button
                          id="cancel-btn"
                          width="100%"
                          height="2.75rem"
                          onClick={cancelChangeData}
                        >
                          ยกเลิก
                        </Button>
                      </Grid>
                    </GridButtonMob>
                  </Grid>
                </Grid>
              </Container>
            </Root>
          </MobileDiv>
        </ModalContainer>
      </StyledModal>
    </div>
  );
}
