import React, { useState, useEffect } from "react";
import colors from "../common/Color";
import styled from "styled-components";
import useForm from "react-hook-form";
import Link from "@material-ui/core/Link";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import ImageBg from "../assets/Images/img_set_password2.jpg";
import IconAdorn from "../common/IconAdornment";
import Button from "../common/WebButton";
import { ReactComponent as LogoWhite } from "../assets/Logo/logo_v_white.svg";
import { ReactComponent as LogoPay } from "../assets/Logo/logo_verme_pay_small_white_2.svg";
import CheckImage from "../assets/ResetPassword/ic_check_single.svg";
import CrossImage from "../assets/ResetPassword/ic_cross.svg";
import { ReactComponent as LogoHead } from "../assets/Logo/logo_verme_color.svg";
import ErrorMessage from "../common/ErrorMessage";
import { getResetPasswordLink, putResetPassword } from "../actions/action";
import ErrorModal from "../common/ErrorModal";
import { CircularProgress } from "@material-ui/core";
import MetaDecorator from "../MetaDecorator/MetaDecorator";

const Container = styled.div`
  width: 100%;
  height: 100vh;
  background-image: url(${ImageBg});
  background-size: auto 100vh;
  background-repeat: no-repeat;
  display: flex;
  @media (min-width: 1025px) and (min-height: 800px) {
    background-position: -380px 0%;
  }
  @media (min-width: 1025px) and (min-height: 700px) {
    background-position: -300px 0%;
  }
  @media (min-width: 1025px) and (max-height: 699px) {
    background-position: -200px 0%;
  }
  @media (max-width: 1024px) {
    background: transparent;
  }
`;
const IconCheck = styled.img`
  margin-right: 0.5rem;
  width: 1rem;
  height: 1rem;
`;
const LeftContent = styled.div`
  width: 32%;
  margin: 1.25rem 1% 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 1024px) {
    display: none;
  }
`;
const LogoVm = styled(LogoWhite)`
  width: 2.25rem;
  height: 2rem;
`;
const PolicyDiv = styled.div`
  display: flex;
  justify-content: space-around;
`;
const PolicyLink = styled(Link)`
  && {
    font-family: "SukhumvitSet-Medium";
    font-size: 14px;
    color: white;
    opacity: 0.7;
    letter-spacing: 0.25px;
    text-align: center;
    line-height: 20px;
    cursor: pointer;
  }
`;
const LeftCenter = styled.div`
  margin: auto 18.5%;
`;
const LogoVmPay = styled(LogoPay)`
  width: 8.5rem;
  height: 2rem;
`;
const BigTxt = styled.div`
  font-family: "SukhumvitSet-Bold";
  font-size: 34px;
  color: ${props => (props.white ? "#ffffff" : "#32324D")};
  margin-top: ${props => (props.white ? "1.25rem" : "0")};
  letter-spacing: 0.25px;
  text-align: left;
  line-height: 48px;
  @media (max-width: 1024px) {
    font-size: 24px;
    font-family: "SukhumvitSet-Semibold";
  }
`;
const SmWhiteTxt = styled.div`
  font-family: "Sarabun-Regular";
  font-size: 14px;
  color: #ffffff;
  letter-spacing: 0.25px;
  text-align: left;
  line-height: 20px;
  margin-top: 1.25rem;
`;
const MdWhiteTxt = styled.div`
  font-family: "Sarabun-Regular";
  font-size: 20px;
  color: #ffffff;
  letter-spacing: 0.25px;
  line-height: 32px;
  margin-top: 1.5rem;
`;
const RightContent = styled.div`
  width: 68%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 1024px) {
    width: 100%;
    height: 100vh;
  }
`;
const RightCenter = styled.form`
  width: 42.85%;
  @media (max-width: 1024px) {
    width: 60%;
  }
  @media (max-width: 767px) {
    width: 90%;
  }
`;
const InputField = styled.div`
  margin-top: 2rem;
`;
const StyledTextField = styled(TextField)`
  & .MuiOutlinedInput-root {
    height: 3rem;
  }
  & .MuiOutlinedInput-adornedEnd {
    padding-right: 0;
    padding-left: 6px;
  }
  & .MuiFormLabel-root {
    font-family: "SukhumvitSet-Semibold";
  }
`;
const Btn = styled(Button)`
  && {
    width: 100%;
    height: 2.75rem;
    margin-top: 3.06rem;
  }
`;
const CheckFieldPasswordFrame = styled.div`
  margin: 1.5rem 0rem;
  width: 100%;
`;

const CheckFieldPasswordContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  font-family: "SukhumvitSet-Text";
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.87);
  letter-spacing: 0.5px;
  line-height: 28px;
`;

const HeaderDiv = styled.div`
  top: 0;
  width: 100%;
  height: 3.5rem;
  background: ${colors.white};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.03);
  position: fixed;
  z-index: 10;
  @media (min-width: 1025px) {
    display: none;
  }
`;
const Header = styled.div`
  width: 90%;
  height: 3.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 5%;
`;
const LogoHeader = styled(LogoHead)`
  width: 2rem;
  height: 1.75rem;
`;
export default function ChangePassword(props) {
  const { register, handleSubmit, errors } = useForm();
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [uid, setUid] = useState("");
  const [otp, setOtp] = useState("");
  const [refer, setRefer] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [textMessage, setTextMessage] = useState("");
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleErrorClose = () => {
    setErrorOpen(false);
  };

  const checkCondition = () => {
    if (letterUpperCase() && letterNumber() && noSpace()) {
      return true;
    } else {
      return false;
    }
  };
  const letterUpperCase = () => {
    const splitPassword = password.split("");
    let status = false;
    splitPassword.forEach(item => {
      const checkUpperCase = item.charCodeAt(0);
      if (checkUpperCase >= 65 && checkUpperCase <= 90) {
        status = true;
      }
    });
    return status;
  };

  const letterNumber = () => {
    const splitPassword = password.split("");
    let status = false;
    splitPassword.forEach(item => {
      const checkUpperCase = item.charCodeAt(0);
      if (checkUpperCase >= 48 && checkUpperCase <= 57) {
        status = true;
      }
    });
    return status;
  };

  const noSpace = () => {
    const splitPassword = password.split("");
    let status = true;
    splitPassword.forEach(item => {
      const checkUpperCase = item.charCodeAt(0);
      if (checkUpperCase === 32) {
        status = false;
      }
    });
    return status;
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const res = await getResetPasswordLink(props.match.params.id);
        if (res.status === 200) {
          setUid(res.data.uid);
          setOtp(res.data.otp);
          setRefer(res.data.ref);
        }
      } catch (err) {
        console.log(err);
      }
    }
    fetchData();
    // eslint-disable-next-line
  }, []);

  const ConfirmFunction = async () => {
    setLoading(true);
    try {
      const res = await putResetPassword(uid, otp, refer, {password: password});
      if (res.status === 200) {
        window.location.href = "/change-password-success";
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      if (err.response) {
        if (err.response.status === 400) {
          setTextMessage(err.response.data.error);
          setErrorOpen(true);
        }
      }
      setLoading(false);
    }
  };
  return (
    <Container>
      <MetaDecorator
        description="มั่นใจทุกการขาย สบายใจทุกการซื้อ"
        title="เปลี่ยนรหัสผ่าน"
        // imageUrl={metaThumbnail}
        // imageAlt={blog.metaImageAlt}
      />
      <HeaderDiv>
        <Header>
          <LogoHeader />
        </Header>
      </HeaderDiv>
      <ErrorModal
        id="err-modal"
        textMessage={textMessage}
        handleErrorClose={handleErrorClose}
        errorOpen={errorOpen}
      />
      <LeftContent>
        <LogoVm />
        <LeftCenter>
          <LogoVmPay />
          <BigTxt white="true">
            บริการช่วยเหลือ
            <br />
            ผู้ซื้อผู้ขายออนไลน์
          </BigTxt>
          <SmWhiteTxt>
            VerME Pay คือระบบการจ่ายเงินที่ช่วยให้ผู้ขายรับเงินง่าย
            ผู้ซื้อจ่ายเงินง่าย สะดวก
            มั่นใจว่าผู้ขายมีตัวตนแน่นอนเพราะผ่านระบบการยืนยันตัวตนของ VerME
          </SmWhiteTxt>
          <MdWhiteTxt>ปลอดภัย มั่นใจ ปิดการขายง่าย</MdWhiteTxt>
        </LeftCenter>
        <PolicyDiv>
          <PolicyLink href="/policy" target="_blank" rel="noopener noreferrer">
            ข้อตกลงการใช้บริการ
          </PolicyLink>
          <PolicyLink href="/policy" target="_blank" rel="noopener noreferrer">
            นโยบายรักษาข้อมูลส่วนตัว
          </PolicyLink>
        </PolicyDiv>
      </LeftContent>
      <RightContent>
        <RightCenter onSubmit={handleSubmit(ConfirmFunction)}>
          <BigTxt>รหัสผ่านใหม่</BigTxt>
          <CheckFieldPasswordFrame id="check-pw-frame">
            <CheckFieldPasswordContent id="check-pw-ct">
              <IconCheck
                id="ic-check"
                src={password.length >= 8 ? CheckImage : CrossImage}
              />{" "}
              8-16 อักขระ
            </CheckFieldPasswordContent>
            <CheckFieldPasswordContent id="check-pw-ct">
              <IconCheck
                id="ic-check"
                src={letterUpperCase() ? CheckImage : CrossImage}
              />{" "}
              มีตัวภาษาอังกฤษพิมพ์ใหญ่อย่างน้อย 1 ตัว
            </CheckFieldPasswordContent>
            <CheckFieldPasswordContent id="check-pw-ct">
              <IconCheck
                id="ic-check"
                src={letterNumber() ? CheckImage : CrossImage}
              />{" "}
              มีตัวเลขอย่างน้อย 1 ตัว
            </CheckFieldPasswordContent>
            <CheckFieldPasswordContent id="check-pw-ct">
              <IconCheck
                id="ic-check"
                src={noSpace() ? CheckImage : CrossImage}
              />{" "}
              ไม่มีช่องว่าง
            </CheckFieldPasswordContent>
          </CheckFieldPasswordFrame>
          <InputField id="input-field">
            <StyledTextField
              id="password"
              name="password"
              label="รหัสผ่าน"
              error={!!errors.password}
              value={password}
              inputRef={register({
                required: "กรุณากรอกรหัสผ่าน",
                validate: password =>
                  checkCondition(password) === true ||
                  "กรุณาตั้งรหัสผ่านให้ตรงตามเงื่อนไขด้านบน"
              })}
              onChange={e => setPassword(e.target.value)}
              type={showPassword ? "text" : "password"}
              placeholder="รหัสผ่าน"
              variant="outlined"
              inputProps={{
                maxLength: 16
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment id="adornment-2" position="end">
                    <IconAdorn id="disabled-2" disabled />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment id="adornment" position="end">
                    <IconButton
                      id="ic-btn"
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
              fullWidth
            />
            {errors.password && (
              <ErrorMessage>{errors.password.message}</ErrorMessage>
            )}
          </InputField>
          <InputField>
            <StyledTextField
              id="confirmPassword"
              name="confirmPassword"
              label="ยืนยันรหัสผ่าน"
              value={confirmPassword}
              error={!!errors.confirmPassword}
              inputRef={register({
                required: "กรุณากรอกยืนยันรหัสผ่าน",
                validate: confirmPassword =>
                  confirmPassword === password || "กรุณาตั้งรหัสผ่านให้ตรงกัน"
              })}
              onChange={e => setConfirmPassword(e.target.value)}
              type={showConfirmPassword ? "text" : "password"}
              placeholder="ยืนยันรหัสผ่าน"
              variant="outlined"
              inputProps={{
                maxLength: 16
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment id="adornment-2" position="end">
                    <IconAdorn id="disabled-3" disabled />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment id="adornment" position="end">
                    <IconButton
                      id="ic-btn-2"
                      aria-label="toggle password visibility"
                      onClick={handleClickShowConfirmPassword}
                    >
                      {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
              fullWidth
            />
            {errors.confirmPassword && (
              <ErrorMessage>{errors.confirmPassword.message}</ErrorMessage>
            )}
          </InputField>
          <Btn primary="true" type="submit">
            {loading ? (
              <CircularProgress
                id="circular-progress"
                size={25}
                thickness={4}
                style={{ color: "white" }}
              />
            ) : (
              "ยืนยัน"
            )}
          </Btn>
        </RightCenter>
      </RightContent>
    </Container>
  );
}
