import React, { useContext } from "react";
import styled from "styled-components";
import { ReactComponent as IconBaac } from "../assets/Icons Bank/ic_baac.svg";
import { ReactComponent as IconBnp } from "../assets/Icons Bank/ic_bnp.svg";
import { ReactComponent as IconBoa } from "../assets/Icons Bank/ic_boa.svg";
import { ReactComponent as IconBualuang } from "../assets/Icons Bank/ic_bualuang.svg";
import { ReactComponent as IconCacib } from "../assets/Icons Bank/ic_cacib.svg";
import { ReactComponent as IconCimb } from "../assets/Icons Bank/ic_cimb.svg";
import { ReactComponent as IconCiti } from "../assets/Icons Bank/ic_citi.svg";
import { ReactComponent as IconDb } from "../assets/Icons Bank/ic_db.svg";
import { ReactComponent as IconGhb } from "../assets/Icons Bank/ic_ghb.svg";
import { ReactComponent as IconGsb } from "../assets/Icons Bank/ic_gsb.svg";
import { ReactComponent as IconHsbc } from "../assets/Icons Bank/ic_hsbc.svg";
import { ReactComponent as IconIcbc } from "../assets/Icons Bank/ic_icbc.svg";
import { ReactComponent as IconIsbt } from "../assets/Icons Bank/ic_isbt.svg";
import { ReactComponent as IconJpm } from "../assets/Icons Bank/ic_jpm.svg";
import { ReactComponent as IconKbank } from "../assets/Icons Bank/ic_kbank.svg";
import { ReactComponent as IconKkp } from "../assets/Icons Bank/ic_kkp.svg";
import { ReactComponent as IconKrungsri } from "../assets/Icons Bank/ic_krungsri.svg";
import { ReactComponent as IconKtb } from "../assets/Icons Bank/ic_ktb.svg";
import { ReactComponent as IconLh } from "../assets/Icons Bank/ic_lh.svg";
import { ReactComponent as IconMb } from "../assets/Icons Bank/ic_mb.svg";
import { ReactComponent as IconMega } from "../assets/Icons Bank/ic_mega.svg";
import { ReactComponent as IconMufg } from "../assets/Icons Bank/ic_mufg.svg";
import { ReactComponent as IconRbs } from "../assets/Icons Bank/ic_rbs.svg";
import { ReactComponent as IconSc } from "../assets/Icons Bank/ic_sc.svg";
import { ReactComponent as IconScb } from "../assets/Icons Bank/ic_scb.svg";
import { ReactComponent as IconSmbc } from "../assets/Icons Bank/ic_smbc.svg";
import { ReactComponent as IconTbank } from "../assets/Icons Bank/ic_tbank.svg";
import { ReactComponent as IconTcd } from "../assets/Icons Bank/ic_tcd.svg";
import { ReactComponent as IconTisco } from "../assets/Icons Bank/ic_tisco.svg";
import { ReactComponent as IconTmb } from "../assets/Icons Bank/ic_tmb.svg";
import { ReactComponent as IconUob } from "../assets/Icons Bank/ic_uob.svg";
import { LoginContext } from "../Context/LoginStore";
import { Bank } from "../Register/BankItem";

const Box = styled.div`
  width: 33.7rem;
  height: 25.38rem;
  background: #ffffff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.12);
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 1023px) {
    width: 25.7rem;
    height: 22.38rem;
  }
  @media (max-width: 767px) {
    width: 100%;
    height: 26.5rem;
    margin-bottom: 1.5rem;
  }
`;
export const TitleText = styled.div`
  opacity: ${props => (props.bank ? "0.87" : "0.6")};
  font-family: "SukhumvitSet-Semibold";
  font-size: 1rem;
  color: #000000;
  letter-spacing: 0.01rem;
  line-height: 1.5rem;
  margin-bottom: 1rem;
`;
const SalesText = styled.div`
  opacity: 0.87;
  font-family: "SukhumvitSet-Text";
  font-size: 2.13rem;
  color: #000000;
  letter-spacing: 0.02rem;
  line-height: 3.38rem;
  margin-top: 0.25rem;
  margin-bottom: 1rem;
`;
const BankDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
const BankAcc = styled.div`
  width: 11.5rem;
  height: 3rem;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.25rem;
  color: rgba(0, 0, 0, 0.6);
  font-family: SukhumvitSet-SemiBold;
  font-size: 0.88rem;
  letter-spacing: 0.01rem;
  line-height: 1.5rem;
  margin-right: 0.75rem;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  @media (max-width: 767px) {
    width: 100%;
  }
`;
const InnerDiv = styled.div`
  width: 29.75rem;
  height: 16.13rem;
  @media (max-width: 1023px) {
    width: 23.7rem;
  }
  @media (max-width: 767px) {
    width: 15.5rem;
    height: 21.5rem;
  }
`;
const IconDiv = styled.div`
  margin: auto 0.5rem auto 0.75rem;
  display: flex;
  align-self: center;
`;

export default function BankBox() {
  const { userLogin } = useContext(LoginContext);

  return (
    <Box id="box">
      <InnerDiv id="inner-div">
        <TitleText id="title">ยอดขายทั้งหมด (coming soon)</TitleText>
        <SalesText id="sales">-</SalesText>
        <TitleText id="title" bank="true">
          บัญชีธนาคารของคุณ
        </TitleText>
        <BankDiv id="bank-div">
          {userLogin[0].userProfile ? (
            userLogin[0].userProfile.bankAccount.map((item, index) => {
              return (
                <BankAcc id="bank-acc" key={index}>
                  <IconDiv id="ic-div">
                    {item.bankName === Bank[0].option ? (
                      <IconKbank id="ic-kbank" />
                    ) : item.bankName === Bank[1].option ? (
                      <IconBualuang id="ic-bl" />
                    ) : item.bankName === Bank[2].option ? (
                      <IconScb id="ic-scb" />
                    ) : item.bankName === Bank[3].option ? (
                      <IconTmb id="ic-tmb" />
                    ) : item.bankName === Bank[4].option ? (
                      <IconKrungsri id="ic-ks" />
                    ) : item.bankName === Bank[5].option ? (
                      <IconKtb id="ic-ktb" />
                    ) : item.bankName === Bank[6].option ? (
                      <IconKkp id="ic-kkp" />
                    ) : item.bankName === Bank[7].option ? (
                      <IconUob id="ic-uob" />
                    ) : item.bankName === Bank[8].option ? (
                      <IconGsb id="ic-gsb" />
                    ) : item.bankName === Bank[9].option ? (
                      <IconBaac id="ic-baac" />
                    ) : item.bankName === Bank[10].option ? (
                      <IconTbank id="ic-tbank" />
                    ) : item.bankName === Bank[11].option ? (
                      <IconIsbt id="ic-isbt" />
                    ) : item.bankName === Bank[12].option ? (
                      <IconTisco id="ic-tisco" />
                    ) : item.bankName === Bank[13].option ? (
                      <IconHsbc id="ic-hsbc" />
                    ) : item.bankName === Bank[14].option ? (
                      <IconSc id="ic-sc" />
                    ) : item.bankName === Bank[15].option ? (
                      <IconCiti id="ic-citi" />
                    ) : item.bankName === Bank[16].option ? (
                      <IconGhb id="ic-ghb" />
                    ) : item.bankName === Bank[17].option ? (
                      <IconSmbc id="ic-smbc" />
                    ) : item.bankName === Bank[18].option ? (
                      <IconCimb id="ic-cimb" />
                    ) : item.bankName === Bank[19].option ? (
                      <IconMega id="ic-mega" />
                    ) : item.bankName === Bank[20].option ? (
                      <IconBoa id="ic-boa" />
                    ) : item.bankName === Bank[21].option ? (
                      <IconMb id="ic-mb" />
                    ) : item.bankName === Bank[22].option ? (
                      <IconIcbc id="ic-icbc" />
                    ) : item.bankName === Bank[23].option ? (
                      <IconTcd id="ic-tcd" />
                    ) : item.bankName === Bank[24].option ? (
                      <IconLh id="ic-lh" />
                    ) : item.bankName === Bank[25].option ? (
                      <IconBnp id="ic-bnp" />
                    ) : item.bankName === Bank[26].option ? (
                      <IconDb id="ic-db" />
                    ) : item.bankName === Bank[27].option ? (
                      <IconCacib id="ic-cacib" />
                    ) : item.bankName === Bank[28].option ? (
                      <IconRbs id="ic-rbs" />
                    ) : item.bankName === Bank[29].option ? (
                      <IconJpm id="ic-jpm" />
                    ) : item.bankName === Bank[30].option ? (
                      <IconMufg id="ic-mufg" />
                    ) : (
                      ""
                    )}
                  </IconDiv>
                  {item.bankNumber}
                </BankAcc>
              );
            })
          ) : (
            <div id="blank"></div>
          )}
        </BankDiv>
      </InnerDiv>
    </Box>
  );
}
