import React from "react";
import Button from "@material-ui/core/Button";
import { ReactComponent as IconBack } from "../../assets/ic_back.svg";
import styled from "styled-components";
import colors from "../Color";

const Root = styled.div`
  text-align: justify;
  @media (max-width: 600px) {
    text-align: left;
    padding: 0;
  }
`;
const Text = styled.span`
  font-size: 0.875rem;
  color: ${colors.primary};
  letter-spacing: 0.08rem;
  line-height: 1rem;
  font-family: "Sarabun-Semibold";
`;
const IcBack = styled(IconBack)`
  margin-right: 0.5rem;
`;

export default function BackButtonBlue() {
  return (
    <Root>
      <Button>
        <IcBack/>
        <Text>ย้อนกลับ</Text>
      </Button>
    </Root>
  );
}
