import React, { useState, useEffect } from "react";
import styled from "styled-components";
import colors from "../common/Color";
import { ReactComponent as ImageFlag } from "../assets/Images/img_flag.svg";
// import { getCountStatus } from "../actions/action";

const Root = styled.div`
  height: 32.5rem;
  background: ${colors.grayDark};
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 1024px) {
    height: 100%;
    padding: 2.5rem 0 1.5rem;
  }
`;
const Container = styled.div`
  width: 64.64rem;
  display: flex;
  justify-content: space-between;
  @media (max-width: 1190px) {
    width: 61.64rem;
  }
  @media (max-width: 1024px) {
    width: 23.38rem;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  @media (max-width: 375px) {
    width: 17rem;
  }
`;
const BigText = styled.div`
  font-family: "SukhumvitSet-Text";
  font-size: 2.13rem;
  color: #fff;
  letter-spacing: 0.02rem;
  line-height: 3.38rem;
  @media (max-width: 1024px) {
    font-family: "SukhumvitSet-Semibold";
    font-size: 1.5rem;
    letter-spacing: 0;
    line-height: 2.31rem;
    text-align: center;
  }
`;
// const SmallText = styled.div`
//   font-family: "SukhumvitSet-Semibold";
//   font-size: 1.25rem;
//   letter-spacing: 0.02rem;
//   height: 1.94rem;
//   color: #fff;
//   @media (max-width: 1024px) {
//     font-family: "SukhumvitSet-Light";
//     text-align: center;
//     display: ${props => (props.mobile ? "none" : "block")};
//   }
// `;
// const NumBox = styled.div`
//   background: #fff;
//   width: 2.44rem;
//   height: 3.06rem;
//   border-radius: 0.5rem;
//   border: 0.06rem solid #e4e4e4;
//   font-family: "SukhumvitSet-Text";
//   font-size: 2.13rem;
//   letter-spacing: 0.02rem;
//   line-height: 3.38rem;
//   color: #212121;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   margin: 0 0.25rem;
// `;
// const NumDiv = styled.div`
//   margin: 0 1rem;
//   display: flex;
//   @media (max-width: 1024px) {
//     margin: 1.5rem auto 0;
//   }
// `;
// const CountRow = styled.div`
//   display: flex;
//   align-items: center;
//   margin-top: 5.38rem;
//   height: 3.06rem;
//   @media (max-width: 1024px) {
//     flex-direction: column;
//     margin-top: 2rem;
//     height: auto;
//   }
// `;
const LeftDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const ImgFlag = styled(ImageFlag)`
  @media (max-width: 1024px) {
    width: 18rem;
  }
`;

export default function CountSection() {
  // const [data, setData] = useState([0, 0, 0, 0, 0]);

  // useEffect(() => {
  //   async function fetchData() {
  //     const res = await getCountStatus();
  //     var Data = res.data.verified ? res.data.verified.toString() : "0";
  //     var Split = Data.split("");
  //     setData([
  //       Split[Split.length - 5],
  //       Split[Split.length - 4],
  //       Split[Split.length - 3],
  //       Split[Split.length - 2],
  //       Split[Split.length - 1]
  //     ]);
  //   }
  //   fetchData();
  // }, []);

  return (
    <Root id="root">
      <Container id="container">
        <LeftDiv id="left-div">
          <BigText id="big-txt">
            VerME เชื่อว่าทุกคน <br /> สามารถเป็นผู้ขายได้
          </BigText>
          {/* <CountRow id="count-row">
            <SmallText id="sm-txt">ตอนนี้ผู้ใช้บริการ VerME</SmallText>
            <NumDiv id="num-div">
              <NumBox id="num-box">{data[0]}</NumBox>
              <NumBox id="num-box">{data[1]}</NumBox>
              <NumBox id="num-box">{data[2]}</NumBox>
              <NumBox id="num-box">{data[3]}</NumBox>
              <NumBox id="num-box">{data[4]}</NumBox>
            </NumDiv>
            <SmallText id="sm-txt" mobile="true">
              คน
            </SmallText>
          </CountRow> */}
        </LeftDiv>
        <ImgFlag id="img-flag" />
      </Container>
    </Root>
  );
}
