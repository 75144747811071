import React, { useState, useRef } from "react";
import { withStyles } from "@material-ui/core/styles";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import moment from "moment";
import InputAdornment from "@material-ui/core/InputAdornment";
import Button from "@material-ui/core/Button";
import CheckStatus from "./CheckStatus";
import NotFound from "./NotFound";
import "firebase/auth";
import colors from "../common/Color";
import { CircularProgress } from "@material-ui/core";
import { getIdentity } from "../actions/action";

const styles = theme => ({
  root: {
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "4.25rem"
    }
  },
  textInputAdornment: {
    color: "#000"
  },
  button: {
    backgroundColor: colors.primary,
    width: "18.75rem",
    height: "2.25rem",
    marginTop: "1rem",
    color: "#fff",
    border: "0",
    boxShadow: "0 0.25rem 0.5rem 0 rgba(0,0,0,0.20)",
    fontFamily: "SukhumvitSet-Semibold",
    fontSize: "0.88rem",
    letterSpacing: "0.08rem",
    lineHeight: "1rem",
    "&:hover": {
      backgroundColor: colors.primaryLight
    },
    [theme.breakpoints.down("sm")]: {
      width: "17rem",
      height: "2.25rem"
    }
  },
  textfield: {
    [theme.breakpoints.down("sm")]: {
      width: "17rem"
    }
  },
  formSize: {
    padding: "0 5rem 0 5rem",
    marginTop: "2rem",
    [theme.breakpoints.down("sm")]: {
      padding: "0"
    }
  },
  successMessages: {
    border: "1px solid #4CD964"
  },
  errorMessages: {
    border: "1px solid #FF3B30"
  },
  defaultMessages: {
    border: "1px solid rgba(0, 0, 0, 0.54)"
  },
  colorLabelError: {
    color: colors.statusError
  },
  colorLabelSuccess: {
    color: "#4CD964 !important"
  },
  colorLabelDefault: {
    color: "rgba(0, 0, 0, 0.54)"
  },
  errorDetailMessage: {
    fontSize: "0.75rem",
    color: colors.statusError,
    letterSpacing: "0.025rem",
    lineHeight: "1rem",
    textAlign: "left",
    fontFamily: "Sarabun-Regular"
  },
  successDetailMessage: {
    fontSize: "0.75rem",
    color: colors.statusSuccess,
    letterSpacing: "0.025rem",
    lineHeight: "1rem",
    textAlign: "left",
    fontFamily: "Sarabun-Regular"
  }
});

function TextFieldCheck(props) {
  const { classes } = props;
  const inputRef = useRef("form");
  const [data, setData] = useState({});
  const [vmId, setVmId] = useState("");
  const [isStatusCheck, setIsStatusCheck] = useState("");
  const [loading, setLoading] = useState(false);

  const handleChange = async event => {
    const target = event.target;
    if (!target.value) {
      setIsStatusCheck("empty");
    }
    const id = target.value;
    setVmId(id);
  };

  const handleSubmit = async () => {
    setLoading(true);
    if (vmId.length === 6) {
      const id = "vm-" + vmId.toLowerCase();
      try {
        const res = await getIdentity(id);
        if (res.status === 200) {
          let user = {
            data: {
              expiry: moment
                .unix(res.data.expired)
                .utc()
                .format("MM/YY"),
              id: res.data.vm_id.toUpperCase(),
              name: res.data.name.prefix.en + " " + res.data.name.data.en
            }
          };
          setData(user);
          setIsStatusCheck(res.data.status);
          setLoading(false);
        }
      } catch (err) {
        if (err.response.status === 404) {
          setIsStatusCheck("not found");
          setLoading(false);
        }
      }
    } else {
      setIsStatusCheck("not found");
      setLoading(false);
    }
  };

  return (
    <div id="root">
      <ValidatorForm
        id="validate-form"
        ref={inputRef}
        onSubmit={handleSubmit}
        onError={errors => setIsStatusCheck("empty")}
        noValidate
        className={classes.formSize}
      >
        <TextValidator
          style={{ backgroundColor: "#fff" }}
          id="vmId"
          onChange={handleChange}
          value={vmId.toUpperCase()}
          label="VerME ID"
          fullWidth
          margin="normal"
          variant="outlined"
          InputLabelProps={{
            shrink: true,
            className:
              isStatusCheck === "not found"
                ? classes.colorLabelError
                : ""
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment id="adornment" position="start">
                <span id="span" className={classes.textInputAdornment}>
                  VM&nbsp;&nbsp;&nbsp;-
                </span>
              </InputAdornment>
            ),
            classes: {
              notchedOutline:
                isStatusCheck === "not found"
                  ? classes.errorMessages
                  : classes.defaultMessages
            }
          }}
          inputProps={{
            maxLength: 6
          }}
          validators={["required"]}
          errorMessages={["กรุณากรอก VerME ID ก่อนกดตรวจสอบการยืนยันตัวตน"]}
        />
        {isStatusCheck === "not found" ? (
          <div id="error-msg" className={classes.errorDetailMessage}>
            ไม่พบผู้ขายนี้ในระบบ VerME
          </div>
        ) : isStatusCheck === "terminated" ? (
          <div id="error-msg" className={classes.errorDetailMessage}>
            การยืนยันตัวตนของผู้ขายนี้ถูกระงับการใช้งานแล้ว
          </div>
        ) : (
              ""
            )}
        <Button
          id="btn"
          variant="outlined"
          className={classes.button}
          type="submit"
          disabled={loading}
        >
          {loading ? (
            <CircularProgress
              id="circular-progress"
              size={20}
              thickness={4}
              style={{ color: "white" }}
            />
          ) : (
              "ตรวจสอบ"
            )}
        </Button>
      </ValidatorForm>
      <div id="status-div" style={{ marginTop: "2rem" }}>
        {isStatusCheck === "verified" ? (
          <CheckStatus
            id="check-status"
            data={data}
            isStatusCheck={isStatusCheck}
          ></CheckStatus>
        ) : isStatusCheck === "expired" ? (
          <CheckStatus
            id="check-status"
            data={data}
            isStatusCheck={isStatusCheck}
          ></CheckStatus>
        ) : isStatusCheck === "terminated" ? (
          <CheckStatus
            id="check-status"
            data={data}
            isStatusCheck={isStatusCheck}
          ></CheckStatus>
        ) : isStatusCheck === "checking" ? (
          <CheckStatus
            id="check-status"
            data={data}
            isStatusCheck={isStatusCheck}
          ></CheckStatus>
        ) : isStatusCheck === "not found" ? (
          <NotFound id="not-found"></NotFound>
        ) : (
                    ""
                  )}
      </div>
    </div>
  );
}
export default withStyles(styles)(TextFieldCheck);
