import React from "react";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import colors from "../common/Color";
import InputAdornment from "@material-ui/core/InputAdornment";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { ReactComponent as IconBaac } from "../assets/Icons Bank/ic_baac.svg";
import { ReactComponent as IconBnp } from "../assets/Icons Bank/ic_bnp.svg";
import { ReactComponent as IconBoa } from "../assets/Icons Bank/ic_boa.svg";
import { ReactComponent as IconBualuang } from "../assets/Icons Bank/ic_bualuang.svg";
import { ReactComponent as IconCacib } from "../assets/Icons Bank/ic_cacib.svg";
import { ReactComponent as IconCimb } from "../assets/Icons Bank/ic_cimb.svg";
import { ReactComponent as IconCiti } from "../assets/Icons Bank/ic_citi.svg";
import { ReactComponent as IconDb } from "../assets/Icons Bank/ic_db.svg";
import { ReactComponent as IconGhb } from "../assets/Icons Bank/ic_ghb.svg";
import { ReactComponent as IconGsb } from "../assets/Icons Bank/ic_gsb.svg";
import { ReactComponent as IconHsbc } from "../assets/Icons Bank/ic_hsbc.svg";
import { ReactComponent as IconIcbc } from "../assets/Icons Bank/ic_icbc.svg";
import { ReactComponent as IconIsbt } from "../assets/Icons Bank/ic_isbt.svg";
import { ReactComponent as IconJpm } from "../assets/Icons Bank/ic_jpm.svg";
import { ReactComponent as IconKbank } from "../assets/Icons Bank/ic_kbank.svg";
import { ReactComponent as IconKkp } from "../assets/Icons Bank/ic_kkp.svg";
import { ReactComponent as IconKrungsri } from "../assets/Icons Bank/ic_krungsri.svg";
import { ReactComponent as IconKtb } from "../assets/Icons Bank/ic_ktb.svg";
import { ReactComponent as IconLh } from "../assets/Icons Bank/ic_lh.svg";
import { ReactComponent as IconMb } from "../assets/Icons Bank/ic_mb.svg";
import { ReactComponent as IconMega } from "../assets/Icons Bank/ic_mega.svg";
import { ReactComponent as IconMufg } from "../assets/Icons Bank/ic_mufg.svg";
import { ReactComponent as IconRbs } from "../assets/Icons Bank/ic_rbs.svg";
import { ReactComponent as IconSc } from "../assets/Icons Bank/ic_sc.svg";
import { ReactComponent as IconScb } from "../assets/Icons Bank/ic_scb.svg";
import { ReactComponent as IconSmbc } from "../assets/Icons Bank/ic_smbc.svg";
import { ReactComponent as IconTbank } from "../assets/Icons Bank/ic_tbank.svg";
import { ReactComponent as IconTcd } from "../assets/Icons Bank/ic_tcd.svg";
import { ReactComponent as IconTisco } from "../assets/Icons Bank/ic_tisco.svg";
import { ReactComponent as IconTmb } from "../assets/Icons Bank/ic_tmb.svg";
import { ReactComponent as IconUob } from "../assets/Icons Bank/ic_uob.svg";
import ErrorMessage from "../common/ErrorMessage";
import IconAdorn from "../common/IconAdornment";

export const Bank = [
  {
    option: "ธนาคารกสิกรไทย"
  },
  {
    option: "ธนาคารกรุงเทพ"
  },
  {
    option: "ธนาคารไทยพาณิชย์"
  },
  {
    option: "ธนาคารทหารไทย"
  },
  {
    option: "ธนาคารกรุงศรีอยุธยา"
  },
  {
    option: "ธนาคารกรุงไทย"
  },
  {
    option: "ธนาคารเกียรตินาคิน"
  },
  {
    option: "ธนาคารยูโอบี"
  },
  {
    option: "ธนาคารออมสิน"
  },
  {
    option: "ธนาคารเพื่อการเกษตรและสหกรณ์ (ธ.ก.ส.)"
  },
  {
    option: "ธนาคารธนชาต"
  },
  {
    option: "ธนาคารอิสลามแห่งประเทศไทย"
  },
  {
    option: "ธนาคารทิสโก้"
  },
  {
    option: "ธนาคารฮ่องกงและเซี่ยงไฮ้ (HSBC)"
  },
  {
    option: "ธนาคารสแตนดาร์ดชาร์เตอร์ด (ไทย)"
  },
  {
    option: "ธนาคารซิตี้แบงก์"
  },
  {
    option: "ธนาคารอาคารสงเคราะห์"
  },
  {
    option: "ธนาคารซูมิโตโม มิตซุย"
  },
  {
    option: "ธนาคารซีไอเอ็มบี (CIMB)"
  },
  {
    option: "ธนาคารเมกะ สากลพาณิชย์"
  },
  {
    option: "ธนาคารแห่งอเมริกา เนชั่นแนลฯ"
  },
  {
    option: "ธนาคารมิซูโฮ"
  },
  {
    option: "ธนาคารไอซีบีซี"
  },
  {
    option: "ธนาคารไทยเครดิต เพื่อรายย่อย"
  },
  {
    option: "ธนาคารแลนด์แอนด์เฮ้าส์"
  },
  {
    option: "ธนาคารบีเอ็นพี พารีบาส์"
  },
  {
    option: "ธนาคารดอยซ์แบงก์"
  },
  {
    option: "ธนาคารเครดิต อากริโคล"
  },
  {
    option: "รอยัล แบงก์ ออฟ สกอตแลนด์"
  },
  {
    option: "เจพีมอร์แกนเชส"
  },
  {
    option: "ธนาคารโตเกียว-มิตซูบิชิ ยูเอฟเจ"
  }
];

export const StyledTextField = styled(TextField)`
  & .MuiTypography-body1 {
    font-family: "Sarabun-Regular";
    color: ${colors.black};
    opacity: 0.87;
    letter-spacing: 0.03rem;
    line-height: 1.75rem;
  }
  & .MuiFormLabel-root {
    border-color: ${colors.black};
  }
  & .MuiFormLabel-root.Mui-focused {
    color: ${colors.primary};
  }
  & .MuiOutlinedInput-root.Mui-focused fieldset {
    border-color: ${colors.primary};
    opacity: 1;
  }
  & .MuiOutlinedInput-root fieldset {
    border-color: ${colors.black};
    opacity: 0.38;
  }
  & .MuiOutlinedInput-adornedStart {
    padding-left: 0.5rem;
  }
  & .MuiSelect-selectMenu {
    display: flex;
    align-items: center;
    height: 1.2rem;
  }
  width: 100%;
  height: ${props => (props.primary ? "" : "3.5rem")};
  @media (min-width: 960px) {
    width: ${props => (props.primary ? "95%" : "100%")};
  }
`;
const ItemText = styled(ListItemText)`
  & .MuiTypography-body1 {
    font-family: "Sarabun-Regular";
    color: ${colors.black};
    opacity: 0.87;
    letter-spacing: 0.03rem;
    line-height: 1.75rem;
  }
`;
const Root = styled.div`
  width: 100%;
`;

export default function BankItem(props) {
  // const [bankAccNumber, setBankAccNumber] = useState("")
  const handleChangeBank = event => {
    props.editBankData(
      {
        bankName: event.target.value,
        bankNumber: props.bankData.bankNumber
      },
      props.indexData
    );
  };

  const handleChangeBankData = e => {
    // let value = e.target.value;
    // value = value.replace(/[^0-9]/gi, "");
    props.editBankData(
      {
        bankName: props.bankData.bankName,
        bankNumber: e.target.value
      },
      props.indexData
    );
    // setBankAccNumber(value)
  };

  return (
    <Root id="root">
      <Grid container item xs={12} id="container-item">
        <Grid item md={6} xs={12} id="item-1">
          <StyledTextField
            id="bankName"
            primary="true"
            name="bankName"
            select
            label="ธนาคาร"
            value={props.bankData.bankName}
            onChange={handleChangeBank}
            margin="normal"
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment id="adornment" position="start">
                  <IconAdorn id="disabled" disabled />
                </InputAdornment>
              )
            }}
          >
            {Bank.map((item, index) => (
              <MenuItem
                id="menu-item"
                key={index}
                value={item.option}
                style={{ display: "flex" }}
              >
                <ListItemIcon id="list-icon">
                  {index === 0 ? (
                    <IconKbank id="ic-kbank" />
                  ) : index === 1 ? (
                    <IconBualuang id="ic-bl" />
                  ) : index === 2 ? (
                    <IconScb id="ic-scb" />
                  ) : index === 3 ? (
                    <IconTmb id="ic-tmb" />
                  ) : index === 4 ? (
                    <IconKrungsri id="ic-ks" />
                  ) : index === 5 ? (
                    <IconKtb id="ic-ktb" />
                  ) : index === 6 ? (
                    <IconKkp id="ic-kkp" />
                  ) : index === 7 ? (
                    <IconUob id="ic-uob" />
                  ) : index === 8 ? (
                    <IconGsb id="ic-gsb" />
                  ) : index === 9 ? (
                    <IconBaac id="ic-baac" />
                  ) : index === 10 ? (
                    <IconTbank id="ic-tbank" />
                  ) : index === 11 ? (
                    <IconIsbt id="ic-isbt" />
                  ) : index === 12 ? (
                    <IconTisco id="ic-tisco" />
                  ) : index === 13 ? (
                    <IconHsbc id="ic-hsbc" />
                  ) : index === 14 ? (
                    <IconSc id="ic-sc" />
                  ) : index === 15 ? (
                    <IconCiti id="ic-citi" />
                  ) : index === 16 ? (
                    <IconGhb id="ic-ghb" />
                  ) : index === 17 ? (
                    <IconSmbc id="ic-smbc" />
                  ) : index === 18 ? (
                    <IconCimb id="ic-cimb" />
                  ) : index === 19 ? (
                    <IconMega id="ic-mega" />
                  ) : index === 20 ? (
                    <IconBoa id="ic-boa" />
                  ) : index === 21 ? (
                    <IconMb id="ic-mb" />
                  ) : index === 22 ? (
                    <IconIcbc id="ic-icbc" />
                  ) : index === 23 ? (
                    <IconTcd id="ic-tcd" />
                  ) : index === 24 ? (
                    <IconLh id="ic-lh" />
                  ) : index === 25 ? (
                    <IconBnp id="ic-bnp" />
                  ) : index === 26 ? (
                    <IconDb id="ic-db" />
                  ) : index === 27 ? (
                    <IconCacib id="ic-cacib" />
                  ) : index === 28 ? (
                    <IconRbs id="ic-rbs" />
                  ) : index === 29 ? (
                    <IconJpm id="ic-jpm" />
                  ) : index === 30 ? (
                    <IconMufg id="ic-mufg" />
                  ) : (
                    ""
                  )}
                </ListItemIcon>
                <ItemText id="item-txt">{item.option}</ItemText>
              </MenuItem>
            ))}
          </StyledTextField>
        </Grid>
        <Grid item md={6} xs={12} id="grid-item">
          <StyledTextField
            id={props.indexData.toString()}
            label="เลขที่บัญชี"
            name="bankNumber"
            placeholder="เลขที่บัญชี"
            margin="normal"
            variant="outlined"
            inputRef={props.formProps.register}
            error={!!props.formProps.errors.bankNumber}
            value={props.bankData.bankNumber}
            onChange={handleChangeBankData}
            InputProps={{
              startAdornment: (
                <InputAdornment id="adornment" position="start">
                  <IconAdorn id="disabled" disabled />
                </InputAdornment>
              )
            }}
          />
          {props.formProps.errors.bankNumber && (
            <ErrorMessage id="err-msg">
              {props.formProps.errors.bankNumber.message}
            </ErrorMessage>
          )}
        </Grid>
      </Grid>
    </Root>
  );
}
