import styled from "styled-components";
import colors from "../common/Color";

const BlueText = styled.div`
  font-family: "Sarabun-Regular";
  font-size: ${props => (props.primary ? "0.88rem" : "1rem")};
  color: ${colors.primary};
  letter-spacing: 0.03rem;
  line-height: 1.75rem;
`;
export default BlueText;
