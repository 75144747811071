import React, { useState } from "react";
import styled from "styled-components";
import Button from "@material-ui/core/Button";
import colors from "../common/Color";
import { ReactComponent as IconCheck } from "../assets/Icons/ic_check_single.svg";
import TitlePricing from "../Homepage/TitleText";
import { ReactComponent as Badge } from "../assets/Images/badge-special-price.svg";
import { ReactComponent as BadgeMobile } from "../assets/Images/badge_special_price_mobile.svg";
import PricingBox from "./PricingBox";
import BackgroundImage from "../assets/Background/bg_curve_02.svg";
import Divider from "../Homepage/Divider";

const content = [
  "VerME Card",
  "การเก็บรักษาข้อมูลส่วนตัวของคุณ",
  "จบการขายกับลูกค้าได้ไวขึ้น",
  "เป็นส่วนหนึ่งของสังคมซื้อขายที่โปร่งใส"
];
const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin-top: 10rem; */
  /* background-image: url(${BackgroundImage}); */
  /* background-position: top;
  background-size: cover; */
  padding-bottom: 8.69rem;
  padding-top: 2.5%;
  @media (max-width: 1024px){
        /* margin-top: 8rem; */
        padding: 10% 20px;
        padding-bottom: 4rem;
  }
`;
const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 3.31rem;
`;
const ContentPricing = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 4.56rem;
  @media (max-width: 1024px) {
    flex-direction: column-reverse;
    margin-top: 3.5rem;
  }
  @media (max-width: 450px) {
    margin-top: 2rem;
  }
`;
const ContentContainer = styled.div`
  display: block;
  margin: auto;
`;
const MonthButton = styled(Button)`
  && {
    width: 7.5rem;
    height: 3.38rem;
    border-radius: 1.69rem;
    color: ${colors.black};
    opacity: 0.87;
    font-family: "SukhumvitSet-SemiBold";
    font-size: 1.25rem;
    letter-spacing: 0.02rem;
    line-height: 1.94rem;
    margin-left: 3rem;
    &:hover {
      color: ${colors.primary};
      background-color: rgba(59, 84, 212, 0.12);
      opacity: 1;
    }
    @media (max-width: 1024px) {
      margin-left: 0.63rem;
      font-size: 0.75rem;
      width: 3.75rem;
      height: 2.75rem;
    }
    @media (max-width: 450px) {
      letter-spacing: 0.01rem;
      line-height: 1.63rem;
      margin-left: 1rem;
    }
  }
`;
const ActiveButton = styled(Button)`
  && {
    width: 7.5rem;
    height: 3.38rem;
    border-radius: 1.69rem;
    font-family: "SukhumvitSet-SemiBold";
    font-size: 1.25rem;
    letter-spacing: 0.02rem;
    line-height: 1.94rem;
    margin-left: 3rem;
    color: ${colors.primary};
    background-color: rgba(59, 84, 212, 0.12);
    position: relative;
    &:hover {
      color: ${colors.primary};
      background-color: rgba(59, 84, 212, 0.12);
      opacity: 1;
    }
    @media (max-width: 1024px) {
      margin-left: 0.63rem;
      font-size: 0.75rem;
      width: 3.75rem;
      height: 2.75rem;
    }
    @media (max-width: 450px) {
      letter-spacing: 0.01rem;
      line-height: 1.63rem;
      margin-left: 1.25rem;
    }
  }
`;
const ContentTitle = styled.div`
  font-family: "SukhumvitSet-Bold";
  font-size: 1.5rem;
  color: ${colors.black};
  opacity: 0.87;
  line-height: 2.31rem;
  margin-bottom: 1.56rem;
  @media (max-width: 1024px) {
    text-align: center;
  }
`;
const Content = styled.div`
  font-family: "Sarabun-Regular";
  font-size: 1rem;
  color: ${colors.black};
  opacity: 0.87;
  letter-spacing: 0.03rem;
  line-height: 1.75rem;
  text-align: left;
  @media (max-width: 1024px) {
    font-family: "Sarabun-Light";
  }
`;
const IconChecking = styled(IconCheck)`
  fill: ${colors.black};
  opacity: 0.87;
  font-size: 1.75rem;
  margin-right: 1.5rem;
`;
const ContentRow = styled.div`
  display: flex;
  margin-bottom: 0.5rem;
`;
const StyledBadge = styled(Badge)`
  top: -2.5rem;
  position: absolute;
  @media (max-width: 1024px) {
    display: none;
  }
`;
const StyledBadgeMob = styled(BadgeMobile)`
  top: -3.2rem;
  right: 0;
  position: absolute;
  @media (min-width: 1025px) {
    display: none;
  }
`;
const YearPriceDiv = styled.div`
  position: relative;
`;
const SubTitle = styled.div`
  font-family: "SukhumvitSet-Semibold";
  font-size: 1.25rem;
  color: #000;
  opacity: 0.6;
  letter-spacing: 0.02rem;
  line-height: 1.94rem;
  text-align: center;
  @media (max-width: 1024px) {
    font-family: "SukhumvitSet-Text";
    font-size: 1rem;
    letter-spacing: 0.03rem;
    line-height: 1.75rem;
  }
`;
const TitleDiv = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 0.25rem;
  @media (max-width: 1024px) {
    display: block;
  }
  @media (max-width: 450px) {
    margin-bottom: 1rem;
  }
`;

export default function PricingDetail(props) {
  const [oneMonth, setOneMonth] = useState(false);
  const [fourMonth, setFourMonth] = useState(false);
  const [twelveMonth, setTwelveMonth] = useState(true);

  const handleOneMonth = () => {
    if (oneMonth === false) {
      setOneMonth(true);
      setFourMonth(false);
      setTwelveMonth(false);
    }
  };
  const handleFourMonth = () => {
    if (fourMonth === false) {
      setOneMonth(false);
      setFourMonth(true);
      setTwelveMonth(false);
    }
  };
  const handleTwelveMonth = () => {
    if (twelveMonth === false) {
      setOneMonth(false);
      setFourMonth(false);
      setTwelveMonth(true);
    }
  };

  return (
    <Root id="root">
      {props.props === "homepage" ? (
        <div id="title-container">
          <TitleDiv id="title-div">
            <TitlePricing id="title">
              ทดลองใช้ <br /> VerME ฟรี 1 เดือน
            </TitlePricing>
          </TitleDiv>
          <Divider id="divider" />
          <TitleDiv id="title-div">
            <SubTitle id="subtitle">
              ไม่ต้องต่อบัตรเครดิต ใช้บริการได้ฟรีเต็มรูปแบบ
              และสามารถต่ออายุได้หลังจากช่วงทดลองใช้
            </SubTitle>
          </TitleDiv>
        </div>
      ) : (
        <div id="title-container">
          <TitleDiv>
            <TitlePricing>ทดลองใช้ VerME ฟรี 1 เดือน</TitlePricing>
          </TitleDiv>
          <TitleDiv>
            <SubTitle>
              ไม่ต้องต่อบัตรเครดิต ใช้บริการได้ฟรีเต็มรูปแบบ
              และสามารถต่ออายุได้หลังจากช่วงทดลองใช้
            </SubTitle>
          </TitleDiv>
        </div>
      )}

      <ButtonContainer id="btn-container">
        {oneMonth === false ? (
          <MonthButton id="mn-btn" onClick={handleOneMonth}>
            1 เดือน
          </MonthButton>
        ) : (
          <ActiveButton id="active-btn" onClick={handleOneMonth}>
            1 เดือน
          </ActiveButton>
        )}
        {fourMonth === false ? (
          <MonthButton id="mn-btn" onClick={handleFourMonth}>
            4 เดือน
          </MonthButton>
        ) : (
          <ActiveButton id="active-btn" onClick={handleFourMonth}>
            4 เดือน
          </ActiveButton>
        )}
        <YearPriceDiv id="year-div">
          {twelveMonth === false ? (
            <MonthButton id="mn-btn" onClick={handleTwelveMonth}>
              12 เดือน
            </MonthButton>
          ) : (
            <ActiveButton id="active-btn" onClick={handleTwelveMonth}>
              12 เดือน
            </ActiveButton>
          )}
          <StyledBadge id="badge" />
          <StyledBadgeMob id="badge-mb" />
        </YearPriceDiv>
      </ButtonContainer>
      <ContentPricing id="content-price">
        <ContentContainer id="content-container">
          <ContentTitle id="ct-title">คุณจะได้อะไรบ้างจาก VerME?</ContentTitle>
          {content.map((item, index) => (
            <ContentRow id="ct-row" key={index}>
              <IconChecking id="check-ic" />
              <Content id="content">{item}</Content>
            </ContentRow>
          ))}
        </ContentContainer>
        <PricingBox
          id="pricing-box"
          oneMonth={oneMonth}
          fourMonth={fourMonth}
          twelveMonth={twelveMonth}
        />
      </ContentPricing>
    </Root>
  );
}
