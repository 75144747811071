import React, { useState } from "react";
import styled from "styled-components";
import colors from "./Color";
import { ReactComponent as LogoVerme } from "../assets/Logo/logo_verme_white.svg";
import { ReactComponent as IconFacebook } from "../assets/Icons/Icons Black/ic_social_facebook_filled.svg";
import { ReactComponent as IconInstagram } from "../assets/Icons/Icons Black/ic_social_instagram_filled.svg";
import { ReactComponent as IconTwitter } from "../assets/Icons/Icons Black/ic_social_twitter_filled.svg";
import { ReactComponent as IconDropDown } from "../assets/Icons/ic_dropdown.svg";
import Linker from "@material-ui/core/Link";
import { Link, animateScroll as scroll } from "react-scroll";
import { makeStyles } from "@material-ui/core/styles";

const Desktop = styled.div`
  width: 100%;
  height: 24.88rem;
  background-color: #2b2c34;
  left: 0;
  bottom: 0;
  @media (max-width: 900px) {
    display: none;
  }
`;
const UpperContainer = styled.div`
  width: 80%;
  height: 19.81rem;
  display: flex;
  justify-content: space-between;
  margin: auto 10%;
`;
const LowerContainer = styled.div`
  width: 100%;
  height: 5rem;
  border-top: 0.06rem solid rgba(256, 256, 256, 0.12);
`;
const LowerDiv = styled.div`
  margin: auto 10%;
  height: 5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Div = styled.div`
  margin-top: 4.5rem;
  @media (max-width: 900px) {
    margin: 4rem auto 0;
  }
`;
const SocialDiv = styled.div`
  display: flex;
  margin-top: 1rem;
  @media (max-width: 900px) {
    justify-content: center;
  }
`;
const Logo = styled(LogoVerme)`
  width: 7.63rem;
  height: 2rem;
  margin-bottom: 1rem;
  @media (max-width: 900px) {
    margin: 0 28% 1rem;
  }
`;
const IconFb = styled(IconFacebook)`
  font-size: 1.75rem;
  fill: ${colors.white};
  cursor: pointer;
  margin-right: 1.25rem;
`;
const IconIg = styled(IconInstagram)`
  font-size: 1.75rem;
  fill: ${colors.white};
  cursor: pointer;
  margin-right: 1.25rem;
`;
const IconTwit = styled(IconTwitter)`
  font-size: 1.75rem;
  fill: ${colors.white};
  cursor: pointer;
`;
const SloganText = styled.div`
  font-family: "Sarabun-Light";
  font-size: 1rem;
  letter-spacing: 0.03rem;
  line-height: 1.75rem;
  color: ${colors.white};
  opacity: 0.87;
  @media (max-width: 900px) {
    text-align: center;
  }
`;
const Title = styled.div`
  font-family: "Sarabun-Regular";
  font-size: 1.25rem;
  letter-spacing: 0.02rem;
  line-height: 1.63rem;
  color: ${colors.white};
  opacity: 0.87;
  @media (max-width: 900px) {
    font-size: 1rem;
  }
`;
const SubTitle = styled(Linker)`
  && {
    font-family: "Sarabun-Light";
    font-size: 1rem;
    letter-spacing: 0.03rem;
    line-height: 1.75rem;
    color: ${colors.white};
    opacity: 0.6;
    margin-bottom: 0.25rem;
    &:hover {
      opacity: 0.87;
    }
    cursor: pointer;
  }
`;
const useStyles = makeStyles({
  btn: {
    marginBottom: "0.25rem",
    fontFamily: "Sarabun-Light",
    fontSize: "1rem",
    letterSpacing: "0.03rem",
    lineHeight: "1.75rem",
    color: "#fff",
    opacity: "0.6",
    cursor: "pointer",
    "&:hover": {
      opacity: "0.87"
    }
  }
});
const Credit = styled.div`
  font-family: "Sarabun-Semibold";
  font-size: 0.8rem;
  letter-spacing: 0.03rem;
  line-height: 1.4rem;
  color: #b7b7ba;
  opacity: 0.56;
  @media (max-width: 900px) {
    margin-bottom: 0.63rem;
    text-align: center;
  }
`;
const Divider = styled.div`
  width: 10rem;
  height: 0.13rem;
  background-color: #d8d8d8;
  opacity: 0.2;
  margin: 0.56rem 0 0.75rem;
`;
const ContentContainer = styled.div`
  display: flex;
  margin-top: 4.5rem;
`;
const Content = styled.div`
  margin-left: 4.94rem;
  @media (max-width: 1280px) {
    margin-left: 3.5rem;
  }
`;
const Mobile = styled.div`
  width: 100%;
  background-color: #2b2c34;
  left: 0;
  bottom: 0;
  @media (min-width: 901px) {
    display: none;
  }
`;
const UpperMobile = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 5%;
`;
const LowerMobile = styled.div`
  width: 100%;
  height: 7.31rem;
  border-top: 0.06rem solid rgba(256, 256, 256, 0.12);
`;
const LowerDivMobile = styled.div`
  margin: auto 5%;
  height: 7.31rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const ContentMobile = styled.div`
  margin-top: 1.5rem;
`;
const CollapseBox = styled.div`
  border-top: 0.06rem solid rgba(216, 216, 216, 0.5);
`;
const CollapseTab = styled.div`
  width: 96%;
  margin: 1.66rem auto;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
`;
const CollapseContent = styled.div`
  margin: 0 0 1.66rem 1.5rem;
  display: ${({ iscollapse }) => (iscollapse === "true" ? "block" : "none")};
`;
const IcDropdown = styled(IconDropDown)`
  font-size: 1.5rem;
  fill: ${colors.white};
  cursor: pointer;
  transform: ${({ iscollapse }) =>
    iscollapse === "true" ? "rotate(180deg)" : "rotate(0deg)"};
  transition: all 0.3s ease-in;
`;
const content = [
  {
    title: "เกี่ยวกับ VerME",
    subtitle: [
      {
        name: "",
        path: ""
      }
    ],
    linkscroll: [
      { name: "VerME คืออะไร?", path: "main-section" },
      { name: "สำหรับผู้ขาย", path: "seller-section" },
      { name: "สำหรับผู้ซื้อ", path: "buyer-section" }
    ],
    nothomepage: [
      { name: "VerME คืออะไร?", path: "/" },
      { name: "สำหรับผู้ขาย", path: "/" },
      { name: "สำหรับผู้ซื้อ", path: "/" }
    ],
    status: false
  },
  {
    title: "บริการของเรา",
    subtitle: [
      { name: "สมัครใช้บริการ", path: "/prepare-before-register" },
      { name: "ตรวจสอบตัวตน", path: "/identity-check" },
      { name: "รายงานการโกง", path: "/report-form" }
    ],
    linkscroll: [{ name: "ค่าบริการ", path: "price-section" }],
    nothomepage: [
      { name: "สมัครใช้บริการ", path: "/prepare-before-register" },
      { name: "ตรวจสอบตัวตน", path: "/identity-check" },
      { name: "รายงานการโกง", path: "/report-form" },
      { name: "ค่าบริการ", path: "/pricing" }
    ],
    status: false
  },
  {
    title: "อื่นๆ",
    subtitle: [
      { name: "นโยบายและข้อตกลง", path: "/policy" },
      {
        name: "คำถามที่พบบ่อย",
        path: "/faq"
      }
    ],
    linkscroll: [{ name: "", path: "" }],
    nothomepage: [
      { name: "นโยบายและข้อตกลง", path: "/policy" },
      {
        name: "คำถามที่พบบ่อย",
        path: "/faq"
      }
    ],
    status: false
  }
];

export default function FooterBar(props) {
  const classes = useStyles();
  const [isCollapse, setIsCollapse] = useState([
    {
      title: "เกี่ยวกับ VerME",
      subtitle: [
        {
          name: "",
          path: ""
        }
      ],
      linkscroll: [
        { name: "VerME คืออะไร?", path: "main-section" },
        { name: "สำหรับผู้ขาย", path: "seller-section" },
        { name: "สำหรับผู้ซื้อ", path: "buyer-section" }
      ],
      nothomepage: [
        { name: "VerME คืออะไร?", path: "/" },
        { name: "สำหรับผู้ขาย", path: "/" },
        { name: "สำหรับผู้ซื้อ", path: "/" }
      ],
      status: false
    },
    {
      title: "บริการของเรา",
      subtitle: [
        { name: "สมัครใช้บริการ", path: "/prepare-before-register" },
        { name: "ตรวจสอบตัวตน", path: "/identity-check" },
        { name: "รายงานการโกง", path: "/report-form" }
      ],
      linkscroll: [{ name: "ค่าบริการ", path: "price-section" }],
      nothomepage: [
        { name: "สมัครใช้บริการ", path: "/prepare-before-register" },
        { name: "ตรวจสอบตัวตน", path: "/identity-check" },
        { name: "รายงานการโกง", path: "/report-form" },
        { name: "ค่าบริการ", path: "/pricing" }
      ],
      status: false
    },
    {
      title: "อื่นๆ",
      subtitle: [
        { name: "นโยบายและข้อตกลง", path: "/policy" },
        {
          name: "คำถามที่พบบ่อย",
          path: "/faq"
        }
      ],
      linkscroll: [{ name: "", path: "" }],
      nothomepage: [
        { name: "นโยบายและข้อตกลง", path: "/policy" },
        {
          name: "คำถามที่พบบ่อย",
          path: "/faq"
        }
      ],
      status: false
    }
  ]);
  const handleCollapse = index => {
    var newVal = [...isCollapse];
    var checkVal = newVal.map((itemData, indexData) => {
      if (indexData !== index) {
        itemData.status = false;
      } else {
        itemData.status = !itemData.status;
      }
      return itemData;
    });
    setIsCollapse(checkVal);
  };
  const scrollToTop = () => {
    scroll.scrollToTop();
  };
  return (
    <div id="root">
      <Desktop id="desktop-div">
        <UpperContainer id="upper-ctn">
          <Div id="st-div">
            {props.props === "homepage" ? (
              <Logo id="logo" onClick={scrollToTop} />
            ) : (
              <a id="link" href="/">
                <Logo id="logo" />
              </a>
            )}
            <SloganText id="slogan">
              เราจะช่วยให้การซื้อขายบนโซเชียลมีเดียของคุณ <br />{" "}
              มีความเชื่อมั่นและปลอดภัยมากขึ้น
            </SloganText>
            <SocialDiv id="social-div">
              <a
                id="link"
                href="https://www.facebook.com/verme.me/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <IconFb id="ic-fb" />
              </a>
              <a
                id="link"
                href="https://www.instagram.com/verifyme.me/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <IconIg id="ic-ig" />
              </a>
              <a
                id="link"
                href="https://twitter.com/verme_me"
                target="_blank"
                rel="noopener noreferrer"
              >
                <IconTwit id="ic-twt" />
              </a>
            </SocialDiv>
          </Div>
          {props.props === "nothomepage" ? (
            <ContentContainer id="content-ctn">
              {content.map((item, index) => (
                <Content id="content" key={index}>
                  <Title id="title">{item.title}</Title>
                  <Divider id="divider" />
                  {item.nothomepage.map(({ name, path }, i) => (
                    <div id="subtitle-div" key={i}>
                      <SubTitle id="subtitle" href={path} underline="none">
                        {name}
                      </SubTitle>
                    </div>
                  ))}
                </Content>
              ))}
            </ContentContainer>
          ) : (
            <ContentContainer id="content-ctn">
              {content.map((item, index) => (
                <Content id="content" key={index}>
                  <Title id="title">{item.title}</Title>
                  <Divider id="divider" />
                  {item.subtitle.map(({ name, path }, i) => (
                    <div id="subtitle-div" key={i}>
                      <SubTitle id="subtitle" href={path} underline="none">
                        {name}
                      </SubTitle>
                    </div>
                  ))}
                  {item.linkscroll.map(({ name, path }, i) => (
                    <div id="link-div" key={i}>
                      <Link
                        id="linker"
                        to={path}
                        className={classes.btn}
                        spy={true}
                        smooth={true}
                        duration={500}
                      >
                        {name}
                      </Link>
                    </div>
                  ))}
                </Content>
              ))}
            </ContentContainer>
          )}
        </UpperContainer>
        <LowerContainer id="lower-ctn">
          <LowerDiv id="lower-div">
            <Credit id="credit">
              Copyright © 2020 VerME.me All rights reserved.
            </Credit>
            <Credit id="credit">Resource by Freepik.com</Credit>
          </LowerDiv>
        </LowerContainer>
      </Desktop>
      <Mobile id="mobile-div">
        <UpperMobile id="upper-mb">
          <Div id="st-div">
            <Logo id="logo" />
            <SloganText id="slogan">
              เราจะช่วยให้การซื้อขายบนโซเชียลมีเดีย <br /> ของคุณ
              มีความเชื่อมั่นและปลอดภัยมากขึ้น
            </SloganText>
            <SocialDiv id="social-div">
              <a
                id="link"
                href="https://www.facebook.com/verme.me/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <IconFb id="ic-fb" />
              </a>
              <a
                id="link"
                href="https://www.instagram.com/verifyme.me/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <IconIg id="ic-ig" />
              </a>
              <a
                id="link"
                href="https://twitter.com/verme_me"
                target="_blank"
                rel="noopener noreferrer"
              >
                <IconTwit id="ic-twt" />
              </a>
            </SocialDiv>
            {props.props === "nothomepage" ? (
              <ContentMobile id="content-mb">
                {isCollapse.map((item, index) => (
                  <CollapseBox id="collapse-box" key={index}>
                    <CollapseTab id="collapse-tab">
                      <Title id="title">{item.title}</Title>
                      <div id="ic-div">
                        <IcDropdown
                          id="ic-dropdown"
                          onClick={() => handleCollapse(index)}
                          iscollapse={item.status.toString()}
                        />
                      </div>
                    </CollapseTab>
                    <CollapseContent
                      id="collapse-ct"
                      iscollapse={item.status.toString()}
                    >
                      {item.nothomepage.map(({ name, path }, i) => (
                        <div id="subtitle-div" key={i}>
                          <SubTitle id="subtitle" href={path} underline="none">
                            {name}
                          </SubTitle>
                        </div>
                      ))}
                    </CollapseContent>
                  </CollapseBox>
                ))}
              </ContentMobile>
            ) : (
              <ContentMobile id="content-mb">
                {isCollapse.map((item, index) => (
                  <CollapseBox id="collapse-box" key={index}>
                    <CollapseTab
                      id="collapse-tab"
                      onClick={() => handleCollapse(index)}
                    >
                      <Title id="title">{item.title}</Title>
                      <div id="ic-div">
                        <IcDropdown
                          id="ic-dropdown"
                          iscollapse={item.status.toString()}
                        />
                      </div>
                    </CollapseTab>
                    <CollapseContent
                      id="collapse-ct"
                      iscollapse={item.status.toString()}
                    >
                      {item.subtitle.map(({ name, path }, i) => (
                        <div id="subtitle-div" key={i}>
                          <SubTitle id="subtitle" href={path} underline="none">
                            {name}
                          </SubTitle>
                        </div>
                      ))}
                      {item.linkscroll.map(({ name, path }, i) => (
                        <div id="link-div" key={i}>
                          <Link
                            id="linker"
                            to={path}
                            className={classes.btn}
                            spy={true}
                            smooth={true}
                            duration={500}
                          >
                            {name}
                          </Link>
                        </div>
                      ))}
                    </CollapseContent>
                  </CollapseBox>
                ))}
              </ContentMobile>
            )}
          </Div>
        </UpperMobile>
        <LowerMobile id="lower-mb">
          <LowerDivMobile id="lower-div-mb">
            <Credit id="credit">Resource by Freepik.com</Credit>
            <Credit id="credit">
              Copyright © 2020 VerME.me All rights reserved.
            </Credit>
          </LowerDivMobile>
        </LowerMobile>
      </Mobile>
    </div>
  );
}
