import React, { useState, useEffect } from "react";
import Title from "../common/Title";
import SubTitle from "./SubTitle";
import Grid from "@material-ui/core/Grid";
import BankItem from "./BankItem";
import styled from "styled-components";
import IconButton from "@material-ui/core/IconButton";
import { ReactComponent as DeleteButton } from "../assets/Icons/Icons Black/ic_cross.svg";
import colors from "../common/Color";
import Button from "@material-ui/core/Button";
import { ReactComponent as AddIcon } from "../assets/Icons/ic_add_circle.svg";

const Box = styled.div`
  width: 100%;
  height: 8.5rem;
  background: ${colors.white};
  border: 0.06rem solid #ebedfa;
  border-radius: 0.5rem;
  @media (max-width: 960px) {
    height: 14.75rem;
  }
`;
const DeleteIcon = styled(DeleteButton)`
  width: 1.5rem;
  height: 1.5rem;
`;
const IconBtn = styled(IconButton)`
  && {
    padding: 0;
  }
`;
const Blank = styled.div`
  width: 1.5rem;
  height: 1.5rem;
`;

const AddButton = styled(Button)`
  && {
    width: 100%;
    height: 4.5rem;
    border-radius: 0.25rem;
    border: 0.06rem solid #ebedfa;
    background: ${colors.white};
    display: flex;
    align-items: center;
    cursor: pointer;
    &:hover {
      background: ${colors.surfaceGray};
    }
  }
`;
const AddingIcon = styled(AddIcon)`
  font-size: 1.46rem;
  fill: ${colors.primary};
  margin-right: 1.14rem;
`;
const AddText = styled.div`
  font-family: "SukhumvitSet-Semibold";
  font-size: 0.88rem;
  letter-spacing: 0.08rem;
  line-height: 1rem;
  color: ${colors.primary};
  align-self: center;
`;
const BtnLayout = styled.div`
  display: flex;
  padding: 1rem 1.5rem;
  margin-right: auto;
`;
export default function BookBank(props) {
  const { setBookbank } = props;
  const [counter, setCounter] = useState(
    props.data[0].length ? props.data[0].length : 1
  );
  const [bankData, setBankData] = useState(
    props.data[0].length
      ? props.data[0]
      : [
          {
            bankName: "ธนาคารกสิกรไทย",
            bankNumber: ""
          }
        ]
  );

  useEffect(() => {
    setBookbank(bankData);
  },[bankData,setBookbank]);

  const editBankData = async (item, index) => {
    var newData = [...bankData];
    newData[index] = item;
    setBankData(newData);
  };

  const addBank = () => {
    setBankData(prevBankData => [
      ...prevBankData,
      { bankName: "ธนาคารกสิกรไทย", bankNumber: "" }
    ]);
    setCounter(prevCounter => prevCounter + 1);
  };

  const removeBank = index => () => {
    setBankData(prevBankData => [
      ...prevBankData.filter((item, i) => i !== index)
    ]);
    setCounter(prevCounter => prevCounter - 1);
  };
  return (
    <Grid container id="root">
      <Grid item xs={12} id="grid-title">
        <Title id="title">บัญชีธนาคาร</Title>
      </Grid>
      <Grid id="grid-subtitle" item xs={12}>
        <SubTitle id="subtitle" />
      </Grid>
      <Grid id="grid-container" container spacing={2}>
        {bankData.map((item, index) => {
          return (
            <Grid key={index} item xs={12} id="grid-item">
              <Box id="box">
                <Grid
                  id="container"
                  container
                  style={{ padding: "1rem 1.5rem" }}
                >
                  <Grid
                    id="container-flex-end"
                    container
                    justify="flex-end"
                    item
                    xs={12}
                  >
                    {(index === 0 && counter === 1) ||
                    (index > 2 && counter === 1) ? (
                      <IconBtn id="disabled" disabled>
                        <Blank id="blank" />
                      </IconBtn>
                    ) : (
                      <IconBtn id="ic-btn" onClick={removeBank(index)}>
                        <DeleteIcon id="delete-ic" />
                      </IconBtn>
                    )}
                  </Grid>
                  <BankItem
                    id="bank-item"
                    formProps={props.formProps}
                    editBankData={editBankData}
                    indexData={index}
                    bankData={item}
                  />
                </Grid>
              </Box>
            </Grid>
          );
        })}

        {counter === 3 ? (
          ""
        ) : (
          <Grid item xs={12} id="grid-item">
            <AddButton id="add-btn" onClick={addBank}>
              <BtnLayout id="btn-layout">
                <AddingIcon id="add-icon" />
                <AddText id="add-txt">เพิ่มบัญชี</AddText>
              </BtnLayout>
            </AddButton>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
