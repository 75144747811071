import React, { useState, useContext } from "react";
import styled from "styled-components";
import Button from "@material-ui/core/Button";
import { CircularProgress } from "@material-ui/core";
import colors from "../common/Color";
import Modal from "@material-ui/core/Modal";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import GrayText from "../common/GrayText";
import ErrorMessage from "../common/ErrorMessage";
import BlueText from "../common/BlueText";
import IconAdorn from "../common/IconAdornment";
import { sendCheckOTPReport } from "../actions/action";
import { RegisterContext } from "../Context/RegisterStore";

const ModalBox = styled.div`
  width: 27.88rem;
  height: 18.31rem;
  background: ${colors.white};
  border-radius: 0.5rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: auto;
  margin-top: calc(50vh - 9.15rem);
  @media (max-width: 960px) {
    width: 22.31rem;
    height: 18.88rem;
  }
`;
const BackBtn = styled(Button)`
  && {
    width: 6.56rem;
    height: 2.75rem;
    border-radius: 0.25rem;
    border: 0.06rem solid rgba(0, 0, 0, 0.12);
    font-family: "SukhumvitSet-SemiBold";
    font-size: 0.88rem;
    color: ${colors.primary};
    letter-spacing: 0.08rem;
    line-height: 1rem;
    margin: 0 0.75rem;
    @media (max-width: 960px) {
      width: 8.88rem;
      margin: 0;
    }
  }
`;
const ConfirmBtn = styled(Button)`
  && {
    width: 6.56rem;
    height: 2.75rem;
    border-radius: 0.25rem;
    background: ${colors.primary};
    font-family: "SukhumvitSet-SemiBold";
    font-size: 0.88rem;
    color: ${colors.white};
    letter-spacing: 0.08rem;
    line-height: 1rem;
    margin: 0 0.75rem;
    box-shadow: 0 0.063rem 0.063rem 0 rgba(0, 0, 0, 0.5);
    &:hover {
      background-color: ${colors.primaryDark};
    }
    @media (max-width: 960px) {
      width: 8.88rem;
      margin: 0;
    }
  }
`;
const StyledTextField = styled(TextField)`
  & .MuiTypography-body1 {
    font-family: "Sarabun-Regular";
    color: ${colors.black};
    opacity: 0.87;
    letter-spacing: 0.03rem;
    line-height: 1.75rem;
  }
  & .MuiFormLabel-root {
    border-color: ${colors.black};
  }
  & .MuiFormLabel-root.Mui-focused {
    color: ${colors.primary};
  }
  & .MuiOutlinedInput-root.Mui-focused fieldset {
    border-color: ${colors.primary};
    opacity: 1;
  }
  & .MuiOutlinedInput-root fieldset {
    border-color: ${colors.black};
    opacity: 0.38;
  }
  & .MuiOutlinedInput-adornedStart {
    padding-left: 0.5rem;
  }
  & .MuiSelect-selectMenu {
    display: flex;
    align-items: center;
    height: 1.2rem;
  }
`;
export const NextBtn = styled(Button)`
  && {
    width: 10.81rem;
    height: 2.75rem;
    background-color: ${colors.primary};
    font-family: "SukhumvitSet-Semibold";
    font-size: 0.875rem;
    line-height: 1rem;
    letter-spacing: 0.08rem;
    color: ${colors.white};
    box-shadow: 0 0.063rem 0.063rem 0 rgba(0, 0, 0, 0.5);
    &:hover {
      background-color: ${colors.primaryDark};
    }
    margin-bottom: 2rem;
    @media (max-width: 960px) {
      width: 100%;
      margin: 0 auto 6rem auto;
    }
  }
`;
const FlexRow = styled.div`
  display: flex;
`;
const FlexCol = styled.div`
  display: flex;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;
const TextGroup = styled.div`
  margin: 5%;
`;
const BtnRow = styled.div`
  display: flex;
  margin-left: auto;
  margin-right: 5%;
  @media (max-width: 960px) {
    width: 90%;
    margin: 0 auto;
    justify-content: space-between;
  }
`;
export default function OtpBtn(props) {
  const { loading, open, count, phone } = useContext(RegisterContext);
  const { handleClose, handleNext, resetTimer } = props;
  const [otp, setOtp] = useState("");
  const [errors, setErrors] = useState(false);

  async function confirmOtp() {
    loading[1](true);

    var data = {
      phone: phone[0],
      otp: otp
    };

    try {
      const res = await sendCheckOTPReport(data);
      if (res.status === 200) {
        setErrors(false);
        loading[1](false);
        handleNext();
      }
    } catch (err) {
      setErrors(true);
      loading[1](false);
    }
  }

  return (
    <div id="root">
      <Modal
        id="modal"
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open[0]}
        onClose={handleClose}
        disableEnforceFocus={true}
        disableBackdropClick={true}
      >
        <ModalBox id="modal-box">
          <StyledTextField
            id="otp"
            label="OTP"
            placeholder="กรอก OTP"
            margin="normal"
            variant="outlined"
            style={{ width: "90%", height: "3.5rem", margin: "0 auto" }}
            error
            onChange={e => setOtp(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment id="adornment" position="end">
                  <IconAdorn id="disabled-ic" disabled />
                </InputAdornment>
              )
            }}
            inputProps={{
              maxLength: 6
            }}
          />
          {errors === true ? (
            <ErrorMessage id="err-msg" otp>
              รหัส OTP ที่กรอกไม่ถูกต้อง
            </ErrorMessage>
          ) : (
            ""
          )}
          <TextGroup id="txt-group">
            <FlexCol id="flex-col">
              <GrayText id="txt-gray">
                กรุณากรอกรหัส OTP ที่ส่งไปยัง &nbsp;
              </GrayText>
              <BlueText id="txt-blue">
                +66 &nbsp;
                {phone[0].substring(1)} &nbsp;
              </BlueText>
            </FlexCol>
            <GrayText id="txt-gray">เพื่อยืนยันการลงทะเบียน</GrayText>
            <FlexRow id="flex-row">
              <GrayText id="txt-gray" primary="true">
                ไม่ได้รับรหัส OTP? &nbsp;
              </GrayText>
              {count[0] <= 0 ? (
                <BlueText
                  id="txt-blue"
                  onClick={() => resetTimer(phone[0])}
                  style={{ cursor: "pointer" }}
                  primary
                >
                  ขอรหัสอีกครั้ง
                </BlueText>
              ) : (
                <GrayText
                  id="txt-gray"
                  primary="true"
                  style={{ cursor: "default" }}
                >
                  {count[0]} วินาที
                </GrayText>
              )}
            </FlexRow>
          </TextGroup>
          <BtnRow id="btn-row">
            <BackBtn id="back-btn" onClick={handleClose}>
              ยกเลิก
            </BackBtn>
            <ConfirmBtn
              id="confirm-btn"
              onClick={() => confirmOtp()}
              disabled={loading[0]}
            >
              {loading[0] ? (
                <CircularProgress
                  id="circular-progress"
                  size={25}
                  thickness={4}
                  style={{ color: "white" }}
                />
              ) : (
                "ยืนยัน"
              )}
            </ConfirmBtn>
          </BtnRow>
        </ModalBox>
      </Modal>
    </div>
  );
}
