import React, { useState, useEffect, useRef, useContext } from "react";
import styled from "styled-components";
import colors from "./Color";
import { ReactComponent as IconDropDown } from "../assets/Icons/ic_dropdown.svg";
import Button from "@material-ui/core/Button";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { ReactComponent as IconId } from "../assets/Icons/Icons Black/ic_id_card.svg";
import { ReactComponent as IconLogout } from "../assets/Icons/Icons Black/ic_logout.svg";
import { ReactComponent as IconPerson } from "../assets/Icons/Icons Black/ic_person.svg";
import { ReactComponent as IconRenew } from "../assets/Icons/Icons Black/ic_renew.svg";
import { LoginContext } from "../Context/LoginStore";
import { useAuth } from "../use-auth";
import Skeleton from "@material-ui/lab/Skeleton";

const Root = styled.div`
  width: 11rem;
`;
const NameText = styled.div`
  opacity: ${props => (props.dark ? "0.6" : "0.87")};
  font-family: "SukhumvitSet-SemiBold";
  font-size: 1rem;
  color: #000000;
  letter-spacing: 0.03rem;
  text-align: left;
  line-height: 1.25rem;
`;
const SkeletonNameText = styled(Skeleton)`
  width: 4.44rem !important;
  height: 1.25rem !important;
`;
const Circle = styled.div`
  background: ${props => (props.big ? colors.primary : "#D1D5E8")};
  width: ${props => (props.big ? "2.5rem" : "2rem")};
  height: ${props => (props.big ? "2.5rem" : "2rem")};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "SukhumvitSet-Medium";
  font-size: ${props => (props.big ? "1.25rem" : "1rem")};
  color: rgba(255, 255, 255, 0.81);
  letter-spacing: ${props => (props.big ? "0.04rem" : "0.03rem")};
  text-align: center;
  margin-right: ${props => (props.big ? "0.75rem" : "0.5rem")};
  margin-left: ${props => (props.big ? "1rem" : "0")};
`;
const SkeletonCircle = styled(Skeleton)`
  width: ${props => (props.big ? "2.5rem !important" : "2rem !important")};
  height: ${props => (props.big ? "2.5rem !important" : "2rem !important")};
  border-radius: 50% !important;
  margin-right: ${props =>
    props.big ? "0.75rem !important" : "0.5rem !important"};
  margin-left: ${props => (props.big ? "1rem !important" : "0rem !important")};
`;
const IcDropdown = styled(IconDropDown)`
  font-size: 1rem;
  fill: rgba(0, 0, 0, 0.6);
  margin-left: 0.25rem;
`;
const ThinText = styled.div`
  opacity: ${props => (props.light ? "0.6" : "0.34")};
  font-family: "SukhumvitSet-Text";
  font-size: 14px;
  color: #000000;
  letter-spacing: 0.25px;
  text-align: left;
  line-height: 20px;
`;
const StyledBtn = styled(Button)`
  && {
    width: 11.2rem;
    height: 4rem;
    top: ${({ ismenuopen }) => (ismenuopen === "true" ? "10.4rem" : 0)};
  }
`;
const MenuList = styled.ul`
  padding-left: 0;
  width: 14.81rem;
  height: 18.69rem;
  background-color: ${colors.white};
  position: relative;
  border-radius: 0.38rem;
  border: 1px solid #e5e5e5;
  top: 10.1rem;
  right: 5.4rem;
  &:before {
    content: "";
    display: block;
    border-width: 0.7rem 0.7rem 0;
    border-style: solid;
    border-color: #e5e5e5 transparent transparent;
    left: 11rem;
    position: absolute;
    top: -0.73rem;
    transform: rotate(180deg);
  }
  &:after {
    content: "";
    display: block;
    border-width: 0.64rem 0.64rem 0;
    border-style: solid;
    border-color: #ffffff transparent transparent;
    left: 11.1rem;
    position: absolute;
    top: -0.63rem;
    transform: rotate(180deg);
  }
`;
const InfoDiv = styled.div`
  height: 5.75rem;
  border-bottom: 0.06rem solid #e5e5e5;
  display: flex;
  align-items: center;
`;
const CenterGroup = styled.div`
  height: 8.88rem;
  border-bottom: 0.06rem solid #e5e5e5;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const IcId = styled(IconId)`
  font-size: 1rem;
  fill: ${({ hover }) => (hover === "true" ? colors.primary : "rgba(0, 0, 0, 0.6)")};
`;
const IcLogout = styled(IconLogout)`
  font-size: 1rem;
  fill: ${({ hover }) => (hover === "true" ? colors.primary : "rgba(0, 0, 0, 0.6)")};
`;
const IcPerson = styled(IconPerson)`
  font-size: 1rem;
  fill: ${({ hover }) => (hover === "true" ? colors.primary : "rgba(0, 0, 0, 0.6)")};
`;
const IcRenew = styled(IconRenew)`
  font-size: 1rem;
  fill: ${({ hover }) => (hover === "true" ? colors.primary : "rgba(0, 0, 0, 0.6)")};
`;
const MenuBtn = styled(ListItem)`
  && {
    margin: ${props => (props.logout ? "0.53rem 0.25rem" : "0.25rem auto")};
    width: 14.31rem;
    height: ${props => (props.logout ? "2.81rem" : "2.38rem")};
    font-family: "SukhumvitSet-Text";
    font-size: 0.88rem;
    color: rgba(0, 0, 0, 0.6);
    letter-spacing: 0.25px;
    text-align: left;
    line-height: 20px;
    text-transform: none;
    border-radius: 0.25rem;
    cursor: pointer;
    &:hover {
      font-family: "SukhumvitSet-SemiBold";
      color: ${colors.primary};
      letter-spacing: 0.1px;
      line-height: 24px;
      background-color: RGBA(59, 84, 212, 0.04);
    }
  }
`;
const StyledListIc = styled(ListItemIcon)`
  && {
    min-width: 3rem;
    &:hover {
      color: ${colors.primary};
    }
  }
`;
function ListItemLink(props) {
  return <MenuBtn button component="a" {...props} />;
}

const useOutsideClick = (ref, callback) => {
  const handleClick = e => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  });
};
export default function AccountMenu() {
  const { userLogin } = useContext(LoginContext);
  const { signOutWithEmail, signOutWithFacebook } = useAuth();
  const ref = useRef();
  const [hover, setHover] = useState(false);
  const [hover2, setHover2] = useState(false);
  const [hover3, setHover3] = useState(false);
  const [hover4, setHover4] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useOutsideClick(ref, () => {
    if (isMenuOpen) setIsMenuOpen(false);
  });

  const firstLetter = () => {
    if (userLogin[0].userProfile) {
      var splitLetter = userLogin[0].userProfile.display.name.data.en.split("");
      var upperLetter = splitLetter[0].toUpperCase();
      return upperLetter;
    } else {
      return " ";
    }
  };

  const firstNameLetter = () => {
    if (userLogin[0].userProfile) {
      var firstNameSplit = userLogin[0].userProfile.display.name.data.th.split(
        " "
      );
      var firstLetter = firstNameSplit[0].toUpperCase();
      return firstLetter;
    } else {
      return " ";
    }
  };

  const logOut = async () => {
    try {
      if (userLogin[0].loginType === "email") {
        const res = await signOutWithEmail();
        if (res.status === 200) {
          userLogin[1]({});
        }
      } else if (userLogin[0].loginType === "facebook") {
        const res = await signOutWithFacebook();
        if (res.status === 200) {
          userLogin[1]({});
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Root id="root">
      {userLogin[0].userProfile ? (
        <StyledBtn
          id="styled-btn"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          ismenuopen={isMenuOpen.toString()}
        >
          <Circle id="circle">{firstLetter()}</Circle>
          <div id="status-div">
            <NameText id="name">{firstNameLetter()}</NameText>
            <ThinText id="status" light="true">
              สถานะ: ปกติ
            </ThinText>
          </div>
          <IcDropdown id="ic-dropdown" />
        </StyledBtn>
      ) : (
        <StyledBtn id="styled-btn">
          <SkeletonCircle id="skeleton-btn" variant="circle" />
          <div id="skeleton-div">
            <SkeletonNameText id="skeleton-name" variant="rect" />
            <SkeletonNameText id="skeleton-name" variant="rect" />
          </div>
          <IcDropdown id="ic-dropdown" />
        </StyledBtn>
      )}

      {isMenuOpen && (
        <MenuList id="menu-list" ref={ref}>
          <InfoDiv id="info-div">
            <Circle id="circle-big" big="true">
              {firstLetter()}
            </Circle>
            <div id="info">
              <NameText id="name" dark="true">
                {" "}
                {userLogin[0].userProfile
                  ? userLogin[0].userProfile.display.name.data.th
                  : ""}{" "}
              </NameText>
              <ThinText id="vm-id">
                {" "}
                {userLogin[0].userProfile
                  ? userLogin[0].userProfile.display.vm_id.toUpperCase()
                  : ""}{" "}
              </ThinText>
            </div>
          </InfoDiv>
          <CenterGroup id="center">
            <ListItemLink
              id="list-item-link"
              onMouseEnter={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
              href="/profile"
            >
              <StyledListIc id="st-ic">
                <IcPerson id="ic-person" hover={hover.toString()} />
              </StyledListIc>
              โปรไฟล์
            </ListItemLink>
            <ListItemLink
              id="list-item-link"
              onMouseEnter={() => setHover2(true)}
              onMouseLeave={() => setHover2(false)}
              href={
                "/identity/" +
                (userLogin[0].userProfile
                  ? userLogin[0].userProfile.display.vm_id.toUpperCase()
                  : "") + 
                  "?ga-category=page_identity&ga-action=verme-card.link"
              }
            >
              <StyledListIc id="st-ic">
                <IcId id="ic-id" hover={hover2.toString()} />
              </StyledListIc>
              VerME Card
            </ListItemLink>
            <ListItemLink
              id="list-item-link"
              onMouseEnter={() => setHover3(true)}
              onMouseLeave={() => setHover3(false)}
              href="/payment-page"
            >
              <StyledListIc id="st-ic">
                <IcRenew id="ic-renew" hover={hover3.toString()} />
              </StyledListIc>
              ต่ออายุ
            </ListItemLink>
          </CenterGroup>
          <ListItemLink
            id="list-item-link"
            logout="true"
            onMouseEnter={() => setHover4(true)}
            onMouseLeave={() => setHover4(false)}
          >
            <StyledListIc id="st-ic">
              <IcLogout id="ic-logout" hover={hover4.toString()} />
            </StyledListIc>
            <div
              id="logout-div"
              style={{ width: "100%", height: "100%" }}
              onClick={logOut}
            >
              ออกจากระบบ
            </div>
          </ListItemLink>
        </MenuList>
      )}
    </Root>
  );
}
