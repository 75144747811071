import React from "react";
import styled from "styled-components";
import Button from "@material-ui/core/Button";
import colors from "../common/Color";
import Modal from "@material-ui/core/Modal";
import { ReactComponent as IconWarn } from "../assets/Icons/ic_big_report_outlined.svg";
import GrayText from "../common/GrayText";

const Root = styled.div`
  @media (min-width: 961px) {
    height: 1.5vh;
  }
`;
const ModalBox = styled.div`
  width: 27.88rem;
  height: 18.31rem;
  background: ${colors.white};
  border-radius: 0.5rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-top: calc(50vh - 9.15rem);
  @media (max-width: 960px) {
    width: 22.31rem;
  }
`;
const IcWarn = styled(IconWarn)`
  fill: ${colors.statusWarning};
  font-size: 4.5rem;
`;
const ConfirmBtn = styled(Button)`
  && {
    width: 6.56rem;
    height: 2.75rem;
    border-radius: 0.25rem;
    background: ${colors.primary};
    font-family: "SukhumvitSet-SemiBold";
    font-size: 0.88rem;
    color: ${colors.white};
    letter-spacing: 0.08rem;
    line-height: 1rem;
    margin: 0 0.75rem;
    box-shadow: 0 0.063rem 0.063rem 0 rgba(0, 0, 0, 0.5);
    &:hover {
      background-color: ${colors.primaryDark};
    }
    @media (max-width: 960px) {
      width: 8.88rem;
    }
  }
`;
const FlexRow = styled.div`
  display: flex;
`;
const TextGroup = styled.div`
  margin: 1.75rem;
  text-align: center;
`;

export default function ErrorModal(props) {
  const { handleErrorClose, errorOpen, textMessage, noButton } = props;

  return (
    <Root>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={errorOpen}
        onClose={handleErrorClose}
        disableEnforceFocus={true}
        disableBackdropClick={true}
      >
        <ModalBox>
          <IcWarn />
          <TextGroup>
            <GrayText> {textMessage} </GrayText>
          </TextGroup>
          {noButton === "true" ? (
            ""
          ) : (
            <FlexRow>
              <ConfirmBtn onClick={handleErrorClose}>ยืนยัน</ConfirmBtn>
            </FlexRow>
          )}
        </ModalBox>
      </Modal>
    </Root>
  );
}
