import React from "react";
import styled from "styled-components";
import colors from "../common/Color";
import { ReactComponent as LogoVerme } from "../assets/Logo/logo_verme_dark.svg";
import CancelBtn from "../Register/CancelButton";

export const Root = styled.div`
  top: 0;
  width: 100%;
  height: 4rem;
  background: ${colors.white};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.03);
  position: fixed;
  z-index: 10;
`;
export const Header = styled.div`
  width: 90%;
  height: 4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 5%;
`;
export const Logo = styled(LogoVerme)`
  width: 6.13rem;
  height: 1.63rem;
`;

export default function FormHeader() {
  return (
    <Root>
      <Header>
        <Logo />
        <CancelBtn />
      </Header>
    </Root>
  );
}
