import React, { useState, useCallback, useContext } from "react";
import styled from "styled-components";
import moment from "moment";
import "moment/locale/th";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import colors from "../common/Color";
import { ReactComponent as IconCopy } from "../assets/Icons/Icons Black/ic_copy.svg";
import { ReactComponent as IconForward } from "../assets/Icons/Icons Black/ic_forward.svg";
import { useClipboard } from "use-clipboard-copy";
import { LoginContext } from "../Context/LoginStore";
import Snackbar from "@material-ui/core/Snackbar";

const Box = styled.div`
  width: 23.75rem;
  height: 25.38rem;
  background-color: ${colors.white};
  border-radius: 0.5rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.12);
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 1023px) {
    width: 17.5rem;
    height: 22.38rem;
  }
  @media (max-width: 767px) {
    width: 100%;
    height: 21.5rem;
    margin-bottom: 1.5rem;
  }
`;
const Container = styled.div`
  width: 21.25rem;
  height: 22.75rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 1024px) {
    width: 15.5rem;
    height: 19.5rem;
  }
`;
const VermeCard = styled.img`
  width: 21.25rem;
  height: 12.13rem;
  border-radius: 1.2rem;
  @media (max-width: 1024px) {
    width: 15.5rem;
    height: 8.88rem;
    border-radius: 1.2rem;
  }
`;
const BadgeDiv = styled.div`
  display: flex;
  margin: 1rem 0 0.5rem;
  @media (max-width: 1024px) {
    flex-direction: column-reverse;
  }
`;
const BadgeContainer = styled.div`
  display: flex;
  @media (max-width: 1024px) {
    margin-top: 0.5rem;
  }
`;
const StatusBadge = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5.75rem;
  height: 1.5rem;
  border-radius: 0.2rem;
  background-color: RGBA(76, 217, 100, 0.08);
  color: ${colors.textSuccess};
  font-family: "SukhumvitSet-Semibold";
  font-size: 0.75rem;
  letter-spacing: 0.03rem;
  text-align: center;
  line-height: 1rem;
  margin-right: 0.5rem;
`;
// const PackageBadge = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   width: 5.19rem;
//   height: 1.5rem;
//   border-radius: 0.2rem;
//   background-color: RGBA(0, 0, 0, 0.08);
//   color: rgba(0, 0, 0, 0.34);
//   font-family: "SukhumvitSet-Semibold";
//   font-size: 0.75rem;
//   letter-spacing: 0.03rem;
//   line-height: 1rem;
//   margin-right: 0.75rem;
// `;
const IdText = styled.div`
  opacity: 0.87;
  font-family: "SukhumvitSet-Text";
  font-size: 0.88rem;
  color: #000000;
  letter-spacing: 0.02rem;
  line-height: 1.25rem;
`;
const ExpText = styled.span`
  opacity: ${props => (props.title ? "0.6" : "0.87")};
  font-family: "SukhumvitSet-Text";
  font-size: 0.88rem;
  color: #000000;
  letter-spacing: 0.02rem;
  line-height: 1.25rem;
  margin-left: ${props => (props.title ? "0" : "0.5rem")};
`;
const MainBtn = styled(Button)`
  && {
    color: ${colors.primary};
    font-family: "SukhumvitSet-Bold";
    font-size: 0.88rem;
    letter-spacing: 0.08rem;
    line-height: 1rem;
    height: 2.63rem;
  }
`;
const IcCopy = styled(IconCopy)`
  font-size: 1.5rem;
  fill: ${colors.primary};
  margin-right: 0.25rem;
`;
const IcNext = styled(IconForward)`
  font-size: 1.5rem;
  fill: ${colors.primary};
  margin-left: 0.33rem;
`;
const BtnDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;
const testExpDate = ["1589932800"];

export default function CardBox() {
  const { userLogin } = useContext(LoginContext);
  const clipboard = useClipboard({
    onSuccess() {
      console.log("Text was copied successfully!");
    },
    onError() {
      console.log("Failed to copy text!");
    }
  });
  const [open, setOpen] = useState(false);
  const copyClipboard = useCallback(
    id => {
      const url =
        (process.env.NODE_ENV === "production"
          ? "https://verme.me/identity/"
          : "https://test.verme.me/identity/") + id;
      clipboard.copy(url);
    },
    [clipboard]
  );
  const handleClick = id => {
    setOpen(true);
    copyClipboard(id);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const convertDate = item => {
    moment.locale("th");
    var newDate = moment.unix(item).format("DD MMMM YYYY");
    var adYear = newDate.substr(newDate.length - 4, newDate.length);
    var correctDate = newDate.replace(adYear, adYear * 1 + 543);
    return correctDate;
  };
  const remainDay = item => {
    moment.locale("th");
    var remain = moment.unix(item).fromNow();
    return remain;
  };
  return (
    <Box id="box">
      <Container id="container">
        {console.log(userLogin[0].userProfile.vermeCard)}
        <div id="container">
          {userLogin[0].userProfile ? (
            <VermeCard
              id="vm-card"
              src={
                userLogin[0].userProfile
                  ? userLogin[0].userProfile.vermeCard
                  : ""
              }
            />
          ) : (
            <div id="blank"></div>
          )}
          <BadgeDiv id="badge-div">
            <BadgeContainer id="badge-container">
              <StatusBadge id="status-badge">ตรวจสอบแล้ว</StatusBadge>
              {/* <PackageBadge>แพ็กเกจ 1 ปี</PackageBadge> */}
            </BadgeContainer>
            <IdText id="id-txt">
              {userLogin[0].userProfile
                ? userLogin[0].userProfile.display.vm_id.toUpperCase()
                : ""}
            </IdText>
          </BadgeDiv>
          <ExpText id="exp-txt" title="true">
            วันหมดอายุ: &nbsp;
          </ExpText>
          <ExpText id="exp-txt">
            {convertDate(
              userLogin[0].userProfile
                ? userLogin[0].userProfile.display.expired
                : testExpDate
            )}
            (
            {remainDay(
              userLogin[0].userProfile
                ? userLogin[0].userProfile.display.expired
                : testExpDate
            )}
            )
          </ExpText>
        </div>
        <BtnDiv id="btn-div">
          <MainBtn
            id="btn"
            onClick={() =>
              handleClick(
                userLogin[0].userProfile
                  ? userLogin[0].userProfile.display.vm_id.toUpperCase()
                  : ""
              )
            }
          >
            <IcCopy id="copy-ic" />
            คัดลอกลิงก์
          </MainBtn>
          <Snackbar
            id="snack-bar"
            anchorOrigin={{
              vertical: "top",
              horizontal: "center"
            }}
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
            message="คัดลอกลิงก์ VerME Card ของคุณไปยังคลิปบอร์ดแล้ว"
            action={
              <React.Fragment id="fragment">
                <IconButton
                  id="ic-btn"
                  size="small"
                  aria-label="close"
                  color="inherit"
                  onClick={handleClose}
                >
                  <CloseIcon id="close-ic" fontSize="small" />
                </IconButton>
              </React.Fragment>
            }
          />
          <MainBtn
            id="btn"
            href={
              "/identity/" +
              (userLogin[0].userProfile
                ? userLogin[0].userProfile.display.vm_id.toUpperCase()
                : "")
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            ดูบัตร <IcNext id="next-ic" />
          </MainBtn>
        </BtnDiv>
      </Container>
    </Box>
  );
}
