import React from "react";
import styled from "styled-components";
import colors from "../common/Color";
import { Root, Logo, Header } from "../common/FormHeader";
import Title from "../common/Title";
import { ReactComponent as SuccessIcon } from "../assets/Icons/ic_complete.svg";
import Button from "../common/WebButton";
import MetaDecorator from "../MetaDecorator/MetaDecorator";

const Container = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 500px) {
    width: 80%;
    margin-top: 2rem;
  }
`;
const FlexCenter = styled.div`
  display: flex;
  justify-content: center;
`;
const StyledTitle = styled(Title)`
  && {
    color: #1bc46a;
    text-align: center;
  }
`;
const SuccessIc = styled(SuccessIcon)`
  font-size: 11.25rem;
  margin: 2rem 0;
  @media (max-width: 1024px) {
    font-size: 7.5rem;
  }
`;
const Text = styled.div`
  font-family: "Sarabun-Regular";
  font-size: 1rem;
  color: ${props => (props.email ? colors.primary : "rgba(0,0,0,0.6)")};
  letter-spacing: 0.03rem;
  line-height: 1.75rem;
  text-align: center;
`;
const TextDiv = styled.div`
  margin: 0 auto;
  @media (max-width: 381px) {
    display: none;
  }
`;
const TextDivMob = styled.div`
  margin: 0 auto;
  @media (min-width: 382px) {
    display: none;
  }
`;
const Btn = styled(Button)`
  && {
    width: 12.5rem;
    height: 2.75rem;
    margin-top: 2rem;
    @media (max-width: 500px) {
      width: 100%;
    }
  }
`;
const FlexReverse = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 1024px) {
    flex-direction: column-reverse;
  }
`;

export default function ChangePwSuccess() {
  const redirectToLogin = () => {
    window.location.href = "/sign-in";
  };
  return (
    <div id="root-div">
      <MetaDecorator
        description="มั่นใจทุกการขาย สบายใจทุกการซื้อ"
        title= "เปลี่ยนรหัสผ่านเรียบร้อย"
        // imageUrl={metaThumbnail}
        // imageAlt={blog.metaImageAlt}
      />
      <Root id="root">
        <Header id="header">
          <Logo id="logo" />
        </Header>
      </Root>
      <FlexCenter id="flex-center">
        <Container id="container">
          <FlexReverse>
            <StyledTitle id="title">เปลี่ยนรหัสผ่านเรียบร้อย</StyledTitle>
            <SuccessIc id="success-ic" />
          </FlexReverse>
          <TextDiv id="content">
            <Text id="txt">
              รหัสผ่านถูกเปลี่ยนแล้ว ท่านสามารถใช้อีเมล <br /> และรหัสผ่านนี้
              เพื่อเข้าสู่ระบบ VerME ได้ทันที
            </Text>
          </TextDiv>
          <TextDivMob id="content-mb">
            <Text id="txt">
              รหัสผ่านถูกเปลี่ยนแล้ว ท่านสามารถ <br />
              ใช้อีเมลและรหัสผ่านนี้ เพื่อเข้าสู่ระบบ <br /> VerME ได้ทันที
            </Text>
          </TextDivMob>
          <Btn id="btn" primary="true" onClick={redirectToLogin}>
            เข้าสู่ระบบ
          </Btn>
        </Container>
      </FlexCenter>
    </div>
  );
}
