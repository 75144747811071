import React, { useState, createContext } from "react";

export const LoginContext = createContext({});

export const LoginContextProvider = ({ children }) => {
  const [userLogin, setUserLogin] = useState({});

  const store = {
    userLogin: [userLogin, setUserLogin]
  };

  return (
    <LoginContext.Provider value={store}>{children}</LoginContext.Provider>
  );
};
