import React, { useState } from "react";
import styled from "styled-components";
import PolicyTitle from "./PolicyTitle";
import PoicyContent from "./PolicyContent";
import NavBar from "../common/NavBar";
import NavMobile from "../common/NavMobile";
import Footer from "../common/FooterBar";

const Root = styled.div`
  padding: 8rem 10% 16rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 1024px) {
    padding: 5.94rem 5% 8rem;
  }
`;

const HeaderPolicy = styled.div`
  font-family: "SukhumvitSet-Text";
  font-size: 2.13rem;
  color: #3b54d4;
  letter-spacing: 0.25px;
  text-align: left;
  margin-bottom: 2.31rem;
  width: 100%;
  max-width: 76rem;
`;

const HeaderPolicyFrame = styled.div`
  display: flex;
  justify-content: center;
`;

const ContentFrame = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  @media (max-width: 1024px) {
    display: block;
  }
`;

const TitleContent = styled.div`
  width: 25%;
  max-width: 21rem;
  @media (max-width: 1024px) {
    width: 100%;
    max-width: 55rem;
  }
`;

const ContentData = styled.div`
  width: 75%;
  max-width: 55rem;
  @media (max-width: 1024px) {
    width: 100%;
  }
`;

export default function Policy() {
  const [title, setTitle] = useState(1);

  function setTitleData(item) {
    setTitle(item);
  }

  return (
    <div id="root">
      <NavBar id="navbar" props="policy" />
      <NavMobile id="nav-mb" props="policy" />
      <Root id="root-container">
        <HeaderPolicyFrame id="header-frame">
          <HeaderPolicy id="header"> นโยบายและข้อตกลง </HeaderPolicy>
        </HeaderPolicyFrame>
        <ContentFrame id="content-frame">
          <TitleContent id="title-ct">
            <PolicyTitle
              id="policy-title"
              title={title}
              setTitleData={setTitleData}
            />
          </TitleContent>
          <ContentData id="content-data">
            <PoicyContent id="policy-ct" title={title} />
          </ContentData>
        </ContentFrame>
      </Root>
      <Footer id="footer" props="nothomepage" />
    </div>
  );
}
