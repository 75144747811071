import React from "react";
import styled from "styled-components";
import NavBar from "../common/NavBar";
import NavMobile from "../common/NavMobile";
import Footer from "../common/FooterBar";
import MainSection from "./MainSection";
import SellerSection from "./SellerSection";
import BuyerSection from "./BuyerSection";
import QuoteSection from "./QuoteSection";
import CountSection from "./CountSection";
import PricingSection from "../PricingPage/PricingDetail";
import Partner from "./Partner";

const Root = styled.div`
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
`;
export default function Homepage() {
  return (
    <div id="root-div">
      <Root id="root">
        <NavBar id="navbar" props="homepage" />
        <NavMobile id="nav-mb" props="homepage" />
        <div id="main-section">
          <MainSection id="main-st-component" />
        </div>
        <Partner id="partner" />
        <div id="seller-section">
          <SellerSection id="seller-section-page" />
        </div>
        <div id="buyer-section">
          <BuyerSection id="buyer-section-page" />
        </div>
        <QuoteSection id="quote-section" />
        <CountSection id="count-section" />
        <div id="price-section">
          <PricingSection id="price-section-page" props="homepage" />
        </div>
        <Footer id="footer" props="homepage" />
      </Root>
    </div>
  );
}
