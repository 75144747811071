import React from "react";
import styled from "styled-components";
import colors from "../common/Color";
import Modal from "@material-ui/core/Modal";
import { ReactComponent as CheckIcon } from "../assets/Icons/Icons Black/ic_check_filled.svg";
import CheckedIcon from "../assets/Icons/Icons Black/ic_check_filled copy.svg";
import { ReactComponent as CrossIcon } from "../assets/Icons/Icons Black/ic_cross_filled.svg";
import { ReactComponent as CorrectImg } from "../assets/Images/img_howto_correct_1.svg";
import { ReactComponent as WrongImg1 } from "../assets/Images/img_howto_wrong_1.svg";
import { ReactComponent as WrongImg2 } from "../assets/Images/img_howto_wrong_2.svg";
import { ReactComponent as WrongImg3 } from "../assets/Images/img_howto_wrong_3.svg";
import { ReactComponent as WrongImg4 } from "../assets/Images/img_howto_wrong_4.svg";
import Button from "../common/WebButton";
import FormHeader from "../common/FormHeader";

const StyledModal = styled(Modal)`
  && {
    height: 100vh;
    overflow: scroll;
  }
`;
const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  @media (max-width: 1024px){
    display: block;
  }
`;
const MobileDiv = styled.div`
  background-color: #f7f8fa;
  @media (min-width: 1025px) {
    display: none;
  }
`;
const ModalBox = styled.div`
  width: 35rem;
  height: 46.13rem;
  background: ${colors.white};
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  margin: auto;
  @media (max-width: 1024px) {
    display: none;
  }
`;
const ContentDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const Title = styled.span`
  font-family: "SukhumvitSet-Text";
  font-size: 1.5rem;
  color: ${colors.primary};
  text-align: center;
  line-height: 2.31rem;
  margin: 3rem auto 1.5rem;
  text-decoration: ${props => (props.no ? "underline" : "none")};
`;
const CheckIc = styled(CheckIcon)`
  fill: ${colors.statusSuccess};
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.38rem;
`;
const CorrectText = styled.span`
  font-family: ${props => (props.bold ? "Sarabun-Semibold" : "Sarabun-Light")};
  font-size: 0.88rem;
  color: ${colors.black};
  letter-spacing: 0.02rem;
  line-height: 1.25rem;
`;
const WrongText = styled.div`
  font-family: "Sarabun-Regular";
  font-size: 0.88rem;
  letter-spacing: 0.02rem;
  line-height: 1.25rem;
  text-align: center;
  margin: 0.5rem auto 1rem;
`;
const WrongContent = styled.div`
  display: flex;
  width: 23rem;
  justify-content: space-between;
`;
const WrongContainer = styled.div`
  margin-bottom: 1.81rem;
`;
const Circle = styled.div`
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  background: ${props => (props.active ? colors.primary : "#D8D8D8")};
  margin: 0.25rem;
  cursor: pointer;
`;
const StepDiv = styled.div`
  display: flex;
`;
const BtnDiv = styled.div`
  display: flex;
  justify-content: space-between;
  width: 16.25rem;
  margin-top: 1.38rem;
`;
const HowtoCorDiv = styled.div`
  margin: 1.5rem 0;
`;
//Mobile
const Root = styled.div`
  width: 84%;
  min-height: 100vh;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (min-width: 1025px) {
    display: none;
  }
`;
const WrongContainerMob = styled.div`
  margin: 0 auto;
`;
const TitleMobile = styled.span`
  font-family: "SukhumvitSet-Semibold";
  font-size: 1.25rem;
  color: ${colors.primary};
  text-align: center;
  letter-spacing: 0.02rem;
  line-height: 1.94rem;
  margin: 7.25rem auto 0;
  text-decoration: ${props => (props.no ? "underline" : "none")};
`;
const CorImg = styled(CorrectImg)`
  width: 17.5rem;
  height: 17.5rem;
  margin: 1.25rem auto;
`;
const HowtoDiv = styled.div`
  margin: 1.25rem auto 3.5rem;
`;
const WrongImage1 = styled(WrongImg1)`
  width: 8.25rem;
  height: 8.25rem;
`;
const WrongImage2 = styled(WrongImg2)`
  width: 8.25rem;
  height: 8.25rem;
`;
const WrongImage3 = styled(WrongImg3)`
  width: 8.25rem;
  height: 8.25rem;
`;
const WrongImage4 = styled(WrongImg4)`
  width: 8.25rem;
  height: 8.25rem;
`;
const CrossIc = styled(CrossIcon)`
  fill: ${colors.statusError};
  font-size: 1.5rem;
  margin-right: 0.38rem;
`;
const WrongContentMobile = styled.div`
  display: flex;
  width: 17.5rem;
  margin: 0 auto 1rem;
  justify-content: space-between;
`;
const BtnDivMobile = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 1.25rem 0 5rem;
`;
const StepDivMob = styled.div`
  display: flex;
  margin: auto;
`;
const howto_correct = [
  {
    msg: (
      <div id="msg">
        <CorrectText id="correct-txt">ต้องเห็นหน้า</CorrectText>
        <CorrectText id="correct-txt-bold" bold="true">
          ชัดเจน
        </CorrectText>
      </div>
    )
  },
  {
    msg: (
      <div id="msg">
        <CorrectText id="correct-txt">ต้องเห็นข้อมูลบนบัตรประชาชน</CorrectText>
        <CorrectText id="correct-txt-bold" bold="true">
          ชัดเจน
        </CorrectText>
      </div>
    )
  },
  {
    msg: (
      <div id="msg">
        <CorrectText id="correct-txt">
          เห็นป้าย/แผ่นกระดาษ VerME พร้อมวันที่
        </CorrectText>
        <CorrectText id="correct-txt-bold" bold="true">
          ชัดเจน
        </CorrectText>
      </div>
    )
  }
];
const howto_wrong = [
  {
    msg: (
      <div id="msg">
        <WrongText id="wrong-txt">ถ่ายแต่บัตรประชาชนอย่างเดียว</WrongText>
      </div>
    )
  },
  {
    msg: (
      <div id="msg">
        <WrongText id="wrong-txt">ไม่มีป้าย/แผ่นกระดาษ VerME</WrongText>
      </div>
    )
  },
  {
    msg: (
      <div id="msg">
        <WrongText id="wrong-txt">ถ่ายรูปตัวเองเพียงอย่างเดียว</WrongText>
      </div>
    )
  },
  {
    msg: (
      <div id="msg">
        <WrongText id="wrong-txt">ไม่มีถ่ายคู่กับหน้าตัวเอง</WrongText>
      </div>
    )
  }
];

function HowtoCorrect() {
  return (
    <div id="root">
      {howto_correct.map((item, index) => (
        <div
          id="ic-div"
          key={index}
          style={{ display: "flex", alignItems: "center" }}
        >
          <CheckIc id="ic-check" />
          {item.msg}
        </div>
      ))}
    </div>
  );
}

export default function ImgModal(props) {
  const { openImg, handleCloseModal, isCorrectPage, setIsCorrectPage } = props;

  return (
    <StyledModal
      id="root"
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={openImg}
      onClose={handleCloseModal}
      disableEnforceFocus={true}
      disableBackdropClick={true}
    >
      <Container id="container">
        <ModalBox id="modal-box" style={{ userSelect: "none" }}>
          {isCorrectPage ? (
            <ContentDiv id="content-div">
              <Title id="title">
                การถ่ายภาพหน้าตรง <br /> คู่บัตรประชาชน และป้าย VerME
              </Title>
              <CorrectImg id="correct-img" />
              <HowtoCorDiv id="howto-div-1">{HowtoCorrect()}</HowtoCorDiv>
              <StepDiv id="step-div">
                <Circle id="circle-1" active="true" />
                <Circle id="circle-2" onClick={() => setIsCorrectPage(false)} />
              </StepDiv>
              <BtnDiv id="btn-div">
                <Button
                  id="next-btn"
                  width="16.25rem"
                  height="2.75rem"
                  primary="true"
                  onClick={() => setIsCorrectPage(false)}
                >
                  ต่อไป
                </Button>
              </BtnDiv>
            </ContentDiv>
          ) : (
            <ContentDiv id="content-div">
              <div id="title-div" style={{ margin: "3rem auto 3.82rem" }}>
                <Title id="title">ภาพที่จะ</Title>
                <Title id="title" no="true">
                  ไม่
                </Title>
                <Title id="title">ได้รับการอนุมัติ</Title>
              </div>
              <WrongContainer id="wrong-container">
                <WrongContent id="wrong-content">
                  <div id="wrong-div-1">
                    <WrongImg1 id="wrong-img-1" />
                    <WrongText id="wrong-txt">ถ่ายแค่บัตรประชาชน</WrongText>
                  </div>
                  <div id="wrong-div-2">
                    <WrongImg2 id="wrong-img-2" />
                    <WrongText id="wrong-txt">ไม่มีรูปตัวเอง</WrongText>
                  </div>
                </WrongContent>
                <WrongContent id="wrong-content">
                  <div id="wrong-div-3">
                    <WrongImg3 id="wrong-img-3" />
                    <WrongText id="wrong-txt">
                      ไม่มีป้าย/แผ่นกระดาษVerME
                    </WrongText>
                  </div>
                  <div id="wrong-div-4">
                    <WrongImg4 id="wrong-img-4" />
                    <WrongText id="wrong-txt">
                      ถ่ายรูปตัวเองเพียงอย่างเดียว
                    </WrongText>
                  </div>
                </WrongContent>
              </WrongContainer>
              <StepDiv id="step-div">
                <Circle id="circle-1" onClick={() => setIsCorrectPage(true)} />
                <Circle id="circle-2" active="true" />
              </StepDiv>
              <BtnDiv id="btn-div">
                <Button
                  id="prev-btn"
                  width="7.5rem"
                  height="2.75rem"
                  onClick={() => setIsCorrectPage(true)}
                >
                  ย้อนกลับ
                </Button>
                <Button
                  id="submit-btn"
                  width="7.5rem"
                  height="2.75rem"
                  primary="true"
                  onClick={handleCloseModal}
                >
                  รับทราบ
                </Button>
              </BtnDiv>
            </ContentDiv>
          )}
        </ModalBox>
        <MobileDiv id="mobile-div">
          <FormHeader id="form-header" />
          {isCorrectPage ? (
            <Root id="mobile-root">
              <TitleMobile id="title-mb">
                การถ่ายภาพหน้าตรง <br /> คู่บัตรประชาชน และป้าย VerME
              </TitleMobile>
              <CorImg id="cor-img" />
              <HowtoDiv id="howto-div">
                {howto_correct.map((item, index) => (
                  <div
                    id="img-div"
                    key={index}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <img
                      id="check-img"
                      src={CheckedIcon}
                      alt="check-icon"
                      style={{ marginRight: "0.38rem" }}
                    />
                    {item.msg}
                  </div>
                ))}
              </HowtoDiv>
              <StepDivMob id="step-mb">
                <Circle id="circle-1" active="true" />
                <Circle id="circle-2" onClick={() => setIsCorrectPage(false)} />
              </StepDivMob>
              <BtnDivMobile id="btn-mb">
                <Button
                  id="next-btn"
                  width="100%"
                  height="2.75rem"
                  primary="true"
                  onClick={() => setIsCorrectPage(false)}
                >
                  ต่อไป
                </Button>
              </BtnDivMobile>
            </Root>
          ) : (
            <Root id="root-wrong">
              <div id="title-div" style={{ margin: "7.25rem auto 1.25rem" }}>
                <TitleMobile id="title-mb">ภาพที่จะ</TitleMobile>
                <TitleMobile id="title-mb" no="true">
                  ไม่
                </TitleMobile>
                <TitleMobile id="title-mb">ได้รับการอนุมัติ</TitleMobile>
              </div>
              <WrongContainerMob id="wrong-container-mb">
                <WrongContentMobile id="wrong-content-mb">
                  <WrongImage1 id="wrong-img-1" />
                  <WrongImage2 id="wrong-img-2" />
                </WrongContentMobile>
                <WrongContentMobile id="wrong-content-mb">
                  <WrongImage3 id="wrong-img-3" />
                  <WrongImage4 id="wrong-img-4" />
                </WrongContentMobile>
                <HowtoDiv id="howto-div" style={{ width: "14rem" }}>
                  {howto_wrong.map((item, index) => (
                    <div
                      id="cross-div"
                      key={index}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <CrossIc id="cross-ic" />
                      {item.msg}
                    </div>
                  ))}
                </HowtoDiv>
              </WrongContainerMob>
              <StepDivMob id="step-mob">
                <Circle id="circle-1" onClick={() => setIsCorrectPage(true)} />
                <Circle id="circle-2" active="true" />
              </StepDivMob>
              <BtnDivMobile id="btn-mob">
                <Button
                  id="prev-btn"
                  width="48%"
                  height="2.75rem"
                  onClick={() => setIsCorrectPage(true)}
                >
                  ย้อนกลับ
                </Button>
                <Button
                  id="submit-btn"
                  width="48%"
                  height="2.75rem"
                  primary="true"
                  onClick={handleCloseModal}
                >
                  รับทราบ
                </Button>
              </BtnDivMobile>
            </Root>
          )}
        </MobileDiv>
      </Container>
    </StyledModal>
  );
}
