import React from "react";
import styled from "styled-components";
import FAQOptions from "./FAQOptions";

const HeaderContent = styled.div`
  height: 3.94rem;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  font-family: "SukhumvitSet-Text";
  font-size: 1.5rem;
  color: #3b54d4;
  @media (max-width: 1024px) {
    margin-bottom: 1.5rem;
  }
`;

export default function FAQContent(props) {
  const { title, isReset } = props;
  const titleData = [
    "คำถามทั่วไป",
    "ความปลอดภัย",
    "การใช้งาน VerME",
    "การรายงานผู้ขาย",
    "การชำระเงิน"
  ];
  return (
    <div id="root">
      <HeaderContent id="header-ct">{titleData[title - 1]}</HeaderContent>
      <FAQOptions id="faq-option" title={title} isReset={isReset} />
    </div>
  );
}
