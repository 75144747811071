import React, { useState } from "react";
import colors from "../common/Color";
import styled from "styled-components";
import { ReactComponent as IconDropDown } from "../assets/Icons/ic_dropdown.svg";

const Root = styled.div`
  height: 100%;
  background: #fbfbfb;
  margin-top: 6rem;
  padding-bottom: 10rem;
  @media (max-width: 1024px) {
    margin-top: 4rem;
  }
`;
const Title = styled.div`
  font-family: "SukhumvitSet-Bold";
  font-size: 2.13rem;
  letter-spacing: 0.02rem;
  line-height: 3.38rem;
  text-align: center;
  color: ${colors.black};
  opacity: 0.87;
  padding: 8rem 0 2.5rem 0;
  @media (max-width: 1024px) {
    padding: 4rem 0 2rem 0;
  }
`;
const QuestionText = styled.div`
  font-family: "SukhumvitSet-Semibold";
  font-size: 1rem;
  color: ${colors.black};
  opacity: 0.87;
  letter-spacing: 0.03rem;
  line-height: 1.75rem;
  width: 90%;
`;
const AnswerText = styled.div`
  font-size: 1rem;
  color: ${colors.black};
  opacity: 0.6;
  letter-spacing: 0.03rem;
  line-height: 1.75rem;
  text-align: left;
  margin: ${props => (props.collapse ? "0 1.5rem" : "1.45rem")};
  display: ${props => (props.collapse ? "none" : "block")};
  transition: 0.3s;
  width: 90%;
`;
const IconDropdown = styled(IconDropDown)`
  font-size: 1.5rem;
  fill: ${colors.white};
  transform: ${props => (props.collapse ? "rotate(180deg)" : "")};
  transition: 0.3s;
`;
const BlueCircle = styled.div`
  width: 1.75rem;
  height: 1.75rem;
  background: ${colors.primary};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
const Line = styled.div`
  width: 43.75rem;
  height: 0.06rem;
  background: #d8d8d8;
  @media (max-width: 1024px) {
    width: 100%;
  }
`;
const QuestionDiv = styled.div`
  width: 40.75rem;
  display: flex;
  justify-content: space-between;
  margin: 1.5rem 0 1.5rem 1.5rem;
  @media (max-width: 1024px) {
    width: 90%;
  }
`;
const Container = styled.div`
  margin: auto;
  width: 43.75rem;
  @media (max-width: 1024px) {
    width: 90%;
  }
`;
export default function FaqPage() {
  const [collapsis, setCollapsis] = useState([
    {
      question: "ชำระเงินต่ออายุการใช้งานแล้วจะสามารถใช้ได้เลยหรือไม่",
      answer:
        "หากชำระเงินต่ออายุการใช้งานแล้ว ต้องรอให้ระบบตรวจสอบการชำระเงินให้เสร็จสิ้นก่อน หลังจากนั้นจะมี Email ส่งไปให้ทราบว่าเริ่มใช้บริการต่อได้",
      status: false
    },
    {
      question:
        "หากไม่ต่ออายุหลังจากหมดอายุการใช้งานฟรีไปแล้ว จะโดนเรียกเก็บค่าใช้จ่ายหรือไม่",
      answer:
        "หากหมดอายุการใช้งานแล้วไม่ทำการชำระเงินเพื่อต่ออายุ ทาง VerME ของเราจะไม่มีการเรียกเก็บแบบอัตโนมัติใดๆ มีเพียงการส่ง Email เพื่อแจ้งเตือนบัตรหมดอายุให้ทราบเท่านั้น เพราะการต่ออายุการใช้งานเป็นสิทธิส่วนบุคคลของท่านเองตามกฎหมาย",
      status: false
    }
  ]);

  const handleClick = index => {
    var newData = [...collapsis];
    var checkData = newData.map((itemData, indexData) => {
      if (indexData !== index) {
        itemData.status = false;
      } else {
        itemData.status = !itemData.status;
      }

      return itemData;
    });
    setCollapsis(checkData);
  };

  return (
    <Root id="root">
      <Title id="title">คำถามที่พบบ่อย</Title>
      {collapsis.map((item, index) => (
        <Container id="container" key={index}>
          <QuestionDiv id="question-div">
            <QuestionText id="txt1">{item.question}</QuestionText>
            <BlueCircle id="circle" onClick={() => handleClick(index)}>
              {item.status === false ? (
                <IconDropdown id="dropdown-ic" />
              ) : (
                <IconDropdown id="dropdown-ic" collapse="true" />
              )}
            </BlueCircle>
          </QuestionDiv>
          {item.status === true ? (
            <AnswerText id="txt2">{item.answer}</AnswerText>
          ) : (
            <AnswerText id="txt2" collapse="true">
              {item.answer}
            </AnswerText>
          )}
          <Line id="line" />
        </Container>
      ))}
    </Root>
  );
}
