import React, { useContext } from "react";
import styled from "styled-components";
import Tooltip from "@material-ui/core/Tooltip";
import moment from "moment";
import "moment/locale/th";
import colors from "../common/Color";
// import Modal from "@material-ui/core/Modal";
// import Backdrop from "@material-ui/core/Backdrop";
// import Fade from "@material-ui/core/Fade";
import { ReactComponent as CheckIcon } from "../assets/Icons/Icons Black/ic_check_filled.svg";
import { TitleText } from "./BankBox";
// import FrontCard from "../assets/IDCard/front.svg";
// import BackCard from "../assets/IDCard/back.svg";
// import SelfieImage from "../assets/Images/img_selfie.svg";
import { LoginContext } from "../Context/LoginStore";

const Box = styled.div`
  width: 58.75rem;
  height: 27.25rem;
  background: ${colors.white};
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.12);
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 1023px) {
    width: 44rem;
  }
  @media (max-width: 767px) {
    width: 100%;
    height: 62.31rem;
    margin-bottom: 1.5rem;
  }
`;
const CheckIc = styled(CheckIcon)`
  font-size: 0.75rem;
  margin-left: 0.25rem;
  display: flex;
  align-self: center;
  margin-bottom: 0.2rem;
  cursor: pointer;
`;
const InfoText = styled.div`
  opacity: ${props => (props.dark ? "0.87" : "0.6")};
  font-family: "SukhumvitSet-Text";
  font-size: 14px;
  color: #000000;
  letter-spacing: 0.25px;
  line-height: 20px;
  margin: ${props =>
    props.date ? "0.25rem 0 0.5rem 0.25rem" : "0.25rem 0 0.5rem"};
  text-align: ${props => (props.date ? "right" : "left")};
`;
// const ImageText = styled.div`
//   opacity: ${(props) => (props.dark ? "0.6" : "0.34")};
//   font-family: "SukhumvitSet-SemiBold";
//   font-size: 12px;
//   color: #000000;
//   letter-spacing: 0.4px;
//   text-align: ${(props) => (props.dark ? "left" : "right")};
//   line-height: 16px;
//   @media (max-width: 767px) {
//     display: ${(props) => (props.dark ? "block" : "none")};
//   }
// `;
// const ImageDiv = styled.div`
//   display: flex;
//   margin-top: 0.5rem;
//   @media (max-width: 767px) {
//     flex-direction: column;
//   }
// `;
// const SubImageDiv = styled.div`
//   margin-right: 1.25rem;
//   @media (max-width: 1023px) {
//     margin-right: 0.75rem;
//   }
//   @media (max-width: 767px) {
//     margin-bottom: 1.25rem;
//   }
// `;
// const StyledImage = styled.img`
//   object-fit: contain;
//   width: 16.25rem;
//   height: 9.38rem;
//   background: #eaeaea;
//   cursor: pointer;
//   @media (max-width: 1023px) {
//     width: 13.5rem;
//   }
//   @media (max-width: 767px) {
//     width: 14.5rem;
//     height: 8.38rem;
//   }
// `;
// const StyledZoomImg = styled.img`
//   max-width: 1024px;
//   max-height: 768px;
// `;
// const StyledModal = styled(Modal)`
//   display: flex;
//   align-items: center;
//   justify-content: center;
// `;
const Container = styled.div`
  width: 55.76rem;
  height: 24.37rem;
  margin-left: 1rem;
  @media (max-width: 1023px) {
    width: 44rem;
  }
  @media (max-width: 767px) {
    width: 15.5rem;
    height: 60rem;
  }
`;
const InfoDiv = styled.div`
  display: flex;
  @media (max-width: 767px) {
    flex-direction: column;
  }
`;
const DateDiv = styled.div`
  width: 23.26rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  @media (max-width: 1023px) {
    width: 14.5rem;
  }
  @media (max-width: 767px) {
    width: 15.5rem;
    margin-bottom: 1.5rem;
  }
`;
const SubDateDiv = styled.div`
  display: flex;
  @media (max-width: 767px) {
    display: block;
  }
`;
const SubInfoDiv = styled.div`
  width: 16.25rem;
  height: 12.38rem;
  @media (max-width: 1023px) {
    width: 13.5rem;
  }
  @media (max-width: 767px) {
    width: 14.5rem;
  }
`;
const SubGeneral = styled.div`
  margin-bottom: 1.31rem;
`;
const CheckDiv = styled.div`
  display: flex;
  align-items: baseline;
`;
const testDate = ["1580608920"];

export default function InfoBox() {
  // const [openFront, setOpenFront] = useState(false);
  // const [openBack, setOpenBack] = useState(false);
  // const [openSelfie, setOpenSelfie] = useState(false);

  const { userLogin } = useContext(LoginContext);

  const convertDate = item => {
    moment.locale("th");
    var newDate = moment.unix(item).format("DD MMM YYYY");
    var adYear = newDate.substr(newDate.length - 4, newDate.length);
    var correctDate = newDate.replace(adYear, adYear * 1 + 543);
    return correctDate;
  };

  return (
    <Box id="box">
      <Container id="container">
        <InfoDiv id="info-div">
          <SubInfoDiv id="subinfo-div">
            <TitleText id="title" style={{ marginBottom: "1.5rem" }}>
              ข้อมูลส่วนตัว
            </TitleText>
            <SubGeneral id="sub-general">
              <InfoText id="info-txt" dark="true">
                {" "}
                {userLogin[0].userProfile
                  ? userLogin[0].userProfile.display.name.data.th
                  : ""}{" "}
              </InfoText>
              <InfoText id="info-txt" dark="true">
                {" "}
                {userLogin[0].userProfile
                  ? userLogin[0].userProfile.display.name.data.en
                  : ""}{" "}
              </InfoText>
            </SubGeneral>
            <SubGeneral id="sub-general">
              <CheckDiv id="check-div">
                <InfoText id="info-txt" dark="true">
                  {" "}
                  {userLogin[0].userProfile
                    ? userLogin[0].userProfile.personal.phone.data
                    : ""}{" "}
                </InfoText>
                {userLogin[0].userProfile ? (
                  userLogin[0].userProfile.personal.phone.isPromtpay ? (
                    <Tooltip id="tooltip" title="เบอร์โทรนี้เป็นพร้อมเพย์แล้ว">
                      <CheckIc id="check-ic" />
                    </Tooltip>
                  ) : (
                    <div id="blank"></div>
                  )
                ) : (
                  ""
                )}
              </CheckDiv>
              <CheckDiv id="check-div">
                <InfoText id="info-txt" dark="true">
                  {" "}
                  {userLogin[0].userProfile
                    ? userLogin[0].userProfile.personal.email
                    : ""}{" "}
                </InfoText>
                <Tooltip id="tooltip" title="อีเมลนี้ได้รับการยืนยันแล้ว">
                  <CheckIc id="check-ic" />
                </Tooltip>
              </CheckDiv>
            </SubGeneral>
          </SubInfoDiv>
          <SubInfoDiv id="subinfo-div">
            <TitleText id="title" style={{ marginBottom: "1.5rem" }}>
              ที่อยู่
            </TitleText>
            <InfoText id="info-txt" dark="true">
              {userLogin[0].userProfile
                ? userLogin[0].userProfile.personal.address.address
                : ""}
              <br />
            </InfoText>
            <InfoText id="info-txt" dark="true">
              {userLogin[0].userProfile
                ? userLogin[0].userProfile.personal.address.subDistrict
                : ""}
              <br />
            </InfoText>
            <InfoText id="info-txt" dark="true">
              {userLogin[0].userProfile
                ? userLogin[0].userProfile.personal.address.district
                : ""}
              <br />
            </InfoText>
            <InfoText id="info-txt" dark="true">
              {userLogin[0].userProfile
                ? userLogin[0].userProfile.personal.address.province
                : ""}
              <br />
            </InfoText>
          </SubInfoDiv>
          <DateDiv id="date-div">
            <SubDateDiv id="subdate-div">
              <InfoText id="info-txt">สมัครสำเร็จเมื่อวันที่:</InfoText>
              <InfoText id="info-txt" dark="true" date="true">
                {convertDate(
                  userLogin[0].userProfile
                    ? userLogin[0].userProfile.submit
                    : testDate
                )}
              </InfoText>
            </SubDateDiv>
          </DateDiv>
        </InfoDiv>
        {/* <ImageText id='img-txt' dark="true">รูปถ่าย</ImageText>
        <ImageDiv id='img-div'>
          <SubImageDiv id='subimg-div'>
            <StyledImage
              id='img'
              onClick={() => setOpenSelfie(true)}
              src={SelfieImage}
              alt="selfie"
            />
            <StyledModal
              id='modal'
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              open={openSelfie}
              onClose={() => setOpenSelfie(false)}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500
              }}
            >
              <Fade id='fade' in={openSelfie}>
                <StyledZoomImg id='zoom-img' src={SelfieImage} alt="selfiezoom" />
              </Fade>
            </StyledModal>
            <ImageText id='img-txt'>กดที่รูปเพื่อดู</ImageText>
          </SubImageDiv>
          <SubImageDiv>
            <StyledImage
              src={FrontCard}
              alt="front"
              onClick={() => setOpenFront(true)}
            />
            <StyledModal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              open={openFront}
              onClose={() => setOpenFront(false)}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500
              }}
            >
              <Fade in={openFront}>
                <StyledZoomImg src={FrontCard} alt="frontzoom" />
              </Fade>
            </StyledModal>
            <ImageText>กดที่รูปเพื่อดู</ImageText>
          </SubImageDiv>
          <SubImageDiv>
            <StyledImage
              src={BackCard}
              alt="back"
              onClick={() => setOpenBack(true)}
            />
            <StyledModal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              open={openBack}
              onClose={() => setOpenBack(false)}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500
              }}
            >
              <Fade in={openBack}>
                <StyledZoomImg src={BackCard} alt="backzoom" />
              </Fade>
            </StyledModal>
            <ImageText>กดที่รูปเพื่อดู</ImageText>
          </SubImageDiv>
        </ImageDiv> */}
      </Container>
    </Box>
  );
}
