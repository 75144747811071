import React, { useContext } from "react";
import styled from "styled-components";
import { ReactComponent as LightIcon } from "../assets/Icons/Icons Black/ic_light.svg";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import "moment/locale/th";
import { StoreContext } from "../Context/Store";

const Root = styled.div`
  background: #f8f8f8;
  border-radius: 8px;
  border-radius: 8px;
  padding: 2rem;
  @media (max-width: 1024px) {
    padding: 1.5rem;
  }
`;
const StyledLightIc = styled(LightIcon)`
  opacity: 0.6;
`;
const Title = styled.div`
  opacity: 0.87;
  font-family: "SukhumvitSet-Bold";
  font-size: 1.5rem;
  color: #000000;
`;

const Price = styled.div`
  opacity: 0.87;
  font-family: "Sarabun-Regular";
  font-size: 1rem;
  color: #000000;
  letter-spacing: 0.5px;
  text-align: right;
  line-height: 28px;
`;

const Packge = styled.div`
  opacity: 0.87;
  font-family: "Sarabun-Regular";
  font-size: 1rem;
  color: #000000;
  letter-spacing: 0.5px;
  text-align: left;
  line-height: 28px;
`;

const SubTitle = styled.div`
  opacity: 0.6;
  font-family: "Sarabun-Regular";
  font-size: 0.88rem;
  color: #000000;
  letter-spacing: 0.25px;
  text-align: left;
  line-height: 20px;
`;
const HrStyle = styled.hr`
  margin: 2rem 0rem 2rem 0rem;
  background: #d8d8d8;
`;

// const PromoCode = styled.div`
//   opacity: 0.6;
//   font-family: "Thonburi";
//   font-size: 1rem;
//   color: #000000;
//   letter-spacing: 0.5px;
//   text-align: right;
//   line-height: 28px;
// `

const TotalPrice = styled.div`
  opacity: 0.87;
  font-family: "SukhumvitSet-Bold";
  font-size: 1.5rem;
  color: #000000;
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
`;

const CancelService = styled.div`
  opacity: 0.6;
  font-family: "Thonburi";
  font-size: 0.88rem;
  color: #000000;
  letter-spacing: 0.25px;
  text-align: left;
  line-height: 20px;
`;

// const CancelServiceButton = styled.div`
//   font-family: "Thonburi";
//   font-size: 0.88rem;
//   color: #3B54D4;
//   letter-spacing: 0.25px;
//   text-align: left;
//   line-height: 20px;
// `

const NameData = styled.div`
  opacity: 0.87;
  font-family: "Sarabun-Regular";
  font-size: 1rem;
  color: #000000;
  letter-spacing: 0.5px;
  text-align: left;
  line-height: 28px;
`;
const SubDataDetail = styled.div`
  opacity: 0.6;
  font-family: "Sarabun-Regular";
  font-size: 0.88rem;
  color: #000000;
  letter-spacing: 0.25px;
  text-align: left;
  line-height: 1.3rem;
`;
const StyledGrid = styled(Grid)`
  && {
    margin-top: 1rem;
  }
`;
export default function DetailPrice(props) {
  const { dataUserPayment } = useContext(StoreContext);
  const { activeStep, choosePackage } = props;

  const checkPrice = item => {
    switch (item) {
      case "1 เดือน":
        return "99 บาท";
      case "4 เดือน":
        return "62 บาท ต่อเดือน";
      default:
        return "49 บาท ต่อเดือน";
    }
  };

  const checkSumPrice = item => {
    switch (item) {
      case "1 เดือน":
        return "99 บาท";
      case "4 เดือน":
        return "249 บาท";
      default:
        return "410 บาท";
    }
  };

  const convertDate = item => {
    moment.locale("th");
    var newDate = moment.unix(item).format("DD MMM YYYY");
    return newDate;
  };

  const convertStatus = item => {
    switch (item) {
      case "verified":
        return "Verified";
      case "expired":
        return "Expired";
      default:
        return "";
    }
  };

  const checkRangeDate = (expired, status) => {
    var splitMonth = choosePackage.split(" ");
    var convertMonthToInt = parseInt(splitMonth[0]);
    var expiredDate;
    var newExpiredDate;
    if (status === "verified") {
      expiredDate = moment.unix(expired);

      newExpiredDate = moment.unix(expired).add(convertMonthToInt, "M");

      return (
        expiredDate.format("DD MMM YYYY") +
        " - " +
        newExpiredDate.format("DD MMM YYYY")
      );
    } else {
      expiredDate = moment();

      newExpiredDate = moment().add(convertMonthToInt, "M");

      return (
        expiredDate.format("DD MMM YYYY") +
        " - " +
        newExpiredDate.format("DD MMM YYYY")
      );
    }
  };

  return (
    <Root id="root">
      {activeStep > 0 ? (
        <div id="container">
          <Title id="title">ข้อมูลบัญชี</Title>
          <NameData id="name-data">
            {dataUserPayment[0].name ? dataUserPayment[0].name.data.th : ""}
          </NameData>
          <SubDataDetail id="sub-data">
            VerME:{" "}
            {dataUserPayment[0].vm_id
              ? dataUserPayment[0].vm_id.toUpperCase()
              : ""}{" "}
          </SubDataDetail>
          <SubDataDetail id="sub-data">
            หมดอายุ:{" "}
            {dataUserPayment[0].expired
              ? convertDate(dataUserPayment[0].expired)
              : ""}{" "}
          </SubDataDetail>
          <SubDataDetail id="sub-data">
            สถานะ:{" "}
            {dataUserPayment[0].status
              ? convertStatus(dataUserPayment[0].status)
              : ""}{" "}
          </SubDataDetail>
          <HrStyle />
        </div>
      ) : (
        ""
      )}
      <Title id="title">สรุปรายการสั่งซื้อ</Title>
      <StyledGrid id="grid-container" container>
        <Grid id="grid-item" item xs={9}>
          <Packge id="package">แพ็กเกจ VerME - {choosePackage}</Packge>
          <SubTitle id="subtitle">ใช้บริการของ VerME ได้ทั้งหมด</SubTitle>
          {activeStep > 0 ? (
            <SubTitle id="subtitle">
              จากวันที่{" "}
              {checkRangeDate(
                dataUserPayment[0].expired,
                dataUserPayment[0].status
              )}{" "}
            </SubTitle>
          ) : (
            ""
          )}
        </Grid>
        <Grid id="grid-item" item xs={3}>
          <Price id="price">{checkPrice(choosePackage)}</Price>
        </Grid>
      </StyledGrid>
      <HrStyle id="hr-style" />
      {/* <PromoCode>ใส่รหัสส่วนลด</PromoCode>
      <HrStyle /> */}
      <TotalPrice id="total-price">
        <div id="summary">ยอดรวมทั้งหมด</div>
        <div id="check-summary"> {checkSumPrice(choosePackage)} </div>
      </TotalPrice>
      <div id="bottom-div" style={{ display: "flex" }}>
        <StyledLightIc id="light-ic" style={{ marginRight: "0.63rem" }} />
        <div id="cancel-div">
          <CancelService id="cancel-service">
            ท่านสามารถยกเลิกบริการ VerME ได้ทุกเมื่อ
          </CancelService>
          {/* <CancelServiceButton>ยกเลิกการสมัคร</CancelServiceButton> */}
        </div>
      </div>
    </Root>
  );
}
