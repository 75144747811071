const Color = {
  primary: "#3b54d4",
  white: "#fff",
  black: "#000",
  grayDark: "#323639",
  primaryLight: "#465fdb",
  primaryDark: "#2a43c7",
  primaryOverlayDark: "#1e1e33",
  gradientPrimary1: "#fe9b7f",
  gradientPrimary2: "#4a3af7",
  surfaceGray: "#f7f8fa",
  statusWarning: "#ffca28",
  statusError: "#ff3b30",
  statusProgress: "#5ac8fa",
  statusSuccess: "#4cd964",
  textProgress: "#3dafe3",
  textSuccess: "#36c24e",
  textWarning: "#f5c01d",
  paleGrey: "#e7ecff",
  darkSlateBlue: "#111b47",
  paleGold: "#ffd166",
  bgPdf: "#535659",
  secondaryLight: "#3bdb9c",
  secondary: "#2fcb8d",
  secondaryDark: "#26bf82"
};

export default Color;
