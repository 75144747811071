import React, { useContext } from "react";
import styled from "styled-components";
import Title from "../common/Title";
import SubTitle from "./SubTitle";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import InputAdornment from "@material-ui/core/InputAdornment";
import ErrorMessage from "../common/ErrorMessage";
import StyledTextField from "../common/StyledTextField";
import IconAdorn from "../common/IconAdornment";
import { StoreContext } from "../Context/Store";
import AddressDatabase from "../AddressDatabase/thailand.json";
import _ from "lodash";

const AddressTextField = styled(StyledTextField)`
  && {
    height: auto;
    margin: 1.25rem 0 0;
  }
`;
export default function AddressStep(props) {
  const {
    address,
    province,
    district,
    subDistrict,
    postalCode,
    checkProvince,
    checkDistrict,
    checkSubDistrict
  } = useContext(StoreContext);

  const handleProvince = data => {
    province[1](data);
    district[1]({});
    subDistrict[1]({});
    postalCode[1]("");
  };

  const handleAmphoe = data => {
    district[1](data);
    subDistrict[1]({});
    postalCode[1]("");
  };

  const handleTambon = data => {
    subDistrict[1](data);
    postalCode[1](data.zipcode);
  };

  const provinceDatabase = () => {
    const result = _.map(AddressDatabase, item => item);
    return result;
  };

  const amphoesDatabase = () => {
    if (province[0]) {
      const result = _.map(province[0].amphoes, item => item);
      return result;
    }
    return [];
  };

  const tambonsDatabase = () => {
    if (district[0]) {
      const result = _.map(district[0].tambons, item => item);
      return result;
    }
    return [];
  };

  return (
    <Grid container justify="flex-start" id="root">
      <Grid item xs={12} id="titlegrid">
        <Title id="title">ที่อยู่ปัจจุบัน</Title>
      </Grid>
      <Grid item xs={12}>
        <SubTitle />
      </Grid>
      <Grid container spacing={2} id="container-grid">
        <Grid container item spacing={2} id="container-item-1">
          <Grid item xs={12} id="item-1">
            <AddressTextField
              id="address"
              name="address"
              label="ที่อยู่"
              multiline
              rows="3"
              inputRef={props.formProps.register}
              error={!!props.formProps.errors.address}
              placeholder="12/123 ซอยเวอร์มี ถนนปลอดภัย"
              variant="outlined"
              value={address[0]}
              onChange={e => address[1](e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="end" id="adornment-1">
                    <IconAdorn id="disabled-1" disabled />
                  </InputAdornment>
                )
              }}
            />
            {props.formProps.errors.address && (
              <ErrorMessage id="error-address">
                {props.formProps.errors.address.message}
              </ErrorMessage>
            )}
          </Grid>
        </Grid>
        <Grid container item spacing={2} id="container-item-2">
          <Grid item md={6} xs={12} id="item-2">
            <StyledTextField
              select
              id="province"
              name="province"
              label="จังหวัด"
              value={province[0]}
              onChange={e => handleProvince(e.target.value)}
              error={checkProvince[0] && province[0].name}
              margin="normal"
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="end" id="adornment-2">
                    <IconAdorn id="disabled-2" disabled />
                  </InputAdornment>
                )
              }}
            >
              {provinceDatabase().map((option, index) => (
                <MenuItem key={index} value={option} id="menu-item-1">
                  {option.name.th}
                </MenuItem>
              ))}
            </StyledTextField>
            {checkProvince[0] && (
              <ErrorMessage id="error-2">กรุณาเลือกจังหวัด</ErrorMessage>
            )}
          </Grid>
          <Grid item md={6} xs={12} id="item-3">
            <StyledTextField
              select
              id="district"
              name="district"
              error={checkDistrict[0]}
              label="เขต / อำเภอ"
              placeholder="เขต / อำเภอ"
              value={district[0]}
              margin="normal"
              variant="outlined"
              disabled={province[0].name ? false : true}
              onChange={e => handleAmphoe(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="end" id="adornment-3">
                    <IconAdorn id="disabled-3" disabled />
                  </InputAdornment>
                )
              }}
            >
              {amphoesDatabase().map((option, index) => (
                <MenuItem id="menu-item-2" key={index} value={option}>
                  {option.name.th}
                </MenuItem>
              ))}
            </StyledTextField>
            {checkDistrict[0] && (
              <ErrorMessage id="error-3">กรุณาเลือกเขต / อำเภอ</ErrorMessage>
            )}
          </Grid>
        </Grid>
        <Grid container item spacing={2} id="container-item-3">
          <Grid item md={6} xs={12} id="item-4">
            <StyledTextField
              select
              id="subDistrict"
              name="subDistrict"
              disabled={district[0].name ? false : true}
              value={subDistrict[0]}
              onChange={e => handleTambon(e.target.value)}
              error={checkSubDistrict[0]}
              label="แขวง / ตำบล"
              placeholder="แขวง / ตำบล"
              margin="normal"
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment id="adornment-4" position="end">
                    <IconAdorn id="disabled-4" disabled />
                  </InputAdornment>
                )
              }}
            >
              {tambonsDatabase().map((option, index) => (
                <MenuItem id="menu-item-3" key={index} value={option}>
                  {option.name.th}
                </MenuItem>
              ))}
            </StyledTextField>
            {checkSubDistrict[0] && (
              <ErrorMessage id="error-4">กรุณาเลือกแขวง / ตำบล</ErrorMessage>
            )}
          </Grid>
          <Grid item md={6} xs={12} id="item-5">
            <StyledTextField
              id="postalCode"
              name="postalCode"
              disabled={subDistrict[0].name ? false : true}
              inputRef={props.formProps.register}
              error={!!props.formProps.errors.postalCode}
              label="รหัสไปรษณีย์"
              placeholder="รหัสไปรษณีย์"
              margin="normal"
              variant="outlined"
              value={postalCode[0]}
              onChange={e => postalCode[1](e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment id="adornment-5" position="end">
                    <IconAdorn id="disabled-5" disabled />
                  </InputAdornment>
                )
              }}
              inputProps={{
                maxLength: 5
              }}
            />
            {props.formProps.errors.postalCode && (
              <ErrorMessage id="error-5">
                {props.formProps.errors.postalCode.message}
              </ErrorMessage>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
