import React, { useState, useContext } from "react";
import BackButton from "../common/components/BackButton";
import styled from "styled-components";
import { ReactComponent as LogoVerme } from "../assets/Logo/svg/logo_verme_color.svg";
import { ReactComponent as CheckIcon } from "../assets/Icons/ic_check_single.svg";
import colors from "../common/Color";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { RegisterContext } from "../Context/RegisterStore";

const Root = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: rgba(247, 248, 250, 0.24);
`;

const Logo = styled(LogoVerme)`
  width: 2.63rem;
  height: 2.25rem;
`;
const HeadDiv = styled.div`
  margin-right: 2.25rem;
  margin-left: 2.25rem;
  height: 10vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
const Box = styled.div`
  width: 65.7rem;
  border-radius: 0.5rem;
  box-shadow: 0 0.25rem 0.5rem 0 rgba(0, 0, 0, 0.2);
  background-color: ${colors.white};
  padding: 4rem 0;
  margin-bottom: 7.56rem;
  @media (max-width: 1025px) {
    width: 65.7vw;
    margin-bottom: 6rem;
  }
  @media (max-width: 768px) {
    width: 90vw;
    margin: 0 1.5rem 6rem 1.5rem;
  }
  @media (max-width: 474px) {
    width: 85vw;
    margin-bottom: 6rem;
  }
`;
const Title = styled.div`
  font-family: "SukhumvitSet-Bold";
  font-size: 2.13rem;
  color: ${colors.primary};
  letter-spacing: 0.02rem;
  text-align: center;
  margin-bottom: 1.5rem;
  @media (max-width: 768px) {
    display: none;
  }
`;
const TitleMobile = styled.div`
  font-family: "SukhumvitSet-Bold";
  font-size: 2.13rem;
  color: ${colors.primary};
  letter-spacing: 0.02rem;
  text-align: center;
  margin-bottom: 1.5rem;
  @media (min-width: 769px) {
    display: none;
  }
`;
const Rect = styled.div`
  /* width: 60.75rem; */
  border-radius: 0.75rem;
  margin: 0 5%;
  background-color: #fbfaff;
  /* margin: auto; */
  display: flex;
  flex-direction: column;
`;
const Order = styled.ol`
  font-family: "Sarabun-Regular";
  font-size: 1rem;
  line-height: 1.75rem;
  letter-spacing: 0.03rem;
  color: ${colors.black};
  opacity: 0.6;
  counter-reset: item;
  list-style-type: none;
  margin: 0.5rem;
  padding-left: 1rem;
  display: table;
`;
const List = styled.li`
  font-family: "Sarabun-Regular";
  font-size: 1rem;
  line-height: 1.75rem;
  letter-spacing: 0.03rem;
  color: ${colors.black};
  display: table;
  :before {
    content: counters(item, ".") ".";
    counter-increment: item;
    padding-right: 1rem;
    display: table-cell;
  }
`;
const Content = styled.div`
  padding: 0.5rem 0.5rem 0 0;
`;
const CheckText = styled.div`
  font-family: "Sarabun-Regular";
  font-size: 1rem;
  line-height: 1.75rem;
  letter-spacing: 0.03rem;
  color: ${colors.black};
  opacity: 0.6;
  @media (max-width: 1025px) {
    margin-top: 1.5rem;
  }
  @media screen and (min-width: 750px) and (max-width: 768px) {
    margin-top: 0;
  }
  @media (max-width: 431px) {
    margin-top: 3.5rem;
  }
`;
const Submit = styled(Button)`
  && {
    width: 10.81rem;
    height: 2.75rem;
    background-color: ${props => (props.disabled ? "#E4E4E4" : colors.primary)};
    font-family: "SukhumvitSet-Semibold";
    font-size: 0.875rem;
    line-height: 1rem;
    letter-spacing: 0.08rem;
    color: ${props => (props.disabled ? "#979797" : colors.white)};
    box-shadow: 0 0.063rem 0.063rem 0 rgba(0, 0, 0, 0.5);
    &:hover {
      background-color: ${colors.primaryDark};
    }
    @media (max-width: 768px) {
      width: 100%;
      margin: 0 5%;
    }
  }
`;
const CheckGroup = styled.div`
  position: relative;
  margin: 0.5rem;
`;
const CheckBox = styled.div`
  width: 1.38rem;
  height: 1.38rem;
  border-radius: 0.38rem;
  border: solid 0.063rem #d1d1d1;
  background-color: #f4f4f4;
  position: relative;
  cursor: pointer;
`;
const CheckedIcon = styled(CheckIcon)`
  font-size: 1.5rem;
  fill: ${colors.primary};
  position: absolute;
  top: 0rem;
  cursor: pointer;
`;
const CheckContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 1.25rem 2rem 2rem 5.28rem;
  @media (max-width: 1025px) {
    margin: 0 5% 1.5rem 5%;
  }
  @media (max-width: 768px) {
    margin: 1.25rem 5% 1.5rem 5%;
  }
  @media (max-width: 431px) {
    margin: -1rem 5% 1.5rem 5%;
  }
`;
const FlexCenter = styled.div`
  display: flex;
  justify-content: center;
`;
export default function Policy(props) {
  const [check, setCheck] = useState(false);
  const { checkPolicy } = useContext(RegisterContext);

  const handleCheck = () => {
    setCheck(true);
  };

  const handleUncheck = () => {
    setCheck(false);
  };

  const acceptTerm = () => {
    checkPolicy[1](true);
  };

  return (
    <Root id="root">
      <HeadDiv id="head-div">
        <BackButton id="back-btn" />
        <Logo id="logo" />
      </HeadDiv>
      <Grid
        id="container"
        container
        direction="column"
        justify="center"
        alignItems="center"
      >
        <Grid id="item" item xs={12} sm={12} md={12} lg={12}>
          <Box id="box">
            <Title id="title">นโยบายการรายงานผู้ขาย</Title>
            <TitleMobile id="title-mb">
              นโยบาย
              <br />
              การรายงานผู้ขาย
            </TitleMobile>
            <Rect id="rect">
              <Content id="content">
                <Order id="order">
                  <List id="list">
                    เพื่อใช้เป็นช่องทางรับร้องทุกข์ ร้องเรียน
                    ต่อการกระทำต่างๆที่ไม่ถูกต้อง เกี่ยวกับการกระทำผิดตามกฎหมาย
                    ผิดจริยธรรม ผิดจรรยาบรรณ และอื่นๆ
                  </List>
                  <List id="list">
                    ผู้รายงานควรให้ข้อมุลที่ชัดเจนและเป็นประโยชน์ต่อการสืบค้น
                    ซึ่งข้อมูลที่ผู้รายงานต้องให้กับทาง VerME มีดังนี้
                    <Order id="order" style={{ opacity: "1.6" }}>
                      <List id="list">ชื่อ - นามสกุล</List>
                      <List id="list">อีเมล</List>
                      <List id="list">เบอร์โทร</List>
                      <List id="list">
                        VerME ID ของบุคคลที่ผู้รายงานต้องการที่จะร้องทุกข์
                      </List>
                      <List id="list">
                        รายละเอียดเหตุ หรือ หลักฐานต่างๆ ที่ผู้รายงานมี
                      </List>
                    </Order>
                  </List>
                  <List id="list">
                    เพื่อที่ผู้ดำเนินการสามารแจ้งความคืบหน้า
                    หรือสอบถามรายละเอียดเพิ่มเติมเพื่อประโยชน์ในการตรวจสอบข้อเท็จจริง
                  </List>
                  <List id="list">
                    ข้อมูลของผู้รายงานทาง VerME
                    จะเก็บไว้เท่าที่จำเป็นภายใต้วัตถุประสงค์อันชอบด้วยกฎหมายของผู้ดูแลข้อมูล
                    หากผู้รายงานไม่ปฏิบัติ
                    ตามข้อกําหนดและเงื่อนไขตามคําขอใช้บริการฉบับนี้ในข้อใดข้อหนึ่ง
                    ทาง VerME
                    จะถือว่าท่านส่งข้อมูลมาไม่สมบูรณ์และส่งผลให้การดำเนินการสืบค้น
                    ตรวจสอบหรือระงับบัตรไม่สามารถดำเนินการต่อไปได้
                  </List>
                  <List id="list">
                    ผู้รายงานจำเป็นต้องเข้าใจ
                    และยอมรับข้อตกลงและเงื่อนไขต่างๆของ VerME ทั้งหมด
                  </List>
                  <List id="list">หากมีการรายงานที่เป็นเท็จอาจส่งผลตาม</List>
                </Order>
              </Content>
            </Rect>
            <CheckContainer id="check-container">
              <CheckGroup id="check-group">
                <CheckBox id="check-box" onClick={handleCheck} />
                {check === true ? <CheckedIcon onClick={handleUncheck} /> : ""}
              </CheckGroup>
              <CheckText id="check-txt">
                ข้าพเจ้าได้อ่านและเข้าใจเงื่อนไข ข้อกำหนดต่างๆของ VerME
                ดังที่กล่าวมาข้างต้นแล้ว
              </CheckText>
            </CheckContainer>
            <FlexCenter id="flex-center">
              {check === true ? (
                <Submit id="submit-btn" onClick={acceptTerm}>
                  ยอมรับ
                </Submit>
              ) : (
                <Submit id="submit-btn" disabled>
                  ยอมรับ
                </Submit>
              )}
            </FlexCenter>
          </Box>
        </Grid>
      </Grid>
    </Root>
  );
}
