import React from "react";
import styled from "styled-components";
import { ReactComponent as FirstImage } from "../assets/Images/img_id_card.svg";
import { ReactComponent as SecondImage } from "../assets/Images/img_reg_paper.svg";
import { ReactComponent as ThirdImage } from "../assets/Images/img_reg_bankbook.svg";
import Button from "@material-ui/core/Button";
import colors from "../common/Color";
import FormHeader from "../common/FormHeader";

const Root = styled.div`
  flex-grow: 1;
`;
const Title = styled.div`
  font-family: "SukhumvitSet-Text";
  font-size: 2.13rem;
  color: ${colors.primary};
  letter-spacing: 0.02rem;
  line-height: 3.38rem;
  @media (max-width: 1025px) {
    text-align: center;
    width: 17.5rem;
    margin: 6rem 0 2rem 0;
  }
`;

const Box = styled.div`
  width: 17.5rem;
  height: 17.5rem;
  background-color: #eff1ff;
  border-radius: 0.38rem;
  position: relative;
`;

const FirstImg = styled(FirstImage)`
  width: 13.28rem;
  height: 7.5rem;
  position: absolute;
  top: 5rem;
  left: 2.05rem;
`;

const SecImg = styled(SecondImage)`
  width: 13.25rem;
  height: 9.13rem;
  position: absolute;
  top: 4rem;
  left: 2rem;
`;

const ThirdImg = styled(ThirdImage)`
  width: 10.31rem;
  height: 10.19rem;
  position: absolute;
  top: 3.5rem;
  left: 3.5rem;
`;

const CenterContainer = styled.div`
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @media (max-width: 1025px) {
    flex-direction: column;
    height: 100%;
  }
  @media (min-width: 1025px) and (max-height: 750px) {
    height: 100%;
    margin-top: 5rem;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 58.75rem;
  margin: 4rem auto;
  justify-content: space-between;
  @media (max-width: 1025px) {
    flex-direction: column;
    width: 100%;
  }
`;
const ImageDiv = styled.div`
  position: relative;
  @media (max-width: 1025px) {
    margin: auto;
  }
`;
const Content = styled.div`
  font-family: "SukhumvitSet-Text";
  font-size: 1.5rem;
  text-align: center;
  color: #000;
  opacity: 0.87;
  margin-top: 1rem;
  @media (max-width: 1025px) {
    margin-bottom: 1.5rem;
  }
`;
const Submit = styled(Button)`
  && {
    width: 10.81rem;
    height: 2.75rem;
    background-color: ${colors.primary};
    font-family: "SukhumvitSet-Semibold";
    font-size: 0.875rem;
    line-height: 1rem;
    letter-spacing: 0.08rem;
    color: ${colors.white};
    box-shadow: 0 0.063rem 0.063rem 0 rgba(0, 0, 0, 0.5);
    &:hover {
      background-color: ${colors.primaryDark};
    }
    @media (max-width: 1025px) {
      width: 17.5rem;
      margin-top: 3rem;
    }
  }
`;
const SignInDiv = styled.div`
  margin-top: 1.5rem;
  display: flex;
  @media (max-width: 1025px) {
    margin-bottom: 6rem;
  }
`;
const SignInTxt = styled.a`
  font-family: "SukhumvitSet-Text";
  font-size: 16px;
  color: ${props => (props.primary ? colors.primary : "rgba(0,0,0,0.6)")};
  letter-spacing: 0.03rem;
  text-align: center;
  line-height: 1.75rem;
  cursor: ${props => (props.primary ? "pointer" : "auto")};
  text-decoration: none;
`;

export default function Prepare() {
  return (
    <Root id="root">
      <FormHeader id="form-header" />
      <CenterContainer id="center-container">
        <Title id="title">สิ่งที่ต้องเตรียมก่อนสมัครใช้บริการ</Title>
        <ContentContainer id="center-container">
          <ImageDiv id="img-div">
            <Box id="box" />
            <FirstImg id="first-img" />
            <Content id="content">
              1. บัตรประจำตัวประชาชน <br />
            </Content>
          </ImageDiv>
          <ImageDiv id="img-div">
            <Box id="box" />
            <SecImg id="sec-img" />
            <Content id="content">
              2. กระดาษเขียนคำว่า VerME <br /> พร้อมวันที่ ณ วันที่สมัคร
            </Content>
          </ImageDiv>
          <ImageDiv id="img-div">
            <Box id="box" />
            <ThirdImg id="third-img" />
            <Content id="content">
              3. เลขบัญชีธนาคาร <br />
              (อย่างน้อย 1 บัญชี)
            </Content>
          </ImageDiv>
        </ContentContainer>
        <Submit id="submit-btn" href="/register">
          สมัครใช้บริการ
        </Submit>
        <SignInDiv id="signin-div">
          <SignInTxt id="signin-txt">เป็นสมาชิกแล้ว? &nbsp;</SignInTxt>
          <SignInTxt id="signin-txt-primary" primary href="/sign-in">
            เข้าสู่ระบบ
          </SignInTxt>
        </SignInDiv>
      </CenterContainer>
    </Root>
  );
}
