import React, { Component } from "react";
import SingleHead from "./Header";
import { withStyles } from "@material-ui/core/styles";
import Verified from "./Verified-Example";
import chatIcon from "../assets/Icons/Icons Black/ic_chat.svg";
import primaryColor from "../assets/Colors/Icon/Primary.svg";
import Footer from "../common/Footer";
import qrCode from "../assets/QR code/qr-code.svg";
import MetaDecorator from "../MetaDecorator/MetaDecorator";

const styles = theme => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    height: "100vh",
    [theme.breakpoints.up("md")]: {
      height: "100vh"
    }
  },
  usercard: {
    position: "relative",
    width: "31.38rem",
    height: "17.9rem",
    borderRadius: "1.52rem",
    boxShadow: "0 0.25rem 0.5rem 0 rgba(0,0,0,0.20)",
    marginLeft: "auto",
    marginRight: "auto",
    [[theme.breakpoints.down(1025)]]: {
      width: "22.5rem",
      height: "12.8rem",
      boxShadow: "0 0.19rem 0.38rem 0 rgba(0,0,0,0.20)",
      borderRadius: "1.09rem",
      marginTop: "4.5rem"
    }
  },
  circleColor: {
    width: "3.5rem",
    height: "4rem",
    clipPath: "circle(1.75rem at center)",
    WebkitClipPath: "circle(1.75rem at center)",
    position: "relative",
    [[theme.breakpoints.down(1025)]]: {
      clipPath: "circle(1.315rem at center)",
      WebkitClipPath: "circle(1.315rem at center)"
    }
  },
  chaticon: {
    position: "absolute",
    alignSelf: "center",
    width: "2.1rem",
    [[theme.breakpoints.down(1025)]]: {
      width: "1.58rem"
    }
  },
  chatcircle: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    float: "right",
    filter: "drop-shadow(0 0.25rem 0.5rem rgba(0,0,0,0.20))",
    bottom: 0,
    marginRight: "2rem",
    [[theme.breakpoints.down(1025)]]: {
      marginRight: "0.75rem"
    }
  },
  svgcard: {
    enableBackground: "new 0 0 501 286",
    borderRadius: "1.52rem",
    [[theme.breakpoints.down(1025)]]: {
      borderRadius: "1.09rem"
    }
  },
  st0: {
    fillRule: "evenodd",
    clipRule: "evenodd",
    fill: "url(#SVGID_1_)"
  },
  st1: {
    filter: "url(#Adobe_OpacityMaskFilter)"
  },
  st2: {
    fillRule: "evenodd",
    clipRule: "evenodd",
    fill: "#FFFFFF"
  },
  st3: {
    mask: "url(#prefix__card_03-d_1_)",
    fillRule: "evenodd",
    clipRule: "evenodd",
    fill: "url(#SVGID_2_)"
  },
  st4: {
    filter: "url(#Adobe_OpacityMaskFilter_1_)"
  },
  st5: {
    mask: "url(#prefix__card_03-d_2_)",
    fillRule: "evenodd",
    clipRule: "evenodd",
    fill: "url(#SVGID_3_)"
  },
  st6: {
    filter: "url(#Adobe_OpacityMaskFilter_2_)"
  },
  st7: {
    mask: "url(#prefix__card_03-d_3_)",
    fillRule: "evenodd",
    clipRule: "evenodd",
    fill: "url(#SVGID_4_)"
  },
  st8: {
    filter: "url(#Adobe_OpacityMaskFilter_3_)"
  },
  st9: {
    mask: "url(#prefix__card_03-d_4_)",
    fillRule: "evenodd",
    clipRule: "evenodd",
    fill: "url(#SVGID_5_)"
  },
  st10: {
    filter: "url(#Adobe_OpacityMaskFilter_4_)"
  },
  st11: {
    mask: "url(#prefix__card_03-d_5_)"
  },
  st12: {
    filter: "url(#prefix__card_03-h)"
  },
  st13: {
    enableBackground: "new"
  },
  st14: {
    fill: "#FFFFFF"
  },
  st15: {
    fontFamily: "Courier-Bold"
  },
  st16: {
    fontSize: "20px"
  },
  st17: {
    filter: "url(#Adobe_OpacityMaskFilter_5_)"
  },
  st18: {
    mask: "url(#prefix__card_03-d_6_)"
  },
  st19: {
    fontFamily: "Courier New"
  },
  st20: {
    fontSize: "16px"
  },
  st21: {
    filter: "url(#Adobe_OpacityMaskFilter_6_)"
  },
  st22: {
    mask: "url(#prefix__card_03-d_7_)",
    enableBackground: "new"
  },
  st23: {
    filter: "url(#Adobe_OpacityMaskFilter_7_)"
  },
  st24: {
    mask: "url(#prefix__card_03-d_8_)"
  },
  st25: {
    filter: "url(#Adobe_OpacityMaskFilter_8_)"
  },
  st26: {
    mask: "url(#prefix__card_03-d_9_)"
  },
  st27: {
    filter: "url(#Adobe_OpacityMaskFilter_9_)"
  },
  st28: {
    mask: "url(#prefix__card_03-d_10_)",
    enableBackground: "new"
  },
  st29: {
    fontSize: "12px"
  },
  st30: {
    fill: "#FFFFFF",
    fontFamily: "Courier-Bold",
    fontSize: "20px"
  },
  st31: {
    fill: "#FFFFFF",
    fontFamily: "Courier New",
    fontWeight: "600",
    fontSize: "16px"
  },
  st32: {
    fill: "#FFFFFF",
    fontFamily: "Courier New",
    fontWeight: "600",
    fontSize: "12px"
  },
  qrCodeStyle: {
    right: "8.3%",
    width: "14%",
    bottom: "12%",
    position: "absolute",
    borderRadius: "3px"
  }
});

class SgVerExample extends Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <MetaDecorator
          description="มั่นใจทุกการขาย สบายใจทุกการซื้อ"
          title={window.location.hostname + window.location.pathname}
          // imageUrl={metaThumbnail}
          // imageAlt={blog.metaImageAlt}
        />
        <SingleHead></SingleHead>
        <div>
          <div className={classes.usercard}>
            <img
              src={qrCode}
              className={classes.qrCodeStyle}
              alt="example-qr-code"
            />
            <svg
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 501 286"
              className={classes.svgcard}
              xmlSpace="preserve"
            >
              <filter
                filterUnits="objectBoundingBox"
                height="158.3%"
                id="prefix__card_03-h"
                width="107.8%"
                x="-4.1%"
                y="-20.8%"
              >
                <feOffset
                  dy="2"
                  in="SourceAlpha"
                  result="shadowOffsetOuter1"
                ></feOffset>
                <feGaussianBlur
                  in="shadowOffsetOuter1"
                  result="shadowBlurOuter1"
                  stdDeviation="2"
                ></feGaussianBlur>
                <feColorMatrix
                  in="shadowBlurOuter1"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
                ></feColorMatrix>
              </filter>
              <g>
                <g>
                  <linearGradient
                    id="SVGID_1_"
                    gradientUnits="userSpaceOnUse"
                    x1="-147.4203"
                    y1="443.1343"
                    x2="-148.967"
                    y2="442.7789"
                    gradientTransform="matrix(501 0 0 -286 74500 126828)"
                  >
                    <stop offset="0" style={{ stopColor: "#FE9B7F" }} />
                    <stop offset="1" style={{ stopColor: "#4A3AF7" }} />
                  </linearGradient>
                  <path
                    className={classes.st0}
                    d="M24.3,0h452.4C490.1,0,501,10.9,501,24.3v237.4c0,13.4-10.9,24.3-24.3,24.3H24.3C10.9,286,0,275.1,0,261.7
                            V24.3C0,10.9,10.9,0,24.3,0z"
                  />
                </g>
                <defs>
                  <filter
                    id="Adobe_OpacityMaskFilter"
                    filterUnits="userSpaceOnUse"
                    x="-97.5"
                    y="-114.2"
                    width="260.4"
                    height="260.4"
                  >
                    <feColorMatrix
                      type="matrix"
                      values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"
                    />
                  </filter>
                </defs>
                <mask
                  maskUnits="userSpaceOnUse"
                  x="-97.5"
                  y="-114.2"
                  width="260.4"
                  height="260.4"
                  id="prefix__card_03-d_1_"
                >
                  <g className={classes.st1}>
                    <path
                      id="prefix__card_03-a_1_"
                      className={classes.st2}
                      d="M24.3,0h452.4C490.1,0,501,10.9,501,24.3v237.4c0,13.4-10.9,24.3-24.3,24.3H24.3
                            C10.9,286,0,275.1,0,261.7V24.3C0,10.9,10.9,0,24.3,0z"
                    />
                  </g>
                </mask>
                <linearGradient
                  id="SVGID_2_"
                  gradientUnits="userSpaceOnUse"
                  x1="-148.4172"
                  y1="442.9233"
                  x2="-148.1406"
                  y2="441.9553"
                  gradientTransform="matrix(172.4362 -107.7501 -107.7506 -172.437 73277.8281 60334.9141)"
                >
                  <stop offset="0" style={{ stopColor: "#FFBACB" }} />
                  <stop offset="1" style={{ stopColor: "#FF849C" }} />
                </linearGradient>
                <path
                  className={classes.st3}
                  d="M-85.2-30.2l128-80c12.3-7.7,28.4-3.9,36.1,8.3l80,128c7.7,12.3,3.9,28.4-8.3,36.1l-128,80
                    c-12.3,7.7-28.4,3.9-36.1-8.3l-80-128C-101.2-6.4-97.5-22.6-85.2-30.2L-85.2-30.2z"
                />
                <defs>
                  <filter
                    id="Adobe_OpacityMaskFilter_1_"
                    filterUnits="userSpaceOnUse"
                    x="-9.9"
                    y="179.6"
                    width="340.4"
                    height="340.4"
                  >
                    <feColorMatrix
                      type="matrix"
                      values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"
                    />
                  </filter>
                </defs>
                <mask
                  maskUnits="userSpaceOnUse"
                  x="-9.9"
                  y="179.6"
                  width="340.4"
                  height="340.4"
                  id="prefix__card_03-d_2_"
                >
                  <g className={classes.st4}>
                    <path
                      id="prefix__card_03-a_2_"
                      className={classes.st2}
                      d="M24.3,0h452.4C490.1,0,501,10.9,501,24.3v237.4c0,13.4-10.9,24.3-24.3,24.3H24.3
                            C10.9,286,0,275.1,0,261.7V24.3C0,10.9,10.9,0,24.3,0z"
                    />
                  </g>
                </mask>
                <linearGradient
                  id="SVGID_3_"
                  gradientUnits="userSpaceOnUse"
                  x1="-147.8041"
                  y1="443.0712"
                  x2="-148.1829"
                  y2="442.7995"
                  gradientTransform="matrix(221.7035 -138.5357 -138.5357 -221.7035 94374.8594 77946.75)"
                >
                  <stop offset="0" style={{ stopColor: "#FFDDCD" }} />
                  <stop offset="1" style={{ stopColor: "#FFB99F" }} />
                </linearGradient>
                <path
                  className={classes.st5}
                  d="M2.4,294.3l177.3-110.8c12.3-7.7,28.4-3.9,36.1,8.3l110.8,177.3c7.7,12.3,3.9,28.4-8.3,36.1L140.9,516
                    c-12.3,7.7-28.4,3.9-36.1-8.3L-6,330.4C-13.6,318.2-9.9,302,2.4,294.3L2.4,294.3z"
                />
                <defs>
                  <filter
                    id="Adobe_OpacityMaskFilter_2_"
                    filterUnits="userSpaceOnUse"
                    x="238.6"
                    y="-207.6"
                    width="297.1"
                    height="297.1"
                  >
                    <feColorMatrix
                      type="matrix"
                      values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"
                    />
                  </filter>
                </defs>
                <mask
                  maskUnits="userSpaceOnUse"
                  x="238.6"
                  y="-207.6"
                  width="297.1"
                  height="297.1"
                  id="prefix__card_03-d_3_"
                >
                  <g className={classes.st6}>
                    <path
                      id="prefix__card_03-a_3_"
                      className={classes.st2}
                      d="M24.3,0h452.4C490.1,0,501,10.9,501,24.3v237.4c0,13.4-10.9,24.3-24.3,24.3H24.3
                            C10.9,286,0,275.1,0,261.7V24.3C0,10.9,10.9,0,24.3,0z"
                    />
                  </g>
                </mask>
                <linearGradient
                  id="SVGID_4_"
                  gradientUnits="userSpaceOnUse"
                  x1="-148.56"
                  y1="442.8315"
                  x2="-148.06"
                  y2="442.3315"
                  gradientTransform="matrix(195.0511 -121.8814 -121.8814 -195.0511 83257.6875 68190.75)"
                >
                  <stop offset="0" style={{ stopColor: "#C763BB" }} />
                  <stop offset="1" style={{ stopColor: "#BC63C2" }} />
                </linearGradient>
                <path
                  className={classes.st7}
                  d="M250.9-109.5l150.6-94.1c12.3-7.7,28.4-3.9,36.1,8.3l94.1,150.6c7.7,12.3,3.9,28.4-8.3,36.1L372.8,85.6
                    c-12.3,7.7-28.4,3.9-36.1-8.3L242.6-73.4C234.9-85.6,238.7-101.8,250.9-109.5z"
                />
                <defs>
                  <filter
                    id="Adobe_OpacityMaskFilter_3_"
                    filterUnits="userSpaceOnUse"
                    x="376"
                    y="100.3"
                    width="297.1"
                    height="297.1"
                  >
                    <feColorMatrix
                      type="matrix"
                      values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"
                    />
                  </filter>
                </defs>
                <mask
                  maskUnits="userSpaceOnUse"
                  x="376"
                  y="100.3"
                  width="297.1"
                  height="297.1"
                  id="prefix__card_03-d_4_"
                >
                  <g className={classes.st8}>
                    <path
                      id="prefix__card_03-a_4_"
                      className={classes.st2}
                      d="M24.3,0h452.4C490.1,0,501,10.9,501,24.3v237.4c0,13.4-10.9,24.3-24.3,24.3H24.3
                            C10.9,286,0,275.1,0,261.7V24.3C0,10.9,10.9,0,24.3,0z"
                    />
                  </g>
                </mask>
                <linearGradient
                  id="SVGID_5_"
                  gradientUnits="userSpaceOnUse"
                  x1="-148.3075"
                  y1="442.5809"
                  x2="-148.7983"
                  y2="442.7017"
                  gradientTransform="matrix(195.0511 -121.8814 -121.8814 -195.0511 83395.0703 68498.5938)"
                >
                  <stop offset="0" style={{ stopColor: "#00C9EC" }} />
                  <stop offset="1" style={{ stopColor: "#0098D5" }} />
                </linearGradient>
                <path
                  className={classes.st9}
                  d="M388.3,198.4l150.6-94.1c12.3-7.7,28.4-3.9,36.1,8.3l94.1,150.6c7.7,12.3,3.9,28.4-8.3,36.1l-150.6,94.1
                    c-12.3,7.7-28.4,3.9-36.1-8.3L380,234.5C372.3,222.2,376,206,388.3,198.4L388.3,198.4z"
                />
                <defs>
                  <filter
                    id="Adobe_OpacityMaskFilter_4_"
                    filterUnits="userSpaceOnUse"
                    x="15.4"
                    y="226.1"
                    width="207"
                    height="38"
                  >
                    <feColorMatrix
                      type="matrix"
                      values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"
                    />
                  </filter>
                </defs>
                <mask
                  maskUnits="userSpaceOnUse"
                  x="15.4"
                  y="226.1"
                  width="207"
                  height="38"
                  id="prefix__card_03-d_5_"
                >
                  <g className={classes.st10}>
                    <path
                      id="prefix__card_03-a_5_"
                      className={classes.st2}
                      d="M24.3,0h452.4C490.1,0,501,10.9,501,24.3v237.4c0,13.4-10.9,24.3-24.3,24.3H24.3
                        C10.9,286,0,275.1,0,261.7V24.3C0,10.9,10.9,0,24.3,0z"
                    />
                  </g>
                </mask>
                <g className={classes.st11}>
                  <g className={classes.st12}>
                    <g className={classes.st13}>
                      <text
                        transform="matrix(1 0 0 1 23.524 246.81)"
                        className={classes.st30}
                        id="nameShadow"
                      >
                        Mr.Verme Secure
                      </text>
                    </g>
                  </g>
                  <g>
                    <g className={classes.st13}>
                      <text
                        transform="matrix(1 0 0 1 23.524 246.81)"
                        className={classes.st30}
                        id="name"
                      >
                        Mr.Verme Secure
                      </text>
                    </g>
                  </g>
                </g>
                <defs>
                  <filter
                    id="Adobe_OpacityMaskFilter_5_"
                    filterUnits="userSpaceOnUse"
                    x="287.7"
                    y="209"
                    width="60"
                    height="46.6"
                  >
                    <feColorMatrix
                      type="matrix"
                      values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"
                    />
                  </filter>
                </defs>
                <mask
                  maskUnits="userSpaceOnUse"
                  x="287.7"
                  y="209"
                  width="60"
                  height="46.6"
                  id="prefix__card_03-d_6_"
                >
                  <g className={classes.st17}>
                    <path
                      id="prefix__card_03-a_6_"
                      className={classes.st2}
                      d="M24.3,0h452.4C490.1,0,501,10.9,501,24.3v237.4c0,13.4-10.9,24.3-24.3,24.3H24.3
                            C10.9,286,0,275.1,0,261.7V24.3C0,10.9,10.9,0,24.3,0z"
                    />
                  </g>
                </mask>
                <g className={classes.st18}>
                  <g className={classes.st13}>
                    <text
                      transform="matrix(1 0 0 1 287.728 247.559)"
                      className={classes.st30}
                      id="expiry"
                    >
                      XX/XX
                    </text>
                  </g>
                  <g className={classes.st13}>
                    <text
                      transform="matrix(1 0 0 1 287.725 221.654)"
                      className={classes.st31}
                    >
                      Expiry
                    </text>
                  </g>
                </g>
                <defs>
                  <filter
                    id="Adobe_OpacityMaskFilter_6_"
                    filterUnits="userSpaceOnUse"
                    x="23.5"
                    y="208.2"
                    width="105.6"
                    height="19.2"
                  >
                    <feColorMatrix
                      type="matrix"
                      values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"
                    />
                  </filter>
                </defs>
                <mask
                  maskUnits="userSpaceOnUse"
                  x="23.5"
                  y="208.2"
                  width="105.6"
                  height="19.2"
                  id="prefix__card_03-d_7_"
                >
                  <g className={classes.st21}>
                    <path
                      id="prefix__card_03-a_7_"
                      className={classes.st2}
                      d="M24.3,0h452.4C490.1,0,501,10.9,501,24.3v237.4c0,13.4-10.9,24.3-24.3,24.3H24.3
                            C10.9,286,0,275.1,0,261.7V24.3C0,10.9,10.9,0,24.3,0z"
                    />
                  </g>
                </mask>
                <g className={classes.st22}>
                  <text
                    transform="matrix(1 0 0 1 23.524 220.81)"
                    className={classes.st31}
                  >
                    Card Holder
                  </text>
                </g>
                <defs>
                  <filter
                    id="Adobe_OpacityMaskFilter_7_"
                    filterUnits="userSpaceOnUse"
                    x="32.4"
                    y="27.7"
                    width="30.5"
                    height="26.7"
                  >
                    <feColorMatrix
                      type="matrix"
                      values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"
                    />
                  </filter>
                </defs>
                <mask
                  maskUnits="userSpaceOnUse"
                  x="32.4"
                  y="27.7"
                  width="30.5"
                  height="26.7"
                  id="prefix__card_03-d_8_"
                >
                  <g className={classes.st23}>
                    <path
                      id="prefix__card_03-a_8_"
                      className={classes.st2}
                      d="M24.3,0h452.4C490.1,0,501,10.9,501,24.3v237.4c0,13.4-10.9,24.3-24.3,24.3H24.3
                            C10.9,286,0,275.1,0,261.7V24.3C0,10.9,10.9,0,24.3,0z"
                    />
                  </g>
                </mask>
                <g className={classes.st24}>
                  <path
                    className={classes.st14}
                    d="M44.7,27.7v1.9h-2v2h-5.3L47.7,49l10.2-17.4h-7.3v-1.9h2v-2h7.8c1.4,0,2.5,1.1,2.5,2.5
                        c0,0.4-0.1,0.9-0.3,1.2L49.8,53.1c-0.7,1.2-2.2,1.6-3.4,0.9c-0.4-0.2-0.7-0.5-0.9-0.9L32.8,31.4c-0.7-1.2-0.3-2.7,0.9-3.4
                        c0,0,0,0,0,0c0.4-0.2,0.8-0.3,1.3-0.3L44.7,27.7L44.7,27.7z M47.7,41.2c0.8,0,1.4,0.6,1.4,1.4c0,0.4-0.2,0.7-0.4,1v0h0
                        c-0.3,0.3-0.6,0.4-1,0.4c-0.8,0-1.4-0.6-1.4-1.4C46.3,41.9,46.9,41.2,47.7,41.2z M48.7,33.6v2h-2v-2H48.7z M46.7,31.6v2h-2v-2
                        H46.7z"
                  />
                </g>
                <defs>
                  <filter
                    id="Adobe_OpacityMaskFilter_8_"
                    filterUnits="userSpaceOnUse"
                    x="389.5"
                    y="179.8"
                    width="72.2"
                    height="72.2"
                  >
                    <feColorMatrix
                      type="matrix"
                      values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"
                    />
                  </filter>
                </defs>
                <mask
                  maskUnits="userSpaceOnUse"
                  x="389.5"
                  y="179.8"
                  width="72.2"
                  height="72.2"
                  id="prefix__card_03-d_9_"
                >
                  <g className={classes.st25}>
                    <path
                      id="prefix__card_03-a_9_"
                      className={classes.st2}
                      d="M24.3,0h452.4C490.1,0,501,10.9,501,24.3v237.4c0,13.4-10.9,24.3-24.3,24.3H24.3
                            C10.9,286,0,275.1,0,261.7V24.3C0,10.9,10.9,0,24.3,0z"
                    />
                  </g>
                </mask>
                {/* <g className={classes.st26}>
                <g transform="translate(389.524 179.81)">
                    <path className={classes.st14} d="M3.8,0h64.6c2.1,0,3.8,1.7,3.8,3.8v64.6c0,2.1-1.7,3.8-3.8,3.8H3.8c-2.1,0-3.8-1.7-3.8-3.8V3.8
                        C0,1.7,1.7,0,3.8,0z"/>
                    <path id="logov" d="M34.6,30.4h-1v1H31l5.1,8.8l5.1-8.8h-3.6v-1h1v-1h3.9c0.7,0,1.2,0.5,1.3,1.2c0,0.2-0.1,0.4-0.2,0.6l-6.4,10.9
                            c-0.4,0.6-1.1,0.8-1.7,0.5c-0.2-0.1-0.3-0.3-0.5-0.5l-6.4-10.9c-0.3-0.6-0.1-1.4,0.5-1.7c0,0,0,0,0,0c0.2-0.1,0.4-0.2,0.6-0.2
                            h4.9C34.6,29.4,34.6,30.4,34.6,30.4z"/> 
                    </g>
                </g> */}
                <defs>
                  <filter
                    id="Adobe_OpacityMaskFilter_9_"
                    filterUnits="userSpaceOnUse"
                    x="391.5"
                    y="167.3"
                    width="68.2"
                    height="14.4"
                  >
                    <feColorMatrix
                      type="matrix"
                      values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"
                    />
                  </filter>
                </defs>
                <mask
                  maskUnits="userSpaceOnUse"
                  x="391.5"
                  y="167.3"
                  width="68.2"
                  height="14.4"
                  id="prefix__card_03-d_10_"
                >
                  <g className={classes.st27}>
                    <path
                      id="prefix__card_03-a_10_"
                      className={classes.st2}
                      d="M24.3,0h452.4C490.1,0,501,10.9,501,24.3v237.4c0,13.4-10.9,24.3-24.3,24.3H24.3
                            C10.9,286,0,275.1,0,261.7V24.3C0,10.9,10.9,0,24.3,0z"
                    />
                  </g>
                </mask>
                <g className={classes.st28}>
                  <text
                    transform="matrix(1 0 0 1 391.524 176.81)"
                    className={classes.st32}
                    id="userid"
                  >
                    VM-XXXXXX
                  </text>
                </g>
              </g>
            </svg>
          </div>
          <div></div>
          <Verified></Verified>
        </div>
        <div>
          <div className={classes.chatBtn}>
            <a
              href="https://verme.page.link/check-identity"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className={classes.chatcircle}>
                <img
                  className={classes.circleColor}
                  src={primaryColor}
                  alt="color"
                />
                <img
                  className={classes.chaticon}
                  src={chatIcon}
                  alt="chaticon"
                />
              </div>
            </a>
          </div>
          <Footer></Footer>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(SgVerExample);
