import React, { useContext } from "react";
import styled from "styled-components";
import colors from "../common/Color";
import FormHeader from "../common/FormHeader";
import Button from "@material-ui/core/Button";
import { ReactComponent as LogoFacebook } from "../assets/Icons/Social/icons-icons-ic-facebook.svg";
import { StoreContext } from "../Context/Store";
/*global FB*/

const Container = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color:  ${colors.surfaceGray};
  @media (max-width: 767px) {
    /* height: 90vh; */
  }
`;
const FlexCenter = styled.div`
  display: flex;
  justify-content: center;
`;
const Box = styled.div`
  width: 624px;
  height: 536px;
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.12), 0 4px 8px 0 rgba(0,0,0,0.12);
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 767px){
    width: 86%;
    height: 100%;
    margin-top: 80px;
    margin-bottom: 32px;
  }
`;
const Title = styled.div`
  font-family: "SukhumvitSet-bold";
  font-size: 34px;
  line-height: 1.41;
  letter-spacing: 0.4px;
  text-align: center;
  color: #3b54d4;
  margin-bottom: 13px;
  @media (max-width: 767px){
    font-size: 24px;
    font-family: "SukhumvitSet-SemiBold";
    margin: 16px 60px 13px;
  }
`;
const SubTitle = styled.div`
  opacity: 0.87;
  font-family: "SukhumvitSet-Semibold";
  font-size: 20px;
  letter-spacing: 0.25px;
  text-align: center;
  color: black;
  margin: 24px 0 8px;
  @media (max-width: 767px){
    margin: 30px 0 8px;
  }
`;
const TextGray = styled.div`
  font-family: "SukhumvitSet-Text";
  font-size: 16px;
  line-height: 1.75;
  letter-spacing: 0.5px;
  text-align: ${props => props.list ? "left" : "center"};
  color: rgba(0, 0, 0, 0.6);
  margin: ${props => props.list ? "0" : "0 83px"};
  @media (max-width: 767px){
    margin: ${props => props.list ? "0" : "0 3.7%"};
  }
`;
const RegistBtn = styled(Button)`
  && {
    width: 328px;
    height: 40px;
    border-radius: 4px;
    font-family: "HelveticaNeue";
    font-weight: bold;
    color: white;
    font-size: 0.88rem;
    letter-spacing: 0.25px;
    background-color: #1877f2;
    margin: 46px 0 0;
    text-transform: none;
    &:hover{
      background-color: #1877f2;
    }
    @media (max-width: 767px) {
      margin: 40px 0 32px;
      width: 88%;
    }
  }
`;
const FbLogo = styled(LogoFacebook)`
  width: 24px;
  height: 24px;
  fill: white;
  margin-right: 8px;
`;
const StyledUl = styled.ul`
  color: ${colors.primary};
  padding: 0;
  font-size: 21px;
  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline-start: 14px;

`;
const StyledList = styled.li`
  width: 155px;
  margin-bottom: 9px;
  &:before{
    color: ${colors.primary};
    width: 8px;
    line-height: 0;
  }
  @media (max-width: 767px) {
    margin-bottom: 7px;
  }
`;
const content = ["ชื่อและนามสกุลของคุณ", "อีเมลของคุณ", "ลิงก์โพรไฟล์ของคุณ"];
export default function FacebookRegister() {
  const { facebookUser, email, facebookPic } = useContext(StoreContext);

  const loginWithFacebook = () => {
    try {
      FB.login(
        res => {
          if (res.status === "connected") {
            checkPermission(res.authResponse.userID);
          }
        },
        {
          scope: ["email", "user_link"]
        }
      );
    } catch (err) {
      console.log(err);
    }
  };

  const checkPermission = item => {
    try {
      FB.api(item, { fields: "email, name" }, res => {
        facebookUser[1](res);
        email[1](res.email);
      });
      FB.api(item, "GET", { fields: "picture.width(640).height(640)" }, res => {
        facebookPic[1](res.picture.data);
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div id="root">
      <FormHeader id="form-header" />
      <FlexCenter id="flex-center">
        <Container id="container">
          <Box>
            <Title>สมัครด้วยบัญชี Facebook</Title>
            <TextGray>สมัครใช้บริการด้วย Facebook เพื่อให้เพิ่มความปลอดภัยในการซื้อขาย
บน Social ของคุณ</TextGray>
            <SubTitle>VerME ต้องการเข้าถึง</SubTitle>
            <StyledUl>
              {content.map((item, index) => (
                <StyledList key={index}><TextGray list="true">{item}</TextGray></StyledList>
              ))}
            </StyledUl>
            <RegistBtn id="regist-btn" onClick={loginWithFacebook}>
              <FbLogo id="fb-logo" />
            สมัครใช้บริการด้วย Facebook
          </RegistBtn>
          </Box>
        </Container>
      </FlexCenter>
    </div>
  );
}
