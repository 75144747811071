import React from "react";
import { withStyles } from "@material-ui/core/styles";
import IdentityCheckContent from "./CenterContainer";
import Footer from "../common/Footer";
import chatIcon from "../assets/Icons/Icons Black/ic_chat.svg";
import primaryColor from "../assets/Colors/Icon/Primary.svg";
import BackButton from "../common/components/BackButton";

const styles = theme => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    backgroundColor: "#80808014",
    [theme.breakpoints.up("md", "lg", "xl")]: {
      minHeight: "100vh"
    },
    [theme.breakpoints.down("sm")]: {
      backgroundColor: "#00000000",
      minHeight: "100vh"
    }
  },
  circleColor: {
    width: "3.5rem",
    height: "4rem",
    clipPath: "circle(1.75rem at center)",
    WebkitClipPath: "circle(1.75rem at center)",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      clipPath: "circle(1.315rem at center)"
    }
  },
  chaticon: {
    position: "absolute",
    alignSelf: "center",
    width: "2.1rem",
    [theme.breakpoints.down("sm")]: {
      width: "1.58rem"
    }
  },
  chatcircle: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    float: "right",
    filter: "drop-shadow(0 0.25rem 0.5rem rgba(0,0,0,0.20))",
    bottom: 0,
    marginRight: "2rem",
    [theme.breakpoints.down("sm")]: {
      marginRight: "0.75rem"
    }
  },
  backButton: {
    [theme.breakpoints.up("md", "lg")]: {
      display: "none"
    },
    [theme.breakpoints.down("sm")]: {
      padding: "2rem 1rem 2rem 1rem"
    }
  }
});

export function IdentityCheckPage(props) {
  const { classes } = props;
  return (
    <div id="root" className={classes.root}>
      <div id="btn-div" className={classes.backButton}>
        <BackButton id="back-btn" />
      </div>
      <IdentityCheckContent id="id-check-ct" />
      <div id="chat-btn" className={classes.chatBtn}>
        <a
          id="link"
          href="https://verme.page.link/check-identity"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div id="chat-circle" className={classes.chatcircle}>
            <img
              id="img-circle"
              className={classes.circleColor}
              src={primaryColor}
              alt="color"
            />
            <img
              id="img-ic"
              className={classes.chaticon}
              src={chatIcon}
              alt="chaticon"
            />
          </div>
        </a>
        <div id="blank"></div>
        <Footer id="footer" />
      </div>
    </div>
  );
}

export default withStyles(styles)(IdentityCheckPage);
