import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import logoVerMe from "../assets/Logo/svg/logo_verme_color.svg";
import repIcon from "../assets/Icons/Icons Black/ic_report_outlined.svg";
import { Link } from "react-router-dom";

const styles = theme => ({
  root: {
    top: 0,
    marginLeft: "4rem",
    marginRight: "4rem",
    [theme.breakpoints.down(1025)]: {
      marginLeft: "1.25rem",
      marginRight: "1.5rem"
    }
  },
  sghead: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "5.75rem",
    [theme.breakpoints.down(1025)]: {
      height: "4rem"
    }
  },
  logoimg: {
    width: "2.63rem",
    height: "2.25rem",
    [theme.breakpoints.down(1025)]: {
      width: "2.25rem",
      height: "2rem"
    }
  },
  icon: {
    width: "2rem",
    [theme.breakpoints.down(1025)]: {
      width: "1.5rem"
    }
  }
});

class SingleHead extends Component {
  render() {
    const { classes } = this.props;
    return (
      <div id="root" className={classes.root}>
        <div id="head" className={classes.sghead}>
          <Link id="link" to="/">
            <img
              id="img"
              className={classes.logoimg}
              src={logoVerMe}
              alt="logo"
            />
          </Link>
          <a
            id="link"
            href="/report-form"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              id="img"
              className={classes.icon}
              src={repIcon}
              alt="repicon"
            />
          </a>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(SingleHead);
