import React, { useState } from "react";
import colors from "../common/Color";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { ReactComponent as CheckIcon } from "../assets/Icons/ic_check_single.svg";

const Root = styled.div`
  height: 100%;
  width: 100%;
`;

const Box = styled.div`
  border-radius: 0.5rem;
  background-color: ${colors.surfaceGray};
  padding: 4rem 0;
  @media (max-width: 1025px) {
    width: 65.7vw;
    margin-bottom: 6rem;
  }
  @media (max-width: 768px) {
    width: 90vw;
    margin: 0 1.5rem 6rem 1.5rem;
  }
  @media (max-width: 474px) {
    width: 85vw;
    margin-bottom: 6rem;
  }
`;
const Title = styled.div`
  font-family: "SukhumvitSet-Text";
  font-size: 2.13rem;
  color: ${colors.primary};
  letter-spacing: 0.25px;
  text-align: center;
  margin-bottom: 1.5rem;
  @media (max-width: 768px) {
    display: none;
  }
`;
const TitleMobile = styled.div`
  font-family: "SukhumvitSet-Bold";
  font-size: 2.13rem;
  color: ${colors.primary};
  letter-spacing: 0.02rem;
  text-align: center;
  margin-bottom: 1.5rem;
  @media (min-width: 769px) {
    display: none;
  }
`;
const Rect = styled.div`
  border-radius: 0.75rem;
  margin: 0 5%;
  background-color: #fbfaff;
  display: flex;
  flex-direction: column;
`;
const Order = styled.ol`
  font-family: "Sarabun-Regular";
  font-size: 1rem;
  line-height: 1.75rem;
  letter-spacing: 0.03rem;
  color: ${colors.black};
  opacity: 0.6;
  counter-reset: item;
  list-style-type: none;
  margin: 0.5rem;
  padding-left: 1rem;
  display: table;
`;
const List = styled.li`
  font-family: "Sarabun-Regular";
  font-size: 1rem;
  line-height: 1.75rem;
  letter-spacing: 0.03rem;
  color: ${colors.black};
  display: table;
  :before {
    content: counters(item, ".") ".";
    counter-increment: item;
    padding-right: 1rem;
    display: table-cell;
  }
`;
const Content = styled.div`
  padding: 0.5rem 0.5rem 0 0;
`;
const CheckText = styled.div`
  font-family: "Sarabun-Regular";
  font-size: 1rem;
  line-height: 1.75rem;
  letter-spacing: 0.03rem;
  color: ${colors.black};
  opacity: 0.6;
  @media (max-width: 1025px) {
    margin-top: 1.5rem;
  }
  @media screen and (min-width: 750px) and (max-width: 768px) {
    margin-top: 0;
  }
  @media (max-width: 431px) {
    margin-top: 3.5rem;
  }
`;
const Submit = styled(Button)`
  && {
    width: 10.81rem;
    height: 2.75rem;
    background-color: ${props => (props.disabled ? "#E4E4E4" : colors.primary)};
    font-family: "SukhumvitSet-Semibold";
    font-size: 0.875rem;
    line-height: 1rem;
    letter-spacing: 0.08rem;
    color: ${props => (props.disabled ? "#979797" : colors.white)};
    box-shadow: 0 0.063rem 0.063rem 0 rgba(0, 0, 0, 0.5);
    &:hover {
      background-color: ${colors.primaryDark};
    }
    @media (max-width: 768px) {
      width: 100%;
      margin: 0 5%;
    }
  }
`;
const CheckGroup = styled.div`
  position: relative;
  margin: 0.5rem;
`;
const CheckBox = styled.div`
  width: 1.38rem;
  height: 1.38rem;
  border-radius: 0.38rem;
  border: solid 0.063rem #d1d1d1;
  background-color: #f4f4f4;
  position: relative;
  cursor: pointer;
`;
const CheckedIcon = styled(CheckIcon)`
  font-size: 1.5rem;
  fill: ${colors.primary};
  position: absolute;
  top: 0rem;
  cursor: pointer;
`;
const CheckContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1.25rem 2rem 2rem 5.28rem;
  @media (max-width: 1025px) {
    margin: 0 5% 1.5rem 5%;
  }
  @media (max-width: 768px) {
    margin: 1.25rem 5% 1.5rem 5%;
  }
  @media (max-width: 431px) {
    margin: -1rem 5% 1.5rem 5%;
  }
`;
const FlexCenter = styled.div`
  display: flex;
  justify-content: center;
`;

export default function PromotionPage(props) {
  const [termPolicyCheck, setTermPolicyCheck] = useState(false);

  const handleCheck = () => {
    setTermPolicyCheck(true);
  };

  const handleUncheck = () => {
    setTermPolicyCheck(false);
  };

  return (
    <Root id="root">
      <Grid
        id="container"
        container
        direction="column"
        justify="center"
        alignItems="center"
      >
        <Grid id="grid-item" item xs={12} sm={12} md={12} lg={12}>
          <Box id="box">
            <Title id="title">ข้อตกลง และการใช้บริการ</Title>
            <TitleMobile id="title-mb">
              ข้อตกลง
              <br />
              การใช้บริการ
            </TitleMobile>
            <Rect id="rect">
              <Content id="content">
                <Order id="order">
                  <List id="list">
                    ระยะเวลาใช้งานฟรี 3 เดือน เท่ากับ 90 วัน
                  </List>
                  <List id="list">
                    สำหรับผู้ใช้บริการที่เป็นสมาชิกอยู่ และยังไม่หมดอายุ
                    เมื่อรับสิทธิ์ตามโปรโมชั่นนี้จะได้วันใช้งานเพิ่มต่ออีก 90
                    วัน
                  </List>
                  <List id="list">
                    ระยะเวลาในการรับสิทธิ์ตั้งเเต่วันที่ 14-30 เมษายน 2563
                  </List>
                  <List id="list">
                    บริษัทขอสงวนสิทธิ์การเปลี่ยนเงื่อนไขโดยไม่เเจ้งให้ทราบล่วงหน้า
                  </List>
                  <List id="list">
                    เงื่อนไขอื่นๆ ให้เป็นไปตามที่บริษัทกำหนด
                  </List>
                </Order>
              </Content>
            </Rect>
            <CheckContainer id="check-container">
              <CheckGroup id="check-group">
                <CheckBox id="check-box" onClick={handleCheck} />
                {termPolicyCheck ? (
                  <CheckedIcon id="check-ic" onClick={handleUncheck} />
                ) : (
                  ""
                )}
              </CheckGroup>
              <CheckText id="check-txt">
                ข้าพเจ้าได้อ่านและเข้าใจเงื่อนไข ข้อกำหนดต่างๆของ VerME
                ดังที่กล่าวมาข้างต้นแล้ว
              </CheckText>
            </CheckContainer>
            <FlexCenter id="flex-center">
              {termPolicyCheck ? (
                <Submit id="submit" onClick={props.handleTerm}>
                  ยอมรับ
                </Submit>
              ) : (
                <Submit id="submit" disabled>
                  ยอมรับ
                </Submit>
              )}
            </FlexCenter>
          </Box>
        </Grid>
      </Grid>
    </Root>
  );
}
