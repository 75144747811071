import styled from "styled-components";
import colors from "../common/Color";

const Divider = styled.div`
  width: 8.75rem;
  height: 0.25rem;
  background: ${colors.primary};
  border-radius: 0.13rem;
  margin: 1.5rem auto;
  @media (max-width: 1024px) {
    margin: 1.25rem auto 2rem;
  }
`;
export default Divider;
