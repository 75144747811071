import React from "react";
import styled from "styled-components";
import { ReactComponent as Kx } from "../assets/Logo/logo_kx.svg";
import { ReactComponent as ThaiEcom } from "../assets/Logo/logo_thaiecom.svg";
import { ReactComponent as Ttsa } from "../assets/Logo/logo_ttsa.svg";

const Root = styled.div`
  @media (min-width: 1025px) {
    display: none;
  }
  @media (max-width: 1024px) {
    width: 23.38rem;
    height: 100%;
    margin: 2rem auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  @media (max-width: 375px) {
    width: 17rem;
  }
`;
const KxLogo = styled(Kx)`
  width: 5rem;
  height: 1.13rem;
  opacity: 0.56;
`;
const ThaiEcLogo = styled(ThaiEcom)`
  width: 4.13rem;
  height: 1.75rem;
  opacity: 0.56;
`;
const TtsaLogo = styled(Ttsa)`
  width: 3.31rem;
  height: 1.5rem;
  opacity: 0.56;
`;

export default function Partner() {
  return (
    <Root id="root">
      <KxLogo id="kx-logo" />
      <ThaiEcLogo id="thai-ec-logo" />
      <TtsaLogo id="ttsa-logo" />
    </Root>
  );
}
