import React, { useState } from "react";
import styled from "styled-components";
import Button from "@material-ui/core/Button";
import { ReactComponent as IcCross } from "../assets/Icons/Icons Black/ic_cross.svg";
import colors from "../common/Color";
import Modal from "@material-ui/core/Modal";
import { ReactComponent as IconWarn } from "../assets/Icons/ic_big_report_outlined.svg";
import GrayText from "../common/GrayText";
import BlueText from "../common/BlueText";

const StyledButton = styled(Button)`
  && {
    font-family: "SukhumvitSet-Semibold";
    font-size: 0.88rem;
    letter-spacing: 0.08rem;
    line-height: 1rem;
    color: ${colors.primary};
    display: flex;
  }
`;
const ModalBox = styled.div`
  width: 27.88rem;
  height: 18.31rem;
  background: ${colors.white};
  border-radius: 0.5rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-top: calc(50vh - 9.15rem);
  @media (max-width: 960px) {
    width: 22.31rem;
  }
`;
const IcWarn = styled(IconWarn)`
  fill: ${colors.statusWarning};
  font-size: 4.5rem;
`;
const IconCross = styled(IcCross)`
  font-size: 1.5rem;
  margin-left: 0.5rem;
  opacity: 0.6;
`;
const BackBtn = styled(Button)`
  && {
    width: 6.56rem;
    height: 2.75rem;
    border-radius: 0.25rem;
    border: 0.06rem solid rgba(0, 0, 0, 0.12);
    font-family: "SukhumvitSet-SemiBold";
    font-size: 0.88rem;
    color: ${colors.primary};
    letter-spacing: 0.08rem;
    line-height: 1rem;
    margin: 0 0.75rem;
    @media (max-width: 960px) {
      width: 8.88rem;
    }
  }
`;
const ConfirmBtn = styled(Button)`
  && {
    width: 6.56rem;
    height: 2.75rem;
    border-radius: 0.25rem;
    background: ${colors.primary};
    font-family: "SukhumvitSet-SemiBold";
    font-size: 0.88rem;
    color: ${colors.white};
    letter-spacing: 0.08rem;
    line-height: 1rem;
    margin: 0 0.75rem;
    box-shadow: 0 0.063rem 0.063rem 0 rgba(0, 0, 0, 0.5);
    &:hover {
      background-color: ${colors.primaryDark};
    }
    @media (max-width: 960px) {
      width: 8.88rem;
    }
  }
`;
const FlexRow = styled.div`
  display: flex;
`;
const TextGroup = styled.div`
  margin: 1.75rem;
`;

export default function CancelButton() {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div id="root">
      <StyledButton id="cancel-btn" onClick={handleOpen}>
        ยกเลิก <IconCross id="cancel-ic" />
      </StyledButton>
      <Modal
        id="modal"
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={handleClose}
        disableEnforceFocus={true}
        disableBackdropClick={true}
      >
        <ModalBox id="modal-box">
          <IcWarn id="warning-ic" />
          <TextGroup id="content">
            <FlexRow id="content-1">
              <GrayText id="text-1">ถ้าคุณยืนยันที่จะ</GrayText>
              <BlueText id="text-2">ยกเลิกการสมัครใช้บริการ</BlueText>
            </FlexRow>
            <GrayText id="content-2">
              ระบบจะทำการยกเลิกข้อมูลที่คุณกรอกไว้
            </GrayText>
          </TextGroup>
          <FlexRow id="content-3">
            <BackBtn id="back-btn" onClick={handleClose}>
              ย้อนกลับ
            </BackBtn>
            <ConfirmBtn id="confirm-btn" href="/">
              ยืนยัน
            </ConfirmBtn>
          </FlexRow>
        </ModalBox>
      </Modal>
    </div>
  );
}
