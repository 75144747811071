import React, { useState, useContext } from "react";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import Title from "../common/Title";
import SubTitle from "./SubTitle";
import colors from "../common/Color";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Tooltip from "@material-ui/core/Tooltip";
import { ReactComponent as CheckIcon } from "../assets/Icons/Icons Black/ic_check_filled.svg";
import { ReactComponent as EditIcon } from "../assets/Icons/Icons Black/ic_edit.svg";
import { ReactComponent as IconBaac } from "../assets/Icons Bank/ic_baac.svg";
import { ReactComponent as IconBnp } from "../assets/Icons Bank/ic_bnp.svg";
import { ReactComponent as IconBoa } from "../assets/Icons Bank/ic_boa.svg";
import { ReactComponent as IconBualuang } from "../assets/Icons Bank/ic_bualuang.svg";
import { ReactComponent as IconCacib } from "../assets/Icons Bank/ic_cacib.svg";
import { ReactComponent as IconCimb } from "../assets/Icons Bank/ic_cimb.svg";
import { ReactComponent as IconCiti } from "../assets/Icons Bank/ic_citi.svg";
import { ReactComponent as IconDb } from "../assets/Icons Bank/ic_db.svg";
import { ReactComponent as IconGhb } from "../assets/Icons Bank/ic_ghb.svg";
import { ReactComponent as IconGsb } from "../assets/Icons Bank/ic_gsb.svg";
import { ReactComponent as IconHsbc } from "../assets/Icons Bank/ic_hsbc.svg";
import { ReactComponent as IconIcbc } from "../assets/Icons Bank/ic_icbc.svg";
import { ReactComponent as IconIsbt } from "../assets/Icons Bank/ic_isbt.svg";
import { ReactComponent as IconJpm } from "../assets/Icons Bank/ic_jpm.svg";
import { ReactComponent as IconKbank } from "../assets/Icons Bank/ic_kbank.svg";
import { ReactComponent as IconKkp } from "../assets/Icons Bank/ic_kkp.svg";
import { ReactComponent as IconKrungsri } from "../assets/Icons Bank/ic_krungsri.svg";
import { ReactComponent as IconKtb } from "../assets/Icons Bank/ic_ktb.svg";
import { ReactComponent as IconLh } from "../assets/Icons Bank/ic_lh.svg";
import { ReactComponent as IconMb } from "../assets/Icons Bank/ic_mb.svg";
import { ReactComponent as IconMega } from "../assets/Icons Bank/ic_mega.svg";
import { ReactComponent as IconMufg } from "../assets/Icons Bank/ic_mufg.svg";
import { ReactComponent as IconRbs } from "../assets/Icons Bank/ic_rbs.svg";
import { ReactComponent as IconSc } from "../assets/Icons Bank/ic_sc.svg";
import { ReactComponent as IconScb } from "../assets/Icons Bank/ic_scb.svg";
import { ReactComponent as IconSmbc } from "../assets/Icons Bank/ic_smbc.svg";
import { ReactComponent as IconTbank } from "../assets/Icons Bank/ic_tbank.svg";
import { ReactComponent as IconTcd } from "../assets/Icons Bank/ic_tcd.svg";
import { ReactComponent as IconTisco } from "../assets/Icons Bank/ic_tisco.svg";
import { ReactComponent as IconTmb } from "../assets/Icons Bank/ic_tmb.svg";
import { ReactComponent as IconUob } from "../assets/Icons Bank/ic_uob.svg";
import Button from "@material-ui/core/Button";
import EditGeneral from "./EditGeneralModal";
import EditAddress from "./EditAddressModal";
import EditBank from "./EditBankModal";
import { StoreContext } from "../Context/Store";
import { Bank } from "./BankItem";

const StepText = styled.span`
  opacity: 0.87;
  font-family: "SukhumvitSet-Semibold";
  text-align: left;
  font-size: 1.25rem;
  color: ${colors.black};
  letter-spacing: 0.02rem;
  line-height: 1.94rem;
`;
const EditText = styled.span`
  font-family: "SukhumvitSet-Text";
  font-size: 0.88rem;
  letter-spacing: 0.02rem;
  line-height: 1.25rem;
  color: ${props => (props.edit ? colors.primary : "rgba(0,0,0,0.87)")};
`;
const FieldName = styled.div`
  font-family: "SukhumvitSet-Semibold";
  font-size: 0.75rem;
  letter-spacing: 0.03rem;
  line-height: 1rem;
  color: ${colors.black};
  opacity: 0.6;
  margin-bottom: 0.25rem;
`;
const FieldAns = styled.span`
  font-family: "SukhumvitSet-Text";
  font-size: 1rem;
  letter-spacing: 0.03rem;
  line-height: 1.75rem;
  color: ${colors.black};
  opacity: 0.87;
`;
const HowtoText = styled.div`
  font-family: "Sarabun-Light";
  font-size: 0.88rem;
  letter-spacing: 0.02rem;
  line-height: 1.25rem;
  color: ${colors.black};
  opacity: 0.6;
  margin-left: 0.25rem;
`;
const StyledImage = styled.img`
  object-fit: contain;
  width: 19.81rem;
  height: 11.13rem;
  background: #eaeaea;
  cursor: pointer;
  margin-bottom: ${props => (props.selfie ? "2rem" : "0")};
  @media (max-width: 767px) {
    width: 15.69rem;
    height: 8.81rem;
    margin-bottom: ${props => (props.selfie ? "1.5rem" : "1.25rem")};
  }
`;
const EditIc = styled(EditIcon)`
  font-size: 0.75rem;
  fill: ${colors.primary};
  margin-right: 0.25rem;
`;
const CheckIc = styled(CheckIcon)`
  font-size: ${props => (props.small ? "0.88rem" : "1.25rem")};
  fill: ${colors.statusSuccess};
  margin-left: ${props => (props.small ? "0.38rem" : "0")};
`;
const FlexCenter = styled.div`
  display: flex;
  align-items: center;
`;
const BankDiv = styled.div`
  display: flex;
  align-items: center;
`;
const FlexGrid = styled(Grid)`
  && {
    margin: ${props => (props.sub ? "0" : "1.25rem 0 0")};
    @media (max-width: 960px) {
      margin: ${props => (props.sub ? "0 0 1.25rem" : "1.25rem 0 0")};
      display: ${props => (props.none ? "none" : "block")};
    }
  }
`;
const FlexBank = styled(Grid)`
  && {
    margin-bottom: 1.25rem;
  }
`;
const ContainerGrid = styled(Grid)`
  && {
    margin: 1rem 0 4rem;
    @media (max-width: 960px) {
      margin: 1rem 0;
    }
  }
`;
const StepGrid = styled(Grid)`
  && {
    @media (max-width: 960px) {
      margin-top: 2rem;
    }
  }
`;
const HowtoDiv = styled.div`
  display: flex;
  align-items: center;
  height: 1.25rem;
  margin-bottom: 0.25rem;
`;
const CardImgDiv = styled.div`
  width: 40.87rem;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.25rem;
  @media (max-width: 767px) {
    width: 100%;
    display: block;
    margin-bottom: 0;
  }
`;
const StyledZoomImg = styled.img`
  max-width: 1024px;
  max-height: 768px;
`;
const StyledModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default function Summary(props) {
  const { bookBankData } = props;
  const [openGeneral, setOpenGeneral] = useState(false);
  const [openAddress, setOpenAddress] = useState(false);
  const [openBank, setOpenBank] = useState(false);
  const [openFront, setOpenFront] = useState(false);
  const [openBack, setOpenBack] = useState(false);
  const [openSelfie, setOpenSelfie] = useState(false);
  const {
    bankaccount,
    nameTh,
    surnameTh,
    nameEn,
    surnameEn,
    email,
    phone,
    address,
    province,
    district,
    subDistrict,
    postalCode,
    citizenId,
    laserCode,
    citizenImageFront,
    citizenImageBack,
    citizenImageFace,
    isPromptpay,
    facebookUser
  } = useContext(StoreContext);
  const { handleBack } = props;
  const handleOpenGeneral = () => {
    setOpenGeneral(true);
  };
  const handleCloseGeneral = data => {
    setOpenGeneral(false);
  };
  const handleOpenAddress = () => {
    setOpenAddress(true);
  };
  const handleCloseAddress = () => {
    setOpenAddress(false);
  };
  const handleOpenBank = () => {
    setOpenBank(true);
  };
  const handleCloseBank = () => {
    setOpenBank(false);
  };
  return (
    <Grid container id="root">
      <Grid id="grid-title" item xs={12}>
        <Title id="title">ตรวจสอบข้อมูล</Title>
      </Grid>
      <Grid id="grid-subtitle" item xs={12}>
        <SubTitle id="subtitle" />
      </Grid>
      <ContainerGrid id="container" container>
        <Grid id="container-item" container item xs={12} md={6}>
          <Grid id="grid-item" item xs={12}>
            <StepText id="step-txt">ข้อมูลทั่วไป</StepText>
            <Button id="btn" onClick={handleOpenGeneral}>
              <EditIc id="edit-ic" />
              <EditText id="edit-txt" edit="true">
                แก้ไข
              </EditText>
            </Button>
            <EditGeneral
              id="edit-general"
              formProps={props.formProps}
              openGeneral={openGeneral}
              handleCloseGeneral={handleCloseGeneral}
              handleOpenGeneral={handleOpenGeneral}
            />
          </Grid>
          <FlexGrid id="flex-grid" container item xs={12}>
            <FlexGrid id="flex-grid" sub="true" item xs={12} md={6}>
              <FieldName id="field-name">ชื่อ (ไทย)</FieldName>
              <FieldAns id="field-ans">{nameTh[0]}</FieldAns>
            </FlexGrid>
            <Grid id="grid-item" item xs={12} md={6}>
              <FieldName id="field-name">นามสกุล (ไทย)</FieldName>
              <FieldAns id="field-ans">{surnameTh[0]}</FieldAns>
            </Grid>
          </FlexGrid>
          <FlexGrid id="flex-grid" container item xs={12}>
            <FlexGrid id="flex-grid" sub="true" item xs={12} md={6}>
              <FieldName id="field-name">Firstname (English)</FieldName>
              <FieldAns id="field-ans">{nameEn[0]}</FieldAns>
            </FlexGrid>
            <Grid id="grid-item" item xs={12} md={6}>
              <FieldName id="field-name">Lastname (English)</FieldName>
              <FieldAns id="field-ans">{surnameEn[0]}</FieldAns>
            </Grid>
          </FlexGrid>
          <FlexGrid id="flex-grid" container item xs={12}>
            <Grid id="grid-item" item xs={12}>
              <FieldName id="field-name">อีเมล</FieldName>
              <FieldAns id="field-ans">{email[0]}</FieldAns>
            </Grid>
          </FlexGrid>
          <FlexGrid id="flex-grid" container item xs={12}>
            <Grid id="grid-item" item xs={12} md={6}>
              <FieldName id="field-name">เบอร์โทรศัพท์</FieldName>
              <FlexCenter>
                <FieldAns id="field-ans">{phone[0]}</FieldAns>
                {isPromptpay[0] === true ? (
                  <Tooltip id="tooltip" title="เบอร์โทรนี้เป็นพร้อมเพย์แล้ว">
                    <CheckIc id="check-ic" small="true" />
                  </Tooltip>
                ) : (
                  ""
                )}
              </FlexCenter>
            </Grid>
          </FlexGrid>
          <FlexGrid id="flex-grid" container item xs={12}>
            <Grid id="grid-item" item xs={12}>
              <FieldName id="field-name">Facebook</FieldName>
              <FieldAns id="field-ans">{facebookUser[0].name}</FieldAns>
            </Grid>
          </FlexGrid>
        </Grid>
        <StepGrid id="step-grid" container item xs={12} md={6}>
          <Grid id="grid-item" item xs={12}>
            <StepText id="step-txt">ที่อยู่ปัจจุบัน</StepText>
            <Button id="btn" onClick={handleOpenAddress}>
              <EditIc id="edit-ic" />
              <EditText id="edit-txt" edit="true">
                แก้ไข
              </EditText>
            </Button>
            <EditAddress
              id="edit-address"
              formProps={props.formPropsAd}
              openAddress={openAddress}
              handleCloseAddress={handleCloseAddress}
              handleOpenAddress={handleOpenAddress}
            />
          </Grid>
          <FlexGrid id="flex-grid" container item xs={12}>
            <Grid id="grid-item" item xs={12}>
              <FieldName id="field-name">ที่อยู่</FieldName>
              <FieldAns id="field-ans">
                {address[0] ? address[0] : " "}
              </FieldAns>
            </Grid>
          </FlexGrid>
          <FlexGrid id="flex-grid" container item xs={12}>
            <FlexGrid id="flex-grid" sub="true" item xs={12} md={6}>
              <FieldName id="field-name">แขวง / ตำบล</FieldName>
              <FieldAns id="field-ans">
                {subDistrict[0].name ? subDistrict[0].name.th : " "}
              </FieldAns>
            </FlexGrid>
            <Grid id="grid-item" item xs={12} md={6}>
              <FieldName id="field-name">เขต / อำเภอ</FieldName>
              <FieldAns id="field-ans">
                {district[0].name ? district[0].name.th : " "}
              </FieldAns>
            </Grid>
          </FlexGrid>
          <FlexGrid id="flex-grid" container item xs={12}>
            <FlexGrid id="flex-grid" sub="true" item xs={12} md={6}>
              <FieldName id="field-name">จังหวัด</FieldName>
              <FieldAns id="field-ans">
                {province[0].name ? province[0].name.th : " "}
              </FieldAns>
            </FlexGrid>
            <Grid id="grid-item" item xs={12} md={6}>
              <FieldName id="field-name">รหัสไปรษณีย์</FieldName>
              <FlexCenter id="flex-center">
                <FieldAns id="field-ans">
                  {postalCode[0] ? postalCode[0] : " "}
                </FieldAns>
              </FlexCenter>
            </Grid>
          </FlexGrid>
          <FlexGrid id="flex-grid" none="true" container item md={12}>
            <Grid id="grid-item" item md={12}>
              <FieldName id="field-name">&nbsp;</FieldName>
              <FieldAns id="field-ans">&nbsp;</FieldAns>
            </Grid>
          </FlexGrid>
          <FlexGrid id="flex-grid" none="true" container item md={12}>
            <Grid id="grid-item" item md={12}>
              <FieldName id="field-name">&nbsp;</FieldName>
              <FieldAns id="field-ans">&nbsp;</FieldAns>
            </Grid>
          </FlexGrid>
        </StepGrid>
      </ContainerGrid>
      <ContainerGrid id="container-grid" container>
        <StepGrid id="step-grid" container item xs={12}>
          <Grid id="grid-item" item xs={12}>
            <StepText id="step-txt">บัญชีธนาคาร</StepText>
            <Button id="btn" onClick={handleOpenBank}>
              <EditIc id="edit-ic" />
              <EditText id="edit-txt" edit="true">
                แก้ไข
              </EditText>
            </Button>
            <EditBank
              id="edit-bank"
              formProps={props.formPropsBank}
              openBank={openBank}
              handleCloseBank={handleCloseBank}
              handleOpenBank={handleOpenBank}
              bookBankData={bookBankData}
            />
          </Grid>
          <FlexGrid id="flex-grid" container item xs={12}>
            {bankaccount[0].map((item, i) => (
              <FlexBank id="flex-bank" item xs={12} key={i}>
                <FieldName id="field-name">
                  บัญชีธนาคาร &nbsp; ({i + 1})
                </FieldName>
                <BankDiv id="bank-div">
                  {item.bankName === Bank[0].option ? (
                    <IconKbank id="ic-kb" />
                  ) : item.bankName === Bank[1].option ? (
                    <IconBualuang id="ic-bl" />
                  ) : item.bankName === Bank[2].option ? (
                    <IconScb id="ic-scb" />
                  ) : item.bankName === Bank[3].option ? (
                    <IconTmb id="ic-tmb" />
                  ) : item.bankName === Bank[4].option ? (
                    <IconKrungsri id="ic-ks" />
                  ) : item.bankName === Bank[5].option ? (
                    <IconKtb id="ic-ktb" />
                  ) : item.bankName === Bank[6].option ? (
                    <IconKkp id="ic-kkp" />
                  ) : item.bankName === Bank[7].option ? (
                    <IconUob id="ic-uob" />
                  ) : item.bankName === Bank[8].option ? (
                    <IconGsb id="ic-gsb" />
                  ) : item.bankName === Bank[9].option ? (
                    <IconBaac id="ic-baac" />
                  ) : item.bankName === Bank[10].option ? (
                    <IconTbank id="ic-tbank" />
                  ) : item.bankName === Bank[11].option ? (
                    <IconIsbt id="ic-isbt" />
                  ) : item.bankName === Bank[12].option ? (
                    <IconTisco id="ic-tisco" />
                  ) : item.bankName === Bank[13].option ? (
                    <IconHsbc id="ic-hsbc" />
                  ) : item.bankName === Bank[14].option ? (
                    <IconSc id="ic-sc" />
                  ) : item.bankName === Bank[15].option ? (
                    <IconCiti id="ic-citi" />
                  ) : item.bankName === Bank[16].option ? (
                    <IconGhb id="ic-ghb" />
                  ) : item.bankName === Bank[17].option ? (
                    <IconSmbc id="ic-smbc" />
                  ) : item.bankName === Bank[18].option ? (
                    <IconCimb id="ic-cimb" />
                  ) : item.bankName === Bank[19].option ? (
                    <IconMega id="ic-mega" />
                  ) : item.bankName === Bank[20].option ? (
                    <IconBoa id="ic-boa" />
                  ) : item.bankName === Bank[21].option ? (
                    <IconMb id="ic-mb" />
                  ) : item.bankName === Bank[22].option ? (
                    <IconIcbc id="ic-icbc" />
                  ) : item.bankName === Bank[23].option ? (
                    <IconTcd id="ic-tcd" />
                  ) : item.bankName === Bank[24].option ? (
                    <IconLh id="ic-lh" />
                  ) : item.bankName === Bank[25].option ? (
                    <IconBnp id="ic-bnp" />
                  ) : item.bankName === Bank[26].option ? (
                    <IconDb id="ic-db" />
                  ) : item.bankName === Bank[27].option ? (
                    <IconCacib id="ic-cacib" />
                  ) : item.bankName === Bank[28].option ? (
                    <IconRbs id="ic-rbs" />
                  ) : item.bankName === Bank[29].option ? (
                    <IconJpm id="ic-jpm" />
                  ) : item.bankName === Bank[30].option ? (
                    <IconMufg id="ic-mufg" />
                  ) : (
                    ""
                  )}
                  <FieldAns id="field-ans" style={{ marginLeft: "0.75rem" }}>
                    {item.bankName},&nbsp; {item.bankNumber}
                  </FieldAns>
                </BankDiv>
              </FlexBank>
            ))}
          </FlexGrid>
        </StepGrid>
      </ContainerGrid>
      <ContainerGrid id="container-grid" container>
        <StepGrid id="step-grid" container item xs={12}>
          <Grid id="grid-item" item xs={12}>
            <StepText id="step-txt">ยืนยันตัวตน</StepText>
            <Button id="btn" onClick={handleBack}>
              <EditIc id="edit-ic" />
              <EditText id="edit-txt" edit="true">
                แก้ไข
              </EditText>
            </Button>
          </Grid>
          <Grid id="container-item" container item xs={12}>
            <FlexGrid id="flex-grid" container item xs={12} md={9}>
              <FlexGrid id="flex-grid" sub="true" item xs={12} md={5}>
                <FieldName id="field-name">เลขประจำตัวบัตรประชาชน</FieldName>
                <FieldAns id="field-ans">{citizenId[0]}</FieldAns>
              </FlexGrid>
              <Grid id="grid-item" item xs={12} md={7}>
                <FieldName id="field-name">
                  เลขหลังบัตรประชาชน (Laser Code)
                </FieldName>
                <FieldAns id="field-ans">{laserCode[0]}</FieldAns>
              </Grid>
            </FlexGrid>
          </Grid>
          <Grid id="container-item" container item xs={12}>
            <FlexGrid id="flex-grid" item xs={12}>
              <FieldName id="field-name">รูปถ่าย</FieldName>
              <CardImgDiv id="card-img-div">
                <StyledImage
                  id="img"
                  onClick={() => setOpenFront(true)}
                  src={citizenImageFront[0].preview}
                  alt="front"
                ></StyledImage>
                <StyledModal
                  id="modal"
                  aria-labelledby="transition-modal-title"
                  aria-describedby="transition-modal-description"
                  open={openFront}
                  onClose={() => setOpenFront(false)}
                  closeAfterTransition
                  BackdropComponent={Backdrop}
                  BackdropProps={{
                    timeout: 500
                  }}
                >
                  <Fade id="fade" in={openFront}>
                    <StyledZoomImg
                      id="zoom-img"
                      src={citizenImageFront[0].preview}
                      alt="frontzoom"
                    />
                  </Fade>
                </StyledModal>
                <StyledImage
                  id="img"
                  onClick={() => setOpenBack(true)}
                  src={citizenImageBack[0].preview}
                  alt="back"
                ></StyledImage>
                <StyledModal
                  id="modal"
                  aria-labelledby="transition-modal-title"
                  aria-describedby="transition-modal-description"
                  open={openBack}
                  onClose={() => setOpenBack(false)}
                  closeAfterTransition
                  BackdropComponent={Backdrop}
                  BackdropProps={{
                    timeout: 500
                  }}
                >
                  <Fade id="fade" in={openBack}>
                    <StyledZoomImg
                      id="zoom-img"
                      src={citizenImageBack[0].preview}
                      alt="backzoom"
                    />
                  </Fade>
                </StyledModal>
              </CardImgDiv>
              <StyledImage
                id="img"
                onClick={() => setOpenSelfie(true)}
                selfie="true"
                src={citizenImageFace[0].preview}
                alt="selfie"
              ></StyledImage>
              <StyledModal
                id="modal"
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={openSelfie}
                onClose={() => setOpenSelfie(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500
                }}
              >
                <Fade id="fade" in={openSelfie}>
                  <StyledZoomImg
                    id="zoom-img"
                    src={citizenImageFace[0].preview}
                    alt="selfiezoom"
                  />
                </Fade>
              </StyledModal>
              <div id="field-div">
                <FieldAns id="field-ans">
                  ภาพถ่ายหน้าตรงคู่บัตรประชาชนและป้าย VerME
                </FieldAns>
              </div>
              <div id="text-div" style={{ marginBottom: "0.44rem" }}>
                <EditText id="edit-txt">ภาพถ่ายของคุณต้อง...</EditText>
              </div>
              {[
                "ต้องเห็นหน้าชัดเจน",
                "ต้องเห็นข้อมูลบนบัตรประชาชนชัดเจน",
                "เห็นป้าย/แผ่นกระดาษ VerME พร้อมวันที่ชัดเจน"
              ].map(item => (
                <HowtoDiv id="howto-div" key={item}>
                  <CheckIc id="check-ic" />
                  <HowtoText id="howto-txt">{item}</HowtoText>
                </HowtoDiv>
              ))}
            </FlexGrid>
          </Grid>
        </StepGrid>
      </ContainerGrid>
    </Grid>
  );
}
