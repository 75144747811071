import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TextFieldCheck from "./TextFieldCheck";
import Paper from "@material-ui/core/Paper";
import BackButton from "../common/components/BackButton";
import colors from "../common/Color";

const styles = theme => ({
  root: {
    margin: "auto",
    [theme.breakpoints.up("md", "lg")]: {
      marginTop: "9rem"
    }
  },
  paper: {
    padding: theme.spacing(2),
    paddingBottom: "2rem",
    textAlign: "center",
    color: theme.palette.text.secondary,
    width: "40rem",
    borderRadius: "0.5rem",
    boxShadow: "0 0.25rem 0.5rem 0 rgba(0,0,0,0.20)",
    [theme.breakpoints.down("sm")]: {
      width: "17rem",
      backgroundColor: "#00000000",
      boxShadow: "none"
    }
  },
  txt1: {
    fontFamily: "SukhumvitSet-Medium",
    fontSize: "3rem",
    color: colors.primary,
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: "2rem"
    }
  },
  txt2: {
    opacity: "0.87",
    fontFamily: "Sarabun-Regular",
    fontSize: "1rem",
    color: "#000000",
    letterSpacing: "0.03125rem",
    textAlign: "center",
    lineHeight: "1.75rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.85rem"
    }
  },
  backButton: {
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  }
});

function IdentityCheckContent(props) {
  const { classes } = props;
  return (
    <div id="root" className={classes.root}>
      <Grid
        id="grid-container-center"
        container
        direction="column"
        justify="center"
        alignItems="center"
      >
        <Grid id="grid-item" item xs={12} sm={12} md={12} lg={12}>
          <Paper id="paper" className={classes.paper}>
            <Grid
              id="grid-container"
              container
              direction="column"
              justify="center"
            >
              <Grid
                id="grid-item"
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                className={classes.backButton}
              >
                <BackButton id="back-btn" />
              </Grid>
              <Grid
                id="grid-item"
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                className={classes.txt1}
              >
                ตรวจสอบตัวตน
              </Grid>
              <Grid
                id="grid-item"
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                className={classes.txt2}
              >
                โปรดใส่ VerME ID 6 หลัก
                <br />
                เพื่อตรวจสอบการยืนยันตัวตน
              </Grid>
              <Grid id="grid-item" item xs={12} sm={12} md={12} lg={12}>
                <TextFieldCheck id="txtfield-check" />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

export default withStyles(styles)(IdentityCheckContent);
