import React, { useState } from "react";
import styled from "styled-components";
import * as yup from "yup";
import colors from "../common/Color";
import WebBtn from "../common/WebButton";
import StyledTextField from "../common/StyledTextField";
import { ReactComponent as Image } from "../assets/Images/img_forgot.svg";
import { Root, Logo, Header } from "../common/FormHeader";
import Title from "../common/Title";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconAdorn from "../common/IconAdornment";
import Link from "@material-ui/core/Link";
import useForm from "react-hook-form";
import ErrorMessage from "../common/ErrorMessage";
import { CircularProgress } from "@material-ui/core";
import { sendEmailResetPassword } from "../actions/action";
import ErrorModal from "../common/ErrorModal";
import CheckEmail from "./PleaseCheckEmailPage";
import MetaDecorator from "../MetaDecorator/MetaDecorator";

const StyledRoot = styled.div`
  width: 100vw;
  min-height: 100vh;
  background: ${colors.surfaceGray};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const Box = styled.div`
  width: 28.38rem;
  height: 42.19rem;
  background: ${colors.white};
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-height: 815px) and (min-width: 768px) {
    margin-top: 4rem;
  }
  @media (max-width: 767px) {
    width: 90%;
    height: 100%;
    background: transparent;
    border: none;
    margin-top: 2rem;
  }
`;
const Content = styled.form`
  width: 19.38rem;
  height: calc(100% - 6rem);
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const ImgForgot = styled(Image)`
  width: 14.19rem;
  height: 13.44rem;
  margin-bottom: 2rem;
  @media (max-width: 767px) {
    width: 11.56rem;
    height: 10.94rem;
  }
`;
const StyledTitle = styled(Title)`
  && {
    margin-bottom: 0.75rem;
    @media (max-width: 767px) {
      margin-bottom: 0.5rem;
    }
  }
`;
const Text = styled.span`
  opacity: 0.6;
  font-family: "SukhumvitSet-Text";
  font-size: 16px;
  color: #000000;
  letter-spacing: 0.5px;
  text-align: center;
  line-height: 28px;
`;
const TextFieldDiv = styled.div`
  display: block;
  width: 100%;
  margin-bottom: 2.19rem;
`;
const StyledTextfield = styled(StyledTextField)`
  && {
    margin-top: 1.5rem;
  }
`;
const StyledBtn = styled(WebBtn)`
  width: 100%;
  height: 2.63rem;
`;
const AskText = styled.span`
  opacity: 0.6;
  font-family: "SukhumvitSet-Text";
  font-size: 16px;
  color: #000000;
  letter-spacing: 0.5px;
  text-align: center;
  line-height: 28px;
`;
const AskRegistDiv = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2rem;
`;
const StyledLink = styled(Link)`
  && {
    font-family: "SukhumvitSet-Text";
    font-size: 16px;
    color: ${colors.primary};
    letter-spacing: 0.5px;
    text-align: center;
    line-height: 28px;
    cursor: pointer;
  }
`;
const validateSchema = yup.object().shape({
  email: yup
    .string()
    .email("อีเมลไม่ถูกต้อง")
    .required("กรุณากรอกอีเมล")
});

export default function ForgotPasswordPage() {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [textMessage, setTextMessage] = useState("");
  const [openCheckEmailPage, setOpenCheckEmailPage] = useState(false);
  const forgotForm = useForm({
    validationSchema: validateSchema
  });
  const handleErrorClose = () => {
    setErrorOpen(false);
  };
  const submitForm = async () => {
    setLoading(true);
    try {
      const res = await sendEmailResetPassword({email: email});
      if (res.status === 200) {
        setLoading(false);
        setOpenCheckEmailPage(true);
      }
    } catch (err) {
      if (err.response) {
        if (err.response.status === 400) {
          setTextMessage(err.response.data.error);
          setErrorOpen(true);
        }
      }
      setLoading(false);
      setOpenCheckEmailPage(false);
      console.log(err);
    }
  };
  return (
    <div>
      <MetaDecorator
        description="มั่นใจทุกการขาย สบายใจทุกการซื้อ"
        title= "ลืมรหัสผ่าน"
        // imageUrl={metaThumbnail}
        // imageAlt={blog.metaImageAlt}
      />
      {openCheckEmailPage ? (
        <CheckEmail email={email} />
      ) : (
        <StyledRoot id="root-st">
          <Root id="root">
            <Header id="header">
              <Logo id="logo" />
            </Header>
          </Root>
          <ErrorModal
            id="err-modal"
            textMessage={textMessage}
            handleErrorClose={handleErrorClose}
            errorOpen={errorOpen}
          />
          <Box id="box">
            <Content
              id="content"
              onSubmit={forgotForm.handleSubmit(submitForm)}
            >
              <ImgForgot id="img-forgot" />
              <StyledTitle id="title">ลืมรหัสผ่าน</StyledTitle>
              <Text id="txt">
                กรุณากรอกอีเมลที่ท่านใช้ในการสมัคร <br /> VerME
                เพื่อรับลิงก์สำหรับเปลี่ยนรหัสผ่าน
              </Text>
              <TextFieldDiv>
                <StyledTextfield
                  id="email"
                  name="email"
                  defaultValue={email}
                  onChange={e => setEmail(e.target.value)}
                  label="อีเมล"
                  placeholder="โปรดระบุอีเมลของท่าน"
                  variant="outlined"
                  inputRef={forgotForm.register}
                  error={!!forgotForm.errors.email}
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment id="adornment" position="end">
                        <IconAdorn id="disabled-ic" disabled />
                      </InputAdornment>
                    )
                  }}
                />
                {forgotForm.errors.email && (
                  <ErrorMessage id="err-msg">
                    {forgotForm.errors.email.message}
                  </ErrorMessage>
                )}
              </TextFieldDiv>
              <StyledBtn
                id="btn"
                primary="true"
                type="submit"
                disabled={loading}
              >
                {loading ? (
                  <CircularProgress
                    id="circular-progress"
                    size={25}
                    thickness={4}
                    style={{ color: "white" }}
                  />
                ) : (
                  "ยืนยัน"
                )}
              </StyledBtn>
              <AskRegistDiv id="ask-register-div">
                <AskText>มีบัญชีผู้ใช้อยู่แล้ว?&ensp;</AskText>
                <StyledLink href="/sign-in">เข้าสู่ระบบ</StyledLink>
              </AskRegistDiv>
            </Content>
          </Box>
        </StyledRoot>
      )}
    </div>
  );
}
