import React, { useState, useContext } from "react";
import { StoreContext } from "../Context/Store";
import Checkbox from "@material-ui/core/Checkbox";
import styled from "styled-components";
import Button from "@material-ui/core/Button";
import colors from "../common/Color";
import TextField from "@material-ui/core/TextField";
import ErrorModal from "../common/ErrorModal";
import FormHeader from "../common/FormHeader";

const Root = styled.div`
  margin: 6rem 9% 4.38rem 9%;
`;

const TextPosition = styled.div`
  width: 100%;
  max-width: 72rem;
`;

const HeaderText = styled.div`
  font-family: "SukhumvitSet-Text";
  font-size: 2.13rem;
  color: #3b54d4;
  letter-spacing: 0.25px;
`;

const SubText = styled.div`
  opacity: 0.6;
  font-family: "SukhumvitSet-Text";
  font-size: 1rem;
  color: #000000;
  letter-spacing: 0.5px;
`;

const ChooseText = styled.div`
  font-family: "SukhumvitSet-Text";
  font-size: 1rem;
  color: #3b54d4;
  letter-spacing: 0.5px;
  line-height: 28px;
`;

const CategoryPosition = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const CategoryFrame = styled.div`
  margin: 3rem 0rem;
  display: flex;
  width: 100%;
  max-width: 72rem;
  flex-wrap: wrap;
`;

const CategoryButtonFrame = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CategoryItem = styled.div`
  width: 9.5rem;
  height: 9.5rem;
  margin-right: 2.5rem;
  margin-bottom: 2.5rem;
  font-family: "SukhumvitSet-Text";
  font-size: 1rem;
  letter-spacing: 0.5px;
  text-align: center;
  line-height: 28px;
  user-select: none;
  cursor: pointer;
  @media (max-width: 500px) {
    width: 100%;
    margin-right: 0rem;
  }
`;

const OtherItem = styled.div`
  position: relative;
  width: 21.5rem;
  height: 9.5rem;
  margin-bottom: 3rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-family: "SukhumvitSet-Text";
  font-size: 1rem;
  letter-spacing: 0.5px;
  line-height: 28px;
  cursor: pointer;
  @media (max-width: 500px) {
    width: 100%;
  }
`;

const OtherItemActive = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  border: 1px solid #3b54d4;
  border-radius: 5px;
  border-radius: 5px;
  color: #3b54d4;
  width: 100%;
  height: 100%;
  transition: 0.3s;
`;

const OtherItemInActive = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  border: 1px solid #000000;
  border-radius: 5px;
  border-radius: 5px;
  color: #000000;
  width: 100%;
  height: 100%;
  transition: 0.3s;
`;

const BlockActive = styled.div`
  position: relative;
  color: #3b54d4;
  background: #ffffff;
  border: 1px solid #3b54d4;
  border-radius: 5px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  transition: 0.3s;
`;

const BlockInActive = styled.div`
  position: relative;
  color: #000000;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.6);
  border-radius: 5px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  transition: 0.3s;
`;

const ImageItem = styled.img`
  width: 3.34rem;
  height: 3.34rem;
  margin-bottom: 0.43rem;
`;

const InputOtherItem = styled(TextField)`
  padding: 1rem;
  margin-left: 1rem;
  width: 17.5rem;
  height: 2.5rem;
  border: 1px solid rgba(0, 0, 0, 0.6);
  font-family: "SukhumvitSet-Text";
  font-size: 1rem;
  color: #000000;
  letter-spacing: 0.5px;
  line-height: 28px;
  @media (max-width: 500px) {
    width: 75%;
    padding: 5%;
    margin-left: 8%;
  }
  z-index: 3;
`;

const OtherText = styled.div`
  margin-left: 1.5rem;
  margin-bottom: 0.75rem;
  @media (max-width: 500px) {
    margin-left: 8%;
  }
`;

const CheckBoxItem = styled.div`
  position: absolute;
  right: 0;
  top: 0;
`;

const CancelBtn = styled(Button)`
  && {
    width: 9rem;
    height: 2.75rem;
    border-radius: 0.25rem;
    border: 0.06rem solid ${colors.primary};
    font-family: "SukhumvitSet-SemiBold";
    font-size: 0.88rem;
    color: ${colors.primary};
    letter-spacing: 0.08rem;
    line-height: 1rem;
    margin: 0 0.5rem;
    @media (max-width: 960px) {
      width: 8.88rem;
    }
  }
`;

const ConfirmBtn = styled(Button)`
  && {
    width: 9rem;
    height: 2.75rem;
    border-radius: 0.25rem;
    background-color: ${props => (props.disabled ? "#E4E4E4" : colors.primary)};
    font-family: "SukhumvitSet-SemiBold";
    font-size: 0.88rem;
    color: ${props => (props.disabled ? "#979797" : colors.white)};
    letter-spacing: 0.08rem;
    line-height: 1rem;
    margin: 0 0.5rem;
    box-shadow: 0 0.063rem 0.063rem 0 rgba(0, 0, 0, 0.5);
    &:hover {
      background-color: ${colors.primaryDark};
    }
    @media (max-width: 960px) {
      width: 8.88rem;
    }
  }
`;

const ToggleCheckBoxOther = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
`;

export default function Category() {
  const {
    dataCategory,
    facebookUser,
    itemCategory,
    isCollectCategory
  } = useContext(StoreContext);
  const [errorOpen, setErrorOpen] = useState(false);
  const textMessage = "โปรดระบุประเภทสินค้าในช่องอื่นๆให้ถูกต้อง";

  const handleErrorClose = () => {
    setErrorOpen(false);
  };

  const toggleStatus = index => {
    let newData = [...itemCategory[0]];
    newData[index].status = !newData[index].status;
    itemCategory[1](newData);
    let myItems = new Map();
    itemCategory[0].forEach(item => {
      if (item.status) {
        myItems.set(item.status, item.name);
      }
    });
    if (myItems.has(true)) {
      isCollectCategory[1](true);
    } else {
      isCollectCategory[1](false);
    }
  };

  const submitCategory = () => {
    let data = [];
    var regEx = /^[ก-๏\sA-Za-z]+$/;
    let myItems = new Map();
    itemCategory[0].forEach(item => {
      if (item.status) {
        myItems.set(item.name, item.data);
      }
    });
    var regExResult = regEx.test(myItems.get("อื่นๆ"));
    if (myItems.has("อื่นๆ") && (myItems.get("อื่นๆ") === "" || !regExResult)) {
      setErrorOpen(true);
    } else if (!myItems.has("อื่นๆ")) {
      // eslint-disable-next-line
      for (let [, value] of myItems) {
        data.push(value);
      }
    } else if (
      myItems.has("อื่นๆ") &&
      (myItems.get("อื่นๆ") !== "" || regExResult)
    ) {
      // eslint-disable-next-line
      for (let [, value] of myItems) {
        data.push(value);
      }
    }
    console.log(data);
    dataCategory[1](data);
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  const handleOtherData = e => {
    let newData = [...itemCategory[0]];
    newData[16].data = e;
    itemCategory[1](newData);
  };

  const backCategory = () => {
    facebookUser[1](undefined);
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  return (
    <div id="root-div">
      <FormHeader id="form-header" />
      <Root id="root">
        <ErrorModal
          id="err-modal"
          textMessage={textMessage}
          handleErrorClose={handleErrorClose}
          errorOpen={errorOpen}
        />
        <CategoryPosition id="category">
          <TextPosition id="text-position">
            <HeaderText id="header-text">ประเภทสินค้าที่ขาย</HeaderText>
            <SubText id="sub-text">
              เลือกประเภทสินค้าที่คุณขายอยู่
              เพื่อให้เราสามารถช่วยโปรโมทร้านค้าของคุณได้ดียิ่งขึ้น
            </SubText>
            <ChooseText id="choose-text">(เลือกได้มากกว่า 1 ประเภท)</ChooseText>
          </TextPosition>
          <CategoryFrame id="frame">
            {itemCategory[0].map((item, index) => {
              if (item.name !== "อื่นๆ") {
                return (
                  <CategoryItem
                    id="category-item"
                    key={index}
                    onClick={() => toggleStatus(index)}
                  >
                    {item.status ? (
                      <BlockActive id="active-block">
                        <CheckBoxItem id="check-box-item">
                          <Checkbox
                            id="check-box"
                            checked={item.status}
                            color="primary"
                            inputProps={{ "aria-label": "primary checkbox" }}
                          />
                        </CheckBoxItem>
                        <ImageItem id="image-item" src={item.ActiveImg} />
                        {item.name}
                      </BlockActive>
                    ) : (
                      <BlockInActive id="inactive-block">
                        <CheckBoxItem id="check-box-item">
                          <Checkbox
                            id="check-box"
                            checked={item.status}
                            color="primary"
                            inputProps={{ "aria-label": "primary checkbox" }}
                          />
                        </CheckBoxItem>
                        <ImageItem id="image-item" src={item.img} />
                        {item.name}
                      </BlockInActive>
                    )}
                  </CategoryItem>
                );
              } else {
                return (
                  <OtherItem key={index} id="other-item">
                    {item.status ? (
                      <OtherItemActive id="active-other-item">
                        <ToggleCheckBoxOther
                          id="toggle-other"
                          onClick={() => toggleStatus(index)}
                        />
                        <CheckBoxItem id="check-box-item">
                          <Checkbox
                            item="check-box"
                            checked={item.status}
                            color="primary"
                            inputProps={{ "aria-label": "primary checkbox" }}
                          />
                        </CheckBoxItem>
                        <div
                          id="name-item"
                          onClick={() => toggleStatus(index)}
                          style={{
                            marginLeft: "1.5rem",
                            marginBottom: "0.75rem"
                          }}
                        >
                          {item.name}
                        </div>
                        <div
                          id="div-input-item"
                          style={{ width: "100%", marginLeft: "1.5rem" }}
                        >
                          <InputOtherItem
                            id="input-other-item"
                            placeholder="โปรดระบุประเภทสินค้า"
                            variant="outlined"
                            defaultValue={item.data}
                            disabled={!item.status}
                            onChange={e => handleOtherData(e.target.value)}
                          />
                        </div>
                      </OtherItemActive>
                    ) : (
                      <OtherItemInActive
                        id="inactive-other-item"
                        onClick={() => toggleStatus(index)}
                      >
                        <CheckBoxItem id="check-box-item">
                          <Checkbox
                            id="check-box"
                            checked={item.status}
                            color="primary"
                            inputProps={{ "aria-label": "primary checkbox" }}
                          />
                        </CheckBoxItem>
                        <OtherText id="other-text">{item.name}</OtherText>
                        <div
                          id="input-div"
                          style={{ width: "100%", marginLeft: "1.5rem" }}
                        >
                          <InputOtherItem
                            id="input-other-item"
                            placeholder="โปรดระบุประเภทสินค้า"
                            variant="outlined"
                            defaultValue={item.data}
                            disabled={!item.status}
                          />
                        </div>
                      </OtherItemInActive>
                    )}
                  </OtherItem>
                );
              }
            })}
            <CategoryButtonFrame id="category-btn-fr">
              <CancelBtn id="cancel-btn" onClick={backCategory}>
                ย้อนกลับ
              </CancelBtn>
              {isCollectCategory[0] ? (
                <ConfirmBtn id="confirm-btn" onClick={submitCategory}>
                  ต่อไป
                </ConfirmBtn>
              ) : (
                <ConfirmBtn disabled id="confirm-btn-disabled">
                  ต่อไป
                </ConfirmBtn>
              )}
            </CategoryButtonFrame>
          </CategoryFrame>
        </CategoryPosition>
      </Root>
    </div>
  );
}
