import React, { useState, useContext } from "react";
import Grid from "@material-ui/core/Grid";
import { CircularProgress } from "@material-ui/core";
import styled from "styled-components";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepConnector from "@material-ui/core/StepConnector";
import colors from "../common/Color";
import Button from "@material-ui/core/Button";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import FirstStep from "./FirstStep";
import SecondStep from "./SecondStep";
import SuccessPage from "./SuccessPayment";
import DetailPrice from "./DetailPrice";
import * as yup from "yup";
import { StoreContext } from "../Context/Store";
import useForm from "react-hook-form";
import NavBar from "../common/NavBar";
import Footer from "../common/FooterBar";
import NavMobile from "../common/NavMobile";
import { getIdentity, postPayment, sendEmailPayment } from "../actions/action";

const Root = styled.div`
  min-height: calc(100vh - 13rem);
`;
const Container = styled.div`
  width: 82%;
  margin: 8.5rem auto;
  @media (max-width: 1024px) {
    width: 100%;
  }
`;
const Title = styled.div`
  font-family: "SukhumvitSet-Bold";
  font-size: 3rem;
  color: ${colors.black};
  opacity: 0.87;
  letter-spacing: 0;
  line-height: 4.75rem;
  @media (max-width: 1024px) {
    font-size: 2.5rem;
    line-height: 3.94rem;
    text-align: center;
  }
`;
const StyledStepGrid = styled(Grid)`
  && {
    width: 50%;
    @media (max-width: 1024px) {
      width: 100%;
    }
  }
`;
const StyledLabel = styled(StepLabel)`
  && {
    .MuiStepLabel-alternativeLabel {
      font-family: "SukhumvitSet-SemiBold";
      font-size: 0.75rem;
      letter-spacing: 0.03rem;
      line-height: 1rem;
      color: #666666;
      margin-top: 0.5rem;
    }
    .MuiStepLabel-active {
      color: #2b2b2b;
    }
    .MuiStepLabel-completed {
      color: #666666;
    }
  }
`;

const StyledConnector = withStyles({
  alternativeLabel: {
    top: 35
  },
  line: {
    height: "0.13rem",
    border: 0,
    backgroundColor: "#d0d0d0",
    borderRadius: 1
  }
})(StepConnector);
const StyledStepper = styled(Stepper)`
  &.MuiStepper-root {
    padding: 0;
    margin: 2rem 0;
  }
`;
const StepIconStyling = makeStyles({
  root: {
    backgroundColor: colors.white,
    zIndex: 1,
    color: "#666666",
    width: "3.25rem",
    height: "3.25rem",
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    border: "0.06rem solid #d0d0d0",
    fontSize: "1rem",
    fontFamily: "SukhumvitSet-Text"
  },
  active: {
    border: "0.06rem solid #333333",
    color: "#2B2B2B"
  },
  completed: {
    border: "0.06rem solid #d0d0d0",
    color: "#666666"
  }
});

function StepIcon(props) {
  const classes = StepIconStyling();
  const { active, completed } = props;

  const icons = {
    1: "1",
    2: "2",
    3: "3"
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}
const BtnFirstStep = styled(Button)`
  && {
    width: 100%;
    height: 2.75rem;
    font-family: "SukhumvitSet-SemiBold";
    font-size: 0.88rem;
    letter-spacing: 0.08rem;
    line-height: 1rem;
    color: ${props => (props.disabled ? "#979797" : colors.white)};
    background: ${props => (props.disabled ? "#E4E4E4" : colors.primary)};
    box-shadow: ${props =>
      props.disabled ? "" : "0 0.06rem 0.06rem 0 rgba(0,0,0,0.50)"};
    &:hover {
      background: ${props => (props.disabled ? "" : colors.primaryDark)};
    }
  }
`;
const BtnNextStep = styled(Button)`
  && {
    width: 100%;
    height: 2.75rem;
    font-family: "SukhumvitSet-SemiBold";
    font-size: 0.88rem;
    letter-spacing: 0.08rem;
    line-height: 1rem;
    color: ${props => (props.disabled ? "#979797" : colors.white)};
    background: ${props => (props.disabled ? "#E4E4E4" : colors.primary)};
    box-shadow: ${props =>
      props.disabled ? "" : "0 0.06rem 0.06rem 0 rgba(0,0,0,0.50)"};
    &:hover {
      background: ${props => (props.disabled ? "" : colors.primaryDark)};
    }
  }
`;
const BtnBackStep = styled(Button)`
  && {
    width: 100%;
    height: 2.75rem;
    font-family: "SukhumvitSet-SemiBold";
    font-size: 0.88rem;
    letter-spacing: 0.08rem;
    line-height: 1rem;
    border: 0.06rem solid rgba(0, 0, 0, 0.12);
    color: ${colors.primary};
    margin-right: 0.5rem;
  }
`;

const LoadingScreen = styled.div`
  height: 100vh;
  width: 100vw;
  z-index: 100;
  background: rgba(255, 255, 255, 0.5);
  position: fixed;
`;

const StyledGrid = styled(Grid)`
  && {
    margin-left: 12%;
    width: 75%;
    @media (max-width: 1024px) {
      margin: auto;
      width: 76%;
    }
    @media (max-width: 425px) {
      margin: auto;
      width: 90%;
    }
  }
`;
const StyledDetailGrid = styled(Grid)`
  && {
    width: 42%;
    display: block;
    @media (max-width: 1024px) {
      display: none;
    }
  }
`;
const StyledDetailGridMobile = styled(Grid)`
  && {
    display: none;
    @media (max-width: 1024px) {
      width: 100%;
      margin: auto auto 2rem;
      display: block;
    }
  }
`;

function getSteps() {
  return ["รายละเอียด", "การชำระเงิน", "เสร็จสิ้น"];
}

const firstStepSchema = yup.object().shape({
  vermeID: yup
    .string()
    .required("กรุณากรอก VerME ID ของคุณ")
    .min(6, "กรุณากรอก VerME ID ของคุณให้ครบถ้วน")
    .max(6, "VerME ID จะต้องไม่เกิน 6 หลัก")
});
const secondStepSchema = yup.object().shape({
  fileName: yup.string().required("กรุณาอัปโหลดหลักฐานการโอนเงิน")
});
export default function PaymentPage() {
  const { promotion, dataUserPayment } = useContext(StoreContext);
  const [activeStep, setActiveStep] = useState(0);
  const [vmID, setVmID] = useState("");
  const [choosePackage, setChoosePackage] = useState("1 เดือน");
  const [loading, setLoading] = useState(false);
  const [imageTransfer, setImageTransfer] = useState({ raw: "" });
  const [imagePromptpay, setImagePromptpay] = useState({ raw: "" });
  const [methodPayment, setMethodPayment] = useState("");
  const packageData = [
    { promotion: "1 เดือน", price: "99 บาท" },
    { promotion: "4 เดือน", price: "62 บาท ต่อเดือน" },
    { promotion: "12 เดือน", price: "49 บาท ต่อเดือน" }
  ];

  const steps = getSteps();

  const firstStepForm = useForm({
    validationSchema: firstStepSchema
  });
  const secondStepForm = useForm({
    validationSchema: secondStepSchema
  });

  const onSubmit = data => {
    if (activeStep === 0) {
      promotion[1](data);
    }
    handleNext();
  };

  const handleNext = () => {
    if (activeStep === 0) {
      findUserVerME(vmID);
    }
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handlePackage = item => {
    setChoosePackage(item);
  };

  const handleVmID = item => {
    setVmID(item);
  };

  const setImageTransferData = item => {
    setImageTransfer(item);
  };

  const setImagePromptpayData = item => {
    setImagePromptpay(item);
  };

  const setMethodPaymentData = item => {
    setMethodPayment(item);
  };

  const findUserVerME = async item => {
    var vmId = "vm-" + item;
    setLoading(true);
    try {
      const res = await getIdentity(vmId);
      if (res.status === 200) {
        const data = res.data;
        dataUserPayment[1](data);
        setActiveStep(prevActiveStep => prevActiveStep + 1);
        setLoading(false);
      }
    } catch (err) {
      if (err.response.status === 404) {
        alert("Your verme id is wrong.");
      }
      console.log(err);
      setLoading(false);
    }
  };

  const handleEditMethodPayment = async () => {
    setLoading(true);
    let splitPackage = choosePackage.split(" ");
    let formData = new FormData();
    formData.append("vm_id", "vm-" + vmID);
    formData.append("method", methodPayment);
    formData.append("package", splitPackage[0] + "m");
    if (imageTransfer.raw) {
      formData.append("image", imageTransfer.raw);
    } else if (imagePromptpay.raw) {
      formData.append("image", imagePromptpay.raw);
    }

    try {
      const res = await postPayment(formData);
      if (res.status === 201) {
        sendEmailToVerifyPayment(res.data.id);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const sendEmailToVerifyPayment = async id => {
    try {
      const res = await sendEmailPayment(id);
      if (res.status === 200) {
        setLoading(false);
        setActiveStep(prevActiveStep => prevActiveStep + 1);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return (
          <FirstStep
            data={promotion}
            formProps={firstStepForm}
            packageData={packageData}
            handlePackage={handlePackage}
            choosePackage={choosePackage}
            handleVmID={handleVmID}
            vmID={vmID}
          />
        );
      case 1:
        return (
          <SecondStep
            formProps={secondStepForm}
            setImagePromptpayData={setImagePromptpayData}
            setImageTransferData={setImageTransferData}
            imageTransfer={imageTransfer}
            imagePromptpay={imagePromptpay}
            setMethodPaymentData={setMethodPaymentData}
          />
        );
      default:
        return "Unknown stepIndex";
    }
  }
  return (
    <div id="root-div">
      {loading ? <LoadingScreen id="loading-screen" /> : " "}
      <Root id="root">
        <NavBar id="navbar" props="payment" />
        <NavMobile id="nav-mb" props="payment" />
        {activeStep === steps.length - 1 ? (
          <SuccessPage id="success-page" />
        ) : (
          <Container id="container">
            <form
              id="form"
              onSubmit={
                activeStep === 0
                  ? firstStepForm.handleSubmit(onSubmit)
                  : activeStep === 1
                  ? secondStepForm.handleSubmit(handleEditMethodPayment)
                  : null
              }
            >
              <Grid id="grid-container" container>
                <StyledStepGrid id="step-grid" item>
                  <StyledGrid id="grid-item" item>
                    <Title id="title">การชำระเงิน</Title>
                  </StyledGrid>
                  <StyledStepper
                    id="stepper"
                    alternativeLabel
                    activeStep={activeStep}
                    connector={<StyledConnector />}
                  >
                    {steps.map(label => (
                      <Step id="step" key={label}>
                        <StyledLabel id="label" StepIconComponent={StepIcon}>
                          {label}
                        </StyledLabel>
                      </Step>
                    ))}
                  </StyledStepper>
                  <StyledGrid id="grid-item" item>
                    {getStepContent(activeStep)}
                    <StyledDetailGridMobile id="detail-mb" item>
                      <DetailPrice
                        id="detail-price"
                        choosePackage={choosePackage}
                        activeStep={activeStep}
                      />
                    </StyledDetailGridMobile>
                    <div id="container-div">
                      {activeStep === 0 ? (
                        vmID.length === 0 ? (
                          <BtnFirstStep id="btn-first" disabled={true}>
                            ถัดไป
                          </BtnFirstStep>
                        ) : (
                          <BtnFirstStep
                            id="btn-first"
                            type="submit"
                            disabled={loading}
                          >
                            {loading ? (
                              <CircularProgress
                                id="circular-progress"
                                size={25}
                                thickness={4}
                                style={{ color: "white" }}
                              />
                            ) : (
                              " ถัดไป"
                            )}
                          </BtnFirstStep>
                        )
                      ) : imageTransfer.raw === "" &&
                        imagePromptpay.raw === "" ? (
                        // image.raw.length === 0 ? (
                        <Grid id="grid-container" container spacing={1}>
                          <Grid id="grid-item" item xs={6}>
                            <BtnBackStep id="back-btn" onClick={handleBack}>
                              ย้อนกลับ
                            </BtnBackStep>
                          </Grid>
                          <Grid id="grid-item" item xs={6}>
                            <BtnNextStep id="next-btn" disabled={true}>
                              ยืนยัน
                            </BtnNextStep>
                          </Grid>
                        </Grid>
                      ) : (
                        <Grid id="grid-container" container spacing={1}>
                          <Grid id="grid-item" item xs={6}>
                            <BtnBackStep id="back-btn" onClick={handleBack}>
                              ย้อนกลับ
                            </BtnBackStep>
                          </Grid>
                          <Grid id="grid-item" item xs={6}>
                            <BtnNextStep
                              id="next-btn"
                              type="submit"
                              disabled={loading}
                            >
                              {loading ? (
                                <CircularProgress
                                  id="circular-progress"
                                  size={25}
                                  thickness={4}
                                  style={{ color: "white" }}
                                />
                              ) : (
                                "ยืนยัน"
                              )}
                            </BtnNextStep>
                          </Grid>
                        </Grid>
                      )}
                    </div>
                  </StyledGrid>
                </StyledStepGrid>
                <StyledDetailGrid id="detail-grid" item>
                  <DetailPrice
                    id="detail-price"
                    choosePackage={choosePackage}
                    activeStep={activeStep}
                  />
                </StyledDetailGrid>
              </Grid>
            </form>
          </Container>
        )}
      </Root>
      <Footer props="nothomepage" />
    </div>
  );
}
