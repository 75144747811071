import React, { useEffect } from "react";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import colors from "../common/Color";

const Root = styled.div`
  padding-right: 18%;
  @media (max-width: 1024px) {
    padding: 0;
  }
`;

const LineActive = styled.div`
  height: 3.94rem;
  width: 0.25rem;
  background: #3b54d4;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
`;

const TitleFrame = styled.div`
  height: 3.94rem;
  margin-bottom: 1.13rem;
  display: flex;
  align-items: center;
  user-select: none;
  @media (max-width: 1024px) {
    display: none;
  }
  &:hover {
    background: ${colors.surfaceGray};
    border-radius: 4px;
    transition: 0.3s;
  }
  cursor: pointer;
`;

const TitleFrameActive = styled.div`
  height: 3.94rem;
  margin-bottom: 1.13rem;
  display: flex;
  align-items: center;
  user-select: none;
  background: ${colors.surfaceGray};
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  @media (max-width: 1024px) {
    display: none;
  }
  cursor: pointer;
`;

const TitleChoose = styled.div`
  display: none;
  @media (max-width: 1024px) {
    display: block;
    width: 100%;
    margin-bottom: 2rem;
  }
`;

const TextActive = styled.div`
  font-family: "Sarabun-Regular";
  font-size: 1rem;
  color: #3b54d4;
  letter-spacing: 0.5px;
  text-align: left;
  line-height: 28px;
  padding-left: 1.25rem;
  cursor: pointer;
`;

const TextInActive = styled.div`
  opacity: 0.87;
  font-family: "Sarabun-Regular";
  font-size: 1rem;
  color: #000000;
  letter-spacing: 0.5px;
  text-align: left;
  line-height: 28px;
  padding-left: 1.5rem;
  ${TitleFrame}:hover & {
    color: #3b54d4;
    opacity: 1;
    transition: 0.2s;
  }
  cursor: pointer;
`;

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}));

export default function FAQTitle(props) {
  const { title, setTitleData } = props;
  const classes = useStyles();
  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);

  useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  const handleChange = event => {
    setTitleData(event.target.value);
  };

  return (
    <Root id="root">
      <TitleChoose id="title-choose">
        <FormControl
          id="form-control"
          variant="outlined"
          className={classes.formControl}
          style={{ width: "100%", margin: "0" }}
        >
          <InputLabel ref={inputLabel} id="demo-simple-select-outlined-label" />
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={title}
            onChange={handleChange}
            labelWidth={labelWidth}
          >
            <MenuItem id="menu-item" value={1}>
              คำถามทั่วไป
            </MenuItem>
            <MenuItem id="menu-item" value={2}>
              ความปลอดภัย
            </MenuItem>
            <MenuItem id="menu-item" value={3}>
              การใช้งาน VerME
            </MenuItem>
            <MenuItem id="menu-item" value={4}>
              การรายงานผู้ขาย
            </MenuItem>
            <MenuItem id="menu-item" value={5}>
              การชำระเงิน
            </MenuItem>
          </Select>
        </FormControl>
      </TitleChoose>
      {title === 1 ? (
        <TitleFrameActive id="title-active">
          <LineActive id="line-at" />
          <TextActive id="txt-at">คำถามทั่วไป</TextActive>
        </TitleFrameActive>
      ) : (
        <TitleFrame id="title-frame" onClick={() => setTitleData(1)}>
          <TextInActive id="txt-inat">คำถามทั่วไป</TextInActive>
        </TitleFrame>
      )}
      {title === 2 ? (
        <TitleFrameActive id="title-active">
          <LineActive id="line-at" />
          <TextActive id="txt-at">ความปลอดภัย</TextActive>
        </TitleFrameActive>
      ) : (
        <TitleFrame id="title-frame" onClick={() => setTitleData(2)}>
          <TextInActive id="txt-inat">ความปลอดภัย</TextInActive>
        </TitleFrame>
      )}
      {title === 3 ? (
        <TitleFrameActive id="title-active">
          <LineActive id="line-at" />
          <TextActive id="txt-at">การใช้งาน VerME</TextActive>
        </TitleFrameActive>
      ) : (
        <TitleFrame id="title-frame" onClick={() => setTitleData(3)}>
          <TextInActive id="txt-inat">การใช้งาน VerME</TextInActive>
        </TitleFrame>
      )}
      {title === 4 ? (
        <TitleFrameActive id="title-active">
          <LineActive id="line-at" />
          <TextActive id="txt-at">การรายงานผู้ขาย</TextActive>
        </TitleFrameActive>
      ) : (
        <TitleFrame id="title-frame" onClick={() => setTitleData(4)}>
          <TextInActive id="txt-inat">การรายงานผู้ขาย</TextInActive>
        </TitleFrame>
      )}
      {title === 5 ? (
        <TitleFrameActive id="title-active">
          <LineActive id="line-at" />
          <TextActive id="txt-at">การชำระเงิน</TextActive>
        </TitleFrameActive>
      ) : (
        <TitleFrame id="title-frame" onClick={() => setTitleData(5)}>
          <TextInActive id="txt-inat">การชำระเงิน</TextInActive>
        </TitleFrame>
      )}
    </Root>
  );
}
