import React from "react";
import { Helmet } from "react-helmet"

const MetaDecorator = ({ title, description, imageUrl, imageAlt }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta property="og:title" content={title} />
      <meta name="description" content={description} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={imageUrl} />
      <meta
        property="og:url"
        content={
          window.location.hostname + window.location.pathname
        }
      />
      <meta property="fb:app_id" content="497415160821661"/>
      <meta property="twitter:card" content={imageUrl} />
      <meta property="twitter:url" content={window.location.hostname + window.location.pathname} />
      <meta property="twitter:title" content={title} />
      <meta
        property="twitter:description"
        content="เราจะช่วยให้การซื้อขายบนโซเชียลมีเดียของคุณ
    มีความมั่นใจและปลอดภัยมากยิ่งขึ้น"
      />
    </Helmet>
  );
};

export default MetaDecorator;
