import React, { useState } from "react";
import styled from "styled-components";
import colors from "../common/Color";
import { Root, Logo, Header } from "../common/FormHeader";
import { sendEmailResetPassword } from "../actions/action";
import Title from "../common/Title";
import { ReactComponent as EmailIcon } from "../assets/Icons/ic_mail 3.svg";
import Button from "../common/WebButton";
import Snackbar from "@material-ui/core/Snackbar";
import ErrorModal from "../common/ErrorModal";
import MetaDecorator from "../MetaDecorator/MetaDecorator";

const Container = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 500px) {
    width: 100vw;
    margin: 2rem 5vw 0 5vw;
  }
`;
const FlexCenter = styled.div`
  display: flex;
  justify-content: center;
`;
const StyledTitle = styled(Title)`
  && {
    text-align: center;
  }
`;
const EmailIc = styled(EmailIcon)`
  width: 9rem;
  height: 6.65rem;
  margin: 2rem 0;
`;
const Text = styled.span`
  font-family: "Sarabun-Regular";
  font-size: 1rem;
  color: ${props => (props.email ? colors.primary : "rgba(0,0,0,0.6)")};
  letter-spacing: 0.03rem;
  line-height: 1.75rem;
  text-align: center;
`;
const TextDiv = styled.div`
  margin: 0 auto;
  @media (max-width: 500px) {
    display: none;
  }
`;
const TextDivMob = styled.div`
  margin: 0 auto;
  @media (min-width: 501px) {
    display: none;
  }
`;
const EmailDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 500px) {
    display: block;
  }
`;
const Btn = styled(Button)`
  && {
    width: 24rem;
    height: 2.75rem;
    margin-top: 2rem;
    @media (max-width: 500px) {
      width: 100%;
    }
  }
`;
const SendAgainDiv = styled.div`
  margin-top: 1.5rem;
  display: flex;
  @media (max-width: 1025px) {
    margin-bottom: 6rem;
  }
`;
const SendAgainTxt = styled.a`
  font-family: "Sarabun-Regular";
  font-size: 16px;
  color: ${props => (props.primary ? colors.primary : "rgba(0,0,0,0.6)")};
  letter-spacing: 0.03rem;
  text-align: center;
  line-height: 1.75rem;
  cursor: ${props => (props.primary ? "pointer" : "auto")};
  text-decoration: none;
`;
export default function PleaseCheckEmailPage(props) {
  const { email } = props;
  const [errorOpen, setErrorOpen] = useState(false);
  const [textMessage, setTextMessage] = useState("");
  const [openSnack, setOpenSnack] = useState(false);
  const handleErrorClose = () => {
    setErrorOpen(false);
  };

  const handleCloseSnackbar = () => {
    setOpenSnack(false);
  };

  const InboxPath = email => {
    if (email.search("@gmail.") > 1) {
      return "https://mail.google.com/mail/u/0/#inbox";
    } else if (email.search("@hotmail.") > 1 || email.search("@outlook.") > 1) {
      return "https://outlook.live.com/mail/0/inbox";
    } else if (email.search("@yahoo.") > 1) {
      return "https://mail.yahoo.com";
    } else {
      return "https://mail.google.com/mail/u/0/#inbox";
    }
  };

  async function sendEmailAgain() {
    let formData = new FormData();
    formData.append("email", email);
    try {
      const res = await sendEmailResetPassword(formData);
      if (res.status === 201) {
        setOpenSnack(true);
      }
    } catch (err) {
      if (err.response) {
        if (err.response.status === 400) {
          setTextMessage(err.response.error);
          setErrorOpen(true);
        }
      }
      console.log(err);
    }
  }
  return (
    <div id="root-div">
      <MetaDecorator
        description="มั่นใจทุกการขาย สบายใจทุกการซื้อ"
        title= "ตรวจสอบอีเมล"
        // imageUrl={metaThumbnail}
        // imageAlt={blog.metaImageAlt}
      />
      <ErrorModal
        id="err-modal"
        textMessage={textMessage}
        handleErrorClose={handleErrorClose}
        errorOpen={errorOpen}
      />
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={openSnack}
        onClose={handleCloseSnackbar}
        message="อีเมลถูกส่งแล้ว"
        action={
          <Button color="secondary" size="small" onClick={handleCloseSnackbar}>
            X
          </Button>
        }
      />
      <Root id="head-root">
        <Header id="header">
          <Logo id="logo" />
        </Header>
      </Root>
      <FlexCenter id="flex-center">
        <Container id="container">
          <EmailIc id="email-ic" />
          <StyledTitle id="title">ตรวจสอบกล่องข้อความ</StyledTitle>
          <TextDiv id="content">
            <EmailDiv id="email-div">
              <Text id="txt">เราได้ส่งลิงก์สำหรับการเปลี่ยนรหัสผ่านไปที่ </Text>
              <Text id="txt" email="true">
                {email ? email : ""}
              </Text>
            </EmailDiv>
            <Text id="txt">แล้วกรุณาตรวจสอบกล่องข้อความของคุณ</Text>
          </TextDiv>
          <TextDivMob id="txt-mob">
            <Text id="txt">
              เราได้ส่งลิงก์สำหรับการเปลี่ยนรหัสผ่าน <br />
              ไปที่ &nbsp;
            </Text>
            <Text id="txt" email="true">
              {email ? email : ""}
            </Text>
            <Text id="txt">
              แล้ว <br />
              กรุณาตรวจสอบกล่องข้อความของคุณ
            </Text>
          </TextDivMob>
          <Btn
            id="btn"
            primary="true"
            href={InboxPath(email ? email : "")}
            target="_blank"
            rel="noopener noreferrer"
          >
            เปิดกล่องข้อความ
          </Btn>
          <SendAgainDiv id="sendagain-div">
            <SendAgainTxt id="sendagain-txt">
              ไม่ได้รับอีเมล? &nbsp;
            </SendAgainTxt>
            <SendAgainTxt
              id="sendagain-txt-primary"
              primary
              onClick={sendEmailAgain}
            >
              ส่งอีเมลอีกครั้ง
            </SendAgainTxt>
          </SendAgainDiv>
        </Container>
      </FlexCenter>
    </div>
  );
}
