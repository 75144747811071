import React from "react";
import styled from "styled-components";
import NavBar from "../common/NavBar";
import NavMobile from "../common/NavMobile";
import Footer from "../common/FooterBar";
import HowToVid from "../assets/Video/Sell-how.mp4";
import IdenVid from "../assets/Video/Identity-how.mp4";

const Root = styled.div`
  padding: 8rem 10% 16rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const HeaderContent = styled.div`
  font-family: "SukhumvitSet-Text";
  font-size: 2.13rem;
  color: #3b54d4;
  letter-spacing: 0.25px;
  text-align: left;
  width: 100%;
  max-width: 57rem;
  margin-bottom: 3.88rem;
  @media (max-width: 1024px) {
    margin-bottom: 2rem;
  }
`;

const ContentData = styled.div`
  width: 100%;
  max-width: 57rem;
  display: flex;
  justify-content: center;
  margin-bottom: 7.5rem;
  @media (max-width: 1024px) {
    display: block;
    margin-bottom: 4rem;
  }
`;

const ContentVideo = styled.div`
  width: 60%;
  /* height: 21rem; */
  @media (max-width: 1024px) {
    width: 100%;
  }
`;

const ContentTextRight = styled.div`
  padding-left: 2.25rem;
  width: 40%;
  @media (max-width: 1024px) {
    width: 100%;
    padding: 0;
  }
`;

const ContentTextLeft = styled.div`
  padding-right: 2.25rem;
  width: 40%;
  @media (max-width: 1024px) {
    width: 100%;
    padding: 0;
  }
`;

const TextHeader = styled.div`
  opacity: 0.87;
  font-family: "SukhumvitSet-Text";
  font-size: 1.5rem;
  color: #000000;
  @media (max-width: 1024px) {
    margin-top: 1.5rem;
  }
`;

const SecondVidDiv = styled.div`
  display: ${props => (props.desktop ? "block" : "none")};
  @media (max-width: 1024px) {
    display: ${props => (props.desktop ? "none" : "block")};
  }
`;
const TextContent = styled.div`
  opacity: 0.6;
  font-family: "Sarabun-Regular";
  font-size: 1rem;
  color: #000000;
  letter-spacing: 0.5px;
  text-align: left;
  line-height: 28px;
`;

export default function HowTo() {
  return (
    <div id="root-div">
      <NavBar id="navbar" props="howto" />
      <NavMobile id="nav-mb" />
      <Root id="root">
        <HeaderContent id="header">วิธีใช้งาน VerME</HeaderContent>
        <ContentData id="content-data">
          <ContentVideo id="ct-video">
            <video id="video" width="100%" height="100%" controls>
              <source id="vid-src" src={HowToVid} type="video/mp4" />
            </video>
          </ContentVideo>
          <ContentTextRight id="ct-txt-right">
            <TextHeader id="header-txt">
              การนำ VerME ไปใช้สำหรับผู้ขาย
            </TextHeader>
            <TextContent id="ct-txt">
              ตัวอย่างวิธีการใช้บัตร VerME คู่กับการลงขายของ ของผู้ขายของบน
              Social Media : เป็นวิดีโอที่ใช้สำหรับสอนใช้บัตร VerME
              สำหรับบุคคลที่จะนำบัตรไปใช้ขายของบน Social Media
            </TextContent>
          </ContentTextRight>
        </ContentData>
        <SecondVidDiv id="sec-vid-div" desktop="true">
          <ContentData id="content-data">
            <ContentTextLeft id="ct-txt-left">
              <TextHeader id="header-txt">การตรวจสอบตัวตนผู้ขาย</TextHeader>
              <TextContent id="ct-txt">
                ตัวอย่างการตรวจสอบตัวตนผู้ขาย :
                เป็นวิดีโอที่ใช้สำหรับสอนวิธีการตรวจสอบและเป็นตัวอย่างในการตรวจสอบตัวตนผู้ขาย
                สำหรับบุคคลที่ยังไม่เข้าใจอยู่ว่า ฟังก์ชันตรวจสอบของ VerME
                เป็นอย่างไร
              </TextContent>
            </ContentTextLeft>
            <ContentVideo id="ct-video">
              <video id="video" width="100%" height="100%" controls>
                <source id="vid-src" src={IdenVid} type="video/mp4" />
              </video>
            </ContentVideo>
          </ContentData>
        </SecondVidDiv>
        <SecondVidDiv id="sec-vid-div">
          <ContentData id="content-data">
            <ContentVideo id="ct-video">
              <video id="video" width="100%" height="100%" controls>
                <source id="vid-src" src={IdenVid} type="video/mp4" />
              </video>
            </ContentVideo>
            <ContentTextLeft id="ct-txt-left">
              <TextHeader id="header-txt">การตรวจสอบตัวตนผู้ขาย</TextHeader>
              <TextContent id="ct-txt">
                ตัวอย่างการตรวจสอบตัวตนผู้ขาย :
                เป็นวิดีโอที่ใช้สำหรับสอนวิธีการตรวจสอบและเป็นตัวอย่างในการตรวจสอบตัวตนผู้ขาย
                สำหรับบุคคลที่ยังไม่เข้าใจอยู่ว่า ฟังก์ชันตรวจสอบของ VerME
                เป็นอย่างไร
              </TextContent>
            </ContentTextLeft>
          </ContentData>
        </SecondVidDiv>
      </Root>
      <Footer id="footer" props="nothomepage" />
    </div>
  );
}
