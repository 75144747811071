import React, { useContext } from "react";
import Title from "../common/Title";
import SubTitle from "./SubTitle";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import InputAdornment from "@material-ui/core/InputAdornment";
// import NumberFormat from "react-number-format";
import ErrorMessage from "../common/ErrorMessage";
import StyledTextField from "../common/StyledTextField";
import IconAdorn from "../common/IconAdornment";
import { RegisterContext } from "../Context/RegisterStore";

const Prefix = ["นาย", "นาง", "นางสาว"];

export default function Contact(props) {
  const { prefix, name, surname, email, phone, checkErrorPhone } = useContext(
    RegisterContext
  );

  return (
    <Grid id="root" container justify="flex-start">
      <Grid id="grid-title" item xs={12}>
        <Title id="title">ข้อมูลติดต่อกลับ</Title>
      </Grid>
      <Grid id="grid-subtitle" item xs={12}>
        <SubTitle id="subtitle" />
      </Grid>
      <Grid id="grid-container" container spacing={2}>
        <Grid id="grid-item" item xs={12}>
          <StyledTextField
            id="prefix"
            select
            label="คำนำหน้า"
            value={prefix[0]}
            onChange={e => prefix[1](e.target.value)}
            margin="normal"
            variant="outlined"
            style={{ width: "7.31rem" }}
            InputProps={{
              startAdornment: (
                <InputAdornment id="adornment" position="end">
                  <IconAdorn id="adorn-ic" disabled />
                </InputAdornment>
              )
            }}
          >
            {Prefix.map(option => (
              <MenuItem id="menu-item" key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </StyledTextField>
        </Grid>
        <Grid id="container-item" container item spacing={2}>
          <Grid id="grid-item" item md={6} xs={12}>
            <StyledTextField
              id="firstName"
              name="firstName"
              label="ชื่อ"
              inputRef={props.formProps.register}
              error={!!props.formProps.errors.firstName}
              placeholder="ชื่อ"
              margin="normal"
              defaultValue={name[0]}
              variant="outlined"
              onChange={e => name[1](e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment id="adornment" position="end">
                    <IconAdorn id="disabled-ic" disabled />
                  </InputAdornment>
                )
              }}
            />
            {props.formProps.errors.firstName && (
              <ErrorMessage id="err-msg">
                {props.formProps.errors.firstName.message}
              </ErrorMessage>
            )}
          </Grid>
          <Grid id="grid-item" item md={6} xs={12}>
            <StyledTextField
              id="lastName"
              name="lastName"
              inputRef={props.formProps.register}
              error={!!props.formProps.errors.lastName}
              label="นามสกุล"
              placeholder="นามสกุล"
              margin="normal"
              defaultValue={surname[0]}
              variant="outlined"
              onChange={e => surname[1](e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment id="adornment" position="end">
                    <IconAdorn id="disabled-ic" disabled />
                  </InputAdornment>
                )
              }}
            />
            {props.formProps.errors.lastName && (
              <ErrorMessage id="err-msg">
                {props.formProps.errors.lastName.message}
              </ErrorMessage>
            )}
          </Grid>
        </Grid>
        <Grid id="container-item" container item spacing={2}>
          <Grid id="grid-item" item md={6} xs={12}>
            <StyledTextField
              id="email"
              name="email"
              inputRef={props.formProps.register}
              error={!!props.formProps.errors.email}
              label="อีเมล"
              placeholder="example@example.com"
              margin="normal"
              variant="outlined"
              onChange={e => email[1](e.target.value)}
              defaultValue={email[0]}
              InputProps={{
                startAdornment: (
                  <InputAdornment id="adornment" position="end">
                    <IconAdorn id="disabled-ic" disabled />
                  </InputAdornment>
                )
              }}
            />
            {props.formProps.errors.email && (
              <ErrorMessage id="err-msg">
                {props.formProps.errors.email.message}
              </ErrorMessage>
            )}
          </Grid>
          <Grid id="grid-item" item md={6} xs={12}>
            <StyledTextField
              // customInput={StyledTextField}
              id="phone"
              name="phone"
              inputRef={props.formProps.register}
              error={!!props.formProps.errors.phone}
              label="เบอร์โทรศัพท์มือถือ"
              placeholder="เบอร์โทรศัพท์มือถือ 10 หลัก"
              margin="normal"
              variant="outlined"
              onChange={e => phone[1](e.target.value)}
              defaultValue={phone[0]}
              // format="### ### ####"
              InputProps={{
                startAdornment: (
                  <InputAdornment id="adornment" position="end">
                    <IconAdorn id="disabled-ic" disabled />
                  </InputAdornment>
                )
              }}
              inputProps={{
                maxLength: 10
              }}
            />
            {props.formProps.errors.phone && (
              <ErrorMessage id="err-msg">
                {props.formProps.errors.phone.message}
              </ErrorMessage>
            )}
            {checkErrorPhone[0] && (
              <ErrorMessage id="err-msg">
                กรุณากรอกเบอร์โทรศัพท์มือถือให้ครบถ้วน
              </ErrorMessage>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
